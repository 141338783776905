import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import CheckIcon from '../images/icons/CheckIcon';
import arrowDown from '../images/thinArrowDown.svg';
import { Colors } from '../themes/colors';

const opacityVariants = {
  show: { opacity: 1, display: 'flex' },
  hide: { opacity: 0, transitionEnd: { display: 'none' } },
};

export default function InputSelect({
  value: inputValue,
  setValue,
  options = [],
  disabled = false,
  isSelectVisible = true,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const inputRef = useRef(null);
  const selectOption = isSelectVisible ? [{ title: 'Select', value: '' }] : [];
  const handleClickOutside = useCallback((event) => {
    if (inputRef.current && !inputRef.current?.contains(event.target)) {
      setShowOptions(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div ref={inputRef} style={{ position: 'relative', width: '100%' }}>
      <div
        className="select-title"
        style={{
          alignItems: 'center',
          width: '100%',
          height: 42,
          backgroundColor: Colors.neutral_50,
          border: 'none',
          borderRadius: 5,
          appearance: 'none',
          background: `url('${arrowDown}') no-repeat center #F4F8FA`,
          backgroundPosition: 'center right 20px',
          ...(disabled && { backgroundColor: Colors.neutral_150 }),
        }}
      >
        <button
          type="button"
          disabled={disabled}
          style={{
            all: 'unset',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
          }}
          onClick={() => setShowOptions(true)}
        >
          <input
            placeholder="Select"
            readOnly
            style={{
              fontFamily: 'Nunito',
              margin: '0 60px 0 20px',
              fontSize: 16,
              padding: 0,
              width: '100%',
              maxWidth: '-webkit-fill-available',
              boxShadow: 'none',
              color: Colors.neutral_600,
              background: 'transparent',
              cursor: 'pointer',
            }}
            value={
              [{ title: '', value: '' }, ...options]?.find(({ value }) => value === inputValue)
                ?.title
            }
          />
        </button>
      </div>

      <motion.div
        initial="hide"
        transition={{ duration: 0.1 }}
        animate={showOptions ? 'show' : 'hide'}
        variants={opacityVariants}
        className="select-options"
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          overflow: 'auto',
          maxHeight: 240,
          top: 10,
          zIndex: 4,
          minWidth: '100%',
          whiteSpace: 'nowrap',
          backgroundColor: Colors.neutral_150,
          borderRadius: 10,
          boxShadow: '0px 6px 10px 2px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
          padding: '10px 0px',
        }}
      >
        {[...selectOption, ...options]?.map(({ title, value }) => (
          <motion.div
            key={value}
            onHoverStart={() => setHoveredItem(value)}
            onHoverEnd={() => setHoveredItem(null)}
            onClick={() => {
              setShowOptions(false);
              setValue(value);
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: hoveredItem === value ? Colors.secondary_500 : null,
              borderRadius: 8,
              margin: '0px 10px',
            }}
          >
            {inputValue === value ? (
              <div style={{ with: 24, height: 24, margin: '6px 8px' }}>
                <CheckIcon color={hoveredItem === value ? Colors.white : Colors.neutral_600} />
              </div>
            ) : (
              <div style={{ width: 24, height: 24, margin: '6px 8px' }} />
            )}

            <span
              style={{
                fontFamily: 'Nunito',
                fontWeight: inputValue === value || hoveredItem === value ? 'bold' : 'normal',
                fontSize: 16,
                color: hoveredItem === value ? Colors.white : Colors.neutral_600,
                paddingRight: 20,
              }}
            >
              {title}
            </span>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}
