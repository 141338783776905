import { getValue } from "firebase/remote-config";
import { remoteConfig } from "../api/firebaseConfig";

/**
 * Keys for Firebase Remote Config keys.
 * Can be used when calling {@link remoteConfig}'s `getValue()`
 *
 * @enum {string}
 */
const RemoteConfigKeys = {
  allowedWcStates: 'ALLOWED_WC_STATES',
  stateToDisableReferralProgram: 'stateToDisableReferralProgram',
};

export const RemoteConfigUtils = {
  getReferralUnavailableStates() {
    const stateToDisableReferralProgram = getValue(remoteConfig, RemoteConfigKeys.stateToDisableReferralProgram)?.asString()?.replace(/\s+/g, '');

    return stateToDisableReferralProgram?.split(',');
  },

  getAllowedWcStates() {
    return getValue(remoteConfig, RemoteConfigKeys.allowedWcStates).asString();
  },
};
