import { motion } from 'framer-motion';
import checkSent from '../../images/check-sent.svg';
import HeartIcon from '../../images/icons/Heart';
import { Colors } from '../../themes/colors';

export const ProfessionalRow = ({ item, onSelect, selected }) => (
  <motion.div
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0 }}
    layout
    onClick={() => onSelect(item)}
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 0px',
      borderBottom: `1px solid ${Colors.neutral_100}`,
      cursor: item?.inviteSent ? 'auto' : 'pointer',
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
      <div
        style={{
          position: 'relative',
          border: `2px solid ${item?.isFavorite ? Colors.favorites_400 : 'transparent'}`,
          width: 65,
          height: 65,
          padding: 2,
          borderRadius: '100%',
        }}
      >
        <img
          src={item.profileImage}
          style={{
            width: 65,
            height: 65,
            borderRadius: '100%',
            marginRight: 13,
          }}
          alt="profile_image"
        />
        {item?.isFavorite && (
          <div style={{ position: 'absolute', top: '75%', right: 0 }}>
            <HeartIcon color={Colors.favorites_400} />
          </div>
        )}
      </div>

      <div>
        <div
          style={{
            color: Colors.neutral_600,
            fontSize: 20,
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 280,
          }}
        >
          {item.fullname}
        </div>

        <div
          style={{
            color: Colors.neutral_500,
            fontSize: 14,
            fontFamily: 'Nunito',
          }}
        >
          {item.isAvailable ? item?.profession : 'Currently unavailable'}
        </div>
      </div>
    </div>

    {item?.inviteSent ? (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: 5,
        }}
      >
        <img src={checkSent} style={{ width: 24, height: 24 }} alt="check_sent" />

        <p
          style={{
            color: Colors.neutral_400,
            fontSize: 14,
            fontFamily: 'Nunito',
          }}
        >
          Sent
        </p>
      </motion.div>
    ) : (
      <motion.svg
        animate={selected ? 'checked' : 'unchecked'}
        initial="unchecked"
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
      >
        <motion.rect
          x="1"
          y="1"
          width="18"
          height="18"
          rx="9"
          stroke={Colors.secondary_500}
          strokeWidth="2"
        />
        <motion.circle
          cx="10"
          cy="10"
          r={10}
          fill={Colors.secondary_500}
          variants={circleVariants}
        />

        <motion.path
          d="M6.5 10.5L8.86667 13L14 8"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDashoffset={20}
          strokeDasharray={40}
          variants={tickVariants}
        />
      </motion.svg>
    )}
  </motion.div>
);

const circleVariants = {
  checked: {
    opacity: 1,
  },
  unchecked: {
    opacity: 0,
  },
};

const tickVariants = {
  checked: {
    opacity: 1,
    strokeDashoffset: 20,
  },
  unchecked: {
    opacity: 0,
    strokeDashoffset: 50,
  },
};
