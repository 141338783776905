import { professionSkillMap, skillCategory } from '../constants';

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function isPartialShift(job) {
  if (!job || !job.originalBegin || !job.begin || !job.originalEnd || !job.end) {
    return false;
  }

  const originalBegin = new Date(job.originalBegin);
  const currentBegin = new Date(job.begin);
  const originalEnd = new Date(job.originalEnd);
  const currentEnd = new Date(job.end);

  return (
    originalBegin.getTime() !== currentBegin.getTime()
    || originalEnd.getTime() !== currentEnd.getTime()
  );
}

export const validatePhone = (phone) => {
  const cleanedNumber = phone.replace(/\D/g, '');

  const phoneRegex = /^(?:\d{1}\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;

  return phoneRegex.test(cleanedNumber);
};

export const extractSkills = (allSkillCategories, job, candidate) => {
  /**
   * Speciality Skills
   */
  const allSpecialtySkills = allSkillCategories
    ?.filter(
      ({ code, professional }) => Object.values(professionSkillMap).includes(code)
        && professional?.includes(job?.profession),
    )
    ?.map(({ skills }) => skills)
    ?.flat();

  const userSpecialtySkills = candidate?.skills
    ?.map((skill) => allSpecialtySkills?.find(({ code }) => code === skill.code))
    ?.filter((skill) => skill);

  const jobSpecialtySkills = allSpecialtySkills
    ?.filter((specialty) => job?.skills?.some((skill) => skill?.code === specialty?.code));

  /**
   * Insurance Providers
   */
  const allInsurances = allSkillCategories
    ?.find((category) => category?.code === skillCategory.INSURANCE_PROVIDER)?.skills ?? [];

  const userInsurances = candidate?.skills
    ?.map((skill) => allInsurances?.find(({ code }) => code === skill.code))
    ?.filter((skill) => skill);

  const jobInsurances = allInsurances
    .filter((insurance) => job?.skills?.some((skill) => skill?.code === insurance?.code));

  /**
   * Other Skills
   */
  const allOtherSkills = allSkillCategories
    ?.find(({ code }) => code === skillCategory.SKILLS)?.skills;

  const userOtherSkills = candidate?.skills
    ?.map((skill) => allOtherSkills?.find(({ code }) => code === skill.code))
    ?.filter((skill) => skill);

  return {
    allSpecialtySkills,
    userSpecialtySkills,
    jobSpecialtySkills,
    allInsurances,
    userInsurances,
    jobInsurances,
    allOtherSkills,
    userOtherSkills,
  };
};

export function getAverageRating(ratings) {
  if (!ratings || !Array.isArray(ratings)) {
    return null;
  }

  const filteredRatings = ratings.filter((rating) => rating !== null);

  return filteredRatings.length > 0
    ? filteredRatings.reduce((sum, rating) => sum + rating, 0) / filteredRatings.length
    : null;
}
