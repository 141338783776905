import React from 'react';
import { Label } from '../../components/Label';

export const FieldValue = ({
  label, value, setValue, required = false
}) => (
  <div>
    <Label text={label} required={required} />
    <input
      className="input-field"
      style={{ margin: 0, width: 252 }}
      type="text"
      value={value}
      placeholder={label}
      onChange={(e) => {
        const name = e.target.value
          .split(/\s+/)
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
          .join(' ');
        setValue(name);
      }}
    />
  </div>
);
