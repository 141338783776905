import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: process.env.PUBLIC_URL });

let prevLocation = null;

browserHistory.listen((_, action) => {
  if (action === 'PUSH') {
    prevLocation = browserHistory.location;
  }
});

export function getPreviousLocation() {
  return prevLocation;
}

export default browserHistory;
