import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  MULTIDAY_BOOKING_INVITE_POST,
  MULTIDAY_BOOKING_POST,
  cancelJobPosting,
  closeOverlappingModal,
  editJobInfoMdb,
  hideJobCreatePaymentError,
} from '../../../actions';
import useLoading from '../../../hooks/useLoading';

export const useGuidelinesStep = ({ onConfirm }) => {
  const dispatch = useDispatch();
  const [showLeavingPopup, setShowLeavingPopup] = useState(false);
  const { isLoading } = useLoading([MULTIDAY_BOOKING_POST, MULTIDAY_BOOKING_INVITE_POST]);
  const history = useHistory();
  const jobInfo = useSelector((state) => state.multiday.jobInfo);
  const job = useSelector((state) => state.job);
  const showJobCreatePaymentError = useSelector((state) => state.job.showJobCreatePaymentError);

  const handleConfirmButton = () => {
    onConfirm();
  };

  const confirmOverlapping = () => {
    for (let i = 0; i < jobInfo.length; i += 1) {
      const jobData = jobInfo[i];
      dispatch(editJobInfoMdb(jobData.localDate, { ...jobData, duplicate: true }));
    }
    dispatch(closeOverlappingModal());
    handleConfirmButton();
  };

  const cancelRequest = () => {
    cancelJobPosting({ timeout: -1, job: jobInfo[0] });
    history.push('/');
  };

  const hidePaymentError = () => {
    history.goBack();
    dispatch(hideJobCreatePaymentError());
  };

  const updatePayment = () => {
    history.replace('/');
    history.push('/dashboard/payment');
    dispatch(hideJobCreatePaymentError());
  };

  return {
    isLoading,
    jobInfo,
    job,
    showLeavingPopup,
    showJobCreatePaymentError,
    setShowLeavingPopup,
    handleConfirmButton,
    confirmOverlapping,
    cancelRequest,
    hidePaymentError,
    updatePayment,
  };
};
