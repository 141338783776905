/* eslint-disable import/prefer-default-export */
import { GrowthBook } from '@growthbook/growthbook-react';
import { LocalStorageStickyBucketService } from '@growthbook/growthbook';

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK_KEY,
  enableDevMode: process.env.REACT_APP_TEMP_MEE_ENV === 'development',
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    console.log('Experiment Viewed', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
  stickyBucketService: new LocalStorageStickyBucketService(),
});


export const shouldUsePaymentService = () => growthbook.isOn('should-use-payment-service-after-pilot')
export const showProfessionalAttendanceBadges = () => growthbook.isOn('show-professional-attendance-badges');
export const showShowWCManagementPage = () => growthbook.isOn("should-display-wc-managment-page")
export const showDecoupledDntEodFlow = () => growthbook.isOn('show-decoupled-dnt-eod-flow');
export const showCandidateOvertime = () => growthbook.isOn('should-use-overtime');
export const showClockInOutFeW2 = () => growthbook.isOn('show-clock-in-out_fe-w2');
export const showClockInOutFe1099 = () => growthbook.isOn('show-clock-in-out_fe-1099');
export const getShouldShowFrontDeskSpecialty = () => growthbook.isOn('should-show-frontdesk-specialty');
export const getShouldOfficeShowEfda = () => growthbook.isOn('should-office-show-efda');
export const getShouldOfficeShowFrontDeskProfession = () => growthbook.isOn('should-office-show-frontdesk-profession');
export const getShouldAllowOfficeToPostProcedure = () => growthbook.isOn('should-allow-office-to-post-procedure');
export const getShouldUsingCredentialServiceEndpoint = () => growthbook.isOn('should-use-credential-service-endpoint');
export const shouldDisplayRebookFeature = () => growthbook.isOn('should-display-rebook-feature');

