import HouseIcon from '../../../../../images/house-icon.svg';
import { Colors } from '../../../../../themes/colors';
import { CollapsibleRow } from '../CollapsibleRow/CollapsibleRow';
import { Title } from '../CollapsibleRow/Title';
import { Value } from '../CollapsibleRow/Value';

export const OfficeRow = ({ value }) => (
  <CollapsibleRow
    disabled
    TitleComponent={() => <Title text="Office:" />}
    ValueComponent={() => (
      <Value
        customStyles={{
          color: Colors.neutral_600,
        }}
        text={value}
      />
    )}
    icon={HouseIcon}
  />
);
