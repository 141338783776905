import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputSelect from '../../../../../commonComponents/InputSelect';
import MailIcon from '../../../../../images/mail-solid.svg';
import { Colors } from '../../../../../themes/colors';
import { RadioOption } from '../../../../registration/components/RadioOption';
import { CollapsibleRow } from '../../../multiday/components/CollapsibleRow/CollapsibleRow';
import { Title } from '../../../multiday/components/CollapsibleRow/Title';
import { Value } from '../../../multiday/components/CollapsibleRow/Value';

const OPTIONS = {
  FAVORITE_PROFESSIONALS: 'Favorite Professionals',
  RECENT_PROFESSIONALS: 'Recent Professionals',
};

export const InviteRow = ({ defaultProfessionalId, onToggle, isOpen, onConfirm }) => {
  const [professional, setProfessional] = useState({
    id: '',
    name: '',
  });
  const [selectedOption, setSelectedOption] = useState('');
  const favoriteProfessionals = useSelector((state) => state.user.favoriteProfessionals);
  const recentProfessionals = useSelector((state) => state.user.recentProfessionals);

  const favoriteProfessionalsMapped = favoriteProfessionals?.map((item) => ({
    title: item?.fullname,
    value: item?.id,
  }));

  const recentProfessionalsMapped = recentProfessionals?.map((item) => ({
    title: item?.professional?.full_name,
    value: item?.professional?.id,
  }));

  const showSelectComponent =
    !selectedOption ||
    (selectedOption === OPTIONS.FAVORITE_PROFESSIONALS &&
      favoriteProfessionalsMapped?.length > 0) ||
    (selectedOption === OPTIONS.RECENT_PROFESSIONALS && recentProfessionalsMapped?.length > 0);

  const options =
    selectedOption === OPTIONS.FAVORITE_PROFESSIONALS
      ? favoriteProfessionalsMapped
      : recentProfessionalsMapped;

  const handleOptionChange = (option) => {
    const selectedProfessional = {
      id: '',
      name: '',
    };
    setSelectedOption(option);
    setProfessional(selectedProfessional);
    onConfirm(selectedProfessional);
  };

  const handleValueChange = (value) => {
    const selectedProfessional = {
      id: value,
      name: [...favoriteProfessionalsMapped, ...recentProfessionalsMapped].find(
        (item) => item.value === value,
      )?.title,
    };

    setProfessional(selectedProfessional);
    onConfirm(selectedProfessional);
    if (isOpen) {
      onToggle();
    }
  };

  useEffect(() => {
    if (defaultProfessionalId && defaultProfessionalId !== professional?.id) {
      const professionalFromFavorites = favoriteProfessionalsMapped.find(
        (prof) => prof.value === defaultProfessionalId,
      );
      const professionalFromRecent = recentProfessionalsMapped.find(
        (prof) => prof.value === defaultProfessionalId,
      );

      let selectedOption = '';
      const selectedProfessional = {
        id: '',
        name: '',
      };
      if (professionalFromFavorites) {
        selectedOption = OPTIONS.FAVORITE_PROFESSIONALS;
        selectedProfessional.id = professionalFromFavorites?.value;
        selectedProfessional.name = professionalFromFavorites?.title;
      } else if (professionalFromRecent) {
        selectedOption = OPTIONS.RECENT_PROFESSIONALS;
        selectedProfessional.id = professionalFromRecent?.value;
        selectedProfessional.name = professionalFromRecent?.title;
      }
      setSelectedOption(selectedOption);
      handleValueChange(selectedProfessional.id);
    }
  }, [defaultProfessionalId, favoriteProfessionalsMapped, recentProfessionalsMapped]);

  return (
    <CollapsibleRow
      isOpen={isOpen}
      onToggle={onToggle}
      TitleComponent={() => <Title text="Invite:" />}
      ValueComponent={() => (
        <Value
          customStyles={{
            color: isOpen || !professional?.id ? Colors.neutral_300 : Colors.neutral_600,
          }}
          text={!isOpen && professional?.id ? professional?.name : 'Professional'}
        />
      )}
      icon={MailIcon}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 40,
          width: '100%',
        }}
      >
        <div>
          {showSelectComponent ? (
            <InputSelect
              value={professional?.id}
              setValue={handleValueChange}
              options={options}
              disabled={selectedOption === ''}
              showDropdownIcon={false}
            />
          ) : (
            <input
              className="input-field"
              placeholder={`No ${
                selectedOption === OPTIONS.FAVORITE_PROFESSIONALS
                  ? OPTIONS.FAVORITE_PROFESSIONALS
                  : OPTIONS.RECENT_PROFESSIONALS
              } Yet`}
              disabled
              style={{ width: 217, height: 42 }}
            />
          )}
        </div>
        <div>
          <div style={{ marginBottom: 10, marginTop: 12 }}>
            <RadioOption
              onClick={() => handleOptionChange(OPTIONS.FAVORITE_PROFESSIONALS)}
              text="Favorite Professionals"
              checked={selectedOption === OPTIONS.FAVORITE_PROFESSIONALS}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <RadioOption
              onClick={() => handleOptionChange(OPTIONS.RECENT_PROFESSIONALS)}
              text="Recent Professionals"
              checked={selectedOption === OPTIONS.RECENT_PROFESSIONALS}
            />
          </div>
        </div>
      </div>
    </CollapsibleRow>
  );
};
