import HeartIcon from '../../../../../images/icons/Heart';
import starIcon from '../../../../../images/star_icon.svg';
import { Colors } from '../../../../../themes/colors';

export const Header = ({ fullName, imageSrc, rating, professionName, isFavorite }) => (
  <div
    style={{
      display: 'flex',
      marginLeft: 12,
      marginTop: 8,
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...(isFavorite && {
          border: `3px solid ${Colors.favorites_400}`,
          borderRadius: 100,
          padding: 3,
        }),
        width: 75,
        height: 75,
        marginRight: 10,
      }}
    >
      <img
        src={imageSrc}
        style={{
          width: 75,
          height: 75,
          borderRadius: 100,
          objectFit: 'cover',
          border: `1px solid ${Colors.neutral_200}`,
        }}
        alt="profile_image"
      />
      {isFavorite && (
        <div style={{ position: 'absolute', marginTop: 55, marginLeft: 65 }}>
          <HeartIcon color={Colors.favorites_400} />
        </div>
      )}
    </div>

    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{marginLeft: 4}}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4 className="global_font f-dark f-18" style={{ margin: 0 }}>
              {fullName}
            </h4>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={starIcon}
                style={{
                  width: 18,
                  objectFit: 'contain',
                  marginLeft: 8,
                }}
                alt="star_icon"
              />

              <span
                style={{
                  fontFamily: 'Nunito',
                  fontWeight: 700,
                  fontSize: 16,
                  marginLeft: 4,
                  color: Colors.neutral_600,
                }}
              >
                {rating}
              </span>
            </div>
          </div>

          <span className="global_font f-dark f-16">{professionName}</span>
        </div>
      </div>
    </div>
  </div>
);
