import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MULTIDAY_BOOKING_POST,
  getAllProfessionals, getAllSpecializations,
  selectDaysMDB,
} from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import BackButton from '../../../commonComponents/BackButton';
import CardSpinner from '../../../commonComponents/CardSpinner';
import history from '../../../history';
import { Colors } from '../../../themes/colors';
import DatePickersMDB from './DatePickersMDB';
import ProgressBar from './ProgressBar';

const loadingSelector = createLoadingSelector([MULTIDAY_BOOKING_POST]);

export default () => {
  const user = useSelector((state) => state.user.user);
  const isLoading = useSelector((state) => loadingSelector(state));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProfessionals());
    dispatch(getAllSpecializations());
  }, []);

  const handleNextButton = (jobData) => {
    dispatch(selectDaysMDB({ jobData }));
    history.push('/dashboard/multi-day/info');
  };

  if (isLoading) {
    return (
      <CardSpinner />
    );
  }
  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{ justifyContent: 'flex-start', flexDirection: 'column' }}
      >
        <div className="card">
          <div style={{ marginTop: 20, marginLeft: 30 }}>
            <BackButton url="/" />
          </div>
          <div className="card-body" style={{ margin: 30 }}>
            <ProgressBar step={1} />
            <h3
              style={{
                fontFamily: 'Nunito',
                color: Colors.neutral_500,
                textAlign: 'center',
                fontSize: 16,
                fontStyle: 'italic',
                marginTop: 55,
                fontWeight: 400,
                justifyContent: 'center'
              }}
            >
              GoTu offers booking services to help you fill multiple days, weeks, or months of Temp shifts-fast!
              <br />
              Start here by selecting the dates on the calendar you wish to request a temp(s).
            </h3>
            <DatePickersMDB
              nexBtnClicked={(jobData) => handleNextButton(jobData)}
              user={user}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
