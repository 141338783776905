import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideConnectionsHubPopup, showConnectionsHubPopup } from '../../../actions';
import ProfessionalsHub from '../../../images/professionals-hub.svg';
import { Colors } from '../../../themes/colors';
import ConfirmPopup from '../../shared/ConfirmPopup';

export function NewProfessionalsHubPopup() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const showPopup = useSelector((state) => state.user.showConnectionsHubPopup);

  const getStorage = () => {
    const isProfessionalHubNew = localStorage.getItem(
      `isProfessionalHubNew-${user?.id}`,
    );

    if (isProfessionalHubNew === null) {
      dispatch(showConnectionsHubPopup());
    }
  };

  const setStorage = () => {
    localStorage.setItem(`isProfessionalHubNew-${user?.id}`, 'false');
    dispatch(hideConnectionsHubPopup());
  };

  const handleConfirm = () => {
    setStorage();
  };

  useEffect(() => {
    getStorage();
  }, [user]);

  return (
    showPopup && (
      <ConfirmPopup
        Icon={() => (
          <img
            alt="Professionasl Hub Feature"
            src={ProfessionalsHub}
            style={{ width: 224, height: 158, marginBottom: 24 }}
          />
        )}
        title="Welcome to the Professionals Hub!"
        description={
          <>
            This page will display your <b>Recent</b> and<br/><b> Favorite </b>
            professionals. From this page, you can<br />block professionals, add them to your 
            favorites, or bulk-send shift invites!
          </>
        }
        descriptionStyle={{ color: Colors.neutral_500, marginBottom: 32 }}
        rightButtonText="Got it!"
        rightButtonAction={handleConfirm}
        closePopup={handleConfirm}
      />
    )
  );
}
