export const convertSvgToJpeg = (svgUrl, quality = 1) => new Promise((resolve, reject) => {
  const img = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  img.onload = () => {
    try {
      const scaleFactor = quality * (window.devicePixelRatio || 1);
      canvas.width = img.width * scaleFactor;
      canvas.height = img.height * scaleFactor;
      // Draw white background
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);    
      // Draw SVG image
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      // Convert canvas to JPEG blob
      canvas.toBlob(blob => {
        const url = URL.createObjectURL(blob);
        resolve({ blob, url });  // Resolve with both the blob and its URL
      }, 'image/jpeg', quality);
    } catch (err) {
      reject(err);  // Catch errors related to canvas operations
    }
  };
  img.onerror = reject;  // Directly reject on image load errors
  img.src = svgUrl;  // Start the image loading process
});