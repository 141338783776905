import '../../../themes/global.scss';
import '../../../themes/home.scss';

import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_JOB_FROM_MDB, deleteJobFromMDB, selectDaysMDB } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import BackButton from '../../../commonComponents/BackButton';
import Button from '../../../commonComponents/Button';
import CardSpinner from '../../../commonComponents/CardSpinner';
import history from '../../../history';
import BlockCrossIcon from '../../../images/icons/BlockCrossIcon';
import WarningIcon from '../../../images/icons/Warning';
import { Colors } from '../../../themes/colors';
import ConfirmPopup from '../../shared/ConfirmPopup';
import JobMDBCard from './JobMDBCard';
import ProgressBar from './ProgressBar';
import { handleConfirmRemoveDate, isDailyOvertime } from './utils';

const loadingSelector = createLoadingSelector([DELETE_JOB_FROM_MDB]);

export default () => {
  const dispatch = useDispatch();
  const [showDeleted, setShowDeleted] = useState(false);
  const [deletedJob, setDeletedJob] = useState(null);
  const jobDates = useSelector((state) => state.multiday.jobDates);
  const user = useSelector((state) => state.user.selectedChildOffice);
  const workerClassificationType = user?.workerClassification?.classificationType;
  const overtimeLimits = user?.overtimeLimits?.[workerClassificationType];
  const jobInfo = useSelector((state) => state.multiday.jobInfo);
  const isLoading = useSelector((state) => loadingSelector(state));
  const hasOvertimeDay = jobInfo.some((job) => isDailyOvertime(job, overtimeLimits?.daily));
  const [showOvertimeLimitPopup, setShowOvertimeLimitPopup] = useState(false);

  const nexBtnClicked = ({ alertOvertime = true }) => {
    if (alertOvertime && overtimeLimits && hasOvertimeDay) {
      setShowOvertimeLimitPopup(true);
      return;
    }

    history.push('/dashboard/multi-day/guidelines');
  };
  const editPressed = (editedDate) => {
    history.push('/dashboard/multi-day/info', { editedDate });
  };

  const confirmRemoveDate = () => {
    const jobData = handleConfirmRemoveDate(jobDates, deletedJob);

    dispatch(selectDaysMDB({ jobData }));
    dispatch(deleteJobFromMDB({ deletedJob }));
  };

  const deletePressed = (jobDate) => {
    setDeletedJob(jobDate);
    setShowDeleted(true);
  };

  if (!jobInfo?.length) {
    history.push('/dashboard/multi-day/info');
    return null;
  }

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{ flexDirection: 'column', justifyContent: 'flex-start' }}
      >
        <div style={{ marginTop: 20, marginLeft: 30 }}>
          <BackButton />
        </div>
        <ProgressBar step={2} />
        <motion.div
          layout
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'grid',
            gridTemplateColumns: jobInfo?.length > 1 ? '368px 368px' : '368px',
            gridGap: 12,
            alignSelf: 'center',
            marginTop: 55,
          }}
        >
          <AnimatePresence>
            {jobInfo?.map((job) => (
              <JobMDBCard
                key={job.localDate}
                job={job}
                editHasBeenClicked={(data) => editPressed(data)}
                deleteJobPressed={(id) => deletePressed(id)}
              />
            ))}
          </AnimatePresence>
        </motion.div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 120,
            marginTop: 85,
          }}
        >
          <Button
            text="Send Request"
            onClick={nexBtnClicked}
            style={{
              backgroundColor: Colors.primary_500,
            }}
          />
        </div>
      </div>

      {showDeleted && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <BlockCrossIcon />
            </div>
          )}
          title="Delete Shift"
          description={
            <>
              Are you sure you would like to delete the shift for
              {'\n'}
              <strong>{moment(deletedJob).format('ddd, MMM DD, YYYY')}</strong>?
            </>
          }
          descriptionStyle={{ width: '76%', marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonStyle={{
            backgroundColor: Colors.secondary_500,
          }}
          leftButtonAction={() => setShowDeleted(false)}
          rightButtonText="Delete"
          rightButtonAction={() => {
            confirmRemoveDate(jobDates, deletedJob);
            setShowDeleted(false);
          }}
        />
      )}

      {showOvertimeLimitPopup && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={64} height={64} color={Colors.warning_500} />
            </div>
          )}
          title="Overtime Hours"
          description={`
            The payable hours of this shift exceed your state’s daily 
            overtime limit. Your office will be subject to additional
            charges for any overtime hours worked by a professional.
          `}
          descriptionStyle={{ width: '76%', marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonAction={() => setShowOvertimeLimitPopup(false)}
          rightButtonText="Continue"
          rightButtonAction={() => {
            nexBtnClicked({ alertOvertime: false });
            setShowOvertimeLimitPopup(false);
          }}
        />
      )}
    </div>
  );
};
