import { useEffect } from 'react';
import '../../../themes/office_info.scss';

import BackButton from '../../../commonComponents/BackButton';
import { Colors } from '../../../themes/colors';
import { Content } from '../../WorkerClassification/components/Content';
import { ContainerLayout, ContentLayout, Title } from '../components';

export const WorkerClassification = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ContainerLayout>
        <div style={{ position: 'absolute', left: 60, top: 140 }}>
          <BackButton color={Colors.white} url="/registration/office-info" />
        </div>
        <Title text="Worker Type" progress="step4" />
        <ContentLayout isRegistration>
          <Content isRegistration />
        </ContentLayout>
      </ContainerLayout>
    </>
  );
};
