import moment from 'moment';
import React from 'react';
import { Colors } from '../../../../themes/colors';
import { StatusEnum } from '../../../../utils/Enum';
import { getCalendarJobStatus } from '../../utils';
import { Date } from './components/Date';
import { Image } from './components/Image';
import { UserProfession, UserSubProfession } from '../../../../enums/UserProfession';

export const JobCardSmall = ({ job, onCardClick }) => {
  const jobStatus = getCalendarJobStatus(job);

  const Title = () => (
    <>
      {[StatusEnum.today, StatusEnum.scheduled, StatusEnum.completed].includes(jobStatus.label) && (
        <span style={styles.personName}>
          {job.professional[0]?.full_name}
        </span>
      )}
      <span style={styles.professionName}>
        {UserSubProfession.getName(job.subProfession) ?? UserProfession.getName(job.profession)}
      </span>
      <span style={{ ...styles.datetimeText }}>
        {`${job.offerStarts} - ${job.offerEnds}`}
      </span>
      {jobStatus.label === StatusEnum.open && job.autoFill
      && <span style={styles.professionName}>Auto-Confirm: On</span>}
      <span style={{ ...styles.statusText, color: jobStatus.color }}>
        {jobStatus.label}
      </span>
    </>
  );

  return (
    <>
      <button
        style={{
          all: 'unset',
          cursor: 'pointer',
        }}
        type="button"
        onClick={() => onCardClick(job)}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: Colors.neutral_50,
            borderRadius: 8,
            minHeight: 80,
            paddingLeft: 12,
            width: 480,
          }}
        >
          <div
            style={{
              width: 96,
            }}
          >
            <Date
              localDate={moment(job.localDate, 'ddd, MM/DD/YYYY').format('YYYY-MM-DD')}
              backgroundColor={jobStatus.color}
              small
            />
          </div>
          <div
            style={{
              flex: 1,
              marginLeft: 48,
              marginRight: 32,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Title />
          </div>

          <div style={{ width: 60, display: 'flex', justifyContent: 'center' }}>
            <Image
              small
              label={jobStatus.label}
              multipleCandidates={job?.multipleCounters}
              profilePicture={job.professional[0]?.profile_image}
            />
          </div>
        </div>
      </button>
    </>
  );
};

const styles = {
  statusText: {
    fontFamily: 'Nunito',
    fontSize: 18,
    textTransform: 'uppercase',
  },
  datetimeText: {
    fontFamily: 'Nunito',
    fontSize: 16,
    textTransform: 'uppercase',
    color: Colors.neutral_600,
  },
  personName: {
    color: Colors.neutral_600,
    fontFamily: 'Nunito',
    fontSize: 18,
    fontWeight: '700',
  },
  professionName: {
    color: Colors.neutral_600,
    fontFamily: 'Nunito',
    fontSize: 16,
  },
};
