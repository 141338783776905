import moment from 'moment';
import isHoliday from '../../../../isHoliday';

export const checkIfAnyHoliday = (dates) => Object.values(dates).some((date) => isHoliday(date));

export const createObjOfDates = (datesArr) => {
  let objHelper = {};
  datesArr.forEach((date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    objHelper = { ...objHelper, [formattedDate]: date };
  });
  return objHelper;
};

export const handleConfirmRemoveDate = (jobDates, deletedDate) => {
  const jobData = {};
  const datesFilteredArr = jobDates.filter((date) => date !== deletedDate);
  datesFilteredArr.forEach((date) => {
	  jobData[date] = {};
  });
  return jobData;
};

/**
 * Converts an array of date strings to local time strings in a specified timezone.
 * @param {string[]} dateStrings - Array of date strings in 'YYYY-MM-DD' format.
 * @param {string} timeZone - Timezone identifier (e.g., 'America/New_York').
 * @returns {string[]} Array of local time strings formatted according to the timezone.
 */
export const convertDatesToTimezone = (dateStrings, timeZone) => dateStrings.map((dateString) => {
  // Parse year, month, and day from the dateString
  const [year, month, day] = dateString.split('-').map(Number);

  // Create a new Date object with the parsed year, month, and day
  const currentDate = new Date();
  currentDate.setFullYear(year);
  currentDate.setMonth(month - 1); // Months are zero-indexed in JavaScript Date object
  currentDate.setDate(day);

  // Convert currentDate to a string representing the local time in the specified timeZone
  return currentDate.toLocaleString('en-US', {
    timeZone,
  });
});
