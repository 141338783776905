import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import arrowRight from '../../images/arrow_right.svg';
import { Colors } from '../../themes/colors';
import { Table } from '../JobList/TodayJobs/Table';

const Card = ({ children }) => (
  <div
    className="dashboard-billing-shadow"
    style={{ minHeight: 258, cursor: 'default' }}
  >
    {children}
  </div>
);

const LIMIT = 5;

export const TodayShiftCard = () => {
  const dashboardInfo = useSelector((state) => state.job.dashboardInfo);

  return (
    <Card>
      <div style={{ paddingBottom: 20 }}>
        <h1
          className="global_font f-dark"
          style={{
            marginLeft: 30,
            marginBottom: 50,
            marginTop: 10,
            textAlign: 'center',
            textTransform: 'capitalize',
            fontSize: 24
          }}
        >
          Today&apos;s Shifts
        </h1>

        <div style={{ paddingRight: 30 }}>
          <Table limit={LIMIT} />
        </div>

        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 30,
          marginBottom: 30,
        }}
        >
          <Link
            to="/dashboard/jobs/today"
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
              marginRight: 12
            }}
          >
            <div style={{ display: 'flex', gap: 14 }}>
              <span
                style={{
                  fontFamily: 'Nunito',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: 18,
                  color: Colors.secondary_500,
                }}
              >
                View All
              </span>
              <img
                src={arrowRight}
                style={{ width: 10, objectFit: 'contain' }}
                alt="arrow_right"
              />
            </div>
          </Link>
        </div>
      </div>
    </Card>
  );
};
