import { useEffect, useState } from 'react';
import Icon from '../../../../../images/dentist-tools.svg';
import { Colors } from '../../../../../themes/colors';
import { RadioOption } from '../../../../registration/components/RadioOption';
import { CollapsibleRow } from '../CollapsibleRow/CollapsibleRow';
import { Title } from '../CollapsibleRow/Title';
import { Value } from '../CollapsibleRow/Value';

export const AssistedHygieneAvailableRow = ({ defaultValue, onToggle, isOpen, onConfirm }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    onConfirm(selectedValue);

    if (isOpen) {
      onToggle();
    }
  }, [selectedValue]);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const getText = () => {
    if (isOpen || defaultValue === undefined) {
      return 'Select';
    }

    if (selectedValue === undefined) {
      return '';
    }

    return selectedValue ? 'Yes' : 'No';
  }

  return (
    <CollapsibleRow
      isOpen={isOpen}
      onToggle={onToggle}
      TitleComponent={() => <Title text="Assisted Hygiene Available:" />}
      ValueComponent={() => (
        <Value
          customStyles={{
            color: isOpen || defaultValue === undefined ? Colors.neutral_300 : Colors.neutral_600,
          }}
          text={getText()}
        />
      )}
      icon={Icon}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <div
          style={{
            marginRight: 68,
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <RadioOption
              onClick={() => setSelectedValue(true)}
              text="Yes"
              checked={selectedValue === true}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <RadioOption
              onClick={() => setSelectedValue(false)}
              text="No"
              checked={selectedValue === false}
            />
          </div>
        </div>
      </div>
    </CollapsibleRow>
  );
};
