import React, { useState } from 'react';

export const ButtonGroup = ({
  options, value, onClick, showTitle = false,
}) => {
  const [selected, setSelected] = useState(value);

  const handleOnClick = (optionValue) => {
    setSelected(optionValue);
    onClick(optionValue);
  };

  const getClassNames = (index, optionValue) => {
    let className = '';

    switch (index) {
    case 0:
      className = 'button_group_container_first_option';
      break;
    case Object.keys(options).length - 1:
      className = 'button_group_container_last_option';
      break;
    default:
      className = 'button_group_container_option';
      break;
    }

    return `${className} ${
      selected === optionValue ? 'selected_button_group_option' : ''
    }`;
  };

  return (
    <div>
      <div className="button_group_container" style={{ display: 'flex' }}>
        {options.map((option, index) => (
          <button
            key={option.value}
            type="button"
            style={{ all: 'unset' }}
            onClick={() => handleOnClick(option.value)}
          >
            <div className={getClassNames(index, option.value)}>
              <img src={option.src} alt={`${option.value} icon`} />
            </div>
          </button>
        ))}
      </div>

      {showTitle && (
        <div style={{ display: 'flex', marginTop: 5, columnGap: 20 }}>
          {options.map((option) => (
            <p className="global_font" style={{ margin: 0 }}>
              {option.title}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
