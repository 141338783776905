import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Colors } from '../../themes/colors';

export default ({ history }) => {
  const allPPJobs = useSelector((state) => state.pp.allPPJobs);

  const styles = {
    grayTextStyle: {
      fontFamily: 'Nunito', fontSize: 16, color: Colors.neutral_600, flex: 1,
    },
    descriptionText: {
      fontFamily: 'Nunito',
      fontSize: 16,
      color: Colors.neutral_600,
      flex: 1,
      whiteSpace: 'pre-line',
    },

    blueTextStyle: {
      fontFamily: 'Nunito', fontWeight: 'bold', fontSize: 15, color: Colors.primary_500, width: '30%',
    },
  };

  const openJobClicked = (jobId) => {
    history.push(`/dashboard/permanentJobs/${jobId}`);
  };

  const renderLine = (title, value) => (
    <div
      className="border-bottom w-100"
      style={{
        paddingBottom: 13, paddingTop: 13, display: 'flex',
      }}
    >
      <div style={styles.blueTextStyle}>

        {title}
        {' '}
      </div>
      <div style={styles.grayTextStyle}>
        {value}
      </div>
    </div>
  );

  const renderPPJob = (job) => (
    <div
      className="permanent-cards-shadow"
      style={{
        backgroundColor: Colors.white, opacity: 1, maxWidth: 500, marginTop: 30, width: 450, padding: 0,
      }}
      onClick={() => openJobClicked(job.id)}
      key={job.id}
    >

      <div style={{
        backgroundColor: Colors.primary_500, margin: 0, height: 35, borderTopLeftRadius: 25, borderTopRightRadius: 25, alignItems: 'center', justifyContent: 'center', display: 'flex',
      }}
      >
        <div style={{
          fontFamily: 'Nunito', fontWeight: 'bold', fontSize: 15, color: Colors.white, textTransform: 'capitalize',
        }}
        >
          {' '}
          Open Contract
          {' '}
          #
          {job.id}
          {' '}

        </div>

      </div>
      <div style={{ margin: 20 }}>
        {renderLine('Start Date:', job.start_date)}
        {renderLine('Schedule Type:', job.position)}
        {renderLine('Pay Range:', job.rate)}
        {renderLine('Benefits:', job.benefits)}
        <div
          className="border-bottom w-100"
          style={{
            paddingBottom: 13, paddingTop: 13,
          }}
        >
          <div style={styles.blueTextStyle}>
            Description:
          </div>
          <div style={styles.descriptionText}>
            {job.content}
          </div>

        </div>
      </div>

    </div>

  );

  return (
    <div className="component_container">
      <div className="floating_container" style={{ flexDirection: 'column', padding: 40, alignItems: 'center' }}>
        <p style={{
          fontFamily: 'Nunito', fontWeight: 'bold', fontSize: 35, color: Colors.primary_500,
        }}
        >
          Permanent Jobs
        </p>

        <div
          style={{
            width: '80%', display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap',
          }}
        >
          {allPPJobs.map((job) => <div style={{ width: '50%' }}>{renderPPJob(job)}</div>)}
        </div>
      </div>
    </div>
  );
};
