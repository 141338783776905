import { fetchRecentProfessionals } from '../../../actions';
import { EmptyRecentProfessionals } from '../../../components/ProfessionalsHub/components/RecentProfessionalsList/EmptyRecentProfessionals';
import { MAX_RECENT_PROFESSIONALS } from '../../../components/ProfessionalsHub/components/RecentProfessionalsList/RecentProfessionalsList';
import { Colors } from '../../../themes/colors';
import { SendInviteToModal } from '../SendInviteToModal';
import { filterRecentProfessionals, mapRecentToRowProps } from '../utils';

export const SendToRecentProfessionalsModal = ({ onClose }) => (
  <SendInviteToModal
    fetchProfessionalsAction={fetchRecentProfessionals}
    professionalsSelector={(state) => state.user.recentProfessionals}
    filterFunction={filterRecentProfessionals}
    mapProfessionalToRowProps={mapRecentToRowProps}
    EmptyComponent={() => (
      <>
        <div
          style={{
            color: Colors.neutral_600,
            fontSize: 18,
            fontFamily: 'Nunito',
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: 30,
          }}
        >
          No Recent Professionals Available
        </div>
        <EmptyRecentProfessionals />
      </>
    )}
    headerTitle="Recent Professionals"
    headerSubtitle={`
      Below is a list of the ${MAX_RECENT_PROFESSIONALS} 
      most recent professionals who have worked at your office.
    `}
    onClose={onClose}
  />
);
