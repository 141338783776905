import { useFeatureValue } from '@growthbook/growthbook-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GoodNewsPNG from '../images/good-news.png';
import { Colors } from '../themes/colors';
import ConfirmPopup from './shared/ConfirmPopup';

export default function SimplifiedRequestFormPopup() {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [showPopup, setShowPopup] = useState(false);
  const shouldDisplaySimplifiedBooking = useFeatureValue('should-display-simplified-booking');

  const getStorage = () => {
    const showSimplifiedRequestFormPopup = localStorage.getItem(
      `showSimplifiedRequestFormPopup-${user?.id}`,
    );

    if (showSimplifiedRequestFormPopup === null) {
      setShowPopup(true);
    }
  };

  const setStorage = () => {
    localStorage.setItem(`showSimplifiedRequestFormPopup-${user?.id}`, 'false');
    setShowPopup(false);
  };

  useEffect(() => {
    (() => {
      getStorage();
    })();
  }, []);

  return (
    user?.is_profile_complete === true &&
    shouldDisplaySimplifiedBooking &&
    showPopup && (
      <ConfirmPopup
        Icon={() => (
          <img
            alt="Good News"
            src={GoodNewsPNG}
            style={{ width: 224, height: 158, marginBottom: 24 }}
          />
        )}
        title="Simplified Request Form!"
        description="We have made the process of requesting a temp even simpler by combining single-day and multi-day temp requests! You can now use this form to select one or multiple days!"
        descriptionStyle={{ color: Colors.neutral_500 }}
        containerStyle={{ paddingTop: 45, paddingBottom: 50 }}
        rightButtonText="Got it!"
        rightButtonAction={() => {
          setStorage();
        }}
      />
    )
  );
}
