import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
  fetchOpenJobsForRecentProfessional,
} from '../../../actions';
import { clearSuccess } from '../../../actions/success';
import { createSuccessSelector } from '../../../api/selectors';
import { DotsMenu, MenuItem } from '../../../commonComponents/DotsMenu';
import { IconButton } from '../../../commonComponents/IconButton';
import { shouldDisplayRebookFeature } from '../../../growthbook';
import history from '../../../history';
import shareIcon from '../../../images/share-icon.svg';
import { Colors } from '../../../themes/colors';

const actions = [FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION];
const successSelector = createSuccessSelector(actions);

export const InviteButton = ({ user, disabled, showTooltip = true, position = 'right' }) => {
  const dispatch = useDispatch();
  const isSuccessRequest = useSelector((state) => successSelector(state));
  const { offers, professionalId } = useSelector((state) => state.job.recentOpenJobs);

  const handleInviteToExistingShift = () => {
    dispatch(
      fetchOpenJobsForRecentProfessional({
        professionalId: user?.id,
        profession: user?.profession,
        page: 1,
      }),
    );
  };

  const handleInviteNewShift = () => {
    history.push('/dashboard/re-book/info', {
      professional: {
        id: user?.id,
        name: user?.name,
      },
    });
  };

  useEffect(() => {
    if (isSuccessRequest && professionalId === user?.id) {
      if (offers?.length) {
        history.push(`/invite-professional`, {
          professional: {
            id: user?.id,
            image: user?.image,
            name: user?.name,
            profession: user?.profession,
          },
        });
      }
      dispatch(clearSuccess(actions));
    }
  }, [isSuccessRequest, professionalId, dispatch, user, offers]);

  return (
    <>
      {!shouldDisplayRebookFeature() ? (
        <IconButton
          buttonStyle={{ ...styles.buttonStyle, backgroundColor: Colors.primary_500 }}
          imgStyle={styles.imgStyle}
          src={shareIcon}
          tooltipContent="Invite"
          onClick={handleInviteToExistingShift}
          disabled={disabled}
        />
      ) : (
        <DotsMenu
          position={position}
          disabled={disabled}
          CustomMenuIcon={() => (
            <IconButton
              buttonStyle={{ ...styles.buttonStyle, backgroundColor: Colors.primary_500 }}
              imgStyle={styles.imgStyle}
              src={shareIcon}
              tooltipContent={showTooltip ? 'Invite' : ''}
              disabled={disabled}
            />
          )}
        >
          <MenuItem text="Existing Shift" onClick={handleInviteToExistingShift} />
          <MenuItem text="New Shift" onClick={handleInviteNewShift} />
        </DotsMenu>
      )}
    </>
  );
};

const styles = {
  buttonStyle: {
    display: 'flex',
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: Colors.primary_500,
  },
  imgStyle: {
    width: 24,
    height: 24,
  },
};
