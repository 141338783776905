import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Colors } from '../../../themes/colors';
import GeneralHygInfo from './GeneralHygInfo';

import PPnextStep from '../../../images/PPnextStep.png';
import PPdeclineButton from '../../../images/PPdeclineButton.png';
import PPrescheduleIcon from '../../../images/rescheduleIcon.png';

import { styles } from './StylesPopups';
import { FETCH_APPLICATION_BY_ID_PP } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import CustomSpinner from '../../../commonComponents/CustomSpinner';

export default ({
  declinePressed, hirePressed, closePopupClicked, selectedCandidate, interviewPressed,
}) => {
  const [notes, setNotes] = useState('');

  const loadingSelector = createLoadingSelector([FETCH_APPLICATION_BY_ID_PP]);
  const isLoading = useSelector((state) => loadingSelector(state));

  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopupClicked();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        ref={wrapperRef}
        style={{
          backgroundColor: Colors.white,
          maxHeight: '80%',
          height: '80%',
          overflowY: 'auto',
          alignContent: 'center',
          justifyContent: 'center',
          maxWidth: 700,
          minWidth: 450,
          width: '30%',
          borderRadius: 25,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 50,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        <div
          style={{
            marginTop: 20,
            fontSize: 20,
            fontWeight: 'bold',
            color: Colors.neutral_300,
            cursor: 'pointer',
            textAlign: 'right',
            marginRight: 20,
          }}
          onClick={() => closePopupClicked()}
        >
          X
        </div>
        {isLoading ? <CustomSpinner isLoading={isLoading} />
          : (
            <div
              style={{
                height: '100%',
                width: '85%',
                alignSelf: 'center',
              }}
            >
              <GeneralHygInfo selectedCandidate={selectedCandidate} />

              {
                selectedCandidate?.resume?.officeNote && (
                  <>
                    <div
                      style={styles.descriptionTitleGray}
                    >
                      Notes
                    </div>

                    <div
                      style={styles.descriptionGray}
                    >
                      {selectedCandidate.resume.officeNote}
                    </div>
                  </>
                )
              }

              <div
                style={styles.descriptionTitleGray}
              >
                License #:
              </div>

              <div
                style={styles.descriptionGray}
              >
                {selectedCandidate?.resume?.licId || 'N/A'}
              </div>

              <div
                style={styles.descriptionTitleGray}
              >
                School Name:
              </div>

              <div
                style={styles.descriptionGray}
              >
                {selectedCandidate?.resume?.schoolName || 'N/A'}
              </div>

              <div
                style={styles.descriptionTitleGray}
              >
                State:
              </div>

              <div
                style={styles.descriptionGray}
              >
                {selectedCandidate?.resume?.state || 'N/A'}
              </div>

              <div
                style={styles.descriptionTitleGray}
              >
                Years of experience:
              </div>

              <div
                style={styles.descriptionGray}
              >
                {selectedCandidate?.resume?.yearsOfExperience || 'N/A'}
              </div>
              <p style={styles.titleBlue}>
                Notes:

              </p>
              <textarea
                style={{
                  backgroundColor: Colors.primary_50,
                  padding: 10,
                  fontFamily: 'Nunito',
                  fontSize: 16,
                  border: '0px solid #ffffff',
                  borderRadius: 7,
                }}
                placeholder=""
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />

              <div style={{
                display: 'flex', flexDirection: 'row', marginBottom: 50, alignItems: 'center', justifyContent: 'space-between', marginTop: 30,
              }}
              >
                <div
                  onClick={() => declinePressed(notes)}
                  style={styles.roundButton33}
                >
                  <img src={PPdeclineButton} style={{ height: 100, width: 100, cursor: 'pointer' }} alt="decline" />
                  <p style={{ color: Colors.neutral_800, fontSize: 16, fontWeight: 'bold' }}> Reject</p>
                </div>

                <div
                  onClick={() => interviewPressed()}
                  style={styles.roundButton33}
                >
                  <img src={PPrescheduleIcon} style={{ height: 100, width: 100, cursor: 'pointer' }} alt="next" />

                  <p style={{ color: Colors.neutral_800, fontSize: 16, fontWeight: 'bold' }}> Reschedule</p>
                </div>

                <div
                  onClick={() => hirePressed(notes)}
                  style={styles.roundButton33}
                >
                  <img src={PPnextStep} style={{ height: 100, width: 100, cursor: 'pointer' }} alt="next" />

                  <p style={{ color: Colors.neutral_800, fontSize: 16, fontWeight: 'bold' }}> Hire</p>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
