import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FETCH_JOB_ACTION, fetchJob } from '../../actions';
import { createLoadingSelector } from '../../api/selectors';
import BackButton from '../../commonComponents/BackButton';
import CustomSpinner from '../../commonComponents/CustomSpinner';
import history from '../../history';
import { DetailedUserCard } from './components/DetailedUserCard/DetailedUserCard';
import { UserCard } from './components/UserCard/UserCard';

const loadingSelector = createLoadingSelector([FETCH_JOB_ACTION]);

export default function CandidateList({ match }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const job = useSelector((state) => state.job.job);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [selectedUser, setSelectedUser] = useState(job?.multipleCounters[0]);

  const handleUserCardClick = (item) => {
    setSelectedUser(item);
  };

  useEffect(() => {
    if (!job || job?.id !== match?.params?.jobId) {
      dispatch(fetchJob({ jobId: match?.params?.jobId }));
      return;
    }

    if (job && !job?.multipleCounters?.length) {
      history.replace('/');
      return;
    }

    if (job && job?.multipleCounters?.length) {
      setSelectedUser(job?.multipleCounters[0]);
    }
  }, [dispatch, job, match?.params?.jobId]);

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingBottom: 120,
        }}
      >
        <div style={{ width: '95%' }}>
          <BackButton />
        </div>

        <h1 className="global_font f-dark" style={{ marginBottom: 30 }}>
          Candidates
        </h1>

        {isLoading && <CustomSpinner isLoading />}

        {!isLoading && selectedUser && (
          <>
            <div style={{ display: 'flex', gap: 8 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                {job?.multipleCounters?.map((item) => (
                  <UserCard
                    key={item.id}
                    item={item}
                    isSelected={selectedUser?.candidate?.id === item?.candidate?.id}
                    job={job}
                    onClick={handleUserCardClick}
                  />
                ))}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DetailedUserCard item={selectedUser} user={user} job={job} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
