import React from 'react';
import { Colors } from '../../../themes/colors';
import logoLightIcon from '../../../images/logos/logoLightIcon.svg';
import progressBar30 from '../../../images/signup/progressBar_30.svg';
import progressBar65 from '../../../images/signup/progressBar_65.svg';
import progressBar85 from '../../../images/signup/progressBar_85.svg';
import progressBar100 from '../../../images/signup/progressBar_100.svg';

const getProgress = (progress) => {
  let image = '';
  let percent = 0;
  switch (progress) {
    case 'step2':
      percent = 65;
      image = progressBar65;
      break;
    case 'step3':
      percent = 85;
      image = progressBar85;
      break;
    case 'step4':
      percent = 100;
      image = progressBar100;
      break;
    default:
      percent = 30;
      image = progressBar30;
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={image} style={{ width: 400 }} alt="progressBar" />
      <span
        style={{
          fontFamily: 'Nunito',
          fontSize: 20,
          fontWeight: 700,
          marginLeft: 17,
          color: Colors.white,
        }}
      >
        {percent}
        %
      </span>
    </div>
  );
};

export const Title = ({ text, progress }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 45,
      marginTop: 75,
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img src={logoLightIcon} style={{ width: 48 }} alt="logo_light_icon" />
    <span
      style={{
        fontFamily: 'Nunito',
        fontSize: 32,
        fontWeight: 700,
        color: Colors.white,
        marginTop: 15,
        marginBottom: 60,
      }}
    >
      {text}
    </span>
    {getProgress(progress)}
  </div>
);
