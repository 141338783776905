import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSuccessBankAddedPopup } from '../../../actions';
import { CardForm } from '../../../commonComponents/CardForm';
import CustomPortal from '../../../commonComponents/CustomPortal';
import CreditCardIcon from '../../../images/credit-card.svg';
import ConfirmPopup from '../../shared/ConfirmPopup';
import { addExpirationDate } from '../utils';
import { PaymentCard } from './PaymentCard';

export const CardList = ({ selectedPayment, setSelectedPayment }) => {
  const dispatch = useDispatch();
  const allPayments = useSelector((state) => state.payments.allPayments);
  const cardList = allPayments
    ?.filter((item) => item.type === 'card')
    .sort((a, b) => (b.is_selected === true) - (a.is_selected === true))
    .map((item) => addExpirationDate(item));
  const [showAddCardPopup, setShowAddCardPopup] = useState(false);
  const showSuccessCardAddedPopup = useSelector(
    (state) => state.payments.showSuccessCardAddedPopup,
  );

  return (
    <>
      <PaymentCard
        data={cardList}
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
        iconSrc={CreditCardIcon}
        title="Credit Card / Debit Card"
        description="(Adding a Credit/Debit Card comes with a 3.5% Convenience Fee on each transaction)."
        addPaymentText="Add Credit Card / Debit Card"
        handleAddPaymentButton={() => setShowAddCardPopup(true)}
        showExpiration
      />

      {showAddCardPopup && (
        <ConfirmPopup
          showClosePopupIcon
          closePopup={() => setShowAddCardPopup(false)}
          title="Add Credit Card / Debit Card"
          description={<CardForm onSuccess={() => setShowAddCardPopup(false)} />}
        />
      )}

      {showSuccessCardAddedPopup && (
        <CustomPortal
          isSuccess
          redirect={false}
          title="Card Successfully Added!"
          text="Your credit / debit card has been successfully added to your account."
          submitPressed={() => {
            dispatch(hideSuccessBankAddedPopup());
          }}
        />
      )}
    </>
  );
};
