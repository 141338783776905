import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { growthbook, showShowWCManagementPage } from '../../../../growthbook';
import { Colors } from '../../../../themes/colors';
import { MenuItem } from './MenuItem';

const getMenuItems = (hasUserClassification, isPendingWCRequest) => {
  const items = [
    {
      key: 'overview',
      title: 'Overview',
    }, {
      key: 'faqs',
      title: 'FAQs',
    },
  ];

  if (!hasUserClassification && !isPendingWCRequest) {
    return [...items, {
      key: 'worker-classification-selection',
      title: 'Worker Type Selection',
    }];
  }

  return items;
};

export const Siderbar = ({ isRegistration, refsByKey, activeSection }) => {
  const [isActive, setIsActive] = useState('overview');
  const user = useSelector((state) => state.user.selectedChildOffice);
  const userClassification = user?.workerClassification?.classificationType;
  const latestWorkerClassificationRequest = user?.latestWorkerClassificationRequest;
  const isPendingWCRequest = showShowWCManagementPage() && 
  latestWorkerClassificationRequest?.status === 'requested'

  const handleMenuItemClick = (index) => {
    setIsActive(index);

    return refsByKey[index]?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setIsActive(activeSection || 'overview');
  }, [activeSection]);

  return (
    <div
      style={{
        height: isRegistration ? 'auto' : '100%',
        width: 420,
        backgroundColor: isRegistration ? Colors.secondary_800 : Colors.neutral_50,
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          position: 'sticky',
          top: 0,
        }}
      >
        <ul
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 48,
            gap: 25,
            flex: 1,
            marginBottom: 0,
            marginTop: 0,
            paddingLeft: 22,
            marginRight: 32,
            listStyle: 'none',
          }}
        >
          {getMenuItems(!!userClassification, isPendingWCRequest).map(({ title, key }) => (
            <MenuItem
              key={key}
              isRegistration={isRegistration}
              title={title}
              isActive={isActive === key}
              onClick={() => handleMenuItemClick(key)}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};
