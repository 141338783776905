import { motion } from 'framer-motion';
import { Colors } from '../../themes/colors';

const borderColorVariants = {
  active: {
    borderColor: Colors.secondary_500,
  },
  inactive: {
    borderColor: Colors.white,
  },
};

const spanVariants = {
  active: {
    fontWeight: 700,
  },
  inactive: {
    fontWeight: 400,
  },
};

export const Tab = ({ title, badge, isActive, setActiveTab }) => (
  <button
    key={isActive}
    type="button"
    onClick={setActiveTab}
    style={{
      all: 'unset',
      cursor: 'pointer',
    }}
  >
    <motion.div
      initial="inactive"
      transition={{ duration: 0.3 }}
      animate={isActive ? 'active' : 'inactive'}
      variants={borderColorVariants}
      style={{
        paddingRight: 16,
        paddingLeft: 16,
        borderBottomWidth: 3,
        borderBottomStyle: 'solid',
        position: 'relative',
      }}
    >
      <motion.span
        initial="inactive"
        transition={{ duration: 0.1 }}
        animate={isActive ? 'active' : 'inactive'}
        variants={spanVariants}
        style={{
          fontFamily: 'Nunito',
          display: 'flex',
          alignItems: 'center',
          color: isActive ? Colors.neutral_600 : Colors.neutral_300,
          fontSize: 22,
          paddingBottom: 4,
        }}
      >
        {title}
        {!!badge && (
          <span
            style={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'Nunito',
              backgroundColor: Colors.error_500,
              color: Colors.white,
              fontSize: 11,
              fontWeight: 700,
              padding: 2,
              minWidth: 16,
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              borderBottomLeftRadius: 12,
              borderBottomRightRadius: 12,
              top: -4,
              right: -8,
            }}
          >
            {badge}
          </span>
        )}
      </motion.span>
    </motion.div>
  </button>
);

Tab.displayName = 'Tab';
