import React, { useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import { Colors } from '../../../themes/colors';
import arrow from '../../../images/arrow_right.svg';
import userIcon from '../../../images/user-icon.svg';

export default function ProfessionPopup ({
  options,
  title,
  onChanged,
  value
}) {
  const [isRowExpanded, setIsRowExpanded] = useState(false);

  useEffect(() => {
    window.addEventListener('click', () => (setIsRowExpanded(false)));
    return () => window.removeEventListener('click', () => (setIsRowExpanded(false)));
  }, []);

  const handleProfessionChange = (event) => {
    const updatedProfession = event.target.value;

    onChanged(updatedProfession);
    setIsRowExpanded(true);
  };

  const collapsableVariants = {
    open: {
      maxHeight: 900,
      opacity: 1,

    },
    closed: {
      maxHeight: 0,
      opacity: 0,
    },
  };

  const arrowVariants = {
    open: {
      rotate: 90,
    },
    closed: {
      rotate: 0,
    },
  };

  return (
    <>
      <div
        className="row_container"
        style={{ borderBottom: `1px solid ${Colors.neutral_100}` }}
        onClick={(event) => {
          event.stopPropagation();
          setIsRowExpanded(!isRowExpanded);
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              alt="icon"
              src={userIcon}
              style={{ width: 24, height: 24, marginRight: 8 }}
            />
            <p className="date">{title}</p>
          </div>
          <p
            style={{
              fontFamily: 'Nunito',
              color: Colors.neutral_600,
              textTransform: 'capitalize',
            }}
          >
            {value}
          </p>
        </div>
        <div>
          <motion.img
            initial="closed"
            animate={isRowExpanded ? 'open' : 'closed'}
            transition={{ duration: 0.4 }}
            variants={arrowVariants}
            alt="arrow"
            src={arrow}
            style={{ marginLeft: 30, marginRight: -30 }}
          />
        </div>
      </div>
      <motion.div
        initial="closed"
        animate={isRowExpanded ? 'open' : 'closed'}
        transition={{ duration: 0.4 }}
        style={{ overflow: 'hidden' }}
        variants={collapsableVariants}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginRight: 75,
            marginTop: 20,
          }}
        >
          <div style={{ alignItems: 'flex-start', width: 250 }}>
            {options?.length > 0
        && options.map((profession) => (
          <div key={profession.code} style={{ marginBottom: 10 }}>
            <input
              checked={value === profession.name}
              type="radio"
              id={profession.code}
              name="profession"
              value={profession.name}
              onChange={handleProfessionChange}
            />

            <label
              htmlFor={profession.code}
              className="global_font"
              style={{
                fontWeight: 400,
                color: Colors.neutral_600,
                marginBottom: 20,
              }}
            >
              {profession.name}
            </label>
          </div>
        ))}
          </div>
        </div>
      </motion.div>
    </>
  );
};
