import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOfficeJobInvites } from '../../../../actions';

export const useOfficeJobInvites = () => {
  const dispatch = useDispatch();
  const officeJobInvites = useSelector((state) => state.job.officeJobInvites);
  const isLoadingFirstPage = officeJobInvites?.page === 1 && officeJobInvites?.isActiveRequest;

  const handleScrollList = useCallback(
    (event) => {
      const tableScrollHeight = event?.target?.scrollHeight - event?.target?.scrollTop - 20;
      const clientHeight = event?.target?.clientHeight;
      if (
        tableScrollHeight <= clientHeight &&
        !officeJobInvites.isActiveRequest &&
        !officeJobInvites.isFinal
      ) {
        dispatch(
          fetchOfficeJobInvites({
            page: officeJobInvites?.page + 1,
          }),
        );
      }
    },
    [officeJobInvites, dispatch],
  );

  useEffect(() => {
    dispatch(fetchOfficeJobInvites({ page: 1 }));
  }, [dispatch]);

  return {
    isLoadingFirstPage,
    isLoading: officeJobInvites?.isActiveRequest,
    handleScrollList,
    jobs: officeJobInvites?.jobs,
  };
};
