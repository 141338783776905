import { useDayPicker } from 'react-day-picker';

export function HeadRow() {
  const { classNames, styles, showWeekNumber } = useDayPicker();

  return (
    <tr style={styles.head_row} className={classNames.head_row}>
      {showWeekNumber && <td style={styles.head_cell} className={classNames.head_cell} />}
      {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((weekday) => (
        <th key={weekday} scope="col" className={classNames.head_cell} style={styles.head_cell}>
          {weekday}
        </th>
      ))}
    </tr>
  );
}
