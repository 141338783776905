import Button from '../../../commonComponents/Button';
import { Colors } from '../../../themes/colors';
import { TableData } from '../../../commonComponents/Table/TableData';

export const renderRow = (item, handleOnClick) => (
  <>
    <TableData text={item.officeName} />
    <TableData text={item.state} />
    <td>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 16,
        }}
      >
        <Button
          onClick={() => handleOnClick(item, 'contractor')}
          text="Contractor"
          style={{
            backgroundColor: Colors.secondary_500,
            width: 90,
            height: 30,
            marginTop: 0,
          }}
          textStyle={{
            fontSize: 14,
          }}
        />
        <Button
          text="Employee"
          onClick={() => handleOnClick(item, 'employee')}
          style={{
            width: 90,
            height: 30,
            marginTop: 0,
          }}
          textStyle={{
            fontSize: 14,
          }}
        />
      </div>
    </td>
  </>
);
