import React from 'react';
import Portal from '../../commonComponents/Portal';
import WarningIcon from '../../images/icons/Warning';
import { Colors } from '../../themes/colors';
import Button from '../../commonComponents/Button';

const WarningPopup = ({
  title, text, successText, cancelText, btnSucces, btnCancel,
}) => (
  <Portal>
    <div
      className="modal"
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        flex: 1,
        alignItems: 'center',
      }}
    >
      <div className="modal_content" style={{ minHeight: 0, padding: '42px 0px', width: '500px' }}>
        <WarningIcon color={Colors.warning_500} width={80} height={80} />
        <h1
          className="global_font bold f-dark f-18"
          style={{
            textAlign: 'center',
          }}
        >
          {title}
        </h1>
        <p
          className="global_font f-16"
          style={{
            color: Colors.neutral_500, textAlign: 'center', whiteSpace: 'pre-line',
          }}
        >
          {text}
        </p>

        <div
          style={{
            padding: 20,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button
            text={cancelText}
            size="small"
            color="secondary"
            style={{ width: 152 }}
            textClassName="text-capitalize"
            onClick={btnCancel}
          />
          <div style={{ width: 30 }} />
          <Button
            text={successText}
            size="small"
            style={{ width: 152 }}
            textClassName="text-capitalize"
            onClick={btnSucces}
          />
        </div>
      </div>
    </div>
  </Portal>
);

export default WarningPopup;
