import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeSchedule } from '../../../../actions';
import { IconButton } from '../../../../commonComponents/IconButton';
import arrowRight from '../../../../images/arrow_right.svg';
import { Colors } from '../../../../themes/colors';

export const MonthSelection = () => {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.job.schedule);
  const selectedMonth = moment(schedule.dateRange.start);

  const handleMonthChange = (nextDate) => {
    const dateRange = {
      start: nextDate.startOf('month').format('YYYY-MM-DD'),
      end: nextDate.endOf('month').format('YYYY-MM-DD'),
    };

    dispatch(changeSchedule({ payload: { option: 'dateRange', value: dateRange } }));
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 220,
      }}
    >
      <IconButton
        src={arrowRight}
        alt="Previous Month"
        buttonStyle={styles.buttonStyle}
        imgStyle={{
          transform: 'rotate(180deg)',
        }}
        onClick={() => handleMonthChange(selectedMonth.subtract(1, 'month'))}
      />
      <span
        style={{
          fontFamily: 'Nunito',
          fontSize: 22,
          fontWeight: '700',
          color: Colors.neutral_600,
        }}
      >
        {selectedMonth.format('MMMM YYYY')}
      </span>
      <IconButton
        src={arrowRight}
        alt="Next Month"
        buttonStyle={styles.buttonStyle}
        imgStyle={{
          objectFit: 'cover',
        }}
        onClick={() => handleMonthChange(selectedMonth.add(1, 'month'))}
      />
    </div>
  );
};

const styles = {
  buttonStyle: {
    display: 'flex',
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
};
