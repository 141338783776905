import { UserProfession, UserSubProfession } from '../enums/UserProfession';
import { getShouldOfficeShowFrontDeskProfession } from '../growthbook';
import { EnumHelper } from './EnumHelper';


/**
 * Provides the profession type options for the user
 * including sub-professions like {@link UserSubProfession.EFDA}
 *
 * @param {Array.<{code: string, name: string}>} allProfessions - all professions available,
 *    gotten from {@link getAllProfessionals}
 * @param {string[]} licenseTypes - license types user has, usually gotten from `user.licenseTypes`
 * @param {string[]} - sub-professions available, usually gotten from `user.licenseTypes`
 * @returns {Array.<{code: string, name: string}>}
 */
export function getProfessionTypeOptions({allProfessions, licenseTypes, subProfessions,}) {
  const licenses = allProfessions.filter((profession) => licenseTypes.includes(profession.code));

  // find DA index
  const daIndex = licenses.findIndex((license) => license.code === UserProfession.DA);

  if (daIndex > -1) {
    // add DA's sub-profession after DA
    return licenses.toSpliced(...[daIndex + 1, 0]
      .concat(subProfessions.map((subProfession) => ({
        code: subProfession,
        name: UserSubProfession.getName(subProfession),
      }))));
  }

  return licenses;
}

/**
 * Getting all profession type options available for job posting
 * including sub-professions like {@link UserSubProfession.EFDA}
 *
 * @param {Array.<{code: string, name: string}>} allProfessions - all professions available,
 *  gotten from {@link getAllProfessionals}
 * @param {boolean} showEfda - whether to show EFDA sub-profession or not
 * @returns
 */
export function getAllProfessionTypeOptions(allProfessions, {showEfda = false}) {
  let professions = allProfessions ?? [];

  // find DA index
  const daIndex = professions.findIndex((license) => license.code === UserProfession.DA);

  if (daIndex > -1) {
    // add DA's sub-profession after DA
    professions = professions.toSpliced(...[daIndex + 1, 0]
      .concat(EnumHelper.getObjectKeys(UserSubProfession)
        .filter((subProfession) => {
          if (!showEfda && subProfession === UserSubProfession.EFDA) {
            return false;
          }

          return true;
        })
        .map((subProfession) => ({
          code: subProfession,
          name: UserSubProfession.getName(subProfession)
        }))));
  }

  if (!getShouldOfficeShowFrontDeskProfession()) {
    professions = professions?.filter((profession) => profession.code !== UserProfession.FD);
  }

  return professions;
}
