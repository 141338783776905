export const removePrefixes = (obj) => {
  const newObj = {};

  Object.entries(obj).forEach(([key, value]) => {
    const newKey = key.replace(/^specialty_|procedure_/g, '');
    const newValue = value.map((val) => val.replace(/^procedure_/g, ''));

    if (newValue.length > 0) {
      newObj[newKey] = newValue;
    }
  });

  return newObj;
};