import React from 'react';
import { Colors } from '../../themes/colors';

const EyeOffIcon = ({ color = Colors.secondary_500 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.00024 3L6.5894 6.58916M21.0002 21L17.4114 17.4112M13.8751 18.8246C13.268 18.9398 12.6414 19 12.0007 19C7.52305 19 3.73275 16.0571 2.4585 12C2.80539 10.8955 3.33875 9.87361 4.02168 8.97118M9.87892 9.87868C10.4218 9.33579 11.1718 9 12.0002 9C13.6571 9 15.0002 10.3431 15.0002 12C15.0002 12.8284 14.6645 13.5784 14.1216 14.1213M9.87892 9.87868L14.1216 14.1213M9.87892 9.87868L6.5894 6.58916M14.1216 14.1213L6.5894 6.58916M14.1216 14.1213L17.4114 17.4112M6.5894 6.58916C8.14922 5.58354 10.0068 5 12.0007 5C16.4783 5 20.2686 7.94291 21.5429 12C20.836 14.2507 19.3548 16.1585 17.4114 17.4112" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default EyeOffIcon;
