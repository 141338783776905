import { InviteButton } from '../InviteButton';

export const ProfessionalCardActions = ({ user }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '24px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
      marginRight: 32,
    }}
  >
    <InviteButton
      user={{
        id: user?.id,
        image: user?.profileImage,
        name: user?.fullname,
        profession: user?.professions?.[0],
      }}
      disabled={!user?.isAvailable}
    />
  </div>
);
