import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { hideRegistrationPopup } from '../../actions';
import Button from '../../commonComponents/Button';
import WelcomePNG from '../../images/signup/success/welcome.png';
import { Colors } from '../../themes/colors';

export function SuccessRegistrationPopup() {
  const dispatch = useDispatch();
  const { showRegistrationSuccessPopup } = useSelector((state) => state.user);

  if (!showRegistrationSuccessPopup) {
    return <></>;
  }

  const handleOutsideClick = () => {
    dispatch(hideRegistrationPopup());
  };

  return (
    <button type="button" style={{ all: 'unset' }} onClick={handleOutsideClick}>
      <div
        className="modal"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          position: 'fixed',
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          style={{
            width: '28%',
            maxWidth: 700,
            maxHeight: '90%',
            backgroundColor: Colors.white,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 14,
            padding: 35,
            paddingBottom: 48,
            overflowY: 'auto',
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <img
            src={WelcomePNG}
            style={{ width: 220, marginBottom: 16 }}
            alt="welcome_illustration"
          />

          <h1
            style={{
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              fontSize: 18,
              color: Colors.neutral_600,
            }}
          >
            Congratulations!
          </h1>
          <p
            style={{
              fontFamily: 'Nunito',
              fontSize: 16,
              color: Colors.neutral_500,
              margin: 0,
              marginBottom: 32,
              textAlign: 'center',
            }}
          >
            Welcome to the GoTu community! Your account has been created. 
            We look forward to helping you with your dental staffing needs.
            Let&apos;s brighten futures together!
          </p>

          <Button
            type="button"
            style={{
              width: 150,
            }}
            onClick={handleOutsideClick}
            text="Got it!"
          />
        </motion.div>
      </div>
    </button>
  );
}
