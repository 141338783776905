import moment from "moment";
import { UserProfession } from "../../../../enums/UserProfession";
import { Colors } from "../../../../themes/colors";

export const ProfessionalCardDetails = ({ user }) => {
  const { fullname, datetime, profession } = user;
  
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '24px',
        justifyContent: 'center',
      }}
    >
      <span
        style={{
          fontFamily: 'Nunito',
          fontSize: 22,
          fontWeight: 700,
          color: Colors.neutral_600,
          maxWidth: 310,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {fullname}
      </span>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span
          style={{
            fontSize: 16,
            color: Colors.neutral_500,
          }}
        >
          {UserProfession.getName(profession)}
        </span>
        <b
          style={{
            fontFamily: 'Nunito',
            fontSize: 16,
            color: Colors.neutral_500,
          }}
        >
          Worked on:{" "}
          <span
            style={{
              fontWeight: 'normal',
              textTransform: 'capitalize',
            }}
          >
            {moment(datetime).format('ddd, MMM DD, YYYY')}
          </span>
        </b>
      </div>
    </div>
  )
}