import moment from 'moment';
import { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { useDispatch, useSelector } from 'react-redux';
import { selectDaysMDB } from '../../../../../../actions';
import Button from '../../../../../../commonComponents/Button';
import { NUMBER_OF_DAYS_TO_ADD } from '../../../../../../commonComponents/CalendarPopup';
import CalendarIcon from '../../../../../../images/calendar-icon.svg';
import WarningIcon from '../../../../../../images/icons/Warning';
import { Colors } from '../../../../../../themes/colors';
import ConfirmPopup from '../../../../../shared/ConfirmPopup';
import { CollapsibleRow } from '../../CollapsibleRow/CollapsibleRow';
import { Title } from '../../CollapsibleRow/Title';
import { HeadRow } from './HeadRow';
import { MonthSelection } from './MonthSelection';
import { Value } from './Value';
import {
  checkIfAnyHoliday,
  convertDatesToTimezone,
  createObjOfDates,
  handleConfirmRemoveDate,
} from './utils';

export const DateRow = ({ onToggle, isOpen, showInvite = false }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const jobDates = useSelector((state) => state.multiday.jobDates);
  const values = !jobDates ? [] : jobDates.map((date) => moment(date).format('ddd, MMM DD, YYYY'));
  const [showDeleted, setShowDeleted] = useState({ open: false, date: null });
  const [selectedDates, setSelectedDates] = useState([]);
  const isDisabled = !selectedDates.length;
  const fromMonth = new Date();
  const [confirmPostOnHoliday, setConfirmPostOnHoliday] = useState(false);
  const [showInviteNumberWarningPopup, setShowInviteNumberWarningPopup] = useState(false);

  const handleRemoveDate = (index) => {
    setShowDeleted({ open: true, date: jobDates[index] });
  };

  const confirmRemoveDate = () => {
    const jobData = handleConfirmRemoveDate(jobDates, showDeleted.date);
    dispatch(selectDaysMDB({ jobData }));
    setShowDeleted({ open: false, date: null });
  };

  const handleConfirmDates = (jobData) => {
    dispatch(selectDaysMDB({ jobData }));
    setConfirmPostOnHoliday(false);
    onToggle();
  };

  const handleButtonClick = () => {
    if (
      checkIfAnyHoliday(
        convertDatesToTimezone(
          selectedDates.map((date) => moment(date).format('YYYY-MM-DD')),
          user.time_zone,
        ),
      )
    ) {
      setConfirmPostOnHoliday(true);
    } else {
      handleConfirmDates(createObjOfDates(selectedDates));
    }
  };

  useEffect(() => {
    setSelectedDates(
      jobDates?.length
        ? convertDatesToTimezone(jobDates).map((date) => new Date(date))
        : [],
    );
  }, [jobDates, user.time_zone]);

  return (
    <>
      <CollapsibleRow
        isOpen={isOpen}
        onToggle={onToggle}
        disabled={jobDates?.length && !isOpen}
        showArrow={!jobDates?.length}
        TitleComponent={() => <Title text="Dates:" />}
        titlePosition="flex-start"
        ValueComponent={() => (
          <Value
            handleRemoveDate={handleRemoveDate}
            onToggle={onToggle}
            showSelectedDates={!isOpen && jobDates?.length}
            values={values}
          />
        )}
        icon={CalendarIcon}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              fontFamily: 'Nunito',
              color: Colors.neutral_600,
              fontStyle: 'italic',
              fontSize: 16,
              marginBottom: 20,
            }}
          >
            Use the calendar below to request one or more days.
          </span>
          <DayPicker
            mode="multiple"
            onSelect={(dates) => {
              if (showInvite && dates.length > 10) {
                setShowInviteNumberWarningPopup(true);
                return;
              }
              setSelectedDates(dates)
            }}
            selected={selectedDates}
            fromMonth={fromMonth}
            components={{
              Caption: MonthSelection,
              Head: HeadRow,
            }}
            disabled={[
              {
                before: new Date(),
                after: moment().add(NUMBER_OF_DAYS_TO_ADD, 'days').toDate(),
              },
            ]}
          />

          <Button
            text="Select Dates"
            onClick={handleButtonClick}
            disabled={isDisabled}
            style={{
              backgroundColor: !isDisabled ? Colors.secondary_500 : Colors.secondary_100,
              width: 180,
              height: 48,
              marginTop: 20,
              marginBottom: 12,
            }}
          />
        </div>
      </CollapsibleRow>

      {showDeleted.open && (
        <ConfirmPopup
          title="Remove Date"
          description={
            <>
              Are you sure you would like to remove{' '}
              <strong>{moment(showDeleted.date).format('ddd, MMM DD, YYYY')}</strong>
              <br />
              from your request?
            </>
          }
          descriptionStyle={{ width: '95%', marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonStyle={{
            backgroundColor: Colors.secondary_500,
          }}
          closePopup={() => setShowDeleted({ open: false, date: null })}
          leftButtonAction={() => setShowDeleted({ open: false, date: null })}
          rightButtonText="Remove"
          rightButtonAction={confirmRemoveDate}
        />
      )}

      {confirmPostOnHoliday && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={80} height={80} color={Colors.warning_500} />
            </div>
          )}
          title="Major U.S Holiday Conflict"
          description={
            <>
              Looks like you are trying to post a shift on a<br />
              Major U.S. Holiday. Please make sure your office
              <br />
              will be open on this date!
            </>
          }
          rightButtonText="Continue"
          leftButtonText="Go Back"
          rightButtonAction={() => handleConfirmDates(createObjOfDates(selectedDates))}
          leftButtonAction={() => setConfirmPostOnHoliday(false)}
        />
      )}

      {showInviteNumberWarningPopup && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={80} height={80} color={Colors.warning_500} />
            </div>
          )}
          title="Sorry!"
          description="The maximum number of invites have been reached for this request form. You can send up to 10 shift invites at a time."
          rightButtonText="Close"
          rightButtonAction={() => setShowInviteNumberWarningPopup(false)}
        />
      )}
    </>
  );
};
