import React from 'react';
import '../themes/button.scss';
import { ClipLoader } from 'react-spinners';
import { Colors } from '../themes/colors';

type Props = {
    size: 'xsmall' | 'small' | 'medium' | 'large',
    color: 'primary' | 'secondary' | 'tertiary',
    text: string,
    textClassName: String,
    textStyle: React.CSSProperties,
    Icon: any,
    iconClassName: String,
    iconStyle: React.CSSProperties,
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const getButtonClassName = (props) => {
  // Default class names
  const classNames = [
    'button',
    props.size,
    props.color,
  ];

  if (props.disabled || props.isLoading) {
    classNames.push('disabled');
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return classNames.join(' ');
};

const getIconClassName = (props) => {
  // Default class names
  const classNames = [
    'icon',
  ];

  if (props.iconClassName) {
    classNames.push(props.iconClassName);
  }

  return classNames.join(' ');
};

const getTextClassName = (props) => {
  // Default class names
  const classNames = [
    'text',
  ];

  if (props.textClassName) {
    classNames.push(props.textClassName);
  }

  return classNames.join(' ');
};

const Button = (props: Props) => {
  const {
    size = 'medium',
    color = 'primary',
    text,
    textClassName,
    textStyle,
    Icon,
    iconClassName,
    iconStyle,
    disabled = false,
    isLoading = false,
    loadingColor = Colors.white,
    ...rest
  } = props;

  const defaultProps = {
    size,
    color,
    ...props,
  };

  return (
    <button
    // eslint-disable-next-line react/jsx-props-no-spreading
      type="button"
      {...rest}
      disabled={isLoading || disabled}
      className={getButtonClassName({ ...defaultProps })}
    >
      {!isLoading
        ? (
          <>
            {Icon && (
            <img
              src={Icon}
              style={iconStyle}
              className={getIconClassName({ ...defaultProps })}
              alt="button-icon"
            />
            )}
            {text && (
            <span
              style={textStyle}
              className={getTextClassName(defaultProps)}
            >
              {text}
            </span>
            )}
          </>
        )
        : <ClipLoader sizeUnit="px" size={24} color={loadingColor} loading={isLoading} />}
    </button>
  );
};

export default Button;
