import '../themes/confirm_hyg.scss';
import '../themes/global.scss';

import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { FilterStatusEnum } from '../utils/Enum';
import {
  CONFIRM_JOB_ACTION,
  confirmJob,
  DECLINE_CANDIDATE,
  declineCandidate,
  FETCH_JOB_ACTION,
  fetchJob,
  getAllSkillCategories,
  hideMessage,
  hidePaymentMissingPopup,
  showMessage,
} from '../actions';
import {
  createErrorObjectSelector,
  createLoadingSelector,
} from '../api/selectors';
import BackButton from '../commonComponents/BackButton';
import Button from '../commonComponents/Button';
import CardSpinner from '../commonComponents/CardSpinner';
import { isPartialShift } from '../commonComponents/CommonFunctions';
import { showCandidateOvertime } from '../growthbook';
import WarningIcon from '../images/icons/Warning';
import { Colors } from '../themes/colors';
import CandidateInfo from './CandidateInfo';
import {
  renderCounter,
} from './counterJobs/RenderFunc';
import CounterShiftPopup from './CounterShiftPopup';
import DeclineCandidatePopup from './DeclineCandidatePopup';
import ConfirmPopup from './shared/ConfirmPopup';
import Tooltip from './Tooltip';
import { isEmployeeOptionShift } from './WorkerClassification/utils';

const convenienceFeeTooltip = 'GoTu charges a 3.5% convenience fee on Credit Card transactions. If you do not wish to be charged the convenience fee, please update your billing to E-check/ACH.';

const overtimeTooltip = 'Overtime pay (1.5x rate) is required for hours that exceed the state’s daily and/or weekly regular working hour limits.';

class ConfirmHyg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      notes_for_temp: props.user.notes_for_temp || '',
      showFee: false,
      declineCandidatePopup: false,
      counterShiftPopup: false,
    };
  }

  componentDidMount() {
    const {
      location, fetchJob, match, allSkillCategories, getAllSkillCategories,
    } = this.props;

    const jobId = location.state ? location.state.jobId : match.params.jobId;

    fetchJob({ jobId, candidateId: match?.params?.candidateId });

    if (!allSkillCategories || allSkillCategories?.length === 0) {
      getAllSkillCategories();
    }
  }

  confirmOffer = () => {
    const { job, confirmJob, match } = this.props;
    const { notes_for_temp } = this.state;
    const candidateId = match?.params?.candidateId;
    const currentCounter = job?.multipleCounters?.find(
      (counter) => counter?.candidate?.id === candidateId,
    );

    confirmJob({
      jobId: job.id,
      data: { notes: notes_for_temp },
      counterOfferId: currentCounter?.id,
    });
  };

  declineCandidatePressed = () => {
    this.setState({ declineCandidatePopup: true });
  };

  handleAcceptClicked = () => {
    this.setState({ counterShiftPopup: false });
    this.confirmOffer();
  };

  handleCancelClicked = () => {
    this.setState({ counterShiftPopup: false });
  };

  render() {
    const {
      job,
      isLoading,
      declineCandidate,
      match,
      allSkillCategories,
      history,
      isShowPaymentMissingPopup,
      hidePaymentMissingPopup,
    } = this.props;
    const { declineCandidatePopup, counterShiftPopup } = this.state;

    const candidateId = match?.params?.candidateId;
    const currentCounter = job?.multipleCounters?.find(
      (counter) => counter?.candidate?.id === candidateId,
    );

    if (isLoading || !job || !job.best_counter) {
      if (job?.status === FilterStatusEnum.SCHEDULED) {
        history.replace(`/dashboard/job/${job.id}/details`);
        return <></>;
      } if (!currentCounter) {
        history.replace('/')
        return <></>;
      } 
      return <CardSpinner />;
    }

    const candidate = currentCounter?.candidate;

    const isCounterHours = isPartialShift(currentCounter);

    const amount = isCounterHours ? currentCounter?.payment?.hygienistPay : job.amount;
    const totalAmount = isCounterHours ? currentCounter?.payment?.totalAmount : job.total;
    const tempmeeFee = isCounterHours ? currentCounter?.payment?.tempmeeFee : job.tm_fee;
    const tempmeeFeePercent = isCounterHours
      ? (currentCounter?.payment?.tempmeeFeeRatio * 100).toFixed(1)
      : job.tm_fee_percent;
    const convenienceFee = isCounterHours
      ? currentCounter?.payment?.convenienceFee
      : job.convenience_fee;
    const convenienceFeePercent = isCounterHours
      ? (currentCounter?.payment?.convenienceFeeRatio * 100).toFixed(1)
      : job.convenience_fee_percent.toFixed(1);
    const workingHours = isCounterHours
      ? moment(currentCounter?.end).diff(moment(currentCounter?.begin), 'hours')
      : job.workingHourls;

    const chargeDate = moment(job.job_datetime)
      .subtract(28, 'days')
      .format('MM/DD/YYYY');
    const startDiff = moment(job.job_datetime).diff(moment(), 'days');

    const professionName = (() => {
      if (job.subProfession === 'EFDA'){
        return 'Expanded Functions DA'
      }

      return job.professionName
    })()

    return (
      <div
        className="component_container"
        ref={(element) => (this.divRef = element)}
      >
        <div className="floating_container">
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '95%' }}>
              <BackButton />
            </div>

            <h1 className="global_font bold" style={{ color: Colors.neutral_600 }}>
              Confirm Candidate
            </h1>

            <div
              style={{
                width: '90%',
                display: 'flex',
                justifyContent: 'center',
                padding: 25,
                boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  columnGap: 20,
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: 25,
                  marginBottom: 0,
                }}
              >
                <CandidateInfo
                  allSkillCategories={allSkillCategories}
                  candidate={candidate}
                  isFavorite={
                    job?.multipleCounters?.find(
                      (counter) => counter?.candidate?.id === candidate?.id,
                    )?.isFavorite
                  }
                  horizontalCard
                />

                <div
                  style={{
                    width: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '0px 24px 15px 24px',
                    rowGap: 12,
                    backgroundColor: 'rgba(231, 234, 235, 0.4)',
                  }}
                >
                  {renderCounter(
                    job?.rate !== job?.multipleCounters?.find(
                      (counter) => counter?.candidate?.id === candidateId,
                    )?.candidateRate,
                    job?.multipleCounters?.find(
                      (counter) => counter?.candidate?.id === candidateId,
                    )?.expiration || new Date(),
                  )}

                  <h1
                    className="global_font bold_font"
                    style={{
                      fontSize: 20,
                      margin: '0px 0px 25px 0px',
                      color: Colors.primary_500,
                      textAlign: 'center',
                    }}
                  >
                    Shift Details
                  </h1>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <p
                      className="global_font bold_font"
                      style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                    >
                      Office:
                    </p>
                    <p
                      className="global_font"
                      style={{ margin: 0, color: Colors.neutral_600 }}
                    >
                      {job.offer_owner?.office_name}
                    </p>
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <p
                      className="global_font bold_font"
                      style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                    >
                      Date:
                    </p>
                    <p
                      className="global_font"
                      style={{ margin: 0, color: Colors.neutral_600 }}
                    >
                      {job.local_date}
                    </p>
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <p
                      className="global_font bold_font"
                      style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                    >
                      Profession:
                    </p>
                    <p
                      className="global_font"
                      style={{ margin: 0, color: Colors.neutral_600 }}
                    >
                      {professionName}
                    </p>
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <p
                      className="global_font bold_font"
                      style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                    >
                      Preferred Specialty:
                    </p>
                    <p
                      className="global_font"
                      style={{
                        margin: 0,
                        color: Colors.neutral_600,
                        fontSize: 16,
                        textTransform: 'capitalize',
                        textAlign: 'right',
                      }}
                    >
                      {job?.specialty?.split('_')?.[1] || 'General'}
                    </p>
                  </div>

                  <HighlightedLines highlight={isCounterHours}>
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p
                          className="global_font bold_font"
                          style={{
                            margin: 0,
                            color: Colors.neutral_600,
                            fontSize: 16,
                          }}
                        >
                          Hours:
                        </p>

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: Colors.neutral_300,
                            height: 'min-content',
                            padding: '0px 5px',
                            marginLeft: 8,
                            borderRadius: 50,
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              color: Colors.white,
                              fontFamily: 'Nunito',
                              fontSize: 12,
                              fontWeight: 'bold',
                            }}
                          >
                            EST
                          </p>
                        </div>
                      </div>

                      <p
                        className="global_font"
                        style={{
                          margin: 0,
                          color: Colors.neutral_600,
                          textDecoration: isCounterHours ? 'line-through' : 'none',
                        }}
                      >
                        {`${job.local_start_time} - ${job.local_end_time}`}
                      </p>
                    </div>

                    {
                      isCounterHours && (
                        <div
                          style={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                          <p
                            className="global_font bold_font"
                            style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                          >
                            *Counter Offer (hours):
                          </p>
                          <p
                            className="global_font"
                            style={{ margin: 0, color: Colors.neutral_600, textTransform: 'lowercase', fontWeight: 700 }}
                          >
                            {`${currentCounter?.local_begin} - ${currentCounter?.local_end}`}
                          </p>
                        </div>
                      )
                    }
                  </HighlightedLines>

                  {isCounterHours && <div style={{ marginTop: -20 }} />}

                  <HighlightedLines highlight={job?.rate !== currentCounter?.candidateRate}>
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <p
                        className="global_font bold_font"
                        style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                      >
                        Hourly Rate:
                      </p>
                      <p
                        className="global_font"
                        style={{
                          margin: 0,
                          color: Colors.neutral_600,
                          textDecoration: job?.rate !== currentCounter?.candidateRate ? 'line-through' : 'none',
                        }}
                      >
                        {`$${job.rate.toFixed(2)}`}
                      </p>
                    </div>

                    {job?.rate !== currentCounter?.candidateRate ? (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p
                          className="global_font bold_font"
                          style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                        >
                          *Counter Offer (rate):
                        </p>
                        <p
                          className="global_font"
                          style={{ margin: 0, color: Colors.neutral_600, fontWeight: 700 }}
                        >
                          {`$${currentCounter?.candidateRate?.toFixed(2)}`}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </HighlightedLines>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <p
                      className="global_font bold_font"
                      style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                    >
                      Break:
                    </p>
                    <p
                      className="global_font"
                      style={{ margin: 0, color: Colors.neutral_600 }}
                    >
                      {job.lunch_final_break_time}
                      {' '}
                      min
                      {' '}
                      {job.is_lunch_break_paid ? ' (Paid)' : ' (Unpaid)'}
                    </p>
                  </div>

                  {!showCandidateOvertime() ?
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <p
                        className="global_font bold_font"
                        style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                      >
                        {`Professional Pay (${workingHours.toFixed(1)} hrs):`}
                      </p>
                      <p
                        className="global_font"
                        style={{ margin: 0, color: Colors.neutral_600 }}
                      >
                        $
                        {amount.toFixed(2)}
                      </p>
                    </div>
                    :
                    <HighlightedLines highlight={job?.regularAndOvertimeHours?.overtime > 0}>
                      {job?.regularAndOvertimeHours?.regular > 0 && job?.totalRegular > 0 &&
                        <div
                          style={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                          <p
                            className="global_font bold_font"
                            style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                          >
                            Regular Rate Pay ({job?.regularAndOvertimeHours?.regular?.toFixed(2)} hrs):
                          </p>

                          <p
                            className="global_font"
                            style={{ margin: 0, color: Colors.neutral_600 }}
                          >
                            {`$${job?.totalRegular?.toFixed(2)}`}
                          </p>
                        </div>
                      }
                      {job?.regularAndOvertimeHours?.overtime > 0 && job?.totalOvertime > 0 &&
                        <div
                          style={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                          <p
                            className="global_font bold_font"
                            style={{ margin: 0, color: Colors.neutral_600, fontSize: 16, display: 'flex' }}
                          >
                            *Overtime Pay ({job?.regularAndOvertimeHours?.overtime?.toFixed(2)} hrs):
                            <span
                              style={{
                                marginLeft: '5px',
                                position: 'relative',
                                bottom: '2.5px',
                                marginBottom: -10,
                              }}
                            >
                              <Tooltip content={overtimeTooltip} />
                            </span>
                          </p>

                          <p
                            className="global_font"
                            style={{ margin: 0, color: Colors.neutral_600, fontWeight: 700 }}
                          >
                            {`$${job?.totalOvertime?.toFixed(2)}`}
                          </p>
                        </div>
                      }
                    </HighlightedLines>
                  }

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {tempmeeFeePercent !== 0 ? (
                      <p
                        className="global_font bold_font"
                        style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                      >
                        Service Fee (
                        {tempmeeFeePercent}
                        %):
                      </p>
                    ) : (
                      <p
                        className="global_font bold_font"
                        style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                      >
                        Service Fee:
                      </p>
                    )}

                    <p
                      className="global_font"
                      style={{ margin: 0, color: Colors.neutral_600 }}
                    >
                      {`$${tempmeeFee.toFixed(2)}`}
                    </p>
                  </div>
                  {job.employeeOptionFee !== 0 && isEmployeeOptionShift(job) && (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p
                        className="global_font bold_font"
                        style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                      >
                        Employee Fee:
                      </p>
                      <p
                        className="global_font"
                        style={{ margin: 0, color: Colors.neutral_600 }}
                      >
                        {job.employeeOptionFee ? `$${job.employeeOptionFee?.toFixed(2)}` : '$--.--'}
                      </p>
                    </div>
                  )}

                  {job.convenience_fee !== 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <p
                          className="global_font bold_font"
                          style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                        >
                          Convenience Fee
                          {' '}
                          {`(${convenienceFeePercent || '0'}%)`}
                          :
                        </p>
                        <span
                          style={{
                            marginLeft: '5px',
                            position: 'relative',
                            bottom: '2.5px',
                          }}
                        >
                          <Tooltip content={convenienceFeeTooltip} />
                        </span>
                      </div>
                      <p
                        className="global_font"
                        style={{ margin: 0, color: Colors.neutral_600 }}
                      >
                        {`$${convenienceFee.toFixed(2) || '0'}`}
                      </p>
                    </div>
                  ) : null}

                  {job?.multi_day_booking && startDiff > 28 ? (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p
                        className="global_font bold_font"
                        style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                      >
                        Professional pay charge date:
                      </p>
                      <p
                        className="global_font"
                        style={{ margin: 0, color: Colors.neutral_600 }}
                      >
                        {chargeDate}
                      </p>
                    </div>
                  ) : null}

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      className="global_font bold_font"
                      style={{
                        margin: 0,
                        color: Colors.neutral_600,
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: 5,
                        fontSize: 16,
                      }}
                    >
                      <p style={{ color: Colors.success_500, margin: 0 }}>Total Pay:</p>
                    </p>

                    <p
                      className="global_font bold"
                      style={{ margin: 0, color: Colors.neutral_600 }}
                    >
                      $
                      {totalAmount.toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: '90%',
                padding: '0px 25px',
                margin: '8px 0px 40px 0px',
                boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '20px 0px',
                }}
              >
                <h1
                  className="global_font bold_font"
                  style={{ margin: 10, color: Colors.neutral_600 }}
                >
                  Notes for Temp:
                </h1>
                <textarea
                  className="text_notes"
                  style={{
                    borderWidth: 0,
                    borderRadius: 10,
                    backgroundColor: Colors.neutral_50,
                    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
                    padding: '12px 20px',
                    width: 500,
                    height: 80,
                  }}
                  placeholder="(ex. Check in at front desk)"
                  value={this.state.notes_for_temp}
                  onChange={(e) => this.setState({ notes_for_temp: e.target.value })}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  margin: '40px 20px',
                }}
              >
                <Button
                  text="Decline"
                  style={{
                    width: 180, backgroundColor: Colors.secondary_500, marginRight: 20,
                  }}
                  onClick={() => this.declineCandidatePressed()}
                />
                <Button
                  text="Confirm"
                  style={{ width: 180, backgroundColor: Colors.primary_500 }}
                  onClick={() => {
                    if (isCounterHours) {
                      this.setState({ counterShiftPopup: true });
                    } else {
                      this.confirmOffer();
                    }
                  }}
                />

              </div>
            </div>
          </div>
        </div>
        {
          counterShiftPopup ? (
            <CounterShiftPopup
              job={job}
              currentCounter={currentCounter}
              acceptBtnPressed={this.handleAcceptClicked}
              cancelBtnPressed={this.handleCancelClicked}
            />
          ) : null
        }
        {
          declineCandidatePopup ? (
            <DeclineCandidatePopup
              declineBtnPressed={(cancellation_reason) => {
                declineCandidate({
                  jobId: job.id,
                  counterOfferId: currentCounter?.id,
                  cancellation_reason,
                });
                this.setState({ declineCandidatePopup: false });
              }}
              goBackPressed={() => this.setState({ declineCandidatePopup: false })}
            />
          ) : (
            <div />
          )
        }
        {
          isShowPaymentMissingPopup ? (
            <ConfirmPopup
              Icon={() => (
                <div style={{ marginBottom: 20 }}>
                  <WarningIcon width={64} height={64} color={Colors.warning_500} />
                </div>
              )}
              title="Payment Method Missing"
              description='Please add your preferred payment method to "Confirm" or "Decline" a candidate.'
              descriptionStyle={{ width: '76%', marginBottom: 50 }}
              leftButtonText="Go Back"
              leftButtonAction={() => hidePaymentMissingPopup()}
              rightButtonText="Add Payment"
              rightButtonAction={() => {
                hidePaymentMissingPopup();
                history.push('/dashboard/payment');
              }}
            />
          ) : null
        }

      </div>
    );
  }
}

const actions = [CONFIRM_JOB_ACTION, FETCH_JOB_ACTION, DECLINE_CANDIDATE];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorObjectSelector(actions);

const mapStateToProps = (state) => ({
  user: state.user.user,
  error: errorSelector(state),
  job: state.job.job,
  allSkillCategories: state.user.allSkillCategories,
  isLoading: loadingSelector(state),
  isShowPaymentMissingPopup: state.payments.isShowPaymentMissingPopup,
});

export default connect(mapStateToProps, {
  showMessage,
  confirmJob,
  fetchJob,
  getAllSkillCategories,
  hideMessage,
  declineCandidate,
  hidePaymentMissingPopup,
})(ConfirmHyg);

const HighlightedLines = ({ highlight, children }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: highlight ? Colors.neutral_150 : 'transparent',
    gap: highlight ? 10 : 12,
    ...(highlight && {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 10,
      paddingRight: 10,
      marginLeft: -10,
      marginRight: -10,
      borderRadius: 4
    })
  }}>
    {children}
  </div>
);
