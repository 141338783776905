import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DentistCoatImage from '../images/dentist-coat.svg';
import { Colors } from '../themes/colors';
import ConfirmPopup from './shared/ConfirmPopup';
import { getShouldOfficeShowEfda } from '../growthbook';

export function OfficeEfdaOnboardingPopup() {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [showPopup, setShowPopup] = useState(false);
  const isSameOrBefore = moment().isBefore('2024-08-02');

  const shouldOfficeShowEfda = getShouldOfficeShowEfda()

  const getStorage = () => {
    const showOfficeEfdaOnboardingPopUp = localStorage.getItem(
      `showOfficeEfdaOnboardingPopUp-${user?.id}`,
    );

    if (showOfficeEfdaOnboardingPopUp === null) {
      setShowPopup(true);
    }
  };

  const setStorage = () => {
    localStorage.setItem(`showOfficeEfdaOnboardingPopUp-${user?.id}`, 'false');
    setShowPopup(false);
  };

  const handleConfirm = () => {
    setStorage();
  };

  useEffect(() => {
    getStorage();
  }, [user]);

  return (
    shouldOfficeShowEfda &&
    isSameOrBefore &&
    showPopup && (
      <ConfirmPopup
        Icon={() => (
          <img
            alt="Dentist Coat Onboarding Header"
            src={DentistCoatImage}
            style={{ width: 224, height: 158, marginBottom: 24 }}
          />
        )}
        title="We have Expanded Functions DA now!"
        description="We are excited to announce the launch of Expanded Functions DA to GoTu for specific offices on our platform. You will now be able to post shifts and we will be able to find an EFDA for your office!"
        descriptionStyle={{ color: Colors.neutral_500, marginBottom: 32 }}
        rightButtonText="Got it!"
        rightButtonAction={handleConfirm}
        closePopup={handleConfirm}
      />
    )
  );
}
