import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSuccessWorkerClassificationPopup, saveChildOfficeWorkerClassification } from '../../../actions';
import CustomPortal from '../../../commonComponents/CustomPortal';
import WarningIcon from '../../../images/icons/Warning';
import { Colors } from '../../../themes/colors';
import ConfirmPopup from '../../shared/ConfirmPopup';
import { Table } from '../../../commonComponents/Table/Table';
import { WC_MANAGEMENT_STATUS, headerUnselectedTitles } from '../utils';
import { renderRow } from './renderRow';

export const Unselected = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({ isOpen: false, item: null, workerType: null });
  const unselectedRequests = useSelector(
    (state) => state.user.workerClassificationManagement.unselected,
  );
  const user = useSelector(
    (state) => state.user,
  );
  
  const handleOnClick = (item, workerType) => {
    setModal({ isOpen: true, item, workerType });
  };

  const handleConfirmButton = async () => {
    dispatch(saveChildOfficeWorkerClassification({ 
      id: modal.item.id,
      workerType: modal.workerType 
    }));
    handleCloseModal()
  };

  const handleCloseSuccessPopup = async () => {
    dispatch(hideSuccessWorkerClassificationPopup());
  };

  const handleCloseModal = () => {
    setModal({ isOpen: false, item: null, workerType: null });
  }

  return (
    <>
      {!unselectedRequests?.length && (
        <div>
          <span
            style={{
              fontFamily: 'Nunito',
              display: 'flex',
              color: Colors.neutral_500,
              fontStyle: 'italic',
              textAlign: 'center',
            }}
          >
            There are no offices with unselected worker types. The offices associated with
            this account either have requested a preference or already have their worker
            classification confirmed. Please check the &quot;Pending Requests&quot; tab to see if
            there are any outstanding requests.
          </span>
        </div>
      )}

      {
        unselectedRequests?.length > 0 && 
        <Table
          data={unselectedRequests}
          renderRow={(item) => renderRow(item, handleOnClick)}
          headerTitles={headerUnselectedTitles}
        />
      }
      
      {modal.isOpen && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={80} height={80} color={Colors.warning_500} />
            </div>
          )}
          title="Please Confirm"
          description={
            <>
              Please be aware, this decision will be final. By confirming,
              <br />
              you will be approving the worker type for <br />
              {modal.workerType === 'contractor' ? 'Contracted' : 'Employed'} professionals at{' '}
              {modal.item.officeName}.
            </>
          }
          descriptionStyle={{ width: '100%', marginBottom: 80 }}
          leftButtonText="Go Back"
          leftButtonAction={handleCloseModal}
          rightButtonText="Confirm"
          rightButtonAction={handleConfirmButton}
          closePopup={handleCloseModal}
        />
      )}

      {
        user?.showWorkerClassificationManagementPopup === WC_MANAGEMENT_STATUS.CONFIRMED && 
        <CustomPortal
          isSuccess
          redirect={false}
          title="Worker Type Successfully Selected!"
          text="This office’s worker type selection has been confirmed."
          submitPressed={handleCloseSuccessPopup}
        />
      }
    </>
  );
};
