/* eslint-disable no-case-declarations */
import moment from 'moment';
import { apiMethodsConst } from '../services/api';

const initialState = {
  accessToken: '',
  deviceToken: '',
  expirationDate: null,
  forgotPasswordFlow: {
    value: '',
    method: '',
    step: 'verification',
    showErrorModal: false,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
  case apiMethodsConst.SIGN_IN_REQUEST:
  case apiMethodsConst.AUTH_SIGN_UP_REQUEST:
  case apiMethodsConst.AUTH_REFRESH_REQUEST:
    const newRefreshToken = action.payload.refresh_token || '';
    const tokenDuration = parseInt(action.payload.expires_in);
    const expirationDate = moment()
      .add(tokenDuration - 10, 'seconds')
      .toDate();

    localStorage.setItem('refresh_token_dental', newRefreshToken);

    return {
      ...state,
      accessToken: action.payload.access_token,
      expirationDate,
      refreshToken: action.payload.refresh_token,
    };

  case apiMethodsConst.FORGOT_PASSWORD_REQUEST.SEND_CODE:
    return {
      ...state,
      forgotPasswordFlow: {
        ...action.payload,
        step: 'reset',
      }
    };

  case apiMethodsConst.FORGOT_PASSWORD_REQUEST.RESET:
    return {
      ...state,
      forgotPasswordFlow: {
        value: '',
        method: '',
        step: 'successful',
        showErrorModal: false,
      }
    };

  case 'HIDE_FORGOT_PASSWORD_ERROR_MODAL':
    return {
      ...state,
      forgotPasswordFlow: {
        ...state.forgotPasswordFlow,
        showErrorModal: false,
      }
    };

  case 'CLEAR_FORGOT_PASSWORD_STATE':
    return {
      ...state,
      forgotPasswordFlow: {
        value: '',
        method: '',
        step: 'verification',
        showErrorModal: false,
      }
    };
  default:
    return state;
  }
};
