import { UserProfession } from '../../enums/UserProfession';

export const filterFavorites = (list, searchFilter) =>
  list?.filter((professional) =>
    professional?.fullname?.toLowerCase()?.includes(searchFilter?.toLowerCase()),
  );

export const mapFavoriteToRowProps = (item, job, allProfessions) => ({
  ...item,
  _id: item.id,
  isFavorite: true,
  inviteSent: job?.favoriteInvites?.some(({ professional }) => professional === item.id),
  profession: item.professions?.includes(job?.profession)
    ? allProfessions?.find(({ code }) => job?.profession === code)?.name
    : allProfessions?.find(({ code }) => item.professions[0] === code)?.name,
});

export const filterRecentProfessionals = (list, searchFilter) =>
  list?.filter((item) =>
    item?.professional?.full_name?.toLowerCase()?.includes(searchFilter?.toLowerCase()),
  );

export const mapRecentToRowProps = (item, job) => ({
  ...item,
  _id: item.professional?.id,
  fullname: item?.professional?.full_name,
  profileImage: item.professional?.image,
  isAvailable: true,
  profession: UserProfession.getName(item.profession),
  inviteSent: job?.favoriteInvites?.some(
    ({ professional }) => professional === item.professional?.id,
  ),
});
