import { format } from 'date-fns';
import { useNavigation } from 'react-day-picker';
import ArrowRight from '../../../../../../images/arrow_right.svg';
import { Colors } from '../../../../../../themes/colors';

export function MonthSelection(props) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 12px',
      }}
    >
      <button
        type="button"
        style={{
          all: 'unset',
          cursor: !previousMonth ? 'default' : 'pointer',
          opacity: !previousMonth ? 0 : 1,
          transform: 'rotate(180deg)',
          width: 20,
          height: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      >
        <img
          src={ArrowRight}
          alt="Previous month"
          style={{
            width: 16,
            height: 16,
          }}
        />
      </button>
      <span
        style={{
          fontFamily: 'Nunito',
          color: Colors.neutral_600,
          fontSize: 22,
          fontWeight: 700,
        }}
      >
        {format(props.displayMonth, 'MMMM yyy')}
      </span>
      <button
        type="button"
        style={{
          all: 'unset',
          cursor: 'pointer',
          width: 20,
          height: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      >
        <img
          src={ArrowRight}
          alt="Next month"
          style={{
            width: 16,
            height: 16,
          }}
        />
      </button>
    </div>
  );
}
