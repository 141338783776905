import { UserProfession } from "../../../../enums/UserProfession";
import { Colors } from "../../../../themes/colors";

export const ProfessionalCardDetails = ({ user }) => {
  const {isAvailable, fullname, activityLevel, professions } = user;
  const unavailableColorText = isAvailable ? Colors.neutral_500 : Colors.neutral_300;
  
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '24px',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <span
        style={{
          fontFamily: 'Nunito',
          fontSize: 22,
          fontWeight: 700,
          color: isAvailable ? Colors.neutral_600 : Colors.neutral_300,
          maxWidth: 460,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {fullname}
      </span>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 12,
        }}
      >
        <span
          style={{
            fontFamily: 'Nunito',
            fontSize: 16,
            color: unavailableColorText,
          }}
        >
          {UserProfession.getName(professions[0])}
        </span>
        <span
          style={{
            fontFamily: 'Nunito',
            fontSize: 16,
            color: unavailableColorText,
            fontWeight: 'bold',
          }}
        >
          • Activity Level:{' '}
          <span
            style={{
              fontWeight: 'normal',
              textTransform: 'capitalize',
            }}
          >
            {activityLevel}
          </span>
        </span>
        {!isAvailable && (
          <span
            style={{
              fontFamily: 'Nunito',
              fontSize: 16,
              color: unavailableColorText,
            }}
          >
            • Currently Unavailable
          </span>
        )}
      </div>
    </div>
  )
}