import React from 'react';

import { Colors } from '../../themes/colors';

const ArrowUpIcon = ({ color = Colors.success_300 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M5 15L12 8L19 15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default ArrowUpIcon;
