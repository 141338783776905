import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Colors } from '../themes/colors';
import Checkcircle from '../commonComponents/Checkcircle';
import { editUser, EDIT_USER, fetchMetadata } from '../actions';
import arrowDown from '../images/thinArrowDown.svg';
import { createLoadingSelector } from '../api/selectors';
import InputSelect from '../commonComponents/InputSelect';

export default function NewFeaturePopup() {
  const dispatch = useDispatch();
  const [isParkingFree, setIsParkingFree] = useState();
  const [parkingCost, setParkingCost] = useState('');
  const [parkingCostType, setParkingCostType] = useState('hr');
  const [parkingType, setParkingType] = useState('');

  const user = useSelector((state) => state.user.user);
  const loadingSelector = createLoadingSelector([EDIT_USER]);
  const isLoading = useSelector((state) => loadingSelector(state));
  const allMetadata = useSelector((state) => state.user.allMetadata);

  useEffect(() => {
    if (!allMetadata) {
      dispatch(fetchMetadata());
    }
  }, []);

  const goToFeature = () => {
    const data = {
      parkingInformation: {
        isParkingFree,
        cost: Number(parkingCost),
        costType: parkingCostType,
        parkingType,
      },
    };
    dispatch(editUser({ data }));
  };

  const parkingCostInformationProvided = (isParkingFree !== undefined && parkingCostType) && (isParkingFree || parkingCost && parkingCostType);

  const opacityVariants = {
    show: { opacity: 1 },
    hide: { opacity: 0 },
  };

  if (!user?.parkingInformation && user?.is_profile_complete) {
    return (
      <div
        className="modal"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          position: 'fixed',
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          style={{
            width: 500,
            backgroundColor: Colors.white,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 14,
            padding: 60,
            overflowY: 'auto',
            paddingTop: 30,
            paddingBottom: 25,
            overflow: 'visible',
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <div>
            <p
              className="global_font f-dark"
              style={{
                textAlign: 'center',
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >
              Please Answer the Following Question:
            </p>
            <div
              style={{
                width: 400,
                paddingLeft: 40,
                paddingRight: 40,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >

              <p style={{ fontFamily: 'Nunito', color: Colors.neutral_600, margin: '0px 0px 12px 0px' }}>
                Free parking is available.
              </p>

              <div
                style={{
                  display: 'flex', cursor: 'pointer', marginBottom: 8, alignItems: 'center',
                }}
                onClick={() => setIsParkingFree(true)}
              >
                <Checkcircle checked={isParkingFree} />

                <p className="global_font f-dark" style={{ margin: '0px 0px 0px 15px' }}>Yes</p>
              </div>

              <div
                style={{
                  display: 'flex', cursor: 'pointer', height: 42, alignItems: 'center',
                }}
                onClick={() => setIsParkingFree(false)}
              >
                <Checkcircle checked={isParkingFree === false} />

                <p className="global_font f-dark" style={{ alignSelf: 'center', margin: '0px 12px 0px 15px' }}>No</p>

              </div>
              { isParkingFree === false
                ? (
                  <motion.div
                    animate={isParkingFree === false ? 'show' : 'hide'}
                    variants={opacityVariants}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <p className="input_names" style={{ fontSize: 16, width: 'auto', marginRight: 12 }}>Parking cost:</p>

                    <input
                      className="partnership-code-input"
                      type="text"
                      value={parkingCost ? `US$ ${parkingCost}` : ''}
                      placeholder="US$ --,--"
                      onChange={(e) => {
                        const value = e.target.value?.replace('US$ ', '');

                        if (/^\d+(\.\d{0,2})?$/.test(value) || value === '') {
                          setParkingCost(value);
                        }
                      }}
                      onClick={(e) => {
                        e.currentTarget.value = '';
                        e.currentTarget.value = parkingCost ? `US$ ${parkingCost}` : '';
                      }}
                      style={{ ...Styles.textField, width: 120, marginRight: 12 }}
                    />

                    <InputSelect
                      value={parkingCostType}
                      setValue={(value) => setParkingCostType(value)}
                      options={[{ title: 'per hour', value: 'hr' }, { title: 'per day', value: 'day' }]}
                    />
                  </motion.div>
                )

                : <div />}
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 12,
                alignSelf: 'center',
              }}
            >
              <p className="input_names" style={{ alignSelf: 'flex-start', marginTop: 0, fontSize: 16 }}>Parking Type:</p>

              <InputSelect
                value={parkingType}
                setValue={(value) => setParkingType(value)}
                options={allMetadata?.parkingTypes?.map((item) => ({ title: item, value: item }))}
              />
            </div>
          </div>
          {
            isLoading ? <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading={isLoading} />
              : (
                <button
                  disabled={!parkingCostInformationProvided}
                  type="button"
                  className="green-button"
                  style={{
                    alignSelf: 'center',
                    width: 150,
                    backgroundColor: parkingCostInformationProvided ? Colors.primary_500 : Colors.primary_200,
                    marginTop: 20,
                  }}
                  onClick={goToFeature}
                >
                  <p className="white-text-18">Submit</p>
                </button>
              )
          }
        </motion.div>
      </div>
    );
  }
}

const Styles = {
  regularText: {
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    fontSize: 18,
  },
  selectField: {
    width: '100%',
    backgroundColor: Colors.neutral_50,
    border: 'none',
    padding: '0 20px',
    borderRadius: 5,
    appearance: 'none',
    fontSize: 16,
    fontWeight: 'normal',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
    background: `url('${arrowDown}') no-repeat 96% center #F8F8F9`,
  },
  textField: {
    maxWidth: '100%',
    width: 120,
    backgroundColor: Colors.neutral_50,
    border: 'none',
    padding: '10px 20px',
    margin: 0,
    borderRadius: 5,
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    fontSize: 16,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  },
};
