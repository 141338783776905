import { Colors } from '../../themes/colors';

const UserGroup = ({ color = Colors.secondary_500 }) => (
  <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Outline/user-group">
      <path
        id="Icon"
        d="M19.8333 23.3334H25.6666V21.0001C25.6666 19.0671 24.0996 17.5001 22.1666 17.5001C21.0517 17.5001 20.0586 18.0213 19.4176 18.8335M19.8333 23.3334H8.16659M19.8333 23.3334V21.0001C19.8333 20.2345 19.6858 19.5033 19.4176 18.8335M8.16659 23.3334H2.33325V21.0001C2.33325 19.0671 3.90026 17.5001 5.83325 17.5001C6.94813 17.5001 7.94125 18.0213 8.58221 18.8335M8.16659 23.3334V21.0001C8.16659 20.2345 8.31408 19.5033 8.58221 18.8335M8.58221 18.8335C9.44234 16.6846 11.5439 15.1667 13.9999 15.1667C16.456 15.1667 18.5575 16.6846 19.4176 18.8335M17.4999 8.16675C17.4999 10.0997 15.9329 11.6667 13.9999 11.6667C12.0669 11.6667 10.4999 10.0997 10.4999 8.16675C10.4999 6.23375 12.0669 4.66675 13.9999 4.66675C15.9329 4.66675 17.4999 6.23375 17.4999 8.16675ZM24.4999 11.6667C24.4999 12.9554 23.4553 14.0001 22.1666 14.0001C20.8779 14.0001 19.8333 12.9554 19.8333 11.6667C19.8333 10.3781 20.8779 9.33342 22.1666 9.33342C23.4553 9.33342 24.4999 10.3781 24.4999 11.6667ZM8.16659 11.6667C8.16659 12.9554 7.12192 14.0001 5.83325 14.0001C4.54459 14.0001 3.49992 12.9554 3.49992 11.6667C3.49992 10.3781 4.54459 9.33342 5.83325 9.33342C7.12192 9.33342 8.16659 10.3781 8.16659 11.6667Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default UserGroup;
