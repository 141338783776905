import React from 'react';
import Checkcircle from '../../../commonComponents/Checkcircle';

export const RadioOption = ({
  text, checked, onClick, textStyle, disabled = false
}) => (
  <button
    type="button"
    style={{
      all: 'unset', display: 'flex', cursor: 'pointer', alignItems: 'center'
    }}
    onClick={onClick}
    disabled={disabled}
  >
    <Checkcircle checked={checked} />

    <p
      className="global_font f-dark"
      style={{ margin: '0px 0px 0px 22px', ...textStyle }}
    >
      {text}
    </p>
  </button>
);
