import React from 'react';
import Portal from '../../commonComponents/Portal';

import { Colors } from '../../themes/colors';
import { renderText } from './RenderFunc';
import chatGuidelines from '../../images/chatGuidelines.png';

export default ({ gotItClicked }) => (
  <Portal>
    <div className="modal">
      <div
        className="modal_content"
        style={{
          minHeight: 0,
          padding: 20,
          margin: 25,
          width: '40%',
        }}
      >
        <div
          style={{
            backgroundColor: Colors.white,
            width: '90%',
            borderRadius: 20,
            padding: 40,
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 50,
          }}
        >
          <img
            src={chatGuidelines}
            style={{ alignSelf: 'center', width: 116, height: 78 }}
            alt="image"
          />
          <div
            style={{
              fontFamily: 'Nunito',
              color: Colors.primary_500,
              fontSize: 18,
              fontWeight: 'bold',
              marginTop: 20,
              marginBottom: 15,
              textAlign: 'center',
            }}
          >
            Community Communication Guidelines
          </div>
          {renderText('All chats are monitored for safety and security of our community.')}
          {renderText('Chat is for professional purposes around scheduled shift only.')}
          {renderText('Include support to report any issue or for shift adjustments.')}
          <div
            onClick={() => gotItClicked()}
            style={{
              backgroundColor: Colors.primary_500,
              borderRadius: 23,
              height: 50,
              width: '70%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
              marginTop: 30,
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                fontFamily: 'Nunito',
                fontWeight: 'bold',
                fontSize: 18,
                color: Colors.white,
                textAlign: 'center',
              }}
            >
              Got it
            </div>
          </div>
        </div>
      </div>
    </div>
  </Portal>
);
