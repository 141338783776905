import { Component } from 'react';
import { connect } from 'react-redux';
import '../themes/registration.scss';

import { PHONE_VERIFICATION_ACTION, editUser } from '../actions';
import { createLoadingSelector } from '../api/selectors';
import SelectBreakTimePopup from '../components/Request/SelectBreakTimePopup';
import { AdditionalOfficeInformation } from '../components/registration/AdditionalOfficeInformation';
import closeIcon from '../images/close-icon.svg';
import { Colors } from '../themes/colors';
import Button from './Button';
import { VerificationInput } from './VerificationInput';

const breakTimes = ['00', '15', '30', '45', '60', '90', '120', '180'];

const actions = [
  PHONE_VERIFICATION_ACTION,
];
const loadingSelector = createLoadingSelector(actions);

class MobileAppModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: '',
      disabledBtn: false,
      selectedBreakTimeIndex: 2,
      breakPaid: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show &&
      this.props.defaultValue &&
      'lunchBreakTime' in this.props.defaultValue &&
      'isLunchBreakPaid' in this.props.defaultValue) {
      const defaultValueIndex = breakTimes
        .findIndex((time) => time === `${this.props.defaultValue?.lunchBreakTime}`);

      this.setState({
        selectedBreakTimeIndex: defaultValueIndex > -1 ? defaultValueIndex : 2,
        breakPaid: this.props.defaultValue?.isLunchBreakPaid ?? false,
      })
    }
  }

  closeOfficeInfo = () => {
    const { jobId } = this.props;

    this.props.editUser({
      // TODO: fix it
      data: { is_info_asked: true },
      jobId,
    });

    this.props.closeModal();
  };

  submitBreak = (selectedBreakTimeIndex, breakPaid) => {
    if (selectedBreakTimeIndex >= 0) {
      const data = {
        time: breakTimes[selectedBreakTimeIndex],
        paid: breakPaid,
      };
      this.props.result(data);
      this.setState({ disabledBtn: true });
      this.props.closeModal();
    } else {
      alert('Please provide valid information');
    }
  };

  submitPin = (value) => {
    // send verification code
    this.props.result(value);
  };

  submitVerificationAmounts = () => {
    const { amountFirst, amountSecond } = this.state;
    const amounts = [amountFirst, amountSecond];
    this.props.result(amounts);
  };

  showOfficeInfo() {
    return (
      <div className="modal">
        <div className="modal_content">
          <AdditionalOfficeInformation jobId={this.props.jobId} />
          <p
            className="input_names"
            style={{
              fontSize: 36,
              fontWeight: 600,
              color: Colors.neutral_300,
              cursor: 'pointer',
              position: 'absolute',
              margin: '65px 0px 0px 550px',
              width: 24,
            }}
            onClick={() => this.closeOfficeInfo()}
          >
            X
          </p>
        </div>
      </div>
    );
  }

  confirmYourPhoneNumber() {
    const { isLoading } = this.props;

    return (
      <div className="modal">
        <div
          className="modal_content"
          style={{
            justifyContent: 'flex-start',
            paddingBottom: 20,
            position: 'relative',
          }}
        >
          <img
            onClick={this.props.closeModal}
            src={closeIcon}
            style={{
              width: 24,
              height: 24,
              position: 'absolute',
              top: 20,
              left: '92%',
              cursor: 'pointer',
            }}
            alt="close_icon"
          />
          <span style={{
            fontFamily: 'Nunito',
            fontWeight: 700,
            color: Colors.neutral_600,
            fontSize: 28,
            marginTop: 36
          }}
          >
            Confirmation Code
          </span>
          <div
            style={{
              margin: '5px 10px',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              className="global_font f-dark"
              style={{
                margin: 0, marginTop: 56, textAlign: 'center', marginBottom: 32
              }}
            >
              We sent you a 5 digit confirmation
              <br />
              code to
              {' '}
              <strong>{this.props.number}</strong>
            </p>
            <VerificationInput
              onChange={(e) => this.setState({ pin: e })}
              onComplete={(e) => {
                this.setState({ pin: e })
                this.submitPin(e)
              }}
            />
          </div>
          <div style={{ marginBottom: 48, marginTop: 60 }}>
            <Button
              isLoading={isLoading}
              text="Submit"
              style={{ width: 152 }}
              size="small"
              textStyle={{ fontSize: 18 }}
              onClick={() => this.submitPin(this.state.pin)}
            />
          </div>
        </div>
      </div>
    );
  }

  veifyBankAccount() {
    return (
      <div className="modal">
        <div className="modal_content" style={{ justifyContent: 'flex-start' }}>
          <h1 className="title_font" style={{ color: Colors.primary_500, fontSize: 36, margin: 50 }}>
            Check Your Bank Transactions
          </h1>
          <p
            className="global_font f-dark"
            style={{ margin: 0, textAlign: 'center' }}
          >
            You were charge 2 microtransactions. Please enter them below.
          </p>
          <input
            className="input_registration"
            style={{
              width: 300,
              marginTop: 20,
              textAlign: 'center',
              fontSize: 22,
            }}
            placeholder="0.1"
            maxLength={5}
            value={this.state.amountFirst}
            onChange={(e) => this.setState({ amountFirst: e.target.value })}
          />
          <input
            className="input_registration"
            style={{
              width: 300,
              marginTop: 20,
              textAlign: 'center',
              fontSize: 22,
            }}
            placeholder="0.2"
            maxLength={5}
            value={this.state.amountSecond}
            onChange={(e) => this.setState({ amountSecond: e.target.value })}
          />
          <div style={{ marginTop: 25, marginBottom: 25 }}>
            <Button
              text="Confirm"
              size="small"
              textStyle={{ fontSize: 16 }}
              onClick={this.submitVerificationAmounts}
            />
          </div>
          <p
            className="input_names"
            style={{
              fontSize: 36,
              fontWeight: 600,
              color: Colors.neutral_300,
              cursor: 'pointer',
              position: 'absolute',
              margin: '65px 0px 0px 550px',
              width: 24,
            }}
            onClick={this.props.closeModal}
          >
            X
          </p>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.show) {
      switch (this.props.content) {
      case 'office_info':
        return this.showOfficeInfo();
      case 'break':
        return (
          <SelectBreakTimePopup
            breakTimes={breakTimes}
            breakPaid={this.state.breakPaid}
            setBreakPaid={(value) => this.setState({ breakPaid: value })}
            selectedBreakTimeIndex={this.state.selectedBreakTimeIndex}
            setSelectedBreakTimeIndex={(value) => this.setState({ selectedBreakTimeIndex: value })}
            closeModal={this.props.closeModal}
            submitBreak={this.submitBreak}
          />
        );
      case 'pin':
        return this.confirmYourPhoneNumber();
      case 'bankVerification':
        return this.veifyBankAccount();
      default:
        return 'default';
      }
    } else {
      return <div />;
    }
  }
}

const Styles = {
  input: {
    width: 250,
    marginTop: 7,
    fontSize: 20,
    height: 32,
    padding: '0px 0px 10px 0px',
    borderRadius: 0,
  },
  closePopup: {
    position: 'absolute',
    marginTop: 8,
    marginRight: 24,
    fontSize: 24,
    color: Colors.secondary_500,
    cursor: 'pointer',
    width: '100%',
    textAlign: 'right',
  },
};

const mapStateToProps = (state) => ({
  allProfessions: state.user.allProfessions,
  isLoading: loadingSelector(state),
});

export default connect(mapStateToProps, { editUser })(MobileAppModal);
