import './index.css';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { MotionGlobalConfig } from 'framer-motion';
import 'moment-timezone';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import store from './store/configureStore';

import { growthbook } from './growthbook';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

growthbook.loadFeatures();

MotionGlobalConfig.skipAnimations = process.env.REACT_APP_TEMP_MEE_ENV === 'test'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_TEMP_MEE_ENV,
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <GrowthBookProvider growthbook={growthbook}>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Elements>
    </Provider>
  </GrowthBookProvider>,
  document.getElementById('root'),
);
