import moment from 'moment';
import { Colors } from '../../../../../themes/colors';

export const Date = ({ localDate, backgroundColor, small = false }) => {
  const inputDate = moment(localDate, 'YYYY-MM-DD');

  const dayOfWeek = inputDate.format('ddd');
  const dayOfMonth = inputDate.format('D');
  const abbreviatedMonth = inputDate.format('MMM');

  return (
    <div
      style={{
        display: 'flex',
        lineHeight: 1,
        gap: 16,
      }}
    >
      <div
        style={{
          width: 8,
          height: small ? 96 : 62,
          backgroundColor,
          borderRadius: 8,
          marginRight: small ? 16 : 0,
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: small ? 4 : 0,
        }}
      >
        <span
          style={{
            ...styles.dateText,
            fontSize: small ? 18 : 14,
          }}
        >
          {dayOfWeek}
        </span>
        <span
          style={{
            ...styles.dateText,
            fontSize: small ? 36 : 29,
          }}
        >
          {dayOfMonth}
        </span>
        <span
          style={{
            ...styles.dateText,
            fontSize: small ? 22 : 16,
          }}
        >
          {abbreviatedMonth}
        </span>
      </div>
    </div>
  );
};

const styles = {
  dateText: {
    fontFamily: 'Nunito',
    textTransform: 'uppercase',
    fontWeight: '700',
    color: Colors.neutral_600,
  },
};
