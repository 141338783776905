import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../themes/global.scss';

import { RemoteConfigUtils } from '../../utils/RemoteConfigUtils';
import { fetchNotificationSettings, logOut, LOGOUT_ACTION } from '../../actions';
import { createLoadingSelector } from '../../api/selectors';
import { showShowWCManagementPage } from '../../growthbook';

import CardSpinner from '../../commonComponents/CardSpinner';
import warningAlert from '../../images/warning_alert.svg';
import { shouldShowWarning } from '../NotificationSettings/utils';
import ConfirmPopup from '../shared/ConfirmPopup';
import ProfileHeaderCard from './ProfileHeaderCard';


export default ({ history }) => {
  const dispatch = useDispatch();
  const allowedWcStates = RemoteConfigUtils.getReferralUnavailableStates();
  const loadingSelector = createLoadingSelector([LOGOUT_ACTION]);

  const [showLogoutPopup, setShowLogoutPopup] = useState();

  const childOffices = useSelector((state) => state.user.childOffices);
  const user = useSelector((state) => state.user.selectedChildOffice);
  const notificationSettings = useSelector(
    (state) => state.user.notificationSettings,
  );
  const showNotificationWarning = notificationSettings?.some(
    ({ event, ...settings }) => shouldShowWarning(event, settings),
  );

  const isLoading = useSelector((state) => loadingSelector(state));

  const shouldBeDisplayedForParent = childOffices?.find((office) => allowedWcStates.includes(office.address?.[0]?.state));

  useEffect(() => {
    dispatch(fetchNotificationSettings());
  }, []);

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: "40px 80px",
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <h1 className="global_font f-dark" style={{ alignSelf: 'center' }}>
            Account
          </h1>
          <ProfileHeaderCard cardHasBennClicked={() => history.push('/dashboard/edit')} />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: 20,
              marginLeft: 20
            }}
          >
            <Link to="/account/payment" style={{ fontSize: 18 }} className="link">
              Payment Information
            </Link>

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <Link to="/dashboard/receipts" style={{ fontSize: 18 }} className="link">
              Payment History
            </Link>

            {user.is_pre_approved && (
              <Link to="/account/invoices" style={{ fontSize: 18 }} className="link">
                Invoices
              </Link>
            )}

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <Link to="/dashboard/office" style={{ fontSize: 18 }} className="link">
              Office Info
            </Link>

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <Link
              to="/dashboard/notifications"
              className="link"
              style={{ fontSize: 18, display: 'flex', columnGap: 10 }}
            >
              Notification Settings
              {showNotificationWarning && (
                <img
                  src={warningAlert}
                  style={{
                    width: 18,
                    objectFit: 'contain',
                  }}
                  alt="warning_alert"
                />
              )}
            </Link>

            {user?.isWorkerClassificationEnabled && (
              <>
                <div className="line" style={{ width: '95%', margin: 0 }} />
                <Link
                  to="/dashboard/worker-classification"
                  className="link"
                  style={{ fontSize: 18, display: 'flex' }}
                >
                  Worker Type
                  {!user?.workerClassification?.classificationType && (
                    <img
                      src={warningAlert}
                      style={{
                        width: 18,
                        objectFit: 'contain',
                        marginLeft: 10,
                      }}
                      alt="warning_alert"
                    />
                  )}
                </Link>
              </>
            )}

            {childOffices.length > 0 &&
              showShowWCManagementPage() &&
              shouldBeDisplayedForParent && (
              <>
                <div className="line" style={{ width: '95%', margin: 0 }} />
                <Link
                  to="/account/worker-classification-management"
                  className="link"
                  style={{ display: 'flex', fontSize: 18 }}
                >
                  Worker Type Management
                </Link>
              </>
            )}

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <Link to="/dashboard/feedback" style={{ fontSize: 18 }} className="link">
              Send Feedback
            </Link>

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <Link to="/dashboard/about" style={{ fontSize: 18 }} className="link">
              About
            </Link>

            <div className="line" style={{ width: '95%', margin: 0 }} />
            <p
              to="/account"
              className="link"
              style={{ margin: 0, cursor: 'pointer', fontSize: 18 }}
              onClick={() => setShowLogoutPopup(true)}
            >
              Log Out
            </p>
          </div>
        </div>
      </div>

      {showLogoutPopup && (
        <ConfirmPopup
          title="Confirm Log Out"
          description="Are you sure you would like to log out?"
          leftButtonText="Go Back"
          leftButtonAction={() => setShowLogoutPopup(false)}
          rightButtonText="Log Out"
          rightButtonAction={() => {
            sessionStorage.clear();
            dispatch(logOut());
            setShowLogoutPopup(false);
          }}
        />
      )}
    </div>
  );
};
