import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ClipLoader } from 'react-spinners';
import { fetchJob } from '../actions';
import BackButton from '../commonComponents/BackButton';

import CandidateInfo from './CandidateInfo';
import { Colors } from '../themes/colors';

export default ({ match }) => {
  const dispatch = useDispatch();

  const job = useSelector((state) => state.job.job);
  const { jobId } = match.params;

  useEffect(() => {
    if (!job) {
      dispatch(fetchJob({ jobId }));
    }
  }, []);

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingBottom: 120,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '95%' }}>
            <BackButton />
          </div>

          <h1 className="global_font bold" style={{ color: Colors.neutral_600, marginBottom: 60 }}>
            Professional Information
          </h1>

          <div 
            style={{
              position: 'relative',
              backgroundColor: Colors.white,
              width: 600,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: 30,
              paddingBottom: 40,
              boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
              zIndex: 1,
            }}
          >
            {job?.offer_final_reciever
              ? (
                <CandidateInfo
                  horizontalCard
                  candidate={job.offer_final_reciever}
                  isFavorite={job?.offer_final_reciever?.isFavorite}
                  containerStyle={{ width: '90%' }}
                />
              )
              : <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading />}

          </div>
        </div>
      </div>
    </div>
  );
};
