import moment from 'moment';
import browserHistory from '../../history';
import { Colors } from '../../themes/colors';
import { StatusEnum } from '../../utils/Enum';
import { getJobStatus } from '../Dashboard/ReceiptList/utils';

export function createDateArrayWithNumberOfDays(start, numberOfDays) {
  const dateArray = [];
  const startDate = moment(start);

  dateArray.push(startDate.format('YYYY-MM-DD'));

  while (dateArray.length < numberOfDays) {
    startDate.add(1, 'day');
    dateArray.push(startDate.format('YYYY-MM-DD'));
  }

  return dateArray;
}

export function createDaysOfWeekArray(start) {
  const daysArray = [];
  const startDate = moment(start);
  const startDay = startDate.day();

  const arrayStartDate = moment(startDate).subtract(startDay, 'days');

  const lastDayOfMonth = moment(startDate).endOf('month');

  const daysToFillArray = lastDayOfMonth.day() === 0 ? 42 : 35;

  for (let i = 0; i < daysToFillArray; i += 1) {
    const currentDate = moment(arrayStartDate).add(i, 'days');

    const formattedDate = currentDate.format('YYYY-MM-DD');

    daysArray.push(formattedDate);
  }

  return daysArray;
}

export const getCalendarJobStatus = (job) => {
  if (job.status === 'filled' && moment(job.local_date, 'ddd, MMM D, YYYY').isSame(moment(), 'day')) {
    return {
      label: StatusEnum.today,
      color: Colors.primary_500,
    };
  }
  return getJobStatus(job.is_canceled ? 'canceled' : job.status);
};

export const onCardClick = (job) => {
  let url = `/dashboard/job/${job.id}/details`;

  if (job.status === 'open') {
    url = `/dashboard/job/${job.id}`;
  } else if (job.status === 'pending' || job.status === 'counter') {
    url = `/dashboard/candidates/${job.id}`;
  }

  browserHistory.push(url);
  window.scrollTo(0, 0);
};
