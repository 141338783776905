import React from 'react';
import JobSummary from './JobSummary';
import JobSummaryAdjustment from './JobSummaryAdjustment';
import { showDecoupledDntEodFlow } from '../growthbook';

const EndOfDay = (props) => {
  if (showDecoupledDntEodFlow()) {
    return <JobSummaryAdjustment {...props} />;
  }

  return <JobSummary {...props} />;
};

export default EndOfDay;
