import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import downArrow from '../../images/down-arrow.svg';
import upArrow from '../../images/up-arrow.svg';
import { Colors } from '../../themes/colors';
import JobPostingPopup from '../shared/JobPostingPopup';
import { useRate } from './useRate';

const arrowVariants = {
  show: { opacity: 1 },
  hide: { opacity: 0 },
};

export default ({ averagePrice, submitRate, closeModal, defaultValue }) => {
  const {
    rates,
    selectedRate,
    setOfferRate,
    currentIndex,
    disabledBtn,
    getWarningMessage,
    industrialAverageIcon,
    industrialAverageColor,
    setSelectedRate,
  } = useRate({
    averagePrice,
    defaultValue,
  });
  const [rateIsFocused, setRateIsFocused] = useState(false);

  const rateValidation = () => {
    if (selectedRate) {
      submitRate(selectedRate);
      closeModal();
    } else {
      alert('Please provide valid rate');
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setOfferRate(defaultValue);
    }
  }, []);

  return (
    <JobPostingPopup closeModal={closeModal}>
      <h1 className="title_font" style={{ color: Colors.primary_500, fontSize: 24, margin: 25 }}>
        Select Hourly Rate
      </h1>

      <div
        style={{
          width: '60%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: Colors.neutral_50,
          padding: '25px 23px',
          borderRadius: 20,
          boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h1
            className="global_font f-dark"
            style={{
              position: 'absolute',
              left: -25,
              fontSize: 24,
              fontWeight: 'bold',
              marginRight: 10,
              color: Colors.neutral_500,
            }}
          >
            $
          </h1>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <motion.img
              initial={rates[currentIndex + 1] ? 'show' : 'hide'}
              animate={rates[currentIndex + 1] ? 'show' : 'hide'}
              variants={arrowVariants}
              transition={{ duration: 0.3 }}
              src={upArrow}
              className="pressable-img"
              style={{ marginBottom: 12, width: 20, cursor: 'pointer' }}
              onClick={() => {
                const updatedRate = rates[currentIndex + 1];

                if (!updatedRate) return;

                setOfferRate(updatedRate.toFixed(2));
              }}
              alt="up_arrow"
            />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 120,
                height: 57,
                borderRadius: 15,
                backgroundColor: industrialAverageColor,
                boxShadow: '0px 2px 3.3px 0px rgba(0, 0, 0, 0.35) inset',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <motion.input
                key={rateIsFocused || selectedRate}
                onFocus={() => {
                  setSelectedRate(Math.floor(selectedRate));
                  setRateIsFocused(selectedRate);
                }}
                onBlur={() => {
                  setOfferRate(Number(selectedRate).toFixed(2));
                  setRateIsFocused(false);
                }}
                initial={{ opacity: 0, y: -5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  fontFamily: 'Nunito',
                  fontSize: 32,
                  color: Colors.white,
                  textAlign: 'center',
                  margin: 0,
                  padding: 0,
                }}
                value={selectedRate}
                onChange={(event) => {
                  if (Number.isNaN(Number(event.nativeEvent.data))) {
                    return;
                  }

                  setOfferRate(event.target.value);
                }}
              />
            </div>

            <motion.img
              initial={rates[currentIndex - 1] ? 'show' : 'hide'}
              animate={rates[currentIndex - 1] ? 'show' : 'hide'}
              variants={arrowVariants}
              transition={{ duration: 0.3 }}
              src={downArrow}
              className="pressable-img"
              style={{ marginTop: 12, width: 20, cursor: 'pointer' }}
              onClick={() => {
                const updatedRate = rates[currentIndex - 1];

                if (!updatedRate) return;

                setOfferRate(updatedRate.toFixed(2));
              }}
              alt="down_arrow"
            />
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <motion.img
            key={industrialAverageColor}
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            src={industrialAverageIcon[industrialAverageColor]}
            style={{ marginRight: 6 }}
            alt="green_circle_check"
          />

          {getWarningMessage()}
        </div>

        <p
          style={{
            fontFamily: 'Nunito',
            fontStyle: 'italic',
            fontSize: 13,
            color: Colors.neutral_500,
            margin: 0,
          }}
        >
          Our average hourly rate is based on statewide data, and may not precisely reflect rates in
          your local area.
        </p>
      </div>

      <div style={{ margin: '20px 0px' }}>
        <button
          style={{
            width: 154,
            backgroundColor: disabledBtn ? Colors.primary_200 : Colors.primary_500,
            transition: 'all 0.3s ease-in-out',
          }}
          type="button"
          className="green-button"
          onClick={rateValidation}
          disabled={disabledBtn}
        >
          <p className="white-text-18">Confirm</p>
        </button>
      </div>
    </JobPostingPopup>
  );
};
