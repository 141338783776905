import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Colors } from '../../../../../themes/colors';

import arrow from '../../../../../images/arrow_right.svg';

export const Question = ({
  title,
  description,
  isRegistration,
  defaultValue = false
}) => {
  const [isRowExpanded, setIsRowExpanded] = useState(isRegistration || defaultValue);

  const collapsableVariants = {
    open: {
      maxHeight: 900,
      opacity: 1,
    },
    closed: {
      maxHeight: 0,
      opacity: 0,
    },
  };

  const arrowVariants = {
    open: {
      rotate: -90,
    },
    closed: {
      rotate: 90,
    },
  };

  return (
    <>
      <button
        type="button"
        style={{
          all: 'unset',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: isRegistration ? 'default' : 'pointer',
        }}
        onClick={(event) => {
          if (!isRegistration) {
            event.stopPropagation();
            setIsRowExpanded(!isRowExpanded);
          }
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                fontFamily: 'Nunito',
                marginBottom: 10,
                color: Colors.neutral_600,
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              {title}
            </span>
          </div>
        </div>
        {!isRegistration
        && (
        <div>
          <motion.img
            initial={!defaultValue ? 'closed' : 'open'}
            animate={isRowExpanded ? 'open' : 'closed'}
            transition={{ duration: 0.3 }}
            variants={arrowVariants}
            alt="arrow"
            src={arrow}
            style={{ marginLeft: 30, marginRight: -30 }}
          />
        </div>
        )}
      </button>
      <motion.div
        initial={isRegistration || defaultValue ? 'open' : 'closed'}
        animate={isRowExpanded ? 'open' : 'closed'}
        transition={{ duration: 0.4 }}
        style={{ overflow: 'hidden' }}
        variants={collapsableVariants}
      >
        <p
          style={{
            fontFamily: 'Nunito',
            color: Colors.neutral_600,
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          {description}
        </p>
      </motion.div>
    </>
  );
};
