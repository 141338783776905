import React from 'react';
import { ClipLoader } from 'react-spinners';
import { Colors } from '../themes/colors';

export default ({ isLoading, size = 50, ...rest }) => (
  <div
    {...rest}
    style={{
      display: 'flex',
      width: '100%',
      height: '100%',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      ...rest.style,
    }}
  >
    <ClipLoader sizeUnit="px" size={size} color={Colors.primary_500} loading={isLoading} />
  </div>
);
