import { Component } from 'react';
import { connect } from 'react-redux';
import { SEND_NPS_REVIEW, hideMessage, sendNpsReview } from '../actions';
import { createErrorObjectSelector, createLoadingSelector } from '../api/selectors';
import Button from '../commonComponents/Button';
import CardSpinner from '../commonComponents/CardSpinner';
import { Colors } from '../themes/colors';

class NPSReview extends Component {
  constructor(props) {
    super(props);
    this.state = { rate: 0 };
  }

  cancelClicked = () => {
    const { sendNpsReview } = this.props;
    sendNpsReview({ npsScore: -1 });
  };

  btnSubmitClicked = () => {
    const { sendNpsReview } = this.props;
    const { rate } = this.state;
    sendNpsReview({ npsScore: rate });
  };

  ratingIcon() {
    return (
      <div>
        {[...Array(10).keys()].reverse().map((index) => {
          if (index === 0) {
            return this.renderRatingItem(index + 1, '1 Not Likely');
          }
          if (index === 9) {
            return this.renderRatingItem(index + 1, '10 Very Likely');
          }
          return this.renderRatingItem(index + 1);
        })}
      </div>
    );
  }

  renderRatingItem(number, text) {
    const { rate } = this.state;
    return (
      <div
        style={{
          width: 200,
          borderRadius: 25,
          borderWidth: 2,
          marginTop: 6,
          marginBottom: 6,
          backgroundColor: rate === number ? Colors.primary_500 : Colors.white,
          borderColor: rate === number ? Colors.primary_500 : Colors.neutral_500,
          borderStyle: 'solid',
        }}
        onClick={() => this.setState({ rate: number })}
      >
        <h4
          style={{
            textAlign: 'center',
            textAlignVertical: 'center',
            color: rate === number ? Colors.white : Colors.neutral_600,
            fontFamily: 'Nunito',
            fontSize: 16,
            padding: 5,
            fontWeight: 'bold',
            margin: 0,
          }}
        >
          {text || number}
        </h4>
      </div>
    );
  }

  render() {
    const { isLoading, message } = this.props;

    if (isLoading) {
      return <CardSpinner />;
    }
    return (
      <div className="component_container">
        <div className="floating_container">
          <div style={{ margin: '15px 50px' }}>
            <h1 className="global_font f-dark" style={{ textAlign: 'center' }}>
              Tell us what you think!
            </h1>
            <h3 className="global_font f-dark">
              {' '}
              On a scale of 1 to 10 how likely are you to refer GoTu to another dental
              professional or office?
              {' '}
            </h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {this.ratingIcon()}
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 20,
                padding: 10,
              }}
            >
              <Button
                text="Submit"
                style={{ width: 280 }}
                onClick={() => this.btnSubmitClicked()}
              />
              <div style={{ width: 20 }} />
              <Button
                text="Cancel"
                style={{ width: 280 }}
                color="secondary"
                onClick={() => this.cancelClicked()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([SEND_NPS_REVIEW]);
const errorSelector = createErrorObjectSelector([SEND_NPS_REVIEW]);

const mapStateToProps = (state) => ({
  user: state.user.user,
  error: errorSelector(state),
  isLoading: loadingSelector(state),
});

export default connect(mapStateToProps, { sendNpsReview, hideMessage })(NPSReview);
