import { Children, useEffect, useState } from 'react';
import { TabList } from './TabList';
import { TabPanel } from './TabPanel';

export const Tabs = ({ children, initialTab = 0, onChangeTab = () => {} }) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    onChangeTab(activeTab);
  }, [activeTab, onChangeTab]);

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {Children.map(children, (child) => {
        if (child.type.displayName === 'TabList') {
          return (
            <TabList setActiveTab={setActiveTab} activeTab={activeTab}>
              {child.props.children}
            </TabList>
          );
        }
        return null;
      })}
      {Children.map(children, (child) => {
        if (child.type.displayName === 'TabPanel') {
          return <TabPanel key={activeTab}>{child.props.children[activeTab]}</TabPanel>;
        }
        return null;
      })}
    </div>
  );
};
