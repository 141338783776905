import moment from 'moment';

/**
 * Populate the initial form values
 * @param {object} job - The job object
 * @param {boolean} populateBreak - Whether to populate the break details
 * @returns {AdjustHoursFormValues} Form values - The initial form values

 * @typedef {Object} AdjustHoursFormValues
 * @property {Date} originalStartHour - The original start hour
 * @property {Date} originalEndHour - The original end hour
 * @property {number} originalBreakTime - The original break time
 * @property {Date} adjustedStartHour - The adjusted start hour
 * @property {Date} adjustedEndHour - The adjusted end hour
 * @property {number} adjustedBreakTime - The adjusted break time
 * @property {Date} breakStart - The break start time
 * @property {Date} breakEnd - The break end time
 */
export const populateInitialFormValues = (job, populateBreak = false) => {
  // Original start and end time
  const start = moment(
    `${job?.local_date} ${job?.local_start_time}`,
    'ddd, MMM DD, YYYY hh:mm a'
  ).toDate();
  const end = moment(
    `${job?.local_date} ${job?.local_end_time}`,
    'ddd, MMM DD, YYYY hh:mm a'
  ).toDate();

  // Adjusted start and end time
  const adjustedStart = job?.clockInOut?.timeIn
    ? moment(job?.clockInOut?.timeIn).toDate()
    : start;
  const adjustedEnd = job?.clockInOut?.timeOut
    ? moment(job?.clockInOut?.timeOut).toDate()
    : end;

  let breakStart = null;
  let breakEnd = null;
  let breakTime = job?.lunch_final_break_time;

  if (populateBreak && job?.clockInOut?.breakStart) {
    breakStart = moment(job?.clockInOut?.breakStart).toDate();

    if (job?.clockInOut?.breakEnd) {
      breakEnd = moment(job?.clockInOut?.breakEnd).toDate();
    } else {
      // If break end time is not available, then manually estimate the break end time
      const estimatedEnd = moment(job?.clockInOut?.breakStart).add(
        isEmptyBreakTime(job?.lunch_final_break_time, 60),
        'minutes'
      );
      breakEnd = estimatedEnd.isBefore(adjustedEnd)
        ? estimatedEnd.toDate()
        : adjustedEnd;
    }

    breakTime = moment(breakEnd)
      .startOf('minute')
      .diff(moment(breakStart).startOf('minute'), 'minutes');
  }

  return {
    originalStartHour: start,
    originalEndHour: end,
    originalBreakTime: job?.lunch_final_break_time,
    adjustedStartHour: adjustedStart,
    adjustedEndHour: adjustedEnd,
    adjustedBreakTime: breakTime,
    breakStart,
    breakEnd,
  };
};

/**
 * Check if the both hours are equal ignoring the seconds
 * @param {Date} hour1 - The first hour
 * @param {Date} hour2 - The second hour
 * @returns {boolean} - Whether the both hours are equal
 */
export const isEqualHour = (hour1, hour2) => {
  if (hour1 === hour2) {
    return true;
  }

  return moment(hour1)
    .startOf('minute')
    .isSame(moment(hour2).startOf('minute'));
};

/**
 * Formats the break time with paid/unpaid indicator.
 * @param {number} breakTime - The break time in minutes.
 * @param {boolean} isBreakPaid - Indicates if the break is paid.
 * @returns {string} - Formatted break time with paid/unpaid indicator.
 */
export const formatBreak = (breakTime, isBreakPaid) => {
  if (breakTime == null) {
    return '--';
  }

  const formattedPaidText = isBreakPaid ? '(Paid)' : '(Unpaid)';

  let formattedBreakTime = '';
  if (breakTime < 60) {
    formattedBreakTime = `${breakTime} min`;
  } else {
    const formattedHours = `${Math.floor(breakTime / 60)} hr`;
    const formattedMinutes = breakTime % 60 > 0 ? `${breakTime % 60} min` : '';
    formattedBreakTime = [formattedHours, formattedMinutes]
      .filter(Boolean)
      .join(' ');
  }

  return `${formattedBreakTime} ${formattedPaidText}`;
};

/**
 * Check if the break time is empty
 * @param {number} breakTime - The break time
 * @param {number} defaultBreakTime - The default break time if the break time is empty
 * @returns {number} - The break time
 */
export const isEmptyBreakTime = (breakTime, defaultBreakTime) => {
  if (!breakTime || breakTime === '00') {
    return defaultBreakTime;
  }

  return breakTime;
};

/**
 * Converts the picker result to a time object
 * @param {object} job - The job object
 * @param {string} result - The picker result in the format "hh:mm a"
 * @returns {Date} - The time object
 */
export const pickerResultToTime = (job, result) => moment(`${job?.local_date} ${result}`, 'ddd, MMM DD, YYYY hh:mm a').toDate()