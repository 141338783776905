import React from 'react';
import Checkbox from '../../../../commonComponents/Checkbox';
import { Colors } from '../../../../themes/colors';

export const MultipleOptions = ({ options, selected, onClick }) => (
  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 15 }}>
    {options?.map((name) => (
      <button
        type="button"
        onClick={() => onClick(name)}
        style={{
          all: 'unset',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Checkbox checked={selected.includes(name)} />
        <span style={{ ...Styles.regularText, marginLeft: 20 }}>{name}</span>
      </button>
    ))}
  </div>
);

const Styles = {
  regularText: {
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    fontSize: 18,
  },
};
