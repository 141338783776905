import { motion } from 'framer-motion';
import moment from 'moment';
import { Tooltip } from '../../../../commonComponents/TooltipWrapper/TooltipWrapper';
import { UserProfession } from '../../../../enums/UserProfession';
import ArrowDownIcon from '../../../../images/icons/ArrowDown';
import { Colors } from '../../../../themes/colors';
import { capitalizeEveryFirstLetter } from '../../../../utils/String';
import history from '../../../../history';

export const ExpandableInviteCard = ({ job, isOpen, onToggle }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: Colors.white,
      borderRadius: 8,
      boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    }}
  >
    <button
      type="button"
      style={{
        height: '100%',
        all: 'unset',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: '32px 8px 32px 80px',
      }}
      onClick={(event) => {
        event.stopPropagation();
        onToggle();
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '2fr 2fr 2fr 1fr',
          alignItems: 'center',
        }}
      >
        <button
          type="button"
          style={{
            all: 'unset',
            cursor: 'pointer',
            fontFamily: 'Nunito',
            color: Colors.secondary_500,
            fontSize: 18,
            fontWeight: 700,
            justifySelf: 'center',
          }}
          onClick={(event) => {
            event.stopPropagation();
            history.push(`/dashboard/job/${job?.id}`);
          }}
        >
          <Tooltip text="See Details">
            {moment(job?.localDate, 'MM/DD/YYYY').format('ddd, MMM DD, YYYY')}
          </Tooltip>
        </button>
        <span
          style={{
            fontFamily: 'Nunito',
            color: Colors.neutral_600,
            fontSize: 16,
            textAlign: 'center',
            marginLeft: 12,
          }}
        >
          {UserProfession.getName(job?.profession)}
        </span>
        <span
          style={{
            fontFamily: 'Nunito',
            color: Colors.neutral_600,
            fontSize: 16,
            textAlign: 'center',
            textTransform: 'lowercase',
            marginLeft: 12,
          }}
        >
          {job?.localStartTime} - {job?.localEndTime}
        </span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <motion.div
            initial="closed"
            animate={isOpen ? 'open' : 'closed'}
            transition={{ duration: 0.4 }}
            variants={arrowVariants}
          >
            <ArrowDownIcon />
          </motion.div>
        </div>
      </div>
    </button>
    <motion.div
      initial="closed"
      animate={isOpen ? 'open' : 'closed'}
      transition={{ duration: 0.5 }}
      style={{
        overflow: 'hidden',
        height: 'auto',
        backgroundColor: Colors.white,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      }}
      variants={collapsableVariants}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          padding: '0 80px',
          margin: 'auto',
        }}
      >
        <b style={styles.title}>Professional</b>
        <b style={styles.title}>Sent on Date</b>
        <b style={styles.title}>Invite Status</b>

        <div
          style={{
            gridColumn: '1 / -1',
            height: 2,
            backgroundColor: Colors.neutral_100,
            margin: '8px 0',
          }}
        />

        {job?.favoriteInvites?.map((invite, index) => (
          <>
            <span style={styles.text}>
              {capitalizeEveryFirstLetter(invite?.professional?.fullName?.toLowerCase())}
            </span>
            <span style={styles.text}>{moment(invite?.createdAt).format('ddd, MMM DD, YYYY')}</span>
            <b style={styles.text}>{invite?.status === 'active' ? 'Pending' : invite?.status}</b>
            {job?.favoriteInvites?.length - 1 !== index && (
              <div
                style={{
                  gridColumn: '1 / -1',
                  height: 2,
                  backgroundColor: Colors.neutral_100,
                  margin: '12px 0',
                }}
              />
            )}
          </>
        ))}
      </div>
    </motion.div>
  </div>
);

const styles = {
  title: {
    fontFamily: 'Nunito',
    color: Colors.primary_500,
    fontSize: 18,
    textAlign: 'center',
  },
  text: {
    fontFamily: 'Nunito',
    color: Colors.neutral_500,
    fontSize: 16,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
};

const collapsableVariants = {
  open: {
    maxHeight: 2000,
    opacity: 1,
    paddingBottom: 32,
  },
  closed: {
    maxHeight: 0,
    opacity: 0,
  },
};

const arrowVariants = {
  open: {
    rotate: 180,
  },
  closed: {
    rotate: 0,
  },
};
