import { motion } from 'framer-motion';
import { Colors } from '../../../../themes/colors';

export const EmptyRecentProfessionals = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    style={{
      maxWidth: '827px',
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  >
    <span style={styles.text}>
      It looks like you haven&apos;t completed any shifts yet! 
      Once a professional completes a shift, they will
      <br />
      appear on this screen where you will be able to:
    </span>
    <ul style={{ display: 'flex', flexDirection: 'column', gap: 24, margin: 0 }}>
      <li style={styles.text}>
        Add them to your favorites.
      </li>
      <li style={styles.text}>
        Block the professional.
      </li>
      <li style={styles.text}>
        Send direct invites to upcoming shifts.
      </li>
    </ul>
  </motion.div>
);

const styles = {
  text: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    color: Colors.neutral_600,
  },
};
