import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
  fetchOpenJobsForRecentProfessional,
  INVITE_PROFESSIONALS_TO_JOBS,
  inviteProfessionalsToJobs,
} from '../../../../actions';
import { clearSuccess } from '../../../../actions/success';
import { createSuccessSelector } from '../../../../api/selectors';
import BackButton from '../../../../commonComponents/BackButton';
import Button from '../../../../commonComponents/Button';
import CustomPortal from '../../../../commonComponents/CustomPortal';
import CustomSpinner from '../../../../commonComponents/CustomSpinner';
import useLoading from '../../../../hooks/useLoading';
import { Colors } from '../../../../themes/colors';
import { JobCard } from './JobCard';

const successSelector = createSuccessSelector([INVITE_PROFESSIONALS_TO_JOBS]);

export const InviteProfessional = ({ history }) => {
  const dispatch = useDispatch();
  const { isLoading: isLoadingInvite } = useLoading([INVITE_PROFESSIONALS_TO_JOBS]);
  const professional  = history?.location?.state?.professional;
  const [showSuccessProfessionalInvitedModal, setShowSuccessProfessionalInvitedModal] =
    useState(false);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const invitedSuccessfully = useSelector((state) => successSelector(state));
  const recentOpenJobs = useSelector((state) => state.job.recentOpenJobs);
  const isScrollLoading = recentOpenJobs?.isActiveRequest;

  const handleSelect = (id) => {
    if (selectedJobs.includes(id)) {
      setSelectedJobs(selectedJobs.filter((jobId) => jobId !== id));
    } else {
      setSelectedJobs([...selectedJobs, id]);
    }
  };

  const handleSendButton = () => {
    dispatch(
      inviteProfessionalsToJobs({
        jobIds: selectedJobs,
        professionalIds: [professional.id],
      }),
    );
  };

  const handleScrollList = useCallback(
    (event) => {
      const tableScrollHeight = event?.target?.scrollHeight - event?.target?.scrollTop - 20;
      const clientHeight = event?.target?.clientHeight;
      if (
        tableScrollHeight <= clientHeight &&
        !recentOpenJobs.isActiveRequest &&
        !recentOpenJobs.isFinal
      ) {
        dispatch(
          fetchOpenJobsForRecentProfessional({
            professionalId: professional?.id,
            profession: professional?.profession,
            page: recentOpenJobs?.page + 1,
          }),
        );
      }
    },
    [recentOpenJobs, dispatch, professional],
  );

  useEffect(() => {
    if (invitedSuccessfully) {
      setShowSuccessProfessionalInvitedModal(true);
      dispatch(clearSuccess(INVITE_PROFESSIONALS_TO_JOBS));
    }
  }, [invitedSuccessfully, dispatch]);

  useEffect(() => {
    if ((professional?.id && recentOpenJobs?.offers.length === 0) || !professional) {
      history.push('/professionals-hub');
    }

    return () => {
      dispatch(clearSuccess([FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION]));
    };
  }, [recentOpenJobs, history, professional, dispatch]);

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          padding: 40,
        }}
      >
        <div style={{ position: 'absolute', zIndex: 1 }}>
          <BackButton />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'center',
          }}
        >
          <h1 className="global_font f-dark" style={{ alignSelf: 'center' }}>
            Invite Professional
          </h1>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 12,
            }}
          >
            <img
              alt="Professional"
              src={professional?.image}
              style={{
                width: 100,
                height: 100,
                borderRadius: '100%',
                resizeMode: 'contain',
              }}
            />

            <span
              style={{
                marginTop: 28,
                fontSize: 16,
                fontFamily: 'Nunito',
                color: Colors.neutral_600,
                marginBottom: 40,
              }}
            >
              Select the shifts below that you would like to invite <b>{professional?.name}</b> to.
            </span>
          </div>

          {isLoadingInvite ? (
            <CustomSpinner isLoading size={24} />
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 12,
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingRight: 8,
                  width: 860,
                  minHeight: 300,
                  maxHeight: 600,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  scrollbarWidth: 'thin',
                }}
                onScroll={handleScrollList}
              >
                {recentOpenJobs?.offers?.map((job) => (
                  <JobCard
                    key={job.id}
                    job={{
                      id: job.id,
                      status: job.job_status,
                      date: job.local_date,
                      professionName: job.professionName,
                      subProfession: job.subProfession,
                      startTime: job.local_start_time,
                      endTime: job.local_end_time,
                    }}
                    isChecked={selectedJobs.includes(job.id)}
                    onSelect={handleSelect}
                  />
                ))}
                {isScrollLoading && <CustomSpinner isLoading size={32} style={{ marginTop: 20 }} />}
              </div>

              <Button
                disabled={selectedJobs.length === 0}
                text="Send"
                style={{
                  width: 296,
                  height: 48,
                  marginTop: 60,
                }}
                onClick={handleSendButton}
              />
            </div>
          )}
        </div>
      </div>

      {showSuccessProfessionalInvitedModal && (
        <CustomPortal
          isSuccess
          redirect={false}
          title="Request(s) Successfully Sent!"
          text={
            <>
              <b>{professional.name}</b> has been sent a direct invite to the selected shift(s). If
              accepted at the listed rate, {professional.name.split(' ')[0]} will be confirmed!
              Visit Professionals Hub &gt; Invites page to review the status of your sent
              invitations.
            </>
          }
          buttonText="Close"
          submitPressed={() => {
            setShowSuccessProfessionalInvitedModal(false);
          }}
        />
      )}
    </div>
  );
};
