import { Colors } from '../../themes/colors';
import CustomSpinner from '../CustomSpinner';

export const GridTable = ({ data, renderRow, headerTitles, isLoading, headerStyle = {} }) => (
  <div>
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${headerTitles.length}, 2fr) 1fr`,
          ...headerStyle,
        }}
      >
        {headerTitles?.map((title) => (
          <div
            key={title}
            style={{
              fontFamily: 'Nunito',
              fontSize: 18,
              fontWeight: 700,
              color: Colors.primary_500,
              textAlign: 'center',
              paddingBottom: 8,
            }}
          >
            {title}
          </div>
        ))}
        <span
          style={{
            cursor: 'default',
            opacity: 0,
          }}
        >
          Action
        </span>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          marginTop: 12,
        }}
      >
        {data.map((item) => renderRow(item))}
      </div>
    </div>

    {isLoading && <CustomSpinner isLoading size={32} style={{ marginTop: 20 }} />}
  </div>
);
