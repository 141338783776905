import { useSelector } from 'react-redux';
import { extractSkills } from '../../../../../commonComponents/CommonFunctions';
import TimerCountdown from '../../../../../commonComponents/TimerCountdown';
import { Colors } from '../../../../../themes/colors';
import { filterObjectKeys } from '../../../../../utils/Object';
import { UserProfession } from '../../../../../enums/UserProfession';

export const Content = ({ job, profession, candidate, multipleCounters }) => {
  const candidateEducation = candidate?.professional_info?.education;
  const educationInfo = candidateEducation?.[profession];
  const userSpecialties = Object.keys(
    filterObjectKeys(educationInfo?.specialtiesAndProcedures, 'specialty'),
  );

  const allSkillCategories = useSelector((state) => state.user.allSkillCategories);
  const { userSpecialtySkills } = extractSkills(allSkillCategories, job, candidate);
  const uniqueSpecialtySkills = [...new Set(userSpecialtySkills?.map(({ name }) => name))];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 32,
        gap: 8,
        paddingRight: 60,
      }}
    >
      <div style={styles.container}>
        <span style={styles.title}>Years of Experience:</span>
        <span style={styles.text}>{candidate?.years_of_experience}</span>
      </div>
      <div style={styles.container}>
        <span style={styles.title}>
          {profession !== UserProfession.DN ? 'Specialty(ies):' : 'Procedure(s):'}
        </span>
        <span
          style={{
            ...styles.text,
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            lineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {userSpecialties.length && candidate?.educationUpdated
            ? userSpecialties?.map((name) => name?.split('_')?.[1])?.join(', ') || 'N/A'
            : uniqueSpecialtySkills?.join(', ')}
        </span>
      </div>
      <div style={styles.container}>
        <span style={styles.title}>Expires in:</span>
        <TimerCountdown
          expireTime={
            multipleCounters?.find((counter) => counter?.candidate?.id === candidate?.id)
              ?.expiration || new Date()
          }
          allowFontScaling
          style={{
            color: Colors.error_500,
            fontSize: 16,
            textAlign: 'center',
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            marginVertical: 10,
            width: 'fit-content',
          }}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: 16,
    color: Colors.neutral_600,
  },
  text: {
    fontFamily: 'Nunito',
    fontSize: 16,
    color: Colors.neutral_600,
  },
};
