import React from 'react';
import { motion } from 'framer-motion';
import Switch from 'react-switch';

import { Colors } from '../../themes/colors';
import JobPostingPopup from '../shared/JobPostingPopup';
import upArrow from '../../images/up-arrow.svg';
import downArrow from '../../images/down-arrow.svg';

export default function SelectBreakTimePopup({
  breakTimes,
  breakPaid,
  setBreakPaid,
  selectedBreakTimeIndex,
  setSelectedBreakTimeIndex,
  closeModal,
  submitBreak,
}) {
  const arrowVariants = {
    show: { opacity: 1 },
    hide: { opacity: 0 },
  };

  return (
    <JobPostingPopup closeModal={closeModal}>
      <h1 className="title_font" style={{ color: Colors.primary_500, fontSize: 24, margin: 25 }}>
        Select Lunch Break
      </h1>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: Colors.neutral_50,
          padding: '25px 45px',
          borderRadius: 20,
          boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
        }}
      >
        <motion.img
          initial={breakTimes[selectedBreakTimeIndex + 1] ? 'show' : 'hide'}
          animate={breakTimes[selectedBreakTimeIndex + 1] ? 'show' : 'hide'}
          variants={arrowVariants}
          transition={{ duration: 0.3 }}
          src={upArrow}
          className="pressable-img"
          style={{ marginBottom: 12, cursor: 'pointer' }}
          onClick={() => {
            if (!breakTimes[selectedBreakTimeIndex + 1]) {
              return;
            }

            setSelectedBreakTimeIndex(selectedBreakTimeIndex + 1);
          }}
          alt="up_arrow"
        />

        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: 136,
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: Colors.primary_500,
              borderRadius: 17,
              boxShadow: '0px 2.2px 3.6px 0px rgba(0, 0, 0, 0.35) inset',
            }}
          >
            <motion.p
              key={selectedBreakTimeIndex}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                fontFamily: 'Nunito',
                fontSize: 46,
                color: Colors.white,
                margin: 0,
              }}
            >
              {breakTimes[selectedBreakTimeIndex]}
            </motion.p>
          </div>

          <p
            style={{
              position: 'absolute',
              left: 'calc(100% + 13px)',
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              fontSize: 18,
              color: Colors.neutral_500,
              margin: 0,
            }}
          >
            min
          </p>
        </div>

        <motion.img
          initial={breakTimes[selectedBreakTimeIndex - 1] ? 'show' : 'hide'}
          animate={breakTimes[selectedBreakTimeIndex - 1] ? 'show' : 'hide'}
          variants={arrowVariants}
          transition={{ duration: 0.3 }}
          src={downArrow}
          className="pressable-img"
          style={{ marginTop: 12, cursor: 'pointer' }}
          onClick={() => {
            if (!breakTimes[selectedBreakTimeIndex - 1]) {
              return;
            }

            setSelectedBreakTimeIndex(selectedBreakTimeIndex - 1);
          }}
          alt="down_arrow"
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 10,
            marginTop: 16,
          }}
        >
          <Switch
            checked={breakPaid}
            onChange={(value) => setBreakPaid(value)}
            onColor={Colors.secondary_500}
            onHandleColor="#fff"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            offColor={Colors.neutral_100}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.35)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={25}
            width={50}
            className="react-switch"
            id="material-switch"
          />
          <h3
            className="f-18 f-dark bold global_font"
            style={{ color: Colors.neutral_500, margin: 0 }}
          >
            Lunch Break is Paid
          </h3>
        </div>
      </div>

      <div style={{ margin: '20px 0px' }}>
        <button
          style={{ width: 154, backgroundColor: Colors.primary_500 }}
          type="button"
          className="green-button"
          onClick={() => submitBreak(selectedBreakTimeIndex, breakPaid)}
        >
          <p className="white-text-18">Confirm</p>
        </button>
      </div>
    </JobPostingPopup>
  );
}
