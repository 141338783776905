import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../../themes/signup.scss';

import { motion } from 'framer-motion';
import React, { useState } from 'react';

import history from '../../../history';
import { Colors } from '../../../themes/colors';
import Header from '../../Header';
import { ContainerLayout } from '../components/ContainerLayout';
import SignIn from './SignIn';
import SignUp from './SignUp';

const renderContent = (screen) => {
  switch (screen) {
  case 'signup':
    return <SignUp />;
  default:
    return <SignIn />;
  }
};

const selectionVariants = {
  login: {
    left: '0%',
  },
  signup: {
    left: '56%',
  },
};

export default function UserAuth() {
  const [currentScreen, setCurrentScreen] = useState('login');
  const [isDropdownToggleOpen, setIsDropdownToggleOpen] = useState(false);

  return (
    <div>
      <Header
        isOpen={isDropdownToggleOpen}
        onClick={() => history.push('/')}
        toggleDropdown={() => setIsDropdownToggleOpen(!isDropdownToggleOpen)}
        text={currentScreen === 'login' ? 'Login' : 'Sign Up'}
      />

      <ContainerLayout>
        <div
          style={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            paddingTop: 140,
            minWidth: 600,
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <motion.div
              animate={currentScreen}
              variants={selectionVariants}
              style={{
                position: 'absolute',
                backgroundColor: Colors.secondary_500,
                width: 105,
                height: 3,
                top: 40,
              }}
            />

            <div
              style={{ cursor: 'pointer' }}
              onClick={() => setCurrentScreen('login')}
              id="selectSigninFormButton"
            >
              <h1
                className="global_font"
                style={{
                  color:
                    currentScreen === 'login'
                      ? Colors.neutral_600
                      : Colors.neutral_300,
                  fontSize: 28,
                  fontWeight: currentScreen === 'login' ? 'bold' : 'normal',
                  margin: '0px 12px',
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                Log In
              </h1>
            </div>

            <h1
              className="global_font"
              style={{
                color: Colors.neutral_300,
                fontSize: 22,
                fontWeight: 'normal',
                margin: '0px 8px',
              }}
            >
              or
            </h1>

            <div
              style={{ cursor: 'pointer' }}
              id="selectSignupFormButton"
              onClick={() => setCurrentScreen('signup')}
            >
              <h1
                className="global_font f-dark"
                style={{
                  color:
                    currentScreen === 'signup'
                      ? Colors.neutral_600
                      : Colors.neutral_300,
                  fontSize: 28,
                  fontWeight: currentScreen === 'signup' ? 'bold' : 'normal',
                  margin: '0px 12px',
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                Sign Up
              </h1>
            </div>
          </div>

          {renderContent(currentScreen)}
        </div>
      </ContainerLayout>
    </div>
  );
}
