import '../../../../themes/global.scss';

import { motion } from 'framer-motion';
import React from 'react';
import CustomSpinner from '../../../../commonComponents/CustomSpinner';
import { onCardClick } from '../../utils';
import { JobCard } from '../JobCard/JobCard';
import { useJobList } from './useJobList';

export const JobList = ({ status }) => {
  const { jobList, isLoadingList, jobListScrollRef, handleScrollJobList } = useJobList({ status });

  if (jobList?.length === 0 && !isLoadingList) {
    return (
      <motion.div
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <p className="font_bold">No shift(s) available</p>
      </motion.div>
    );
  }

  return (
    <div className="job_list" style={{ height: '100%', width: '100%' }}>
      <div
        ref={jobListScrollRef}
        onScroll={handleScrollJobList}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
          overflowY: 'auto',
          alignItems: 'center',
          height: 750,
          padding: 20,
        }}
      >
        {jobList?.map((job) => (
          <JobCard key={job.id} onCardClick={onCardClick} job={job} />
        ))}
        {isLoadingList && <CustomSpinner style={{ margin: '40px 0' }} isLoading={isLoadingList} />}
      </div>
    </div>
  );
};
