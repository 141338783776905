import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardInfo } from '../../../../actions';
import { fetchJobList, getInitialTab, getTypeByTab } from '../utils';

export const useShiftList = ({ type }) => {
  const dispatch = useDispatch();
  const dashboardInfo = useSelector((state) => state.job.dashboardInfo);
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    setActiveTab(getInitialTab(type));
  }, [type]);

  useEffect(() => {
    if (activeTab !== null) {
      dispatch(fetchDashboardInfo());
      dispatch(fetchJobList(getTypeByTab(activeTab), { page: 1 }));
    }
  }, [type, activeTab, dispatch]);

  return {
    activeTab,
    setActiveTab,
    dashboardInfo,
  };
};
