import { motion } from 'framer-motion';

export const TabPanel = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, y: 0 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3 }}
    style={{
      width: '100%',
    }}
  >
    {children}
  </motion.div>
);

TabPanel.displayName = 'TabPanel';