import { isPartialShift } from '../../../../../commonComponents/CommonFunctions';
import HeartIcon from '../../../../../images/icons/Heart';
import starIcon from '../../../../../images/star_icon.svg';
import { Colors } from '../../../../../themes/colors';
import { PartialShift } from '../../PartialShift';

export const Header = ({ item, candidate }) => {
  const isAvailableForPartialShift = isPartialShift(item);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 10,
          marginBottom: 4,
          height: 20,
          marginRight: -32,
        }}
      >
        {isAvailableForPartialShift && <PartialShift />}
      </div>

      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `3px solid ${Colors.white}`,
            borderRadius: 110,
            padding: 3,
            ...(item.isFavorite && {
              border: `3px solid ${Colors.favorites_400}`,
            }),
            width: 110,
            height: 110,
          }}
        >
          <img
            src={candidate?.profile_image}
            style={{
              width: 110,
              height: 110,
              borderRadius: 110,
              objectFit: 'cover',
              border: `1px solid ${Colors.neutral_200}`,
            }}
            alt="profile_image"
          />
          {item.isFavorite && (
            <div style={{ position: 'absolute', marginTop: 95, marginLeft: 80 }}>
              <HeartIcon color={Colors.favorites_400} width={34} height={34} />
            </div>
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column',justifyContent: "center", marginLeft: 24 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={styles.title}>
              {candidate?.first_name} {candidate?.last_name}
            </span>

            <img
              src={starIcon}
              style={{
                width: 20,
                objectFit: 'cover',
                margin: '0px 4px 0px 8px',
              }}
              alt="star_icon"
            />

            <span style={{...styles.title, fontSize: 18}}>
              {candidate?.average_rating?.toFixed(1) || '5.0'}
            </span>
          </div>

          <span style={styles.text}>
            Drivers License Verified
          </span>

          <span style={styles.text}>
            {candidate?.license?.subProfession ?? candidate?.license?.profession} License Verified
          </span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  title: {
    fontFamily: 'Nunito',
    fontSize: 20,
    fontWeight: 700,
    color: Colors.neutral_600,
  },
  text: {
    fontFamily: 'Nunito',
    fontSize: 16,
    color: Colors.neutral_600,
  }
}
