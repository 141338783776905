import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import BackButton from '../../commonComponents/BackButton';
import Button from '../../commonComponents/Button';
import CardSpinner from '../../commonComponents/CardSpinner';
import history from '../../history';
import BlockCrossIcon from '../../images/icons/BlockCrossIcon';
import WarningIcon from '../../images/icons/Warning';
import { Colors } from '../../themes/colors';
import ConfirmPopup from '../shared/ConfirmPopup';
import { useReviewStep } from './hooks/useReviewStep';
import JobMDBCard from './multiday/JobMDBCard';
import ProgressBar from './multiday/ProgressBar';

export const ReviewContent = ({ title, urlPrefix }) => {
  const {
    jobDates,
    isLoading,
    jobInfo,
    showDeleted,
    deletedJob,
    showOvertimeLimitPopup,
    handleButtonClick,
    editPressed,
    confirmRemoveDate,
    deletePressed,
    setShowDeleted,
    setShowOvertimeLimitPopup,
  } = useReviewStep({ urlPrefix });

  if (!jobInfo?.length) {
    history.push(`${urlPrefix}/info`);
    return null;
  }

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{ flexDirection: 'column', justifyContent: 'flex-start' }}
      >
        <div style={{ marginTop: 20, marginLeft: 30 }}>
          <BackButton />
        </div>
        <ProgressBar step={2} title={title} redirectUrl={`${urlPrefix}/info`} />
        <motion.div
          layout
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'grid',
            gridTemplateColumns: jobInfo?.length > 1 ? '368px 368px' : '368px',
            gridGap: 12,
            alignSelf: 'center',
            marginTop: 55,
          }}
        >
          <AnimatePresence>
            {jobInfo?.map((job) => (
              <JobMDBCard
                key={job.localDate}
                job={job}
                editHasBeenClicked={(data) => editPressed(data)}
                deleteJobPressed={(id) => deletePressed(id)}
              />
            ))}
          </AnimatePresence>
        </motion.div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 120,
            marginTop: 85,
          }}
        >
          <Button
            text="Send Request"
            onClick={handleButtonClick}
            style={{
              backgroundColor: Colors.primary_500,
            }}
          />
        </div>
      </div>

      {showDeleted && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <BlockCrossIcon />
            </div>
          )}
          title="Delete Shift"
          description={
            <>
              Are you sure you would like to delete the shift for
              {'\n'}
              <strong>{moment(deletedJob).format('ddd, MMM DD, YYYY')}</strong>?
            </>
          }
          descriptionStyle={{ width: '76%', marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonStyle={{
            backgroundColor: Colors.secondary_500,
          }}
          leftButtonAction={() => setShowDeleted(false)}
          rightButtonText="Delete"
          rightButtonAction={() => {
            confirmRemoveDate(jobDates, deletedJob);
            setShowDeleted(false);
          }}
        />
      )}

      {showOvertimeLimitPopup && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={64} height={64} color={Colors.warning_500} />
            </div>
          )}
          title="Overtime Hours"
          description={`
            The payable hours of this shift exceed your state’s daily 
            overtime limit. Your office will be subject to additional
            charges for any overtime hours worked by a professional.
          `}
          descriptionStyle={{ width: '76%', marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonAction={() => setShowOvertimeLimitPopup(false)}
          rightButtonText="Continue"
          rightButtonAction={() => {
            handleButtonClick({ alertOvertime: false });
            setShowOvertimeLimitPopup(false);
          }}
        />
      )}
    </div>
  );
};
