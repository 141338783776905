import React from 'react';
import { Colors } from '../../themes/colors';

const ArrowDownIcon = ({ color = Colors.secondary_500 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M19 9L12 16L5 9" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default ArrowDownIcon;
