import moment from 'moment';
import Button from '../../../commonComponents/Button';
import CheckCircleIcon from '../../../images/check-circle.svg';
import XCircleIcon from '../../../images/x-circle.svg';
import { Colors } from '../../../themes/colors';
import { capitalizeEveryFirstLetter } from '../../../utils/String';
import { TableData } from '../../../commonComponents/Table/TableData';
import { WC_MANAGEMENT_STATUS } from '../utils';

export const renderRow = (item, handleOnClick) => (
  <>
    <TableData text={item.officeName} />
    <TableData text={item.state} />
    <TableData
      text={item.requestedDate ? moment(item.requestedDate).format('ddd, MMM DD, YYYY') : '-'}
    />
    <td
      style={{
        fontFamily: 'Nunito',
        fontSize: 16,
        color: Colors.neutral_600,
        textAlign: 'center',
      }}
    >
      {item.workerClassificationType
        ? capitalizeEveryFirstLetter(item.workerClassificationType)
        : '-'}
    </td>
    <td>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 16,
        }}
      >
        <Button
          Icon={XCircleIcon}
          iconStyle={{
            marginRight: 0,
          }}
          text="Decline"
          onClick={() => handleOnClick(item, WC_MANAGEMENT_STATUS.DECLINED)}
          style={{
            backgroundColor: Colors.neutral_400,
            width: 104,
            height: 30,
            marginTop: 0,
          }}
          textStyle={{
            fontSize: 14,
          }}
        />
        <Button
          text="Approve"
          onClick={() => handleOnClick(item, WC_MANAGEMENT_STATUS.CONFIRMED)}
          Icon={CheckCircleIcon}
          iconStyle={{
            marginRight: 0,
          }}
          style={{
            width: 104,
            height: 30,
            marginTop: 0,
          }}
          textStyle={{
            fontSize: 14,
          }}
        />
      </div>
    </td>
  </>
);
