import React from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Colors } from '../../themes/colors';

/** Clickable div with a title on the top and a big number below */
const NumberItemWithTitle = ({
  title, value, clickUrl, highlightCount
}) => {
  const highlighted = highlightCount && value > 0;

  return (
    <Link
      style={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textDecoration: 'none',
        padding: '30px',
      }}
      to={clickUrl}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
        }}
      >
        <span
          style={{
            flex: 1,
            fontFamily: 'Nunito',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '27px',
            color: Colors.neutral_600,
            minWidth: 140,
            textAlign: 'center',
          }}
        >
          {title}
        </span>

        {highlighted && (
          <div
            style={{
              width: 12,
              height: 12,
              backgroundColor: Colors.error_500,
              borderRadius: '50%',
              marginLeft: 8,
            }}
          />
        )}
      </div>

      <span
        style={{
          flex: 1,
          fontFamily: 'Nunito',
          fontWeight: 700,
          fontSize: '48px',
          lineHeight: '65px',
          color: highlighted ? Colors.error_500 : Colors.primary_500,
        }}
      >
        {value}
      </span>
    </Link>
  );
};

const ShiftsWidget = ({ activeCount, scheduledCount, actionRequiredCount }) => (
  <div className="dashboard-shifts-shadow">
    {!activeCount && !scheduledCount && !actionRequiredCount
      ? (
        <div style={{
          height: '258', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', gap: 20
        }}
        >
          <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading />
        </div>
      )
      : (
        <>
          <NumberItemWithTitle title="Open Shifts" value={activeCount} clickUrl="/dashboard/jobs/open" />
          <NumberItemWithTitle title="Action Needed" value={actionRequiredCount} clickUrl="/dashboard/jobs/pending" highlightCount />
          <NumberItemWithTitle title="Scheduled" value={scheduledCount} clickUrl="/dashboard/jobs/scheduled" />
        </>
      )}
  </div>
);

export default ShiftsWidget;
