import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect, Route, Router, Switch, useLocation,
} from 'react-router-dom';
import About from './components/About';
import Alerts from './components/Alerts';
import ConfirmHyg from './components/ConfirmHyg';
import Help from './components/Help';
import HowItWorks from './components/HowItWorks';
import JobDetails from './components/JobDetails';
import NPSReview from './components/NPSReview';
import Payment from './components/Payments/Payment';
import PPScreen from './components/Request/pp/PPScreen';
import JobPost from './components/Request/temp/JobPost';
import SideBar from './components/SideBar';
import TopNavBar from './components/TopNavBar';
import UserAuth from './components/authentication/UserAuth';
import Profile from './components/profile/Profile';

import Feedback from './components/Feedback';
import OfficeInfo from './components/OfficeInfo';
import ForgotPassword from './components/authentication/ForgotPassword';
import { AdditionalOfficeInformation } from './components/registration/AdditionalOfficeInformation';
import appHistory from './history';

import {
  INITIAL_LOADING, hideCreateJobMessage, hideMessage, initialLoading,
} from './actions';
import { createLoadingSelector } from './api/selectors';
import ErrorShower from './commonComponents/ErrorShower';
import UnavalibleMobile from './commonComponents/UnavalibleMobile';
import Footer from './commonComponents/footer';
import Guideline from './components/Guideline';
import CalendarMDB from './components/Request/multiday/CalendarMDB';
import ConfirmMDB from './components/Request/multiday/ConfirmMDB';
import GuidelinesMDB from './components/Request/multiday/GuidelinesMDB';
import SelectDefaultMDB from './components/Request/multiday/SelectDefaultMDB';
import { ContactInformation } from './components/registration/ContactInformation';
import { GeneralOfficeInformation } from './components/registration/GeneralOfficeInformation';
import { WorkerClassification as WorkerClassificationSignUp } from './components/registration/WorkerClassification';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CustomPortal from './commonComponents/CustomPortal';
import CustomSpinner from './commonComponents/CustomSpinner';
import CancellationFeedback from './components/CancellationFeedback';
import Dashboard from './components/Dashboard/Dashboard';
import InvoiceList from './components/Dashboard/ReceiptList/InvoiceList';
import ReceiptList from './components/Dashboard/ReceiptList/ReceiptList';
import ExpiredCounterOffers from './components/ExpiredCounterOffers';
import Inbox from './components/Inbox';
import ParkingInfoPopup from './components/ParkingInfoPopup';
import PermanentDashboard from './components/PermanentJobs/PermanentDashboard';
import PermanentsJobs from './components/PermanentJobs/PermanentsJobs';
import ReferralScreen from './components/Referral/ReferralScreen';
import AdjustHoursScreen from './components/Request/AdjustHoursScreen';
import VirtualJobRedirection from './components/VirtualJobRedirection';
import AdjustmentScreen from './components/adjustments/AdjustmentScreen';
import OpenJobScreen from './components/counterJobs/OpenJobScreen';
import Chat from './components/messages/Chat';
import EditProfile from './components/profile/EditProfile';

import CandidateList from './components/CandidateList/CandidateList';
/* eslint import/no-cycle: [2, { ignoreExternal: true }] */
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import CreateJobPortal from './commonComponents/CreateJobPortal';
import { AcceptTermsPopup } from './components/AcceptTermsPopup';
import TransactionsInfo from './components/Dashboard/ReceiptList/TransactionsInfo';
import EndOfDay from './components/EndOfDay';
import JobSummaryRating from './components/JobSummaryRating';
import LocumTenensDentist from './components/LocumTenensDentist';
import NotificationSettings from './components/NotificationSettings/NotificationSettings';
import { OfficeTimePerPatientPopup } from './components/OfficeTimePerPatientPopup/OfficeTimePerPatientPopup';
import ProfessionalProfile from './components/ProfessionalProfile';
import EditJobPost from './components/Request/temp/EditJobPost';
import { Schedule } from './components/Schedule/Schedule';
import { SuccessRegistrationPopup } from './components/SuccessRegistrationPopup';
import { WorkerClassification } from './components/WorkerClassification';
import { SuccessPopup as SuccessWorkerClassificationPopup } from './components/WorkerClassification/components/SuccessPopup';
import { WorkerClassificationManagement } from './components/WorkerClassificationManagement';
import { growthbook, showDecoupledDntEodFlow, showShowWCManagementPage } from './growthbook';
import { NewPhotoUploadFeaturePopup } from './components/NewPhotoUploadFeaturePopup';
import { ShiftList } from './components/JobList/ShiftList/ShiftList';
import { OfficeEfdaOnboardingPopup } from './components/OfficeEfdaOnboardingPopup';
import { ProfessionalsHub } from './components/ProfessionalsHub/ProfessionalsHub';
import { OfficeFrontDeskOnboardingPopup } from './components/OfficeFrontDeskOnboardingPopup';
import { InviteProfessional } from './components/ProfessionalsHub/components/InviteProfessional/InviteProfessional';
import { TodayJobs } from './components/JobList/TodayJobs';
import { OfficeJobInvites } from './components/ProfessionalsHub/components/OfficeJobInvites/OfficeJobInvites';
import { RebookShiftDetails, RebookGuidelines } from './components/Request/rebook';
import { ReviewContent as RebookReview } from './components/Request/ReviewContent';

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const loadingSelector = createLoadingSelector([INITIAL_LOADING]);

  const isLoading = useSelector((state) => loadingSelector(state));
  const user = useSelector((state) => state.user.selectedChildOffice);
  const missingWorkerClassification = user?.isWorkerClassificationEnabled && !user?.workerClassification?.classificationType;
  const message = useSelector((state) => state.message.message);

  const [requestedUser, setRequestedUser] = useState(false);

  const homePath = process.env.PUBLIC_URL;
  const isMobileTablet = window.innerWidth <= 760;

  useEffect(() => {
    dispatch(initialLoading());

    setRequestedUser(true);
  }, []);

  useEffect(() => {
    if (requestedUser && user) {
      growthbook.setAttributes({
        id: user?.id,
        userType: user?.user_type,
        ...(user?.address && user?.address[0] && { state: user.address[0].state }),
      });
    }
  }, [user, requestedUser]);

  useEffect(() => {
    window.analytics.page(location.pathname);
  }, [location]);

  const {
    text, title, route, params, isSuccess, isError, popupProps, jobData,
  } = message;
  if (isMobileTablet) {
    return <UnavalibleMobile />;
  }
  if (isLoading || !requestedUser) {
    return <CustomSpinner isLoading={isLoading} />;
  }

  if (requestedUser && (!user || !user.is_profile_complete || user.user_type !== 'DNT')) {
    return (
      <Router basename={homePath} history={appHistory}>
        <div style={{ backgroundColor: '#FAFAFA'}}>
          <Route path="/" exact component={UserAuth} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/registration" exact component={() => <ContactInformation />} />
          <Route
            path="/registration/admin"
            component={(props) => <GeneralOfficeInformation {...props} />}
          />

          <Route
            path="/registration/office-info"
            component={() => <AdditionalOfficeInformation isRegistration />}
          />
          <Route
            path="/registration/worker-classification"
            component={() => <WorkerClassificationSignUp />}
          />

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </div>
        <ErrorShower />
        {text || title ? (
          <CustomPortal
            isSuccess={isSuccess}
            isError={isError}
            popupProps={popupProps}
            title={title}
            text={text}
            route={route}
            params={params}
            submitPressed={() => dispatch(hideMessage())}
          />
        ) : null}
        <SuccessWorkerClassificationPopup />
      </Router>
    );
  }

  return (
    <Router basename={homePath} history={appHistory} style={{ backgroundColor: '#FAFAFA' }}>
      <Route path="/" component={TopNavBar} />
      <div style={{ display: 'flex', flex: 1, minHeight: "calc(100% - 180px)" }}>
        <Route path="/" component={(props) => <SideBar {...props} />} />
        <Switch>
          <Route exact path="/dashboard/home" render={() => <Redirect to="/" />} />

          {/* <Route path="*" element={<Dashboard />} /> */}
          <Route path="/" exact component={() => <Dashboard />} />

          <Route path="/account/invoices" component={() => <InvoiceList />} />

          {!missingWorkerClassification && (
            <Route path="/dashboard/temp" component={(props) => <JobPost {...props} />} />
          )}

          <Route
            path="/dashboard/temporaryJob/:jobId/edit"
            component={(props) => <EditJobPost {...props} />}
          />
          <Route
            path="/dashboard/permanentJobs/:jobId"
            component={(props) => <PermanentDashboard {...props} />}
          />
          <Route
            path="/dashboard/permanentJobs"
            component={(props) => <PermanentsJobs {...props} />}
          />

          <Route
            path="/dashboard/inbox"
            component={(props) => (
              <div className="component_container inbox_screen">
                <div className="floating_container">
                  <Inbox {...props} />
                </div>
              </div>
            )}
          />

          <Route path="/dashboard/chat/:jobId" component={(props) => <Chat {...props} />} />

          <Route
            path="/dashboard/jobs/:jobId/expired"
            exact
            component={(props) => <ExpiredCounterOffers {...props} />}
          />

          <Route path="/dashboard/guideline" component={(props) => <Guideline {...props} />} />

          <Route
            path="/dashboard/jobs/:jobId/adjust"
            component={(props) => <AdjustHoursScreen {...props} />}
          />

          {!missingWorkerClassification && (
            <Route
              path="/dashboard/multi-day/calendar"
              component={(props) => <CalendarMDB {...props} />}
            />
          )}

          <Route
            path="/dashboard/multi-day/info"
            component={(props) => <SelectDefaultMDB {...props} />}
          />

          <Route
            path="/dashboard/multi-day/confirm"
            component={(props) => <ConfirmMDB {...props} />}
          />

          <Route
            path="/dashboard/multi-day/guidelines"
            component={(props) => <GuidelinesMDB {...props} />}
          />
          
          <Route
            path="/dashboard/re-book/info"
            component={(props) => <RebookShiftDetails {...props} />}
          />

          <Route
            path="/dashboard/re-book/confirm"
            component={() => <RebookReview title="Re-Book Request" urlPrefix='/dashboard/re-book' />}
          />

          <Route
            path="/dashboard/re-book/guidelines"
            component={() => <RebookGuidelines />}
          />

          <Route path="/dashboard/pprs" component={(props) => <PPScreen {...props} />} />

          <Route
            path="/dashboard/receipts/transactions/:jobId"
            component={(props) => <TransactionsInfo {...props} />}
          />

          <Route path="/dashboard/receipts" component={(props) => <ReceiptList {...props} />} />
          <Route
            path="/dashboard/jobs/today"
            component={(props) => <TodayJobs {...props} />}
          />
          <Route
            path="/dashboard/jobs/:type"
            component={(props) => <ShiftList {...props} />}
          />
          <Route
            exact
            path="/dashboard/job/:jobId/redirect"
            component={(props) => <VirtualJobRedirection {...props} />}
          />

          <Route
            exact
            path="/dashboard/candidates/:jobId"
            component={(props) => <CandidateList {...props} />}
          />
          <Route
            exact
            path="/dashboard/candidate/:jobId"
            component={(props) => <ProfessionalProfile {...props} />}
          />
          <Route
            exact
            path="/dashboard/confirm-hygienist/:jobId/:candidateId"
            component={(props) => <ConfirmHyg {...props} />}
          />

          <Route
            path="/dashboard/job/:jobId/feedback"
            exact
            component={(props) => <EndOfDay {...props} />}
          />
          {showDecoupledDntEodFlow() && (
            <Route
              path="/dashboard/job/:jobId/feedback-rating"
              exact
              component={(props) => <JobSummaryRating {...props} />}
            />
          )}
          <Route
            path="/dashboard/job/:jobId/details"
            exact
            component={(props) => <JobDetails {...props} />}
          />
          <Route path="/dashboard/job/:jobId" component={(props) => <OpenJobScreen {...props} />} />
          <Route path="/dashboard/nps" component={(props) => <NPSReview {...props} />} />

          <Route path="/dashboard/alerts" component={(props) => <Alerts {...props} />} />
          <Route path="/dashboard/calendar" component={(props) => <Schedule {...props} />} />
          <Route path="/account/payment" exact component={(props) => <Payment {...props} />} />

          <Route path="/dashboard/referral" component={(props) => <ReferralScreen {...props} />} />
          <Route path="/dashboard/edit" component={(props) => <EditProfile {...props} />} />

          <Route path="/dashboard/help" component={(props) => <Help {...props} />} />
          <Route path="/dashboard/about" component={(props) => <About {...props} />} />
          <Route path="/dashboard/office" component={(props) => <OfficeInfo {...props} />} />
          <Route
            path="/dashboard/notifications"
            component={(props) => <NotificationSettings {...props} />}
          />
          <Route
            path="/dashboard/feedback/:jobId"
            component={(props) => <CancellationFeedback {...props} />}
          />
          <Route path="/dashboard/feedback" component={(props) => <Feedback {...props} />} />

          <Route
            path="/dashboard/adjustments/:jobId"
            component={(props) => <AdjustmentScreen {...props} />}
          />

          <Route path="/dashboard/howitworks" component={(props) => <HowItWorks {...props} />} />

          <Route
            path="/dashboard/locum-tenens-dentist"
            component={(props) => <LocumTenensDentist {...props} />}
          />

          <Route
            path="/dashboard/worker-classification"
            component={(props) => <WorkerClassification {...props} />}
          />

          {
            showShowWCManagementPage() &&(
              <Route
                path="/account/worker-classification-management"
                component={(props) => <WorkerClassificationManagement {...props} />}
              />
            )}
          <Route path="/professionals-hub" component={(props) => <ProfessionalsHub {...props} />} />
          <Route path="/invite-professional" component={(props) => <InviteProfessional {...props} />} />
          <Route path="/invites" component={(props) => <OfficeJobInvites {...props} />} />

          <Route path="/account" component={(props) => <Profile {...props} />} />

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
      <div className="footer-box">
        <Footer />
      </div>
      {text || title ? (
        <CustomPortal
          isSuccess={isSuccess}
          isError={isError}
          popupProps={popupProps}
          title={title}
          text={text}
          route={route}
          params={params}
          submitPressed={() => dispatch(hideMessage())}
        />
      ) : null}
      {jobData && (
        <CreateJobPortal
          jobData={jobData}
          route={route}
          params={params}
          submitPressed={() => dispatch(hideCreateJobMessage())}
        />
      )}

      <ParkingInfoPopup />
      <SuccessRegistrationPopup />
      <AcceptTermsPopup />
      <SuccessWorkerClassificationPopup />
      <OfficeTimePerPatientPopup />
      <NewPhotoUploadFeaturePopup />
      <OfficeEfdaOnboardingPopup />
      <OfficeFrontDeskOnboardingPopup />
      <ErrorShower />
    </Router>
  );
};
