import React from 'react';
import { motion } from 'framer-motion';

import closeIcon from '../../images/close-icon.svg';

export default function JobPostingPopup({ children, closeModal }) {
  return (
    <div className="modal" onClick={closeModal}>
      <motion.div
        onClick={(event) => event.stopPropagation()}
        className="modal_content"
        style={{ position: 'relative' }}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <img
          src={closeIcon}
          style={{
            position: 'absolute',
            top: 16,
            left: '92%',
            cursor: 'pointer',
          }}
          alt="close_button"
          onClick={closeModal}
        />

        {children}
      </motion.div>
    </div>
  );
}
