import { showProfessionalAttendanceBadges } from '../../../../../growthbook';
import { Colors } from '../../../../../themes/colors';
import AttendanceBadge from '../../../../candidates/AttendanceBadge';
import MultiProfessionalBadges from '../../../../candidates/MultiProfessionalBadges';

export const Divider = ({ badges, currentRank }) => {
  const renderAttendanceBadges = () => {
    if (badges) {
      return <MultiProfessionalBadges badges={badges} isPreview showPriorityOnly />;
    }

    return <AttendanceBadge currentRank={currentRank} width={26} height={26} noLabel />;
  };

  return (
    <div style={{ position: 'relative', marginBlockEnd: badges ? 55 : 0 }}>
      <div
        style={{
          backgroundColor: Colors.primary_500,
          width: '100%',
          height: 1,
          margin: '16px 0px 25px 0px',
          alignSelf: 'center',
        }}
      />

      {showProfessionalAttendanceBadges() && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
            paddingRight: 16,
          }}
        >
          {renderAttendanceBadges()}
        </div>
      )}
    </div>
  );
};
