import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchNotificationSettings, updateNotificationSettings } from '../../actions';
import { createLoadingSelector } from '../../api/selectors';
import BackButton from '../../commonComponents/BackButton';
import Button from '../../commonComponents/Button';
import CustomSpinner from '../../commonComponents/CustomSpinner';
import WarningBanner from '../../commonComponents/WarningBanner';
import browserHistory from '../../history';
import { Colors } from '../../themes/colors';
import SaveNotificationSettingsPopup from '../SaveNotificationSettingsPopup';
import { Content } from './components/Content';
import { getAllItems, groupAndSortNotifications, shouldShowWarning } from './utils';

const loadingFecthSelector = createLoadingSelector(['FETCH_NOTIFICATION_SETTINGS']);
const loadingSaveSelector = createLoadingSelector(['UPDATE_NOTIFICATION_SETTINGS']);

export default function NotificationSettings() {
  const dispatch = useDispatch();
  const notificationSettings = useSelector((state) => state.user.notificationSettings);
  const isFetching = useSelector((state) => loadingFecthSelector(state));
  const isSaving = useSelector((state) => loadingSaveSelector(state));
  const [initialAllSettings, setInitialAllSettings] = useState([]);
  const [currentSettings, setCurrentSettings] = useState({});
  const allSettings = useMemo(() => getAllItems(currentSettings), [currentSettings]);
  const showNotificationWarning = allSettings?.some(({ event, ...settings }) =>
    shouldShowWarning(event, settings),
  );
  const [showSaveSettingsPopup, setShowSaveSettingsPopup] = useState(false);
  const hasChanges = useMemo(() => JSON.stringify(initialAllSettings) !== JSON.stringify(allSettings), [initialAllSettings, allSettings]);

  const handleOnSwitchChange = (group, code, checked, eventType) => {
    const newSettingsArr = currentSettings[group]?.items.map((notification) => {
      if (notification.event === eventType) {
        return { ...notification, [code]: checked };
      }

      return notification;
    });

    setCurrentSettings((prevState) => ({
      ...prevState,
      [group]: { ...prevState[group], items: newSettingsArr },
    }));
  };

  const handleBackButtonClick = () => {
    if (!hasChanges) {
      browserHistory.goBack();
    } else {
      setShowSaveSettingsPopup('reminder');
    }
  };

  const handleOnSaveButton = () => {
    dispatch(
      updateNotificationSettings({
        notificationSettings: allSettings.map(({ email, event, push, text }) => ({
          email,
          event,
          push,
          text,
        })),
        callback: () => {
          setShowSaveSettingsPopup('save');
        },
      }),
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchNotificationSettings());
  }, [dispatch]);

  useEffect(() => {
    if (notificationSettings) {
      const sortedSettings = groupAndSortNotifications(notificationSettings);
      setInitialAllSettings(getAllItems(sortedSettings));
      setCurrentSettings(sortedSettings);
    }
  }, [notificationSettings]);


  const renderContent = () => {
    if (isFetching) {
      return <CustomSpinner isLoading={isFetching} />;
    }
    if (!notificationSettings) {
      return       <p
        style={{
          fontSize: 16,
          color: '#546e7a',
          textAlign: 'center',
          marginTop: 20,
        }}
      >
        There are no settings to show.
      </p>
    }
    if (notificationSettings) {
      return       <div
        style={{
          width: '-webkit-fill-available',
          backgroundColor: Colors.neutral_50,
          marginRight: 54,
          marginLeft: 54,
          paddingRight: 32,
          paddingLeft: 32,
          paddingTop: 24,
        }}
      >
        {showNotificationWarning && (
          <div style={{ marginBottom: -8 }}>
            <WarningBanner description="You have opted out of an important notification" />
          </div>
        )}
        {notificationSettings && (
          <>
            <Content
              notifications={currentSettings}
              handleOnSwitchChange={handleOnSwitchChange}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 46,
                marginBottom: 56,
              }}
            >
              <Button
                text="Save"
                disabled={!hasChanges}
                size="medium"
                onClick={handleOnSaveButton}
                isLoading={isSaving}
              />
            </div>
          </>
        )}
      </div>
    }

  }


  return (
    <>
      <div className="component_container">
        <div
          className="floating_container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            paddingBottom: 50,
          }}
        >
          <div style={{ width: '100%', marginLeft: 20 }}>
            <BackButton onClick={handleBackButtonClick} />
          </div>

          <h1 className="global_font f-dark" style={{ marginBottom: 30, textAlign: 'center' }}>
            Notification Settings
          </h1>

          {renderContent()}
        </div>
      </div>

      {showSaveSettingsPopup && (
        <SaveNotificationSettingsPopup
          showSaveSettingsPopup={showSaveSettingsPopup}
          setShowSaveSettingsPopup={setShowSaveSettingsPopup}
        />
      )}
    </>
  );
}
