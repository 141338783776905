import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Colors } from '../../../themes/colors';

import PPnextStep from '../../../images/PPnextStep.png';
import PPdeclineButton from '../../../images/PPdeclineButton.png';
import GeneralHygInfo from './GeneralHygInfo';
import PPVirtualResume from '../../../images/PPVirtualResume.png';
import { styles } from './StylesPopups';
import { createLoadingSelector } from '../../../api/selectors';
import { FETCH_APPLICATION_BY_ID_PP } from '../../../actions';
import CustomSpinner from '../../../commonComponents/CustomSpinner';

export default ({
  declinePressed, nextPressed, closePopupClicked, isDeclinedStage,
}) => {
  const selectedCandidate = useSelector((state) => state.pp.currentApplication);

  const loadingSelector = createLoadingSelector([FETCH_APPLICATION_BY_ID_PP]);

  const isLoading = useSelector((state) => loadingSelector(state));
  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopupClicked();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        ref={wrapperRef}
        style={{
          backgroundColor: Colors.white,
          alignContent: 'center',
          justifyContent: 'center',
          maxWidth: 700,
          minWidth: 450,
          width: '30%',
          borderRadius: 25,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 50,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        <div
          style={{
            marginTop: 8,
            fontSize: 20,
            fontWeight: 'bold',
            color: Colors.neutral_300,
            cursor: 'pointer',
            textAlign: 'right',
            marginRight: 20,
          }}
          onClick={() => closePopupClicked()}
        >
          X
        </div>

        {isLoading ? <CustomSpinner isLoading={isLoading} />
          : (
            <div
              style={{
                width: '85%',
                alignSelf: 'center',
              }}
            >

              <GeneralHygInfo selectedCandidate={selectedCandidate} />

              {
                selectedCandidate?.resume?.officeNote && (
                  <>
                    <div
                      style={styles.descriptionTitleGray}
                    >
                      Notes
                    </div>

                    <div
                      style={styles.descriptionGray}
                    >
                      {selectedCandidate.resume.officeNote}
                    </div>
                  </>
                )
              }

              <div
                style={styles.descriptionTitleGray}
              >
                License #:
              </div>

              <div
                style={styles.descriptionGray}
              >
                {selectedCandidate?.resume?.licId || 'N/A'}
              </div>

              <div
                style={styles.descriptionTitleGray}
              >
                School Name:
              </div>

              <div
                style={styles.descriptionGray}
              >
                {selectedCandidate?.resume?.schoolName || 'N/A'}
              </div>

              <div
                style={styles.descriptionTitleGray}
              >
                State:
              </div>

              <div
                style={styles.descriptionGray}
              >
                {selectedCandidate?.resume?.state || 'N/A'}
              </div>

              <div
                style={styles.descriptionTitleGray}
              >
                Years of experience:
              </div>

              <div
                style={styles.descriptionGray}
              >
                {selectedCandidate?.resume?.yearsOfExperience || 'N/A'}
              </div>
              {selectedCandidate?.resumeUrl ? (
                <a
                  href={selectedCandidate?.resumeUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  style={{ textDecoration: 'none' }}
                >
                  <div style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
                    <img src={PPVirtualResume} alt="resume" style={{ width: 25, height: 25 }} />

                    <div style={{
                      fontFamily: 'Nunito', fontWeight: 'bold', fontSize: 15, color: Colors.primary_500,
                    }}
                    >
                      Download Resume
                    </div>
                  </div>
                </a>
              ) : <div />}

              <div style={{
                display: 'flex', flexDirection: 'row', marginBottom: 50, alignItems: 'center', justifyContent: 'space-between', marginTop: 30,
              }}
              >
                <div
                  onClick={() => declinePressed()}
                  style={styles.roundButton45}
                >
                  <img src={PPdeclineButton} style={{ height: 100, width: 100, cursor: 'pointer' }} alt="decline" />
                  <p style={{ color: Colors.neutral_800, fontSize: 16, fontWeight: 'bold' }}>
                    {' '}
                    {isDeclinedStage ? 'Close' : 'Reject'}
                  </p>
                </div>
                <div
                  onClick={() => nextPressed()}
                  style={styles.roundButton45}
                >
                  <img src={PPnextStep} style={{ height: 100, width: 100, cursor: 'pointer' }} alt="next" />

                  <p style={{ color: Colors.neutral_800, fontSize: 16, fontWeight: 'bold' }}>
                    {isDeclinedStage ? 'Interview' : ' Next Step'}
                  </p>
                </div>
              </div>
            </div>
          )}

      </div>
    </div>
  );
};
