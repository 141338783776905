import arrowRight from '../../images/arrow_right.svg';

export const RedirectButton = ({ onClick }) => (
  <td
    onClick={onClick}
    style={{
      cursor: 'pointer',
      textAlign: 'center',
    }}
  >
    <img
      src={arrowRight}
      style={{
        objectFit: 'contain',
      }}
      alt="arrow_right"
    />
  </td>
);
