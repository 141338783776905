import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import '../themes/registration.scss';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { Colors } from '../themes/colors';

const override2 = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default class LocationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null,
    };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address, placeId) => {
    this.setState({ address });
    this.props.result({ place_id: placeId, formatted_address: address });
  };

  render() {
    const { address } = this.state;
    return (
      <PlacesAutocomplete
        value={this.props.address && address === null ? this.props.address : address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={1000}
        searchOptions={{
          componentRestrictions: { country: ['us'] },
          types: ['address'],
        }}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading,
        }) => (
          <>
            <input
              {...getInputProps({
                placeholder: this.props.placeholder,
                className: 'input-field',
                style: {
                  width: '100%',
                  maxWidth: '-webkit-fill-available',
                  marginBottom: 0
                },
              })}
            />
            <div
              className="autocomplete-dropdown-container"
              style={{
                boxShadow: '0px 2px 4px rgba(111, 131, 141, 0.3)',
                width: 510,
                borderRadius: '5px',
                position: 'absolute',
              }}
            >
              {loading ? (
                <div style={{ background: Colors.white }}>
                  <ClipLoader
                    css={override2}
                    sizeUnit="px"
                    size={50}
                    color={Colors.primary_500}
                    loading={loading}
                  />
                </div>
              ) : (
                suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active suggest'
                    : 'suggestion-item suggest';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: 'rgba(62, 176, 202, 1)', cursor: 'pointer' }
                    : { backgroundColor: Colors.white, cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span onClick={() => this.setState({ address: suggestion.description })}>
                        {suggestion.description}
                      </span>
                    </div>
                  );
                })
              )}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    );
  }
}
