import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';

import {
  CANCEL_JOB_POSTING,
  CREATE_JOB_ACTION,
  cancelJobPosting,
  closeOverlappingModal,
  hideJobCreatePaymentError,
  hideMessage, createJob,
} from '../actions';
import { createErrorObjectSelector, createLoadingSelector } from '../api/selectors';
import CardSpinner from '../commonComponents/CardSpinner';
import { renderPoints } from '../commonComponents/RenderListFunc';
import { Colors } from '../themes/colors';
import '../themes/global.scss';
import '../themes/home.scss';

import { Links } from '../constants';
import arrowBack from '../images/backButtonIcon.png';
import headerImage from '../images/guideImage.png';
import WarningIcon from '../images/icons/Warning';
import warningIcon from '../images/warning-icon.svg';
import ConfirmPopup from './shared/ConfirmPopup';
import { isEmployeeOption } from './WorkerClassification/utils';

class Guideline extends Component {
  timeoutID = '';

  constructor(props) {
    super(props);

    this.state = { showLeavingPopup: false };
  }

  componentDidMount() {
    const { location, cancelJobPosting } = this.props;
    const { jobData } = location.state;

    this.timeoutID = setTimeout(() => cancelJobPosting({ timeout: 60000, job: jobData }), 60000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  btnSubmitClicked = (another_job_offer) => {
    clearTimeout(this.timeoutID);

    const { location, createJob } = this.props;
    const { jobData } = location.state;
    createJob({ ...jobData, duplicate: another_job_offer });
  };

  render() {
    const { showLeavingPopup } = this.state;
    const {
      cancelJobPosting,
      history,
      location,
      closeOverlappingModal,
      hideJobCreatePaymentError,
    } = this.props;

    const { jobData } = location.state;
    const { isLoading, job, user } = this.props;
    const { isOverlappingJob, showJobCreatePaymentError } = job;
    const isEmployee = isEmployeeOption(user);

    if (isLoading) {
      return <CardSpinner />;
    }

    return (
      <div
        className="component_container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflowX: 'hidden',
        }}
      >
        <div style={{
          display: 'flex',
          width: '100%',
          boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
          zIndex: 3,
        }}
        />
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: Colors.primary_500,
            width: '100%',
            boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 50,
              top: 40,
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              marginLeft: 10,
            }}
            onClick={() => history.goBack()}
          >
            <img src={arrowBack} alt="back" style={{ height: 17, marginRight: 10 }} />
            <p
              className="global_font f-dark"
              style={{
                color: Colors.white,
                fontWeight: 'bold',
                fontSize: 22,
                textAlign: 'left',
                width: '100%',
                cursor: 'pointer',
              }}

            >
              Back
            </p>
          </div>

          <img
            alt="guide-img"
            src={headerImage}
            style={{
              resizeMode: 'contain',
              width: 190,
              marginTop: 60,
              marginBottom: 35,
            }}
          />
        </div>

        <div
          className="floating_container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '95%',
            marginTop: 0,
            boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
          }}
        >
          <div
            style={{
              marginLeft: 30,
              marginRight: 30,
              marginBottom: 60,
              padding: '0px 80px',
            }}
          >
            <h3
              style={{
                marginTop: 45,
                color: Colors.primary_500,
                fontFamily: 'Nunito',
                fontSize: 22,
                fontWeight: 'bold',
                letterSpacing: 0,
              }}
            >
              GoTu Guidelines
            </h3>
            <p
              style={{
                marginTop: 10,
                color: Colors.neutral_600,
                fontFamily: 'Nunito',
                fontSize: 16,
                letterSpacing: 0,
              }}
            >
              In order to be a good member of the community and receive the best service, please
              remember to follow these guidelines:
            </p>

            {renderPoints(
              'I agree to confirm or decline the dental professional who accepts my shift within 5 hours.',
            )}
            {renderPoints(
              'I agree to make any edits, changes, or cancellations to this shift through GoTu directly.',
            )}
            {renderPoints(
              'I agree to schedule all temp shifts with this dental professional through GoTu.',
            )}
            {renderPoints('I agree to follow all local and national health guidelines.')}
            {isEmployee && renderPoints('I agree to let the professional take earned rest breaks in compliance with my state’s employment laws, if applicable.')}
            {renderPoints(
              <>
                I agree with the
                {' '}
                <a href={Links.termsOfUse} target="_blank" rel="noopener noreferrer"
                   style={{ color: Colors.secondary_500, textDecoration: 'none' }}>Offices Cancellation Policy</a>
                . Filled shifts are subject to cancellation fees up to 7 days before the start of the shift. For
                details, please refer to the Terms and Conditions.
              </>,
            )}

            <p
              style={{
                color: Colors.neutral_600,
                fontFamily: 'Nunito',
                fontSize: 15,
                marginTop: 40,
                marginBottom: 70,
                marginLeft: 35,
                textAlign: 'center',
              }}
            >
              If you have any questions, please call or text our Customer Support at 786-460-2170.
            </p>

            <div
              style={{
                display: 'flex',
                columnGap: 16,
                alignItems: 'center',
                justifyContent: 'center',
                padding: 10,
              }}
            >
              <button
                type="button"
                className="green-button"
                style={{
                  margin: 0,
                  width: 300,
                  backgroundColor: Colors.neutral_500,
                }}
                onClick={() => this.setState({ showLeavingPopup: true })}
              >
                <p className="white-text-18">Cancel Request</p>
              </button>

              <button
                type="button"
                className="green-button"
                style={{
                  margin: 0,
                  width: 300,
                  backgroundColor: Colors.primary_500,
                }}
                onClick={() => this.btnSubmitClicked()}
              >
                <p className="white-text-18">I Agree & Confirm Request</p>
              </button>
            </div>
          </div>

          {isOverlappingJob && (
            <ConfirmPopup
              Icon={() => <div style={{ marginBottom: 20 }}><WarningIcon width={64} height={64} /></div>}
              title="Please Confirm"
              description={(
                <>
                  You have another shift posted and/or confirmed for
                  {' '}
                  <strong>{moment(jobData.localDate).format('ddd, MMM DD, YYYY')}</strong>
                  . If you wish to continue with your request, click on “Confirm”.
                </>
              )}
              descriptionStyle={{ width: '76%', marginBottom: 50 }}
              leftButtonText="Go Back"
              leftButtonStyle={{
                backgroundColor: Colors.secondary_500,
              }}
              leftButtonAction={() => closeOverlappingModal()}
              rightButtonText="Confirm"
              rightButtonAction={() => this.btnSubmitClicked(true)}
            />
          )}

          {showLeavingPopup && (
            <ConfirmPopup
              title="Please Confirm"
              description="Are you sure you want to leave?"
              leftButtonText="Cancel"
              leftButtonAction={() => this.setState({ showLeavingPopup: false })}
              rightButtonText="Confirm"
              rightButtonAction={() => {
                cancelJobPosting({ timeout: -1, job: jobData });
                history.push('/');
              }}
            />
          )}

          {showJobCreatePaymentError && (
            <ConfirmPopup
              Icon={() => <img src={warningIcon} style={{ marginBottom: 20 }} alt="warning_icon" />}
              title="Auto-Confirm Disabled"
              description={(
                <>
                  The auto-confirm feature is only available for offices that have input their payment information.
                  <br />
                  <br />
                  You can update your payment information now or continue without payment, and with auto-confirm
                  disabled.
                </>
              )}
              descriptionStyle={{ marginBottom: 45 }}
              containerStyle={{ paddingTop: 45, paddingBottom: 50 }}
              leftButtonText="Go Back"
              leftButtonAction={() => {
                history.goBack();

                hideJobCreatePaymentError();
              }}
              rightButtonText="Update"
              rightButtonAction={() => {
                history.replace('/');
                history.push('/dashboard/payment');

                hideJobCreatePaymentError();
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const actions = [CREATE_JOB_ACTION, CANCEL_JOB_POSTING];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorObjectSelector(actions);

const mapStateToProps = (state) => ({
  user: state.user.user,
  error: errorSelector(state),
  isLoading: loadingSelector(state),
  job: state.job,
});

export default connect(mapStateToProps, {
  createJob,
  hideMessage,
  cancelJobPosting,
  closeOverlappingModal,
  hideJobCreatePaymentError,
})(Guideline);
