import React from 'react';
import { Colors } from '../../themes/colors';

const GalleryIcon = ({ color = Colors.secondary_500, size = 38 }) => (
  <svg width={size} height={size} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.8333 7.55954V22.6818C34.8168 22.7061 34.7912 22.7288 34.7854 22.7555C34.3962 24.4897 33.1592 25.475 31.3623 25.475C24.8691 25.4758 18.3759 25.4767 11.8818 25.475C9.87877 25.475 8.44472 24.0825 8.44224 22.1167C8.43565 17.4508 8.43565 12.7849 8.44802 8.119C8.44884 7.72066 8.51069 7.30532 8.63768 6.92803C9.09783 5.56542 10.3381 4.75174 11.8983 4.75093C18.2373 4.74932 24.5755 4.75013 30.9146 4.75093C31.1619 4.75093 31.4093 4.74851 31.6567 4.75741C33.0355 4.80599 34.209 5.66501 34.6551 6.95475C34.7236 7.15311 34.7739 7.35794 34.8333 7.55954ZM32.1202 17.0937C32.1433 17.0832 32.1664 17.0735 32.1895 17.063V16.7942C32.1895 13.92 32.1895 11.0466 32.1895 8.17243C32.1895 7.5474 31.9701 7.33204 31.3343 7.33204C24.8732 7.33204 18.4113 7.33204 11.9503 7.33204C11.307 7.33204 11.0836 7.55549 11.0836 8.19429C11.0836 12.0805 11.0836 15.9659 11.0836 19.8522V20.1987C11.2064 20.0869 11.2815 20.0222 11.3516 19.9534C12.4376 18.8879 13.5229 17.8208 14.6097 16.7553C15.6776 15.7085 17.0424 15.7044 18.1095 16.7488C18.5985 17.2273 19.076 17.7164 19.5749 18.2167C19.6805 18.0969 19.7613 18.007 19.8396 17.9147C21.357 16.127 22.8726 14.3378 24.3916 12.5525C25.4612 11.2952 27.0511 11.2919 28.1429 12.5404C29.0088 13.5306 29.873 14.5224 30.7381 15.5133C31.1982 16.0404 31.6592 16.5667 32.1202 17.0937Z"
      fill={color}
    />
    <path
      d="M1.58325 26.1476C2.22152 24.0482 2.8598 21.9481 3.49807 19.8487C3.91039 18.4934 4.32354 17.1389 4.73668 15.7844C4.76225 15.7018 4.79028 15.62 4.87605 15.5479C4.87605 15.6548 4.87605 15.7617 4.87605 15.8686C4.87605 18.5808 4.85708 21.2931 4.88264 24.0045C4.90079 25.9606 5.76996 27.4641 7.5248 28.4308C8.27687 28.8445 9.09904 29.0048 9.95996 29.004C13.5768 28.9999 17.1945 29.0024 20.8114 29.0024C23.4082 29.0024 26.005 29.0024 28.6018 29.0024C28.7148 29.0024 28.8269 29.0024 28.9795 29.0024C28.7618 29.6881 28.5532 30.3496 28.3429 31.0103C28.1615 31.5786 27.9784 32.147 27.797 32.7161C27.4349 33.8513 26.6598 34.537 25.4756 34.7815C25.4261 34.792 25.3799 34.8155 25.3321 34.8333H24.5281C24.3417 34.7823 24.1553 34.7305 23.969 34.6811C19.1737 33.4181 14.3776 32.1559 9.58228 30.8921C7.59654 30.369 5.60833 29.8549 3.62919 29.3117C2.87217 29.1036 2.30894 28.6113 1.95352 27.9239C1.79271 27.6139 1.70447 27.2673 1.58325 26.9378C1.58325 26.6747 1.58325 26.4107 1.58325 26.1476Z"
      fill={color}
    />
    <path
      d="M32.1202 17.0937C31.6592 16.5667 31.1982 16.0404 30.7381 15.5133C29.873 14.5224 29.0088 13.5306 28.1429 12.5404C27.0511 11.2919 25.4612 11.2952 24.3916 12.5525C22.8726 14.3378 21.357 16.127 19.8396 17.9147C19.7613 18.007 19.6805 18.0969 19.5749 18.2167C19.076 17.7164 18.5985 17.2273 18.1095 16.7488C17.0424 15.7044 15.6776 15.7085 14.6097 16.7553C13.5229 17.8208 12.4376 18.8879 11.3516 19.9534C11.2815 20.0222 11.2064 20.0869 11.0836 20.1987V19.8522C11.0836 15.9659 11.0836 12.0805 11.0836 8.19429C11.0836 7.55549 11.307 7.33204 11.9503 7.33204C18.4113 7.33204 24.8732 7.33204 31.3343 7.33204C31.9701 7.33204 32.1895 7.5474 32.1895 8.17243C32.1895 11.0466 32.1895 13.92 32.1895 16.7942V17.063C32.1664 17.0735 32.1433 17.0832 32.1202 17.0937ZM15.0291 8.63906C13.5769 8.64473 12.3952 9.80898 12.4001 11.2274C12.405 12.6459 13.5942 13.8118 15.039 13.8126C16.4969 13.8134 17.7025 12.6241 17.6828 11.2032C17.663 9.77092 16.4846 8.63258 15.0291 8.63906Z"
      fill="white"
    />
    <path
      d="M15.0291 8.63906C16.4845 8.63339 17.6616 9.77271 17.6814 11.2041C17.7012 12.625 16.4964 13.8152 15.0376 13.8136C13.5928 13.8128 12.4037 12.6469 12.3987 11.2284C12.3938 9.80915 13.5763 8.64571 15.0277 8.64004L15.0291 8.63906Z"
      fill={color}
    />
  </svg>
);

export default GalleryIcon;
