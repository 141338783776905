import { Colors } from '../../../../themes/colors';

export const EmptyMessage = ({ type }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 100,
    }}
  >
    <span
      style={{
        fontFamily: 'Nunito',
        display: 'flex',
        color: Colors.neutral_500,
        fontStyle: 'italic',
        textAlign: 'center',
      }}
    >
      There are no {type} shifts.
    </span>
  </div>
);
