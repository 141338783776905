import '../../themes/global.scss';
import '../../themes/confirm_hyg.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Colors } from '../../themes/colors';
import defaultImage from '../../images/HY_tooth.png';
import ArrowRightIcon from '../../images/icons/ArrowRight';

export default ({ selectedJobs, isLoading }) => {
  const allMessages = useSelector((state) => state.messages.allJobsWithMessages);

  const isReadMessage = (item) => new Date(item.last_message.created_at) < new Date(item.user_last_reed);

  if (!isLoading && allMessages.length === 0) {
    return (
      <div
        style={{
          width: '95%',
          margin: 40,
          marginLeft: 20,
        }}
      >
        <h2
          className="global_font"
          style={{
            fontSize: 16,
            color: Colors.neutral_500,
            fontWeight: 'normal',
            textAlign: 'center'
          }}
        >
          You have no chats.
        </h2>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '90%',
        margin: 40,
      }}
    >
      {allMessages.map((job, index) => (
        <div key={job.id}>
          <div
            onClick={() => selectedJobs(job)}
            key={index}
            className="line_2"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              paddingBottom: 10,
              paddingTop: 10,
              cursor: 'pointer',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', width: '90%' }}>
              <div
                style={{
                  backgroundColor: Colors.secondary_500,
                  minWidth: 8,
                  minHeight: 8,
                  borderRadius: '50%',
                  margin: '0px 12px',
                  opacity: isReadMessage(job) ? 0 : 1,
                }}
              />

              <img
                src={job.opponent_user.profile_image || defaultImage}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                  objectFit: 'cover',
                }}
                alt="profile_image"
              />
              <div style={{ marginLeft: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
                  <span className="global_font f-dark bold f-16">
                    {`${job.opponent_user.first_name} ${job.opponent_user.last_name}`}
                  </span>
                  <span className="global_font f-dark bold f-16" style={{ width: 20, display: 'flex', justifyContent: 'center' }}> | </span>
                  <span className="global_font f-dark bold f-16" style={{ color: Colors.primary_500 }}>{job.main_user.office_name}</span>
                </div>

                <div
                  className="global_font f-dark"
                  style={{
                    marginTop: 5,
                    marginBottom: 10,
                    fontSize: 16,
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word', /* For older browsers */
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {' '}
                  {job.last_message.text}
                </div>

                <div
                  className="global_font f-light"
                  style={{ fontSize: 13, textDecoration: 'italic' }}
                >
                  Shift Date:
                  {' '}
                  {moment(new Date(job.job_datetime)).format('ddd, MMM DD, YYYY')}
                </div>
              </div>
            </div>
            <div style={{ width: 85, display: 'flex', justifyContent: 'center' }}>
              <div
                className="global_font bold f-16"
                style={{ color: Colors.secondary_500, cursor: 'pointer' }}
                onClick={() => selectedJobs(job)}
              >
                <ArrowRightIcon />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
