import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UserIcon from '../../../../../images/user-icon.svg';
import { Colors } from '../../../../../themes/colors';
import { RadioOption } from '../../../../registration/components/RadioOption';
import { getProfessionName } from '../../utils';
import { CollapsibleRow } from '../CollapsibleRow/CollapsibleRow';
import { Title } from '../CollapsibleRow/Title';
import { Value } from '../CollapsibleRow/Value';

export const ProfessionalRow = ({ professionOptions, onToggle, isOpen, onConfirm }) => {
  const [professionType, setProfessionType] = useState(
    professionOptions?.[0]?.code ?? 'RDH'
  );

  useEffect(() => {
    onConfirm({
      type: getProfessionName(professionOptions, professionType),
    });

    if (isOpen) {
      onToggle();
    }
  }, [professionType]);

  return (
    <CollapsibleRow
      isOpen={isOpen}
      onToggle={onToggle}
      TitleComponent={() => <Title text="Professional:" />}
      ValueComponent={() => (
        <Value
          customStyles={{
            color: Colors.neutral_600,
          }}
          text={getProfessionName(professionOptions, professionType)}
        />
      )}
      icon={UserIcon}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <div
          style={{
            marginRight: 68,
          }}
        >
          {professionOptions?.map(({ code, name }) => (
            <div style={{ marginBottom: 10 }} key={code}>
              <RadioOption
                onClick={() => setProfessionType(code)}
                text={name}
                checked={code === professionType}
              />
            </div>
          ))}
        </div>
      </div>
    </CollapsibleRow>
  );
};
