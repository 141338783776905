import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import React from 'react';
import Portal from './Portal';

import history from '../history';
import '../themes/alertmessage.scss';
import { Colors } from '../themes/colors';
import ConfirmPopup from '../components/shared/ConfirmPopup';
import AnimatedCheckCircle from '../components/shared/AnimatedCheckCircle';

export default ({
  route, params, submitPressed,
}) => (
  <Portal>
    <ConfirmPopup
      Icon={() => <AnimatedCheckCircle />}
      title="Request Successfully Submitted!"
      titleStyle={{ marginTop: 20 }}
      description="Your shift is now available for professionals nearby. Check back for new candidates."
      descriptionStyle={{ width: '90%' }}
      rightButtonText="Done"
      rightButtonAction={() => {
        submitPressed();
        history.push(route, params);
      }}
    />
  </Portal>
);
