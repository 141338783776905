import { useDispatch } from 'react-redux';
import { postInviteToMultiDayJob } from '../../../actions';
import { GuidelinesContent } from '../GuidelinesContent';

export const RebookGuidelines = () => {
  const dispatch = useDispatch();

  return (
    <GuidelinesContent
      title="Re-Book Request"
      urlPrefix="/dashboard/re-book"
      onConfirm={() => {
        dispatch(postInviteToMultiDayJob());
      }}
    />
  );
};
