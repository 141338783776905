import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Colors } from '../../themes/colors';
import BackButton from '../../commonComponents/BackButton';

import defaultProfileImage from '../../images/defaultProfileImage.png';

const ProfileSummary = ({ job, title, noBack }) => {
  const history = useHistory();

  return (
    <div
      style={{
        backgroundColor: Colors.white,
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        paddingTop: '76px',
        paddingBottom: 35,
        boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
        zIndex: 1,
        position: 'relative',
      }}
    >
      {noBack ? null : (
        <div style={{ gridArea: '1 / 1', paddingLeft: '73px' }}>
          <BackButton />
        </div>
      )}
      <div
        style={{
          gridArea: '1 / 2',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1
          className='global_font'
          style={{
            textAlign: 'center',
            color: Colors.neutral_600,
            fontSize: '32px',
            marginBottom: 35,
          }}
        >
          {title}
        </h1>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img
            src={job.offer_final_reciever?.profile_image || defaultProfileImage}
            style={{
              width: 100,
              height: 100,
              borderRadius: 100,
              objectFit: 'cover',
              marginBottom: 10,
              marginRight: 28,
            }}
            alt="profile_image"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <p
              className="global_font bold_font"
              style={{ color: Colors.neutral_600, fontSize: 20 }}
            >
              {job.offer_final_reciever
                ? `${job.offer_final_reciever.first_name} ${job.offer_final_reciever.last_name}`
                : ''}
            </p>
            <button
              type="button"
              className="global_font"
              style={{
                margin: 0,
                color: Colors.secondary_500,
                cursor: 'pointer',
                fontSize: 18,
                fontWeight: 'bold',
                background: 'transparent',
                boxShadow: 'none',
                textTransform: 'capitalize',
                padding: 0,
                border: 'none',
              }}
              onClick={() =>
                history.push(`/dashboard/job/${job.id}/details`, {
                  job_status: job.job_status,
                  isPrevScreenFeedback: true,
                })
              }
            >
              See details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSummary;
