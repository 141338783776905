import { fetchActionRequiredJobs, fetchOpenJobs, fetchScheduledJobs } from '../../../actions';
import { FilterStatusEnum } from '../../../utils/Enum';

export const headerOpenTabTitles = [
  'Office',
  'Shift Details',
  'Profession',
  'Rate',
  'Send to',
];

export const headerPendingTabTitles = [
  'Office',
  'Shift Details',
  'Profession',
  'Candidates',
  'Send to',
  'More',
];

export const headerScheduledTabTitles = [
  'Office',
  'Professional',
  'Shift Details',
  'Profession',
  'Rate',
];

export const getInitialTab = (type) => {
  if (type === FilterStatusEnum.OPEN) return 0;
  if (type === FilterStatusEnum.PENDING) return 1;
  return 2;
};

export const getTypeByTab = (activeTab) => {
  if (activeTab === 0) return FilterStatusEnum.OPEN;
  if (activeTab === 1) return FilterStatusEnum.PENDING;
  return FilterStatusEnum.SCHEDULED;
};

export const fetchJobList = (type, data) => {
  switch (type) {
  case FilterStatusEnum.OPEN:
    return fetchOpenJobs(data);
  case FilterStatusEnum.PENDING:
    return fetchActionRequiredJobs(data);
  case FilterStatusEnum.SCHEDULED:
    return fetchScheduledJobs(data);
  default:
    return () => {};
  }
};
