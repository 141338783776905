import { useDispatch, useSelector } from 'react-redux';
import {
  closeOverlappingModal
} from '../../actions';
import BackButton from '../../commonComponents/BackButton';
import Button from '../../commonComponents/Button';
import CardSpinner from '../../commonComponents/CardSpinner';
import { renderPoints } from '../../commonComponents/RenderListFunc';
import { Links } from '../../constants';
import WarningIcon from '../../images/icons/Warning';
import { Colors } from '../../themes/colors';
import ConfirmPopup from '../shared/ConfirmPopup';
import { isEmployeeOption } from '../WorkerClassification/utils';
import { useGuidelinesStep } from './hooks/useGuidelinesStep';
import ProgressBar from './multiday/ProgressBar';

export const GuidelinesContent = ({ title, urlPrefix, onConfirm }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const isEmployee = isEmployeeOption(user);
  const {
    isLoading,
    job,
    showLeavingPopup,
    showJobCreatePaymentError,
    setShowLeavingPopup,
    handleConfirmButton,
    confirmOverlapping,
    cancelRequest,
    hidePaymentError,
    updatePayment,
  } = useGuidelinesStep({
    onConfirm,
  });

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          justifyContent: 'flex-start',
          flexDirection: 'column',
          boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
        }}
      >
        <div className="card">
          <div style={{ marginTop: 20, marginLeft: 30 }}>
            <BackButton />
          </div>
          <div className="card-body">
            <ProgressBar step={3} title={title} redirectUrl={`${urlPrefix}/info`} />
          </div>
          <div
            style={{
              marginLeft: 30,
              marginRight: 30,
              marginBottom: 60,
              padding: '0px 80px',
            }}
          >
            <h3
              style={{
                marginTop: 55,
                color: Colors.primary_500,
                fontFamily: 'Nunito',
                fontSize: 22,
                fontWeight: 'bold',
                letterSpacing: 0,
              }}
            >
              GoTu Guidelines
            </h3>
            <p
              style={{
                marginTop: 10,
                color: Colors.neutral_600,
                fontFamily: 'Nunito',
                fontSize: 16,
                letterSpacing: 0,
              }}
            >
              In order to be a good member of the community and receive the best service, please
              remember to follow these guidelines:
            </p>

            {renderPoints(
              'I agree to confirm or decline the dental professional who accepts my shift within 5 hours.',
            )}
            {renderPoints(
              'I acknowledge that there is no charge to post shifts. The booking fee will be billed for each individual shift after the dental professional is confirmed by your office. The professional pay will be billed no more than 28 days prior to shift date.',
            )}
            {renderPoints(
              'I agree to make any edits, changes, or cancellations to this shift through GoTu directly.',
            )}
            {renderPoints(
              'I agree to schedule all temp shifts with this dental professional through GoTu.',
            )}
            {renderPoints('I agree to follow all local and national health guidelines.')}
            {isEmployee &&
              renderPoints(
                'I agree to let the professional take earned rest breaks in compliance with my state’s employment laws, if applicable.',
              )}
            {renderPoints(
              <>
                I agree with the{' '}
                <a
                  href={Links.termsOfUse}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: Colors.secondary_500, textDecoration: 'none', fontWeight: 700 }}
                >
                  Offices Cancellation Policy
                </a>
                . Filled shifts are subject to cancellation fees up to 7 days before the start of
                the shift. For details, please refer to the Terms and Conditions.
              </>,
            )}

            <p
              style={{
                color: Colors.neutral_600,
                fontFamily: 'Nunito',
                fontSize: 16,
                marginTop: 40,
                marginBottom: 70,
                textAlign: 'center',
              }}
            >
              If you have any questions, please call or text our Customer Support at 786-460-2170.
            </p>

            <div
              style={{
                display: 'flex',
                columnGap: 16,
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 120,
              }}
            >
              <Button
                text="Cancel Request"
                style={{
                  backgroundColor: Colors.secondary_500,
                }}
                textStyle={{
                  textTransform: 'capitalize',
                }}
                onClick={() => setShowLeavingPopup(true)}
              />
              <Button
                text="I Agree & Confirm Request"
                style={{
                  backgroundColor: Colors.primary_500,
                }}
                textStyle={{
                  textTransform: 'capitalize',
                }}
                onClick={() => handleConfirmButton()}
              />
            </div>
          </div>

          {showJobCreatePaymentError && (
            <ConfirmPopup
              Icon={() => (
                <div style={{ marginBottom: 20 }}>
                  <WarningIcon />
                </div>
              )}
              title="Auto-Confirm Disabled"
              description={
                <>
                  The auto-confirm feature is only available for offices that have input their
                  payment information.
                  <br />
                  <br />
                  You can update your payment information now or continue without payment, and with
                  auto-confirm disabled.
                </>
              }
              descriptionStyle={{ marginBottom: 45 }}
              containerStyle={{ paddingTop: 45, paddingBottom: 50 }}
              leftButtonText="Go Back"
              leftButtonStyle={{
                backgroundColor: Colors.secondary_500,
              }}
              leftButtonAction={hidePaymentError}
              rightButtonText="Update"
              rightButtonAction={updatePayment}
            />
          )}

          {showLeavingPopup && (
            <ConfirmPopup
              Icon={() => (
                <div style={{ marginBottom: 20 }}>
                  <WarningIcon width={64} height={64} />
                </div>
              )}
              title="Please Confirm"
              description="Are you sure you would like to cancel this request?"
              descriptionStyle={{ marginBottom: 100 }}
              containerStyle={{ paddingTop: 45, paddingBottom: 50 }}
              leftButtonText="Go Back"
              closePopup={() => setShowLeavingPopup(false)}
              leftButtonStyle={{
                backgroundColor: Colors.secondary_500,
              }}
              leftButtonAction={() => setShowLeavingPopup(false)}
              rightButtonText="Confirm"
              rightButtonAction={cancelRequest}
            />
          )}

          {job?.isOverlappingJob && (
            <ConfirmPopup
              Icon={() => (
                <div style={{ marginBottom: 20 }}>
                  <WarningIcon width={64} height={64} />
                </div>
              )}
              title="Please Confirm"
              description={
                'At least 1 shift overlaps with an existing shift posted and/or confirmed. If you would like to review the dates, click "Go Back". If you wish to continue, click on "Confirm".'
              }
              descriptionStyle={{ width: '76%', marginBottom: 50 }}
              leftButtonText="Go Back"
              leftButtonStyle={{
                backgroundColor: Colors.secondary_500,
              }}
              leftButtonAction={() => dispatch(closeOverlappingModal())}
              rightButtonText="Confirm"
              rightButtonAction={confirmOverlapping}
            />
          )}
        </div>
      </div>
    </div>
  );
};
