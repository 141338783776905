import React from 'react';
import { Colors } from '../../../themes/colors';
import Background from '../../../images/backgroundGradient.png';
import Logo from '../../../images/logos/logoLightShadowIcon.png';

export const ContainerLayout = ({ children }) => (
  <div
    style={{
      padding: 16,
      backgroundColor: Colors.neutral_50,
    }}
  >
    <div
      style={{
        display: 'flex',
        backgroundColor: Colors.white,
        height: '100%',
        boxShadow: '0px 2px 4px 0px rgba(111, 131, 141, 0.3251)',
      }}
    >
      <div style={{ height: 'auto', flex: 1 }}>{children}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          minWidth: '627px',
          minHeight: '928px',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${Background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={Logo}
              alt="logo"
              style={{ width: '50%', objectFit: 'fill' }}
            />
            <span
              style={{
                fontFamily: 'Nunito',
                fontSize: 24,
                fontWeight: 400,
                textAlign: 'center',
                color: Colors.white,
                marginTop: 10,
                textShadow: "rgba(0, 0, 0, 0.15) 0px 2px"
              }}
            >
              Where Tech Meets Talent
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);
