import { useDispatch, useSelector } from 'react-redux';
import { editUser, hideSuccessWorkerClassificationPopup, showRegistrationPopup } from '../../../../actions';
import CustomPortal from '../../../../commonComponents/CustomPortal';
import PersonHourglassSVG from '../../../../images/person-hourglass.svg';
import ConfirmPopup from '../../../shared/ConfirmPopup';

export const SuccessPopup = () => {
  const dispatch = useDispatch();
  const user = useSelector(
    (state) => state.user,
  );
  const isPendingWCRequest = user?.showSuccessWorkerClassificationPopup === 'requested'

  const handleConfirmButton = () => {
    if (!user?.user?.is_profile_complete) {
      dispatch(hideSuccessWorkerClassificationPopup());
      dispatch(editUser({ data: { is_profile_complete: true } }));
      dispatch(showRegistrationPopup());
    } else {
      window.location.reload();
    }
  };

  if (user?.showSuccessWorkerClassificationPopup === false) return null;

  return !isPendingWCRequest ? 
    <CustomPortal
      isSuccess
      redirect={false}
      title="Worker Type Successfully Selected!"
      text="This office’s worker type selection has been confirmed. Congratulations, you are ready to start posting!"
      submitPressed={handleConfirmButton}
    />
    :
    <ConfirmPopup
      Icon={() => (
        <img
          src={PersonHourglassSVG}
          style={{ marginBottom: 20 }}
          alt="confirm_circle"
        />
      )}
      descriptionStyle={{ width: '90%', marginBottom: 50 }}
      title="Pending Approval"
      description={
        <>
          Thank you for submitting a worker type request. 
          <br/>
          This request has been sent to your managing admin
          <br/>
          (oversight account) for approval. Once approved, you may 
          <br/>
          begin to request temporary staffing.
          <br/>
          <br/>
          For questions, please call or text our Customer Service
          <br/>
          team at (786) 460-2170
        </>
      }
      rightButtonText="Got it!"
      rightButtonAction={handleConfirmButton}
    />
};
