import { motion } from 'framer-motion';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { UPLOAD_AVATAR_ACTION, uploadAvatar } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import { AvatarList } from '../../../commonComponents/AvatarList/AvatarList';
import Button from '../../../commonComponents/Button';
import { ImageCropper } from '../../../commonComponents/ImageCropper/ImageCropper';
import Portal from '../../../commonComponents/Portal';
import ClosePopupIcon from '../../../images/ClosePopupIcon.svg';
import OfficeAvatar1 from '../../../images/avatars/Office-Avatar-1.svg';
import { Colors } from '../../../themes/colors';
import { getBlobFromBlobUrl } from '../../../utils/Blob';

const loadingSelector = createLoadingSelector([UPLOAD_AVATAR_ACTION]);

export const ChangeAccountPhotoModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fullImage, setFullImage] = useState(null);
  const [showCropperModal, setShowCropperModal] = useState(false);
  const { getInputProps, open } = useDropzone({
    accept: { 'image/*': [] },
    maxFiles: 1,
    noClick: true,
    maxSize: 2097152,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      setFullImage(files[0]);
      setShowCropperModal(true);
    },
  });

  const handleAvatarSelect = (img, index) => {
    if (selectedIcon === index) {
      setSelectedIcon(null);
      setSelectedImage(null);
      return;
    }

    setSelectedImage(img);
    setSelectedIcon(index);
  };

  const onCropComplete = async (_croppedImage) => {
    saveImage(_croppedImage);
  };

  const saveImage = async (img) => {
    dispatch(uploadAvatar({ avatar: await getBlobFromBlobUrl(img) }));
  };

  return (
    <>
      {!showCropperModal ? (
        <Portal>
          <div
            className="modal"
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              position: 'fixed',
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              style={{
                width: 600,
                backgroundColor: Colors.white,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 14,
                padding: 35,
                paddingBottom: 48,
                overflowY: 'auto',
              }}
              onClick={(event) => event.stopPropagation()}
            >
              <img
                alt="close-popup-icon"
                src={ClosePopupIcon}
                style={{
                  position: 'absolute',
                  marginTop: -16,
                  marginRight: -16,
                  cursor: 'pointer',
                  alignSelf: 'flex-end',
                }}
                onClick={onClose}
              />

              <span
                style={{
                  fontFamily: 'Nunito',
                  fontSize: 28,
                  fontWeight: 700,
                  color: Colors.neutral_600,
                }}
              >
                Change Account Photo
              </span>

              <img
                alt="profile"
                src={user?.profile_image ?? OfficeAvatar1}
                style={{
                  width: 120,
                  height: 120,
                  borderRadius: '100%',
                  resizeMode: 'contain',
                  marginTop: 28,
                  marginBottom: 24,
                }}
              />

              <input {...getInputProps()} />
              <Button
                text="Upload from Computer"
                style={{
                  backgroundColor: Colors.secondary_500,
                  width: 212,
                  height: 36,
                }}
                textStyle={{
                  fontSize: 16,
                }}
                onClick={open}
              />

              <span
                style={{
                  color: Colors.neutral_500,
                  fontStyle: 'italic',
                  marginTop: 16,
                }}
              >
                (Maximum upload file size 2MB)
              </span>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 12,
                  width: 400,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 24,
                  marginBottom: 24,
                }}
              >
                <div
                  style={{
                    height: 2,
                    flex: 1,
                    backgroundColor: Colors.neutral_100,
                  }}
                />
                <span
                  style={{
                    fontFamily: 'Nunito',
                    fontSize: 18,
                    color: Colors.neutral_600,
                    fontStyle: 'italic',
                  }}
                >
                  or
                </span>
                <div
                  style={{
                    height: 2,
                    flex: 1,
                    backgroundColor: Colors.neutral_100,
                  }}
                />
              </div>

              <AvatarList onSelect={handleAvatarSelect} selected={selectedIcon} horizontal />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 16,
                  marginTop: 24,
                }}
              >
                <Button
                  text="Go Back"
                  style={{
                    backgroundColor: Colors.secondary_500,
                    width: 152,
                    height: 48,
                  }}
                  onClick={onClose}
                />
                <Button
                  text="Select"
                  style={{
                    width: 152,
                    height: 48,
                  }}
				  isLoading={isLoading}
				  disabled={!selectedIcon}
                  onClick={() => saveImage(selectedImage)}
                />
              </div>
            </motion.div>
          </div>
        </Portal>
      ) : (
        <ImageCropper
          src={URL.createObjectURL(fullImage)}
          onComplete={onCropComplete}
          onClose={onClose}
          leftButtonText="Go Back"
          leftButtonOnClick={() => setShowCropperModal(false)}
		  rightButtonLoading={isLoading}
        />
      )}
    </>
  );
};
