import React from 'react';
import { Colors } from '../../themes/colors';

const OutlineCalendarIcon = ({ color = Colors.neutral_600 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Outline/calendar">
      <path
        id="Icon"
        d="M8 6.5V2.5M16 6.5V2.5M7 10.5H17M5 20.5H19C20.1046 20.5 21 19.6046 21 18.5V6.5C21 5.39543 20.1046 4.5 19 4.5H5C3.89543 4.5 3 5.39543 3 6.5V18.5C3 19.6046 3.89543 20.5 5 20.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default OutlineCalendarIcon;
