import React from 'react';
import { Colors } from '../../themes/colors';

const CalendarIcon = ({ color = Colors.neutral_600 }) => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5381 1.20875H16.2506C16.2506 0.683744 15.9006 0.33374 15.3756 0.33374C14.8506 0.33374 14.5006 0.683744 14.5006 1.20875H7.50052C7.50052 0.683744 7.15051 0.33374 6.62551 0.33374C6.10051 0.33374 5.7505 0.683744 5.7505 1.20875H1.46297C0.937962 1.20875 0.500458 1.64625 0.500458 2.17126V21.5381C0.500458 22.0631 0.937962 22.5006 1.46297 22.5006H20.5381C21.0631 22.5006 21.5006 22.0631 21.5006 21.5381V2.17126C21.5006 1.64625 21.0631 1.20875 20.5381 1.20875ZM19.7502 20.7502H2.2501V8.50005H19.7502V20.7502ZM19.7506 7.18793V2.95874H16.2506V3.83375C16.2506 4.35875 15.9006 4.70876 15.3756 4.70876C14.8506 4.70876 14.5006 4.35875 14.5006 3.83375V2.95874H7.5005V3.83375C7.5005 4.35875 7.1505 4.70876 6.6255 4.70876C6.10049 4.70876 5.75049 4.35875 5.75049 3.83375V2.95874H2.25046V7.18793H19.7506Z"
      fill={color}
    />
    <path
      d="M9.54924 16.4376C9.8907 16.7436 10.4029 16.7436 10.7444 16.4376L14.2444 13.301C14.5858 12.9949 14.5858 12.5359 14.2444 12.2299C13.9029 11.9239 13.3907 11.9239 13.0492 12.2299L10.1468 14.9075L8.95168 13.8365C8.61021 13.5305 8.09802 13.5305 7.75655 13.8365C7.41509 14.1425 7.41509 14.6015 7.75655 14.9075L9.54924 16.4376Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5381 1.20875H16.2506C16.2506 0.683744 15.9006 0.33374 15.3756 0.33374C14.8506 0.33374 14.5006 0.683744 14.5006 1.20875H7.50052C7.50052 0.683744 7.15051 0.33374 6.62551 0.33374C6.10051 0.33374 5.7505 0.683744 5.7505 1.20875H1.46297C0.937962 1.20875 0.500458 1.64625 0.500458 2.17126V21.5381C0.500458 22.0631 0.937962 22.5006 1.46297 22.5006H20.5381C21.0631 22.5006 21.5006 22.0631 21.5006 21.5381V2.17126C21.5006 1.64625 21.0631 1.20875 20.5381 1.20875ZM19.7502 20.7502H2.2501V8.50005H19.7502V20.7502ZM19.7506 7.18793V2.95874H16.2506V3.83375C16.2506 4.35875 15.9006 4.70876 15.3756 4.70876C14.8506 4.70876 14.5006 4.35875 14.5006 3.83375V2.95874H7.5005V3.83375C7.5005 4.35875 7.1505 4.70876 6.6255 4.70876C6.10049 4.70876 5.75049 4.35875 5.75049 3.83375V2.95874H2.25046V7.18793H19.7506Z"
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M9.54924 16.4376C9.8907 16.7436 10.4029 16.7436 10.7444 16.4376L14.2444 13.301C14.5858 12.9949 14.5858 12.5359 14.2444 12.2299C13.9029 11.9239 13.3907 11.9239 13.0492 12.2299L10.1468 14.9075L8.95168 13.8365C8.61021 13.5305 8.09802 13.5305 7.75655 13.8365C7.41509 14.1425 7.41509 14.6015 7.75655 14.9075L9.54924 16.4376Z"
      stroke={color}
      strokeWidth="0.3"
    />
  </svg>
);

export default CalendarIcon;
