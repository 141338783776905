import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SAVE_GIFT_EMAIL,
  createReferralLink,
  saveGiftEmail
} from '../../actions';
import { createLoadingSelector } from '../../api/selectors';
import DisclaimerView from './DisclaimerView';

import CardSpinner from '../../commonComponents/CardSpinner';
import referralImage from '../../images/referralImage.png';
import { Colors } from '../../themes/colors';
import CopyLinkPopup from './CopyLinkPopup';

const loadingSelector = createLoadingSelector([SAVE_GIFT_EMAIL]);

export default ({ history }) => {
  const giftEmail = useSelector((state) => state.user.user.gift_email);
  const isLoading = useSelector((state) => loadingSelector(state));

  const dispatch = useDispatch();
  const [email, setEmail] = useState(giftEmail || '');
  const [enableEditing, setEnableEdititng] = useState(false);

  const [showLinkPopup, setShowLinkPopup] = useState(false);
  const [showDisclaimerPopup, setShowDisclaimerPopup] = useState(false);

  useEffect(() => {
    dispatch(createReferralLink());
  }, []);

  const saveEmailClicked = () => {
    setEnableEdititng(false);
    dispatch(saveGiftEmail({ email }));
  };

  if (isLoading) {
    return (
      <CardSpinner />
    );
  }
  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center',
        }}
      >
        <div className="title-text-32 " style={{ marginTop: 50 }}>Referral</div>
        <img src={referralImage} style={{ width: 250, marginTop: 35, marginBottom: 45 }} alt="referral image" />
        <div className="title-text-24"> Invite Friends. Earn Money.</div>
        <div className="title-text-16" style={{ maxWidth: 600, marginTop: 17 }}>
          Invite an office, and earn an E-Gift Card after they complete their first shift. The more amazing dental offices you invite, the more gift cards you can earn - just our way of saying thank you!

        </div>
        <div className="title-text-16" style={{ color: Colors.primary_500, marginTop: 30, marginBottom: 11 }}>Who will this E-Gift Card be sent to?</div>
        {enableEditing || !giftEmail ? <input className="input-field" style={{ width: '440px' }} value={email} onChange={(val) => setEmail(val.target.value)} /> : (
          <p className="title-text-16">
            {email}
          </p>
        ) }
        {!giftEmail || enableEditing
          ? (
            <button
              className="border-blue-button"
              onClick={() => saveEmailClicked()}
            >
              <p className="blue-text-18">Save Email</p>
            </button>
          )
          : (
            <button
              className="border-blue-button"
              onClick={() => setEnableEdititng(true)}
            >
              <p className="blue-text-18">Update Email</p>
            </button>
          )}
        <div className="description-text-14" style={{ maxWidth: 600 }}>
          An e-gift card will be sent to the email associated with your office’s account
          after the office has completed their first shift to GoTu.
        </div>
        <button
          className="green-button"
          onClick={() => setShowLinkPopup(true)}
        >
          <p className="white-text-18">Invite Now</p>
        </button>
        <div
          onClick={() => setShowDisclaimerPopup(true)}
          style={{ marginBottom: 40, marginTop: 20 }}
        >
          <div
            className="description-text-14"
            style={{
              textDecorationLine: 'underline', cursor: 'pointer',
            }}
          >
            Disclaimer/ Terms and Conditions
          </div>
        </div>
      </div>
      {showLinkPopup ? <CopyLinkPopup link="google.com" closePopupClicked={() => setShowLinkPopup(false)} /> : <div />}
      {showDisclaimerPopup ? (
        <DisclaimerView closePopup={() => setShowDisclaimerPopup(false)} />
      ) : (
        <div />
      )}
    </div>
  );
};
