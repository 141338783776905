import React, { useState } from 'react';
import history from '../../../history';
import Background from '../../../images/signup/signupBackground.png';
import Header from '../../Header';

export const ContainerLayout = ({ children }) => {
  const [isDropdownToggleOpen, setIsDropdownToggleOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownToggleOpen(!isDropdownToggleOpen);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
      }}
    >
      <Header
        isOpen={isDropdownToggleOpen}
        onClick={() => history.push('/')}
        toggleDropdown={toggleDropdown}
        text="Sign Up"
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </div>
    </div>
  );
};
