import { shouldUsePaymentService } from "growthbook";
import moment from "moment";

export const addExpirationDate = (item) => {
  const expirationYear = shouldUsePaymentService() ? item.expiry_year : item.exp_year;
  const expirationMonth = shouldUsePaymentService() ? item.expiry_month : item.exp_month;
  const expirationDate = moment({ year: expirationYear, month: expirationMonth - 1 });

  const isExpired = moment(expirationDate, 'MM/YYYY').isBefore(moment());
  return {
    ...item,
    expirationDate,
    isExpired,
  };
}