// import { HIDE_MESSAGE } from '../actions/types';

export default (state = {}, action) => {
  if (action.type === 'HIDE_ERROR_ACTION') {
    return {
      ...state,
      [action.payload]: '',
    };
  }

  const { type, payload } = action;
  const matches = /(.*)_(FAILURE)/.exec(type);
  if (!matches) return state;
  const [, requestName, requestState] = matches;
  const responseMessage = payload?.message || '';
  const message = requestState === 'FAILURE' ? responseMessage : '';
  return {
    ...state,
    [requestName]: {
      message,
    },
  };
};
