import React from 'react';
import { Colors } from '../../themes/colors';

export const Link = ({ text, href }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    className="title_font"
    style={{
      color: Colors.secondary_500,
      textDecoration: 'none',
      fontSize: 16,
    }}
  >
    {text}
  </a>
);
