import '../themes/global.scss';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SEND_FEEDBACK_TEMPMEE, sendTempmeeFeedback, showMessage } from '../actions';
import { createLoadingSelector } from '../api/selectors';
import BackButton from '../commonComponents/BackButton';
import Button from '../commonComponents/Button';
import CardSpinner from '../commonComponents/CardSpinner';

export default function Feedback() {
  const dispatch = useDispatch();

  const loadingSelector = createLoadingSelector([SEND_FEEDBACK_TEMPMEE]);
  const isLoading = useSelector((state) => loadingSelector(state));

  const [feedbackNote, setFeedbackNote] = useState('');

  const submitFeedback = () => {
    if (feedbackNote.length <= 0) {
      dispatch(showMessage({ title: 'Error', message: 'Please enter feedback note', isError: true }));
    } else {
      dispatch(sendTempmeeFeedback({ message: feedbackNote }));
    }
  };

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div className="floating_container">
        <div style={{ margin: '15px 50px', width: '100%' }}>
          <BackButton />

          <h1 className="global_font f-dark" style={{ textAlign: 'center' }}>
            Send Feedback
          </h1>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div style={{ width: '75%' }}>
              <h2
                className="global_font f-dark"
                style={{ textAlign: 'center' }}
              >
                Tell us how we’re doing
              </h2>

              <p
                className="global_font f-dark"
                style={{ textAlign: 'center', marginBottom: 35, marginTop: 50 }}
              >
                Your feedback is invaluable to our efforts to improving the GoTu
                (formerly TempMee) experience for all. Share your thoughts with
                us below!
              </p>

              <p
                className="global_font f-dark"
                style={{ textAlign: 'center', marginBottom: 50 }}
              >
                For
                {' '}
                <strong>urgent questions or requests</strong>
                {' '}
                related to
                your account or an active offer, please reach out to your
                regional account manager, or call
                {' '}
                <strong>(786) 460-2170.</strong>
              </p>

              <textarea
                className="textarea-style"
                style={{ width: '92%' }}
                placeholder="(ex. Signing up was a breeze!)"
                onChange={(e) => setFeedbackNote(e.target.value)}
                value={feedbackNote}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 50,
                }}
              >
                <Button text="Submit" onClick={submitFeedback} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
