import { motion } from 'framer-motion';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import ClosePopupIcon from '../../images/ClosePopupIcon.svg';
import { Colors } from '../../themes/colors';
import Button from '../Button';
import Portal from '../Portal';
import { getCroppedImg } from './utils';

export const ImageCropper = ({
  src,
  onComplete,
  onClose,
  rightButtonLoading,
  leftButtonText = 'Cancel',
  leftButtonOnClick,
}) => {
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((_, _croppedAreaPixels) => {
    setCroppedAreaPixels(_croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    const croppedImageUrl = await getCroppedImg(src, croppedAreaPixels);
    onComplete(croppedImageUrl);
  };

  return (
    <Portal>
      <div
        className="modal"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          position: 'fixed',
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          style={{
            width: 500,
            backgroundColor: Colors.white,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 14,
            padding: 35,
            paddingBottom: 48,
            overflowY: 'auto',
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <img
            alt="close-popup-icon"
            src={ClosePopupIcon}
            style={{
              position: 'absolute',
              marginTop: -16,
              marginRight: -16,
              cursor: 'pointer',
              alignSelf: 'flex-end',
            }}
            onClick={onClose}
          />
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontFamily: 'Nunito',
                fontWeight: 700,
                fontSize: 28,
                color: Colors.neutral_600,
                marginBottom: 20,
              }}
            >
              Edit Photo
            </span>
            <Cropper
              image={src}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              cropSize={{ width: 252, height: 252 }}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              showGrid={false}
              style={{
                containerStyle: {
                  position: 'relative',
                  height: 280,
                  width: 420,
                },
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 16,
                marginTop: 32,
              }}
            >
              <Button
                style={{
                  backgroundColor: Colors.secondary_500,
                  width: 152,
                  height: 48,
                }}
                onClick={() => (leftButtonOnClick ? leftButtonOnClick() : onClose())}
                text={leftButtonText}
              />
              <Button
                style={{
                  width: 152,
                  height: 48,
                }}
                text="Save"
                onClick={handleCrop}
                isLoading={rightButtonLoading}
              />
            </div>
          </div>
        </motion.div>
      </div>
    </Portal>
  );
};
