import { EnumHelper } from '../utils/EnumHelper';

/**
 * @enum {string}
 */
export const UserProfession = {
  DN: 'DN',
  DA: 'DA',
  RDH: 'RDH',
  FD: 'FD',

  /**
   * Return name of profession
   *
   * @param {UserProfession | string} profession - to decide whether user is 'DN', 'DA', or 'RDH'
   * @returns {string | undefined}
   */
  getName(profession) {
    return {
      [this.DN]: 'Associate Dentist',
      [this.DA]: 'Dental Assistant',
      [this.RDH]: 'Dental Hygienist',
      [this.FD]: 'Front Desk',
    }[profession];
  },

  /**
   * Return code of profession or sub-profession when it's available
   *
   * @param {string} professionName
   * @returns {string | undefined}
   */
  getCode(professionName) {
    const subProfessionCode = UserSubProfession.getCode(professionName);

    if (subProfessionCode) {
      return subProfessionCode;
    }

    return {
      'Associate Dentist': this.DN,
      'Dental Assistant': this.DA,
      'Dental Hygienist': this.RDH,
      'Front Desk': this.FD
    }[professionName];
  },

  /**
   * Return profession code of sub-profession and profession when it's available
   *
   * @param {string} code
   * @returns {string | undefined}
   */
  getNormalizedCode(code) {
    const isSubProfession = EnumHelper.getObjectKeys(UserSubProfession).includes(code);

    if (isSubProfession) {
      return UserSubProfession.getProfession(code);
    }

    return code;
  },

  /**
   * Return profession name of sub-profession and profession when it's available
   *
   * @param {string} name
   * @returns {string | undefined}
   */
  getNormalizedName(name) {
    const isSubProfession = EnumHelper.getObjectKeys(UserSubProfession)
      .map((key) => UserSubProfession.getName(key))
      .includes(name);

    if (isSubProfession) {
      const code = UserSubProfession.getCode(name);
      const professionCode = UserSubProfession.getProfession(code);

      return UserProfession.getName(professionCode);
    }

    return name;
  }
};

/**
 * @enum {string}
 */
export const UserSubProfession = {
  EFDA: 'EFDA',

  /**
   * Return name of sub-profession
   * @param {UserSubProfession | string} subProfession - to decide whether user is 'EFDA' or else
   * @returns {string | undefined}
   */
  getName(subProfession) {
    return {
      [this.EFDA]: 'Expanded Functions DA',
    }[subProfession];
  },

  /**
   * Return code of sub-profession
   *
   * @param {string} professionName
   * @returns {string | undefined}
   */
  getCode(professionName) {
    return {
      'Expanded Functions DA': this.EFDA,
    }[professionName];
  },

  /**
   * Return name of sub-profession
   * @param {UserSubProfession | string} subProfession - to decide whether user is 'EFDA' or else
   * @returns {UserProfession | undefined}
   */
  getProfession(subProfession) {
    return {
      [this.EFDA]: UserProfession.DA,
    }[subProfession];
  }
};
