import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobList } from '../utils';

export const useTable = ({ type }) => {
  const dispatch = useDispatch();
  const jobListScrollRef = useRef(null);
  const jobListPagination = useSelector((state) => state.job.dashboardJobListPagination);
  const isLoadingFirstPage = jobListPagination?.page === 1 && jobListPagination?.isActiveRequest;

  const handleScrollJobList = useCallback(() => {
    const jobListRef = jobListScrollRef?.current;
    const userScrollHeight = jobListRef?.scrollHeight - jobListRef?.scrollTop - 20;
    const clientHeight = jobListRef?.clientHeight;

    if (
      userScrollHeight <= clientHeight &&
      !jobListPagination.isActiveRequest &&
      !jobListPagination.isFinal
    ) {
      dispatch(
        fetchJobList(type, {
          page: jobListPagination?.page + 1,
        }),
      );
    }
  }, [jobListPagination, dispatch, type]);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollJobList);
    return () => window.removeEventListener('scroll', handleScrollJobList);
  }, [jobListPagination, handleScrollJobList]);

  return {
    jobListScrollRef,
    isLoadingFirstPage
  };
};
