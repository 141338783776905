import React, { useRef, useEffect } from 'react';
import { Colors } from '../../../themes/colors';

import GeneralHygInfo from './GeneralHygInfo';

import PPworkingIntrerview from '../../../images/PPworkingIntrerview.png';
import PPcallInterview from '../../../images/PPcallInterview.png';
import PPpersonInterview from '../../../images/PPpersonInterview.png';
import { ActionTypes } from '../CandidateCards/Enums';
import { styles } from './StylesPopups';

export default ({
  closePopupClicked, selectedCandidate, selectInterviewClicked,
}) => {
  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopupClicked();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        ref={wrapperRef}

        style={{
          backgroundColor: Colors.white,
          alignContent: 'center',
          justifyContent: 'center',
          maxWidth: 700,
          minWidth: 450,
          width: '30%',
          borderRadius: 25,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 50,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        <div
          style={{
            marginTop: 8,
            fontSize: 20,
            fontWeight: 'bold',
            color: Colors.neutral_300,
            cursor: 'pointer',
            textAlign: 'right',
            marginRight: 20,
          }}
          onClick={() => closePopupClicked()}
        >
          X
        </div>
        <div
          style={{
            width: '85%',
            alignSelf: 'center',
          }}
        >
          <GeneralHygInfo selectedCandidate={selectedCandidate} />
          <p style={styles.titleGray}>
            Next Step
          </p>
          <p style={styles.titleGreen}>
            Schedule an interview by selecting from the following:
          </p>

          <div style={{
            display: 'flex', flexDirection: 'column', marginBottom: 50, alignItems: 'center', justifyContent: 'space-between', marginTop: 30,
          }}
          >
            <div
              style={styles.blueInterviewButton}
              onClick={() => selectInterviewClicked(ActionTypes.interviewPhone)}
            >
              <img src={PPcallInterview} alt="phone-icon" style={{ height: 20, width: 20, marginRight: 5 }} />
              <div style={styles.buttonWhiteTextStyle}>Phone Interview</div>

            </div>

            <div
              style={styles.blueInterviewButton}
              onClick={() => selectInterviewClicked(ActionTypes.interviewInperson)}
            >
              <img src={PPpersonInterview} alt="in-person-icon" style={{ height: 20, width: 20, marginRight: 5 }} />
              <div style={styles.buttonWhiteTextStyle}>In-Person Interview</div>

            </div>

            <div style={styles.blueInterviewButton} onClick={() => selectInterviewClicked(ActionTypes.interviewWorkingday)}>
              <img src={PPworkingIntrerview} alt="interview-icon" style={{ height: 20, width: 20, marginRight: 5 }} />
              <div style={styles.buttonWhiteTextStyle}>Working Interview</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
