import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import EditPencilIcon from '../../images/icons/EditPencil';
import GalleryIcon from '../../images/icons/GalleryIcon';
import { Colors } from '../../themes/colors';
import Button from '../Button';
import { ImageCropper } from '../ImageCropper/ImageCropper';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 280,
  borderWidth: 3,
  borderRadius: 140,
  borderColor: Colors.neutral_150,
  borderStyle: 'dashed',
  backgroundColor: Colors.white,
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: Colors.neutral_150,
};

const acceptStyle = {
  borderColor: Colors.success_500,
};

const rejectStyle = {
  borderColor: Colors.error_400,
};

export function ImageDropzone({ onComplete }) {
  const [fullImage, setFullImage] = useState(null);
  const [showCropperModal, setShowCropperModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const { getRootProps, getInputProps, isDragAccept, isFocused, isDragReject, open } = useDropzone({
    accept: { 'image/*': [] },
    maxFiles: 1,
    noClick: true,
    maxSize: 2097152,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      setFullImage(files[0]);
      setShowCropperModal(true);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const onCropComplete = (_croppedImage) => {
    setCroppedImage(_croppedImage);
    onComplete(_croppedImage);
    setShowCropperModal(null);
  };

  return (
    <>
      {!croppedImage ? (
        <div
          style={{
            width: 280,
          }}
        >
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <GalleryIcon />
              <span
                style={{
                  fontSize: 16,
                  color: Colors.neutral_500,
                  fontFamily: 'Nunito',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  lineHeight: 1.75,
                  marginTop: 8,
                  marginBottom: 4,
                }}
              >
                Drag and drop to upload
                <br />
                OR
              </span>
              <Button
                type="button"
                style={{
                  width: 210,
                  height: 36,
                  backgroundColor: Colors.secondary_500,
                }}
                textStyle={{
                  fontSize: 16,
                }}
                text="Upload from Computer"
                onClick={open}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div>
            <img alt="preview" src={croppedImage} style={styles.previewImage} />
            <div
              style={{
                width: 280,
                display: 'flex',
                justifyContent: 'center',
                gap: 48,
                marginTop: 8,
              }}
            >
              <div onClick={open} style={styles.previewImageContainer}>
                <GalleryIcon size={24} />
                <span style={styles.previewImageText}>Browse Image</span>
              </div>
              <div onClick={() => setShowCropperModal(true)} style={styles.previewImageContainer}>
                <EditPencilIcon color={Colors.secondary_500} size={18} />
                <span style={styles.previewImageText}>Edit</span>
              </div>
            </div>
          </div>
        </>
      )}

      {showCropperModal && fullImage && (
        <ImageCropper
          src={URL.createObjectURL(fullImage)}
          onComplete={onCropComplete}
          onClose={() => setShowCropperModal(false)}
        />
      )}
    </>
  );
}

const styles = {
  previewImage: {
    width: 280,
    height: 280,
    borderRadius: 140,
    border: `1px solid ${Colors.neutral_150}`,
    objectFit: 'cover',
  },
  previewImageContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    cursor: 'pointer',
  },
  previewImageText: {
    fontSize: 16,
    color: Colors.neutral_600,
    fontFamily: 'Nunito',
  },
};
