import '../../../themes/signup.scss';

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { clearForgotPasswordState } from '../../../actions';
import BackButton from '../../../commonComponents/BackButton';
import CustomPortal from '../../../commonComponents/CustomPortal';
import history from '../../../history';
import Header from '../../Header';
import { ContainerLayout } from '../components/ContainerLayout';
import { ChooseVerificationMethodScreen } from './ChooseVerificationMethodScreen';
import ResetScreen from './ResetScreen';

const renderContent = (step) => {
  switch (step) {
    case 'reset':
      return <ResetScreen />;
    default:
      return <ChooseVerificationMethodScreen />;
  }
};

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [isDropdownToggleOpen, setIsDropdownToggleOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const forgotPasswordFlow = useSelector((state) => state.auth.forgotPasswordFlow);

  const toggleDropdown = () => {
    setIsDropdownToggleOpen(!isDropdownToggleOpen);
  };

  useEffect(() => {
    if (forgotPasswordFlow.step === 'successful') {
      setShowSuccessModal(true);
    }
  }, [forgotPasswordFlow.step]);

  useEffect(() => () => {
    dispatch(clearForgotPasswordState());
  }, [dispatch]);

  return (
    <>
      <div>
        <Header
          isOpen={isDropdownToggleOpen}
          onClick={() => history.push('/')}
          toggleDropdown={toggleDropdown}
          text="Forgot Password"
        />
        <ContainerLayout>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <div style={{ marginLeft: 60, marginTop: 40, marginBottom: 40 }}>
              <BackButton url="/" />
            </div>

            {renderContent(forgotPasswordFlow.step)}
          </div>
        </ContainerLayout>
      </div>

      {showSuccessModal
        && (
          <CustomPortal
            isSuccess={forgotPasswordFlow.step === 'successful'}
            title="Password Successfully Reset"
            text="Your password has been updated successfully! "
            submitPressed={() => history.push('/')}
          />
        )}
    </>
  );
}
