import moment from 'moment';
import { Colors } from '../../../themes/colors';
import { capitalizeEveryFirstLetter } from '../../../utils/String';
import { TableData } from '../../../commonComponents/Table/TableData';

export const renderRow = (item) => (
  <>
    <TableData text={item.officeName} />
    <TableData text={item.state} />
    <TableData text={item.requestedDate ? moment(item.requestedDate).format('ddd, MMM DD, YYYY') : "-"} />
    <TableData text={item.setDate ? moment(item.setDate).format('ddd, MMM DD, YYYY') : "-"} />
    <td
      style={{
		  fontFamily: 'Nunito',
		  fontSize: 16,
		  fontWeight: 700,
		  color: Colors.neutral_600,
		  textAlign: 'center',
      }}>
      {item.workerClassificationType ? capitalizeEveryFirstLetter(item.workerClassificationType) : "-"}
    </td>
  </>
);