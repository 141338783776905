import React, { useState } from 'react';
import { motion } from 'framer-motion';

import EyeIcon from '../images/icons/EyeIcon';
import EyeOffIcon from '../images/icons/EyeOffIcon';

export const InputPassword = ({
  onChange, placeholder, id, ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div
      className="input-field"
      style={{ display: 'flex', width: '100%' }}
      {...rest}
    >
      <input
        id={id}
        type={showPassword ? 'text' : 'password'}
        className="input-field"
        placeholder={placeholder}
        style={{
          all: 'unset',
          width: '100%',
        }}
        onChange={onChange}
      />
      <div style={{ display: 'flex', alignSelf: 'center' }}>
        <motion.button
          tabIndex={-1}
          key={showPassword}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          type="button"
          style={{ all: 'unset', display: 'flex', cursor: 'pointer' }}
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <EyeIcon /> : <EyeOffIcon />}
        </motion.button>
      </div>
    </div>
  );
};
