import React from 'react';
import { Colors } from '../../themes/colors';

const GiftIcon = ({ color = Colors.neutral_600 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 7.33333V22.5M12 7.33333C12 7.33333 12 5.59176 12 5C12 3.71134 13.0447 2.66667 14.3333 2.66667C15.622 2.66667 16.6667 3.71134 16.6667 5C16.6667 6.28866 15.622 7.33333 14.3333 7.33333C13.6364 7.33333 12 7.33333 12 7.33333ZM12 7.33333C12 7.33333 12 5.0734 12 4.41667C12 2.80584 10.6942 1.5 9.08333 1.5C7.4725 1.5 6.16667 2.80584 6.16667 4.41667C6.16667 6.0275 7.4725 7.33333 9.08333 7.33333C10.0374 7.33333 12 7.33333 12 7.33333ZM3.83333 12H20.1667M3.83333 12C2.54467 12 1.5 10.9553 1.5 9.66667C1.5 8.378 2.54467 7.33333 3.83333 7.33333H20.1667C21.4553 7.33333 22.5 8.378 22.5 9.66667C22.5 10.9553 21.4553 12 20.1667 12M3.83333 12L3.83333 20.1667C3.83333 21.4553 4.878 22.5 6.16667 22.5H17.8333C19.122 22.5 20.1667 21.4553 20.1667 20.1667V12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GiftIcon;
