import { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useDispatch, useSelector } from 'react-redux';
import { getPlaidToken, hideSuccessBankAddedPopup, submitPlaidToken } from '../../../actions';
import CustomPortal from "../../../commonComponents/CustomPortal";
import LibraryIcon from '../../../images/library.svg';
import { PaymentCard } from './PaymentCard';

export const BankList = ({ selectedPayment, setSelectedPayment }) => {
  const dispatch = useDispatch();
  const allPayments = useSelector((state) => state.payments.allPayments);
  const showSuccessBankAddedPopup = useSelector(
    (state) => state.payments.showSuccessBankAddedPopup,
  );
  const plaidToken = useSelector((state) => state.payments.plaidToken);
  const { open } = usePlaidLink({
    token: plaidToken,
    onSuccess: (publicToken, metadata) => {
      const obj = {
        metadata,
        publicToken,
      };

      dispatch(submitPlaidToken(obj));
    },
  });
  const bankList = allPayments
    ?.filter((item) => item.type === 'bank_account')
    .sort((a, b) => (b.is_selected === true) - (a.is_selected === true));

  useEffect(() => {
    dispatch(getPlaidToken());
  }, [dispatch]);

  return (
    <>
      <PaymentCard
        data={bankList}
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
        iconSrc={LibraryIcon}
        title="Bank Account"
        description="(Adding a Bank Account is the preferred method as you avoid the 3.5% Convenience Fee)."
        addPaymentText="Add Bank Account"
        handleAddPaymentButton={open}
      />

      {showSuccessBankAddedPopup && (
        <CustomPortal
          isSuccess
          redirect={false}
          title="Bank Account Successfully Added!"
          text="Your bank account information has been successfully added to your account."
          submitPressed={() => {
            dispatch(hideSuccessBankAddedPopup());
          }}
        />
      )}
    </>
  );
};
