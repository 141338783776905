import React from 'react';
import { motion } from 'framer-motion';

import { Colors } from '../../themes/colors';

const AnimatedCheckCircle = () => (
  <motion.svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.circle
      initial={{ strokeDashoffset: 2 * Math.PI * (35 * 1) }}
      animate={{ strokeDashoffset: 2 * Math.PI * (35 * 0.25) }}
      transition={{ duration: 0.8 }}
      cx={35}
      cy={35}
      r={30}
      fill="transparent"
      stroke={Colors.success_400}
      strokeDasharray={`${2 * Math.PI * 35} ${2 * Math.PI * 35}`}
      strokeWidth={6}
      strokeLinecap="round"
    />

    <motion.path
      initial={{ strokeDashoffset: 100 }}
      animate={{ strokeDashoffset: 0 }}
      transition={{ duration: 0.8 }}
      transform="translate(2, 4)"
      d="M24 32L30.5 38.5L43.5 25"
      stroke={Colors.success_400}
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray={100}
    />
  </motion.svg>
);

export default AnimatedCheckCircle;
