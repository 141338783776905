import React from 'react';
import '../themes/navigation.scss';
import { useDispatch, useSelector } from 'react-redux';

import logoLightFull from '../images/logos/logoLightFull.svg';

import history from '../history';
import { Colors } from '../themes/colors';
import { showMessage } from '../actions';

const MenuTitle = ({ title, isActive }) => (
  <p style={{
    fontFamily: 'Nunito',
    fontSize: 18,
    color: Colors.white,
    ...isActive && {
      fontWeight: 700,
      color: Colors.success_400,
    },
  }}
  >
    {title}
  </p>
);

export default ({ location }) => {
  const dispatch = useDispatch();

  const badgeCount = useSelector((state) => state.user?.user?.badgeCount || 0);

  const getHelp = () => {
    if (location.pathname === '/registration') {
      dispatch(
        showMessage({
          title: 'Error',
          message: 'Please finish your registration to access the rest of the platform.',
          isError: true,
        })
      );
    } else {
      history.push('/dashboard/help');
    }
  };

  const goToInbox = () => {
    if (location.pathname === '/registration') {
      dispatch(
        showMessage({
          title: 'Error',
          message: 'Please finish your registration to access the rest of the platform.',
          isError: true,
        })
      );
    } else {
      history.push('/dashboard/inbox');
    }
  };

  const goToAccount = () => {
    history.push('/account');
  };

  const getBadgeCount = () => {
    if (badgeCount > 0) {
      return (
        <div style={{
          borderRadius: 25,
          backgroundColor: Colors.error_500,
          position: 'absolute',
          height: 22,
          width: 22,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 10,
        }}
        >
          <span
            style={{
              fontSize: 10,
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              color: 'white',
            }}
          >
            {badgeCount <= 10 ? badgeCount : '10+'}

          </span>
        </div>
      );
    }
    return (
      <div />
    );
  };

  return (
    <div className="container">
      <div className="nav_container" style={{ backgroundColor: Colors.primary_500 }}>
        <img
          src={logoLightFull}
          alt="logo_light_full"
          className="logo"
          style={{ cursor: 'pointer', width: 95, marginLeft: 30 }}
          onClick={() => history.push('/')}
        />
        {!location.pathname.includes('registration')
          ? (
            <div
              style={{
                display: 'flex',
                gap: 36,
                marginRight: 36,
              }}
            >
              <div style={{ cursor: 'pointer' }} onClick={() => goToInbox()}>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <MenuTitle title="Inbox" isActive={location.pathname === '/dashboard/inbox'} />
                  <div>{getBadgeCount()}</div>
                </div>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() => getHelp()}>
                <MenuTitle title="Help" isActive={location.pathname === '/dashboard/help'} />
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() => goToAccount()}>
                <MenuTitle title="Account" isActive={location.pathname === '/account'} />
              </div>
            </div>
          )
          : <div />}
      </div>
    </div>
  );
};
