import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPayment } from '../../../actions';
import PlusIcon from '../../../images/icons/PlusIcon';
import { Colors } from '../../../themes/colors';
import { DeletePaymentModal } from './DeletePaymentModal';
import { PaymentLine } from './PaymentLine';

export const PaymentCard = ({
  selectedPayment,
  setSelectedPayment,
  title,
  description,
  data,
  iconSrc,
  addPaymentText,
  handleAddPaymentButton,
  showExpiration = false,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const allPayments = useSelector((state) => state.payments.allPayments);
  const [paymentToDelete, setPaymentToDelete] = useState(null);
  const showActions = user?.is_pre_approved || allPayments?.length > 1;

  const handleRadioButtonClick = (item) => {
    setSelectedPayment(item);
  };

  const handleOnDelete = (item) => {
    setPaymentToDelete(item);
  };

  const handleMakeDefault = (paymentId) => {
    dispatch(selectPayment({ paymentId }));
  };

  return (
    <div
      data-cy="payment-card"
      style={{
        borderRadius: 8,
        backgroundColor: Colors.white,
        boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15)',
        paddingTop: 32,
        paddingLeft: 40,
        paddingRight: 32,
        paddingBottom: 28,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          marginBottom: 8,
        }}
      >
        <img src={iconSrc} alt="payment-icon" />
        <span
          style={{
            fontFamily: 'Nunito',
            fontSize: 18,
            fontWeight: 700,
            color: Colors.neutral_600,
          }}
        >
          {title}
        </span>
      </div>
      <span
        style={{
          fontFamily: 'Nunito',
          fontSize: 16,
          fontStyle: 'italic',
          color: Colors.neutral_600,
        }}
      >
        {description}
      </span>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
          marginTop: 32,
          marginLeft: 36,
        }}
      >
        {data?.map((item) => (
          <PaymentLine
            key={item.id}
            item={item}
            handleMakeDefault={handleMakeDefault}
            handleOnDelete={handleOnDelete}
            handleRadioButtonClick={handleRadioButtonClick}
            isSelected={selectedPayment?.id === item.id}
            showActions={showActions}
            showExpiration={showExpiration}
          />
        ))}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 40,
        }}
      >
        <button
          type="button"
          style={{
            all: 'unset',
            fontFamily: 'Nunito',
            fontSize: 18,
            fontWeight: 700,
            color: Colors.secondary_500,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
          onClick={handleAddPaymentButton}
        >
          <PlusIcon color={Colors.secondary_500} />
          {addPaymentText}
        </button>
      </div>

      {paymentToDelete && (
        <DeletePaymentModal
          paymentToDelete={paymentToDelete}
          onClose={() => setPaymentToDelete(null)}
        />
      )}
    </div>
  );
};
