import React from 'react';
import { Colors } from '../../themes/colors';

const EditPencilIcon = ({ color = Colors.primary_500, className, size = 22 }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 22" fill="none">
    <path d="M14.2322 4.23223L17.7677 7.76777M15.7322 2.73223C16.7085 1.75592 18.2914 1.75592 19.2677 2.73223C20.244 3.70854 20.244 5.29146 19.2677 6.26777L5.5 20.0355H2V16.4644L15.7322 2.73223Z" stroke={color} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default EditPencilIcon;
