import React from 'react';
import { Colors } from '../themes/colors';

export default ({
  job, currentCounter, acceptBtnPressed, cancelBtnPressed,
}) => (
  <div
    style={{
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      zIndex: 10,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    }}
  >
    <div
      style={{
        backgroundColor: Colors.white,
        alignContent: 'center',
        justifyContent: 'center',
        maxWidth: 700,
        minWidth: 450,
        width: '30%',
        borderRadius: 13,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 50,
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
        shadowColor: Colors.neutral_400,
        shadowOpacity: 0.3,
        shadowOffset: {
          height: 3,
          width: 0,
        },
      }}
    >
      <div
        style={{
          width: '85%',
          alignSelf: 'center',
          paddingInline: 55,
          paddingBlock: 60,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            color: Colors.neutral_600,
            fontSize: 18,
            fontFamily: 'Nunito',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Review Counter
        </div>

        <div
          style={{
            color: Colors.neutral_600,
            fontSize: 16,
            fontFamily: 'Nunito',
            textAlign: 'center',
            marginBlock: 30,
          }}
        >
          The hours for this shift have been countered. Please review and plan
          accordingly.
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 9,
          }}
        >
          <p
            className="global_font bold_font"
            style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
          >
            Original Hours:
          </p>
          <p
            className="global_font"
            style={{
              margin: 0,
              color: Colors.neutral_500,
              textDecoration: 'line-through',
            }}
          >
            {`${job?.local_start_time} - ${job?.local_end_time}`}
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 9,
          }}
        >
          <p
            className="global_font bold_font"
            style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
          >
            Counter Offer (hours):
          </p>
          <p className="global_font" style={{ margin: 0, color: Colors.neutral_500, textTransform: 'lowercase' }}>
            {`${currentCounter?.local_begin} - ${currentCounter?.local_end}`}
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '70%',
            alignSelf: 'center',
            gap: 20,
          }}
        >
          <button
            type="button"
            className="grey-button"
            style={{
              alignSelf: 'center',
              width: 150,
            }}
            onClick={() => cancelBtnPressed()}
          >
            <p className="white-text-18">Cancel</p>
          </button>

          <button
            type="button"
            className="blue-button"
            style={{
              alignSelf: 'center',
              width: 150,
            }}
            onClick={() => acceptBtnPressed()}
          >
            <p className="white-text-18">Accept</p>
          </button>
        </div>
      </div>
    </div>
  </div>
);
