import React from 'react';
import { Colors } from '../../themes/colors';
import PencilIcon from '../../images/icons/Pencil';

const AdjustmentItem = ({
  label,
  adjustedLabel,
  defaultValue,
  value,
  isAdjusted,
  onEditClicks = () => {},
  firstRow,
  disabled,
}) => (
  <div
    style={{
      paddingBlock: 18,
      borderTop: firstRow ? `1px solid ${Colors.neutral_100}` : 'none',
      borderBottom: `1px solid ${Colors.neutral_100}`,
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%',
      }}
    >
      <span className="global_font f-dark bold">{label}</span>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <span
          className="global_font"
          style={{
            color: Colors.neutral_500,
            textDecoration: isAdjusted ? 'line-through' : 'none',
          }}
        >
          {defaultValue}
        </span>
        <div
          onClick={disabled ? null : onEditClicks}
          style={{
            opacity: disabled ? 0 : 1,
            cursor: disabled ? null : 'pointer',
            position: 'absolute',
            right: -35,
          }}
        >
          <PencilIcon color={Colors.secondary_500} />
        </div>
      </div>
    </div>

    {isAdjusted ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '90%',
          marginTop: 19,
        }}
      >
        <span className="global_font bold" style={{ color: Colors.secondary_500 }}>
          {adjustedLabel}
        </span>
        <span className="global_font f-dark" style={{ color: Colors.neutral_500 }}>
          {value}
        </span>
      </div>
    ) : null}
  </div>
);

export default AdjustmentItem;
