/**
 * Convert minutes to formatted hour
 *
 * For example:
 * 30 -> 30 min
 * 60 -> 1 hr
 * 90 -> 1.5 hr
 *
 * @param {number} minute
 * @returns {string}
 */
export function formatFromMinuteToFormattedHour(minute) {
  if (minute < 60) {
    return `${minute} min`;
  }

  const hour = minute / 60;
  return `${hour} hr`;
}
