import moment from 'moment';

export const timeDifferenceCalculator = (
  start_time,
  end_time,
  changed_start_time,
  changed_end_time,
  oldLunchBreakTime,
  newLunchBreakTime
) => {
  const formattedNewStartTime = moment(changed_start_time, 'ddd, MMM DD, YYYY HH:mm:ss a');
  const formattedNewEndTime = moment(changed_end_time, 'ddd, MMM DD, YYYY HH:mm:ss a');
  const oldTimeDifference = moment(end_time).diff(moment(start_time), 'minutes') - parseInt(oldLunchBreakTime, 10);

  const newTimeDifference =
    formattedNewEndTime.diff(formattedNewStartTime, 'minutes') - parseInt(newLunchBreakTime, 10);

  const totalTimeDifference = newTimeDifference - oldTimeDifference;
  const duration = moment.duration(totalTimeDifference, 'minutes');

  const displayHour = (num) => {
    return `${Math.abs(num) === 1 ? `hour` : `hours`}`
  }

  if (duration.hours() === 0 && duration.minutes() === 0) return 'No changes in time.';
  else if (duration.hours() !== 0 && duration.minutes() !== 0)
    return `${duration.hours()} ${displayHour(duration.hours())} ${`and`} ${
      duration.minutes() < 0 ? duration.minutes() * -1 : duration.minutes()
    } minutes`;
  else if (duration.hours() !== 0 && duration.minutes() === 0)
    return `${duration.hours()} ${displayHour(duration.hours())}`;
  else return `${duration.minutes()} minutes`;
};
