import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSuccessWorkerClassificationPopup, saveStatusChildOfficeWorkerClassification } from '../../../actions';
import CustomPortal from '../../../commonComponents/CustomPortal';
import WarningIcon from '../../../images/icons/Warning';
import { Colors } from '../../../themes/colors';
import ConfirmPopup from '../../shared/ConfirmPopup';
import { Table } from '../../../commonComponents/Table/Table';
import { WC_MANAGEMENT_STATUS, pendingHeaderTitles } from '../utils';
import { renderRow } from './renderRow';

export const PendingRequests = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({ isOpen: false, item: null, status: null });
  const pendingRequests = useSelector(
    (state) => state.user.workerClassificationManagement.pending,
  );
  const user = useSelector(
    (state) => state.user,
  );
  
  const handleOnClick = (item, status) => {
    setModal({ isOpen: true, item, status });
  };

  const handleConfirmButton = async () => {
    dispatch(saveStatusChildOfficeWorkerClassification({ 
      id: modal.item.id,
      status: modal.status 
    }));
    handleCloseModal()
  };

  const handleCloseSuccessPopup = async () => {
    dispatch(hideSuccessWorkerClassificationPopup());
  };

  const handleCloseModal = () => {
    setModal({ isOpen: false, item: null, status: null });
  }

  return (
    <>
      {!pendingRequests?.length && (
        <div>
          <span
            style={{
              fontFamily: 'Nunito',
              display: 'flex',
              color: Colors.neutral_500,
              fontStyle: 'italic',
              textAlign: 'center',
            }}
          >
            There are no pending worker type requests. Please check the 
            &quot;Unselected&quot; tab to see if there are any offices that do not have
            a worker type confirmed.
          </span>
        </div>
      )}

      {
        pendingRequests?.length > 0 && 
        <Table
          data={pendingRequests}
          renderRow={(item) => renderRow(item, handleOnClick)}
          headerTitles={pendingHeaderTitles}
        />
      }

      {modal.isOpen && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={80} height={80} color={Colors.warning_500} />
            </div>
          )}
          title="Please Confirm"
          description={
            modal.status === WC_MANAGEMENT_STATUS.CONFIRMED ?
              <>
                Please be aware, this decision will be final. By confirming,
                <br />
                you will be approving the worker type for <br />
                {modal.item.workerClassificationType === 'contractor' ? 'Contracted' : 'Employed'} professionals at {modal.item.officeName}.
              </>
              : 
              <>
                Would you like to proceed with declining the worker type
                <br />
                request for {modal.item.workerClassificationType === 'contractor' ? 'Contracted' : 'Employed'} professionals at 
                <br />
                {modal.item.officeName}.
              </>
          }
          descriptionStyle={{ width: '100%', marginBottom: 80 }}
          leftButtonText="Go Back"
          leftButtonAction={handleCloseModal}
          rightButtonText={modal.status === WC_MANAGEMENT_STATUS.CONFIRMED ? 'Confirm' : 'Decline'}
          rightButtonAction={handleConfirmButton}
          closePopup={handleCloseModal}
        />
      )}

      {
        user?.showWorkerClassificationManagementPopup === WC_MANAGEMENT_STATUS.CONFIRMED && 
        <CustomPortal
          isSuccess
          redirect={false}
          title="Worker Type Successfully Selected!"
          text="This office’s worker type selection has been confirmed."
          submitPressed={handleCloseSuccessPopup}
        />
      }

      {
        user?.showWorkerClassificationManagementPopup === WC_MANAGEMENT_STATUS.DECLINED && 
        <CustomPortal
          redirect={false}
          rightButtonText="Close"
          title="Worker Type Request Declined"
          text="To set a worker type for this Office, visit the “Unselected” tab on the previous page. Please note, this office will not be able to request a professional until a worker type is selected."
          submitPressed={handleCloseSuccessPopup}
        />
      }
    </>
  );
};
