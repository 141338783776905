import { UserProfession } from '../enums/UserProfession';
import { filterObjectKeys } from './Object';

/**
 *
 * @enum {string}
 */
export const SpecialtiesAndProceduresPrefix = {
  specialty: 'specialty',
  procedure: 'procedure',
};

export const EducationUtils = {
  /**
   * Get desired specialties and procedures based on user profession.
   * Hide empty entries when user is signing up.
   *
   * @param {UserProfession} userProfession - to decide whether user is 'DN', 'DA', or 'RDH'
   * @param {boolean} isSignUp - to decide whether user is signing up
   * @param {object} education - education
   * @returns {object}
   */
  getProfessionSpecialtiesAndProcedures({ userProfession, education}) {
    return [UserProfession.DA, UserProfession.DN].includes(userProfession)
      ? {
        'specialties/procedures': education,
      }
      : {
        specialties: filterObjectKeys(education, SpecialtiesAndProceduresPrefix.specialty),
        procedures: filterObjectKeys(education, SpecialtiesAndProceduresPrefix.procedure),
      };
  },
  /**
   *
   * @param skillUpdates
   * @returns {string[]}
   */
  getSpecialtiesAndProceduresSkills({ skillUpdates: updatedSkills}) {
    const skills = updatedSkills['specialties/procedures'] || {};

    return Object.keys(skills)
      .map((item) => item.split('_')?.[1]);
  },

  /**
   * @param {string[]} procedures
   */
  mapToRemoveProcedurePrefix(procedures) {
    return procedures.map((procedure) => procedure.replace(/^procedure_/g, ''));
  },

  /**
   * @param specialtiesAndProcedures
   * @param {UserProfession} profession
   * @param specialty
   * @returns {string[]}
   */
  getProceduresOfProfession({ specialtiesAndProcedures, profession, specialty }) {
    if ([UserProfession.FD, UserProfession.RDH].includes(profession)) {
      const procedures = filterObjectKeys(specialtiesAndProcedures, 'procedure');

      return Object.keys(procedures);
    }

    if ([UserProfession.DA, UserProfession.DN].includes(profession)) {
      return specialtiesAndProcedures?.[specialty] ?? [];
    }

    return [];
  }
};
