import React from 'react';
import { Colors } from '../../themes/colors';

const FavoriteProfessionalIcon = ({ color = Colors.secondary_500 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M10.4936 12.5538C9.49339 12.2427 7.78746 10.4998 7.48075 9.47557C6.91625 7.59033 7.31426 6.08753 8.75445 4.66636C10.1946 3.2454 11.7178 2.8527 13.6287 3.40967C14.7479 3.73601 16.4363 5.40188 16.7671 6.50616C17.3316 8.3916 16.9336 9.8944 15.4934 11.3154C14.0593 12.7301 12.3903 13.1435 10.4936 12.5538ZM4.06369 21.6469C3.87784 21.3503 4.12259 19.3008 4.48941 18.0821C4.65122 17.5448 5.06329 16.724 5.40525 16.2582C6.10221 15.3091 7.6875 14.2241 8.37795 14.2241C8.62739 14.2241 9.57237 14.5989 10.4778 15.057L12.124 15.8896L13.7703 15.057C14.6757 14.5989 15.6207 14.2241 15.8699 14.2241C16.5618 14.2241 18.1463 15.3097 18.8461 16.2628C19.6201 17.3168 20.0927 18.8709 20.1387 20.5126L20.1738 21.7643L12.1884 21.817C6.5155 21.8542 4.16274 21.8049 4.06369 21.6469Z"
      fill={color}
    />
    <path
      d="M17.0864 15.1985L17.5002 15.5926L17.914 15.1985L18.2805 14.8494C18.2805 14.8494 18.2805 14.8494 18.2805 14.8494C19.3308 13.8506 21.0529 13.8497 22.1064 14.8497C22.9694 15.6855 23.1907 17.0488 22.4633 18.732L22.463 18.7326C22.0313 19.7349 21.2583 20.7952 20.3361 21.5997C19.4047 22.4122 18.3966 22.9 17.5002 22.9C16.6037 22.9 15.5949 22.4125 14.6631 21.6002C13.7404 20.796 12.9671 19.7358 12.5354 18.7328L12.5353 18.7325C11.8097 17.049 12.0309 15.6856 12.894 14.8497C13.9475 13.8497 15.6697 13.8506 16.72 14.8494L17.0864 15.1985Z"
      fill="#9D70D8"
      stroke="white"
      strokeWidth="1.2"
    />
  </svg>
);

export default FavoriteProfessionalIcon;
