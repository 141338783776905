import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CANCEL_JOB_ACTION, cancelJob } from '../../../../actions';
import { clearSuccess } from '../../../../actions/success';
import { createSuccessSelector } from '../../../../api/selectors';
import { DotsMenu, MenuItem } from '../../../../commonComponents/DotsMenu';
import useLoading from '../../../../hooks/useLoading';
import TrashIcon from '../../../../images/icons/Trash';
import { Colors } from '../../../../themes/colors';
import AnimatedCloseCircle from '../../../shared/AnimatedCloseCircle';
import ConfirmPopup from '../../../shared/ConfirmPopup';

const actions = [CANCEL_JOB_ACTION];
const successSelector = createSuccessSelector(actions);

export const CancelShift = ({ item }) => {
  const dispatch = useDispatch();
  const { isLoading } = useLoading(actions);
  const [showPopup, setShowPopup] = useState(false);
  const removedSuccessfully = useSelector((state) => successSelector(state));

  useEffect(() => {
    if (removedSuccessfully) {
      setShowPopup(false);
      dispatch(clearSuccess(actions));
    }
  }, [removedSuccessfully, dispatch]);

  return (
    <td>
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DotsMenu position="bottom">
          <MenuItem
            Icon={() => <TrashIcon color={Colors.neutral_600} />}
            text="Cancel Shift"
            onClick={() => setShowPopup(true)}
          />
        </DotsMenu>
      </div>

      {showPopup && (
        <ConfirmPopup
          Icon={() => <AnimatedCloseCircle />}
          title="Are you sure you want to cancel?"
          titleStyle={{ marginTop: 20 }}
          description={
            <>
              Are you sure you would like to cancel the shift for
              <br />
              <b>{item?.local_date}</b>
            </>
          }
          closePopup={(event) => {
            event.stopPropagation();
          }}
          descriptionStyle={{ marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonAction={() => setShowPopup(false)}
          rightButtonText="Cancel Shift"
          leftButtonDisabled={isLoading}
          rightButtonDisabled={isLoading}
          rightButtonAction={() => {
            dispatch(cancelJob({ jobId: item?.id }));
          }}
        />
      )}
    </td>
  );
};
