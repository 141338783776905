import { Colors } from '../../../../../themes/colors';

export const SpecialtiesAndProceduresView = ({ professionProcedures }) =>
  professionProcedures &&
    Object.entries(professionProcedures)?.length > 0 && (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color: Colors.neutral_600,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <b style={{ ...styles.title, marginBottom: 20 }}>Procedures:</b>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
        }}
      >
        {professionProcedures &&
          Object.entries(professionProcedures)?.map(([specialty, procedures]) => (
            <div key={specialty}>
              <div style={{ textAlign: 'center' }}>
                <span style={{ ...styles.text, fontWeight: 700 }}>{specialty}</span>
              </div>
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 12,
                }}
              >
                {procedures?.map((procedure) => (
                  <li style={styles.text} key={procedure}>
                    {procedure}
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
    </div>
  );

const styles = {
  title: {
    fontSize: 18,
  },
  text: {
    fontSize: 16,
  },
};
