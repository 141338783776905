import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  feedbackRequire,
  fetchDashboardInfo,
  fetchJobsWithAdjustmts,
} from '../../actions';
import WarningBanner from '../../commonComponents/WarningBanner';
import history from '../../history';
import '../../themes/inbox.scss';
import Inbox from '../Inbox';
import BillingCard from './BillingCard';
import ShiftsWidget from './ShiftsWidget';
import { TodayShiftCard } from './TodayShiftCard';
import TopButtons from './TopButtons';

const Dashboard = () => {
  const dashboardInfo = useSelector((state) => state.job.dashboardInfo);
  const dispatch = useDispatch();
  const selectedOfficeId = useSelector((state) => state.user.selectedOfficeId);
  const user = useSelector((state) => state.user.user);
  const showBillingCard = dashboardInfo?.lastReceipt

  useEffect(() => {
    dispatch(fetchDashboardInfo());
  }, [selectedOfficeId]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          justifyContent: 'space-between',
          alignContent: 'start'
        }}
      >
        <TopButtons showLocumTenens={user?.address?.[0]?.state === 'FL'} />
        <div
          style={{
            display: 'flex',
            width: '100%',
            ...(showBillingCard && {
              display: 'grid',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 16,
              gridTemplateColumns: '2fr 1fr',
            }),
          }}
        >
          <ShiftsWidget
            activeCount={dashboardInfo?.activeRequest.toString()}
            scheduledCount={dashboardInfo?.scheduled.toString()}
            actionRequiredCount={dashboardInfo?.actionRequired.toString()}
          />
          {showBillingCard
          && (
            <BillingCard
              lastReceipt={dashboardInfo?.lastReceipt}
            />
          )}
        </div>

        {dashboardInfo?.todayShifts?.length > 0 && (
          <div>
            <TodayShiftCard />
          </div>
        )}

        <Inbox history={history} />
      </div>
    </div>
  );
};

const dashboardRequestInterval = 1 * 60 * 60 * 1000; // 1 hour in milliseconds

export default () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.selectedChildOffice);
  const allowedRequest = user?.allowedRequest || {};
  const showLocumTenens = user?.address?.[0]?.state === 'FL';

  const statesToDisableTempRequests = 'CA,CT,OR,WA,VT';
  const showBlockedStateWarning = !allowedRequest?.tempOrMdbShifts
    && (allowedRequest?.ppJob
      || (allowedRequest?.locumTenensDentist && showLocumTenens));

  const showWorkerClassificationWarning = user?.isWorkerClassificationEnabled
    && !user?.workerClassification?.classificationType;

  useEffect(() => {
    const prevDashboardRequestDate = localStorage.getItem(`prevDashboardRequestDate-${user.id}`);

    if (!prevDashboardRequestDate
      || (Date.now() - new Date(prevDashboardRequestDate).getTime() > dashboardRequestInterval)) {
      dispatch(feedbackRequire());
      dispatch(fetchJobsWithAdjustmts());

      localStorage.setItem(`prevDashboardRequestDate-${user.id}`, new Date().toISOString());
    }
  }, [dispatch, user.id]);

  return (
    <div
      className="component_container"
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}
    >
      {showBlockedStateWarning
        && statesToDisableTempRequests
          ?.split(',')
          ?.includes(user?.address?.[0]?.state) && (
          <WarningBanner
          description="TempMee is not offering temporary services in your state as we work on building the best user experience for offices like you. However, “Permanent Hires” are still available!"
          containerStyle={{ width: '100%', alignItems: 'flex-start' }}
          indicatorStyle={{ display: 'none' }}
          iconStyle={{ marginTop: 15, marginLeft: 20 }}
          descriptionStyle={{ marginRight: 80 }}
        />
      )}

      {showWorkerClassificationWarning && (
        <WarningBanner
          description="Temp requests are unavailable until a worker type has been chosen for this office. Please click here to set a preference."
          containerStyle={{ width: '100%', alignItems: 'flex-start' }}
          indicatorStyle={{ display: 'none' }}
          descriptionStyle={{ marginRight: 80 }}
          onClick={() => history.push('/dashboard/worker-classification')}
        />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
          background: '#FAFAFA',
          minWidth: 800,
        }}
      >
        <Dashboard />
      </div>
    </div>
  );
};
