export const InterviewType = {
  call: 'phone',
  inPerson: 'inperson',
  working: 'workingday',
};

export const CardTypes = {
  candidate: 'candidate',
  interview: 'interview',
  decision: 'decision',
  declined: 'declined',
  rejected: 'rejected',
};

export const ActionTypes = {
  declined: 'declined',
  rejected: 'rejected',
  interviewPhone: 'interview-phone',
  interviewInperson: 'interview-inperson',
  interviewWorkingday: 'interview-workingday',
  hired: 'hired',
};
