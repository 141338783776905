import React from 'react';
import { Colors } from '../themes/colors';
import greenCheck from '../images/green-check.svg';

export const renderIntroductionLine = (title) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      margin: 2,
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        backgroundColor: Colors.neutral_600,
        borderRadius: 50,
        width: 6,
        height: 6,
        marginRight: 4,
        marginLeft: 10,
      }}
    />
    <p
      className="card-text"
      style={{
        fontFamily: 'Nunito',
        width: '90%',
        fontSize: 16,
        margin: 5,
        color: Colors.neutral_600,
        fontWeight: 400
      }}
    >
      {title}
    </p>
  </div>
);

export const renderPoints = (text) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginRight: 30,
      margin: '20px 0px',
    }}
  >
    <img
      alt="check"
      src={greenCheck}
      style={{
        resizeMode: 'contain',
        width: 25,
        height: 25,
        marginRight: 5,
      }}
    />
    <p
      style={{
        color: Colors.neutral_600,
        fontFamily: 'Nunito',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: 0,
        margin: 0,
      }}
    >
      {text}
    </p>
  </div>
);
