import ReactSwitch from 'react-switch';
import { Colors } from '../../../themes/colors';

export const CustomSwitch = ({ code, checked, handleOnChange }) => {
  const titleMap = {
    push: 'Push',
    email: 'Email',
    text: 'SMS/Text',
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', columnGap: 20 }}>
      <ReactSwitch
        checked={checked}
        onChange={(value) => handleOnChange(code, value)}
        onColor={Colors.secondary_500}
        onHandleColor="#fff"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        offColor={Colors.neutral_100}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.35)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={25}
        width={50}
        className="react-switch"
        id="material-switch"
      />

      <h3 className="global_font bold" style={{ color: Colors.neutral_600, margin: 0 }}>
        {titleMap[code]}
      </h3>
    </div>
  );
};
