import { motion } from 'framer-motion';
import closeIcon from '../../images/close-icon.svg';
import searchIcon from '../../images/search-icon.svg';
import { Colors } from '../../themes/colors';
import Button from '../Button';
import CustomSpinner from '../CustomSpinner';

export function SendToModal({
  onClose,
  isLoading,
  isEmpty,
  EmptyComponent,
  HeaderComponent,
  onSearchChange,
  isDisabled,
  onSend,
  children,
}) {
  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        position: 'fixed',
        overflow: 'hidden',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'relative',
          backgroundColor: Colors.white,
          alignContent: 'center',
          width: '30%',
          height: isEmpty ? 'auto' : 550,
          minWidth: 400,
          padding: '35px',
          borderRadius: 25,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        <img
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
          src={closeIcon}
          style={{
            width: 24,
            height: 24,
            position: 'absolute',
            top: 20,
            left: '92%',
            cursor: 'pointer',
          }}
          alt="close_icon"
        />

        {isEmpty ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px 10px',
              paddingBottom: 12,
            }}
          >
            <EmptyComponent />

            <Button
              text="Close"
              onClick={onClose}
              style={{
                alignSelf: 'center',
                height: 48,
                width: 152,
                marginTop: 40,
                backgroundColor: Colors.primary_500,
              }}
            />
          </div>
        ) : (
          <>
            <div
              style={{
                color: Colors.neutral_600,
                fontSize: 18,
                fontFamily: 'Nunito',
                textAlign: 'center',
                fontWeight: 'bold',
                marginBottom: 30,
              }}
            >
              Send to
            </div>

            <div style={{ position: 'relative', width: '100%' }}>
              <img
                src={searchIcon}
                style={{
                  width: 22,
                  height: 22,
                  position: 'absolute',
                  top: '25%',
                  left: 10,
                }}
                alt="search_icon"
              />

              <input
                type="text"
                placeholder="Search"
                onChange={(event) => onSearchChange(event.target.value)}
                style={{
                  margin: 0,
                  width: '88%',
                  paddingLeft: 40,
                  backgroundColor: Colors.neutral_50,
                  borderRadius: 10,
                  fontFamily: 'Nunito',
                  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
                }}
              />
            </div>

            <HeaderComponent />

            <div
              style={{
                height: '70%',
                overflowY: 'auto',
                padding: '0px 20px',
                marginTop: 20,
                paddingBottom: 20,
              }}
            >
              {isLoading && <CustomSpinner isLoading />}

              {!isLoading && children}
            </div>

            {!isLoading && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                }}
              >
                <Button
                  disabled={isDisabled}
                  style={{
                    marginTop: 20,
                    height: 48,
                    width: 152,
                    backgroundColor: !isDisabled ? Colors.primary_500 : Colors.primary_200,
                  }}
                  onClick={onSend}
                  text="Send"
                />
              </div>
            )}
          </>
        )}
      </motion.div>
    </div>
  );
}
