import { Colors } from '../../themes/colors';
import CustomSpinner from '../CustomSpinner';

export const Table = ({
  tableRef,
  data,
  renderRow,
  headerTitles,
  onRowClick,
  showActions = false,
  isLoading,
}) => (
  <div>
    <table
      ref={tableRef}
      style={{
        width: '100%',
        borderCollapse: 'collapse',
      }}
    >
      <thead
        style={{
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: Colors.neutral_100,
        }}
      >
        <tr>
          {headerTitles?.map((title) => (
            <th
              key={title}
              style={{
                fontFamily: 'Nunito',
                fontSize: 18,
                fontWeight: 700,
                color: Colors.primary_500,
                textAlign: 'center',
                paddingBottom: 8,
                borderBottomWidth: 1,
                maxWidth: 100,
                borderBottomStyle: 'solid',
                borderBottomColor: Colors.neutral_100,
                width: 'fit-content',
              }}
            >
              {title}
            </th>
          ))}
          {showActions && (
            <th
              style={{
                cursor: 'default',
                opacity: 0,
              }}
            >
              Action
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        <tr style={{ height: 10 }} />
        {data.map((item) => (
          <tr
            style={{
              height: 64,
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderBottom: `1px solid ${Colors.neutral_100}`,
              cursor: onRowClick ? 'pointer' : 'default',
            }}
            key={item}
            onClick={() => onRowClick?.(item)}
          >
            {renderRow(item)}
          </tr>
        ))}
      </tbody>
    </table>

    {isLoading && <CustomSpinner isLoading size={32} style={{ marginTop: 20 }} />}
  </div>
);
