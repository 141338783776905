import React from 'react';
import '../themes/global.scss';
import '../themes/ratingInput.scss';
import { Colors } from '../themes/colors';

import greenStar from '../images/star_Green.png';
import grayStar from '../images/grayStar.png';

const RatingInput = ({
  title,
  value = 0,
  maxValue = 5,
  isError,
  errorMessage = 'Please enter a value.',
  onChange = () => { },
  containerStyle,
  direction = 'column',
}) => (
  <div
    style={{
      marginTop: 10,
      display: 'flex',
      flexDirection: direction,
      alignSelf: 'center',
      ...containerStyle,
    }}
  >
    {title ? (
      <div style={{
        flex: direction === 'row' ? '0 0 40%' : '1',
        paddingRight: direction === 'row' ? 12 : 0,
        paddingTop: 5,
        textAlign: 'center',
      }}
      >
        <span
          className="global_font f-dark f-16 bold"
          style={{
            color: Colors.neutral_600,
          }}
        >
          {title}
        </span>
      </div>
    ) : null}
    <div style={{
      flex: direction === 'row' ? '0 0 60%' : '1',
      marginTop: direction === 'column' ? 12 : 0
    }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
        }}
      >
        {[...Array(maxValue).keys()].map((index) => {
          const number = index + 1;
          return (
            <img
              key={number}
              src={value > number - 1 ? greenStar : grayStar}
              onClick={() => onChange(number)}
              className="star-size"
              style={{ marginRight: 26 }}
              alt="star"
            />
          );
        })}
      </div>
      <div style={{ height: 22, marginTop: 8 }}>
        <span
          className="global_font f-16"
          style={{
            color: Colors.error_500,
          }}
        >
          {isError ? errorMessage : ''}
        </span>
      </div>
    </div>
  </div>
);

export default RatingInput;
