import React, { useState } from 'react';
import '../../themes/attendanceBadge.scss';

import { Colors } from '../../themes/colors';
import ConfirmPopup from '../shared/ConfirmPopup';
import DiamondIcon from '../../images/attendance/DiamondIcon';
import BadgeIcon from '../../images/attendance/BadgeIcon';
import PerfectAttendanceImage from '../../images/attendance/PerfectAttendance.png';
import EliteAttendanceImage from '../../images/attendance/EliteAttendance.png';
import GreatAttendanceImage from '../../images/attendance/GreatAttendance.png';

export const attendanceBadgeMap = {
  gold: {
    color: '#89CDD0',
    icon: PerfectAttendanceImage,
  },
  silver: {
    color: Colors.neutral_300,
    icon: EliteAttendanceImage,
  },
  bronze: {
    color: Colors.warning_400,
    icon: GreatAttendanceImage,
  },
};

const AttendanceBadge = ({ currentRank, width, height, disabled, noLabel, small }) => {
  const [showPopup, setShowPopup] = useState(false);

  const badgeInfo = attendanceBadgeMap[currentRank?.rank] || {};

  if (!currentRank?.rank) {
    return <div style={{ paddingTop: 15 }} />;
  }

  const getLabel = () =>
    currentRank?.label ? (
      <>
        {currentRank?.label}
        <br />
        Attendance
      </>
    ) : (
      ''
    );

  return (
    <>
      <button
        type="button"
        className={`attendance-badge-button ${disabled ? 'disabled' : ''}`}
        style={{
          all: 'unset',
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: !disabled ? 'pointer' : 'auto',
        }}
        onClick={() => setShowPopup(true)}
        onKeyDown={() => setShowPopup(true)}
        disabled={disabled}
      >
        <BadgeIcon
          rank={currentRank?.rank}
          alt="icon"
          className="attendance-badge"
          style={{
            width: width || 50,
            height: height || 50,
          }}
          size={small ? 13 : 18}
        />
        {!noLabel && (
          <span className="global_font f-dark f-14" style={{ paddingTop: 6, textAlign: 'center' }}>
            {getLabel()}
          </span>
        )}
      </button>

      {showPopup && (
        <ConfirmPopup
          Icon={() => (
            <img src={badgeInfo?.icon} style={{ marginBottom: 20 }} alt="attendance_icon" />
          )}
          closePopup={() => setShowPopup(false)}
          rightButtonAction={() => setShowPopup(false)}
          rightButtonText="Got it!"
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="global_font f-dark f-18" style={{ marginRight: 5 }}>
                {currentRank?.titleDnt}
              </span>
              <DiamondIcon color={badgeInfo.color} />
            </div>
          }
          titleStyle={{ marginBottom: 5 }}
          description={currentRank?.descriptionDnt}
        />
      )}
    </>
  );
};

export default AttendanceBadge;
