import { motion } from 'framer-motion';
import { useState } from 'react';
import './TooltipWrapper.scss';

export const Tooltip = ({ children, text }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const tooltipVariants = {
    show: { opacity: 1, display: 'flex' },
    hide: { opacity: 0, transitionEnd: { display: 'none' } },
  };

  return (
    <div className="tooltip-container">
      <div
        className="tooltip-target"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {isTooltipVisible && (
        <motion.div
          className="tooltip-box"
          initial="hide"
          animate={!!text && isTooltipVisible ? 'show' : 'hide'}
          transition={{ duration: 0.3 }}
          variants={tooltipVariants}
        >
          {text}
        </motion.div>
      )}
    </div>
  );
};
