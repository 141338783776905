import React from 'react';
import { Colors } from '../../themes/colors';

const UserIcon = ({ color = Colors.primary_500 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6113 12.5571C9.59295 12.2404 7.85612 10.466 7.54386 9.42319C6.96914 7.50381 7.37435 5.9738 8.84062 4.52689C10.3069 3.0802 11.8576 2.68039 13.8032 3.24745C14.9427 3.57969 16.6616 5.27573 16.9984 6.40001C17.5731 8.31959 17.1679 9.84961 15.7016 11.2963C14.2416 12.7367 12.5423 13.1575 10.6113 12.5571ZM4.06485 21.8148C3.87562 21.5129 4.12481 19.4262 4.49827 18.1855C4.66302 17.6385 5.08254 16.8029 5.4307 16.3285C6.14028 15.3623 7.75428 14.2577 8.45723 14.2577C8.71118 14.2577 9.67328 14.6393 10.5951 15.1056L12.2712 15.9533L13.9472 15.1056C14.869 14.6393 15.8311 14.2577 16.0849 14.2577C16.7893 14.2577 18.4024 15.3629 19.1149 16.3332C19.9029 17.4063 20.3841 18.9886 20.431 20.66L20.4667 21.9344L12.3367 21.988C6.56106 22.0259 4.16568 21.9757 4.06485 21.8148Z"
      fill={color}
    />
  </svg>
);

export default UserIcon;
