import React from 'react';
import { Colors } from '../../themes/colors';

const InfoIcon = ({ color = Colors.primary_500 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M21.6 12C21.6 17.302 17.302 21.6 12 21.6C6.69809 21.6 2.40002 17.302 2.40002 12C2.40002 6.69809 6.69809 2.40002 12 2.40002C17.302 2.40002 21.6 6.69809 21.6 12ZM13.2 7.20002C13.2 7.86277 12.6628 8.40002 12 8.40002C11.3373 8.40002 10.8 7.86277 10.8 7.20002C10.8 6.53728 11.3373 6.00002 12 6.00002C12.6628 6.00002 13.2 6.53728 13.2 7.20002ZM12 9.80002C11.3373 9.80002 10.8 10.3373 10.8 11V13.2V16.8C10.8 17.4628 11.3373 18 12 18C12.6628 18 13.2 17.3501 13.2 16.6874C13.2 16.0246 13.2 15.6 13.2 15.6V11C13.2 10.3373 12.6628 9.80002 12 9.80002Z" fill={color} />
  </svg>

);

export default InfoIcon;
