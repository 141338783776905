import { useSelector } from 'react-redux';
import { FETCH_OPEN_JOBS_ACTION } from '../../../../../actions';
import history from '../../../../../history';
import { FilterStatusEnum } from '../../../../../utils/Enum';
import { headerOpenTabTitles } from '../../utils';
import { CustomTable } from '../CustomTable';
import { renderRow } from './renderRow';

const actions = [FETCH_OPEN_JOBS_ACTION];

export const OpenTab = () => {
  const openJobs = useSelector((state) => state.job.openJobs);

  const handleOnClick = (item) => {
    history.push(`/dashboard/job/${item.id}`);
  };

  return (
    <CustomTable
      type={FilterStatusEnum.OPEN}
      data={openJobs}
      renderRow={(item) => renderRow(item, handleOnClick)}
      headerTitles={headerOpenTabTitles}
      onRowClick={(item) => handleOnClick(item)}
      actions={actions}
    />
  );
};
