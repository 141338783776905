import { useSelector } from 'react-redux';
import { useState } from 'react';
import OfficeAvatar1 from '../../../images/avatars/Office-Avatar-1.svg';
import PencilWithBackground from '../../../images/pencil-with-background.svg';
import { ChangeAccountPhotoModal } from './ChangeAccountPhotoModal';

export const ChangeAccountPhoto = () => {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [showChangeAccountPhotoModal, setShowChangeAccountPhotoModal] = useState(false);

  return (
    <div
      onClick={() => setShowChangeAccountPhotoModal(true)}
      style={{
        cursor: 'pointer',
      }}
    >
      <img
        alt="profile"
        src={user?.profile_image ?? OfficeAvatar1}
        style={{
          width: 120,
          height: 120,
          borderRadius: '100%',
          resizeMode: 'contain',
        }}
      />
      <img
        alt="pencil"
        src={PencilWithBackground}
        style={{
          position: 'absolute',
          marginTop: 80,
          marginLeft: -40,
        }}
      />

      {showChangeAccountPhotoModal && (
        <ChangeAccountPhotoModal onClose={() => setShowChangeAccountPhotoModal(false)} />
      )}
    </div>
  );
};
