import moment from 'moment';
import { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import Button from '../../../commonComponents/Button';
import { NUMBER_OF_DAYS_TO_ADD } from '../../../commonComponents/CalendarPopup';
import '../../../themes/calendar.scss';
import WarningPopup from '../WarningPopup';
import isHoliday from '../isHoliday';

const checkIfAnyHoliday = (dates) => Object.values(dates).some((date) => isHoliday(date));

const createObjOfDates = (datesArr) => {
  let objHelper = {}
  datesArr.map((date) => {      
    const formattedDate = moment(date).format('YYYY-MM-DD');
    objHelper = {...objHelper, [formattedDate]: date}
  })
  return objHelper
}

export default ({ nexBtnClicked }) => {

  const [selectedDates, setSelectedDates] = useState([]);
  const [confirmPostOnHoliday, setConfirmPostOnHoliday] = useState(false);
  const fromMonth = new Date();
  const selectedDays = Object.keys(selectedDates).length;
  const btnDisabled = selectedDays < 2;

  
  const handleNextButton = () => {
    if (checkIfAnyHoliday(selectedDates)) {
      setConfirmPostOnHoliday(true);
    } else {
      nexBtnClicked(createObjOfDates(selectedDates));
    }
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
        marginTop: 20,
      }}
    >
      <DayPicker
        mode='multiple'
        onSelect={setSelectedDates}
        selected={selectedDates}
        fromMonth={fromMonth}
        disabled={[
          {
            before: new Date(),
            after: moment().add(NUMBER_OF_DAYS_TO_ADD, 'days').toDate(),
          },
        ]}
      />
      <Button
        text={`Next (selected ${selectedDays} days)`}
        onClick={() => handleNextButton()}
        style={{
          marginTop: 50,
          marginBottom: 120,
        }}
        textStyle={{ fontSize: 18 }}
        disabled={btnDisabled}
      />
      {confirmPostOnHoliday && (
        <WarningPopup
          title="Major U.S Holiday Conflict"
          text={
            'Looks like you are trying to post a shift on a\nMajor U.S. Holiday. Please make sure your office\nwill be open on this date!'
          }
          successText="Continue"
          cancelText="Go Back"
          btnSucces={() => nexBtnClicked(createObjOfDates(selectedDates))}
          btnCancel={() => setConfirmPostOnHoliday(false)}
        />
      )}
    </div>
  );
};
