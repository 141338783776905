import React from 'react';
import { Colors } from '../../themes/colors';

const WarningSolidIcon = ({ color = Colors.primary_500 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.90822 3.71861C10.8258 2.08745 13.1743 2.08745 14.0918 3.71861L20.7882 15.6233C21.6881 17.2231 20.532 19.1999 18.6964 19.1999H5.30364C3.46806 19.1999 2.31194 17.2231 3.21186 15.6233L9.90822 3.71861ZM13.1999 15.6C13.1999 16.2628 12.6626 16.8 11.9999 16.8C11.3372 16.8 10.7999 16.2628 10.7999 15.6C10.7999 14.9373 11.3372 14.4 11.9999 14.4C12.6626 14.4 13.1999 14.9373 13.1999 15.6ZM11.9999 6.00002C11.3372 6.00002 10.7999 6.53728 10.7999 7.20002V10.8C10.7999 11.4628 11.3372 12 11.9999 12C12.6626 12 13.1999 11.4628 13.1999 10.8V7.20002C13.1999 6.53728 12.6626 6.00002 11.9999 6.00002Z" fill={color} />
  </svg>

);

export default WarningSolidIcon;
