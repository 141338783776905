import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NewPhotoFeature from '../images/NewPhotoFeature.svg';
import { Colors } from '../themes/colors';
import ConfirmPopup from './shared/ConfirmPopup';

export function NewPhotoUploadFeaturePopup() {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [showPopup, setShowPopup] = useState(false);
  const isSameOrBefore = moment().isSameOrBefore(moment('2024-06-26', 'YYYY-MM-DD'));

  const getStorage = () => {
    const showNewPhotoUploadFeaturePopup = localStorage.getItem(
      `showNewPhotoUploadFeaturePopup-${user?.id}`,
    );

    if (showNewPhotoUploadFeaturePopup === null) {
      setShowPopup(true);
    }
  };

  const setStorage = () => {
    localStorage.setItem(`showNewPhotoUploadFeaturePopup-${user?.id}`, 'false');
    setShowPopup(false);
  };

  const handleConfirm = () => {
    setStorage();
  };

  useEffect(() => {
    getStorage();
  }, [user]);

  return (
    user?.is_profile_complete === true &&
    isSameOrBefore &&
    showPopup && (
      <ConfirmPopup
        Icon={() => (
          <img
            alt="Upload Avatar Feature"
            src={NewPhotoFeature}
            style={{ width: 224, height: 158, marginBottom: 24 }}
          />
        )}
        title="New Photo Feature!"
        description="Introducing account photos & avatars! Your office can now personalize its account icon by uploading a photo of your building / entrance, or by selecting an avatar that best represents your office’s specialty."
        descriptionStyle={{ color: Colors.neutral_500, marginBottom: 32 }}
        rightButtonText="Got it!"
        rightButtonAction={handleConfirm}
        closePopup={handleConfirm}
      />
    )
  );
}
