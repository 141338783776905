import { Colors } from '../../themes/colors';

export const HeaderComponent = ({ title, subtitle }) => (
  <>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        gap: 8,
      }}
    >
      <b
        style={{
          fontFamily: 'Nunito',
          color: Colors.neutral_600,
          fontSize: 18,
        }}
      >
        {title}
      </b>
      <span
        style={{
          fontFamily: 'Nunito',
          color: Colors.neutral_500,
          fontSize: 16,
          fontStyle: 'italic',
        }}
      >
        {subtitle}
      </span>
    </div>
  </>
);
