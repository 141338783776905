import React from 'react';

import { Colors } from '../../themes/colors';

const BlockCrossIcon = ({ color = Colors.primary_500 }) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M70.5556 37.2223C69.0226 37.2223 67.7779 38.4671 67.7779 40.0001C67.7779 55.3161 55.3161 67.7779 40.0001 67.7779C24.6841 67.7779 12.2223 55.3161 12.2223 40.0001C12.2223 24.6841 24.6841 12.2223 40.0001 12.2223C47.4567 12.2223 54.4567 15.1355 59.7119 20.4272C60.7917 21.5174 62.5504 21.5227 63.639 20.4411C64.7275 19.3612 64.7327 17.6025 63.6529 16.514C57.349 10.1633 48.948 6.66675 40.0001 6.66675C21.6199 6.66675 6.66675 21.6199 6.66675 40.0001C6.66675 58.3803 21.6199 73.3334 40.0001 73.3334C58.3803 73.3334 73.3334 58.3803 73.3334 40.0001C73.3334 38.4671 72.0886 37.2223 70.5556 37.2223Z" fill={color} stroke={color} strokeWidth="0.925" />
    <path d="M29.9511 46.5365C28.7636 47.757 29.0692 49.6684 30.5414 50.4184C31.5084 50.9097 32.6005 50.7188 33.4442 49.8785C35.5345 47.7986 37.6143 45.7118 39.6994 43.6268C39.8418 43.4844 39.9945 43.3507 40.163 43.1945C40.3279 43.349 40.4668 43.4722 40.597 43.6042C42.2411 45.2465 43.8834 46.8924 45.5275 48.5347C46.0484 49.0556 46.5518 49.5972 47.097 50.0903C48.5275 51.382 50.7359 50.658 51.1126 48.7778C51.3001 47.842 50.9373 47.0868 50.2775 46.4323C48.1873 44.3542 46.1074 42.2656 44.0241 40.1806C43.8834 40.0399 43.7515 39.8889 43.5484 39.6702C43.7723 39.4879 43.9616 39.3594 44.1213 39.1997C46.1682 37.158 48.2081 35.1094 50.2567 33.0712C50.7567 32.5747 51.1126 32.0174 51.1543 31.2969C51.2116 30.3004 50.656 29.3802 49.755 28.9653C48.8036 28.5278 47.7845 28.7014 46.9928 29.4879C44.8609 31.6042 42.7428 33.7344 40.6178 35.8594C40.4755 36.0018 40.3227 36.1354 40.1543 36.2917C39.9755 36.1215 39.8244 35.9827 39.6803 35.8386C37.5952 33.7552 35.5084 31.6719 33.4286 29.5834C32.9581 29.1111 32.4199 28.7899 31.7463 28.7465C30.748 28.6806 29.8209 29.2257 29.3956 30.1146C28.9407 31.0695 29.1195 32.1129 29.9251 32.9236C32.0275 35.0417 34.1421 37.1511 36.2584 39.257C36.4043 39.4011 36.5987 39.4965 36.84 39.6615C35.7914 40.7031 34.8921 41.592 33.9963 42.4844C32.6456 43.8316 31.281 45.1702 29.9511 46.5365Z" fill={color} stroke={color} strokeWidth="1.54167" strokeMiterlimit="10" />
  </svg>
);

export default BlockCrossIcon;
