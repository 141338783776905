import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import rootSaga from '../rootSaga';
import rootReducer from '../reducers';

const sagaMiddleware = createSagaMiddleware();

let middleware;
if (process.env.REACT_APP_TEMP_MEE_ENV === 'production') {
  middleware = applyMiddleware(sagaMiddleware);
} else {
  middleware = applyMiddleware(sagaMiddleware, createLogger());
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(middleware));

sagaMiddleware.run(rootSaga);
export default store;
