import React from 'react';
import { motion } from 'framer-motion';

import { Colors } from '../../../themes/colors';

const InputError = ({ text, textStyle, showError }) => (
  <motion.p
    initial="hide"
    animate={showError ? 'show' : 'hide'}
    variants={errorVariants}
    style={{
      fontFamily: 'Nunito',
      fontSize: 16,
      color: Colors.error_500,
      position: 'absolute',
      width: '100%',
      margin: 0,
      marginTop: 5,
      ...textStyle,
    }}
  >
    {text}
  </motion.p>
);

const errorVariants = {
  show: { opacity: 1, y: 0 },
  hide: { opacity: 0, y: -5 },
};

export default InputError;
