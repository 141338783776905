import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { allErrorSelector } from '../api/selectors';
import AlertIcon from '../images/alert-error.svg';

import { Colors } from '../themes/colors';

export default ({}) => {
  const errorSelector = allErrorSelector();
  const error = useSelector((state) => errorSelector(state));
  const dispatch = useDispatch();
  if (!error?.message) {
    return <></>;
  }
  return (
    <div
      className="modal"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 10 }}
      onClick={() => dispatch({ type: 'HIDE_ERROR_ACTION', payload: error?.action })}
    >
      <motion.div
        onClick={(event) => event.stopPropagation()}
        className="modal_content"

        style={{ position: 'relative', padding: '35px', alignItems: 'center' }}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <img src={AlertIcon} style={{ marginBottom: 20 }} alt="AlertIcon" />

        <h1
          style={{
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: 18,
            color: Colors.neutral_600,
            margin: 0,
            marginBottom: 20,
          }}
        >
          Sorry!
        </h1>

        <p
          style={{
            width: '80%',
            fontFamily: 'Nunito',
            fontSize: 16,
            color: Colors.neutral_500,
            textAlign: 'center',
            margin: 0,
            marginBottom: 50,
          }}
        >
          {error?.message}
          {' '}
        </p>

        <div style={{ display: 'flex', columnGap: 16 }}>
          <button
            type="button"
            className="green-button"
            style={{
              alignSelf: 'center',
              width: 152,
              margin: 0,
              backgroundColor: Colors.primary_500,
            }}
            onClick={() => dispatch({ type: 'HIDE_ERROR_ACTION', payload: error?.action })}
          >
            <p className="white-text-18" style={{ margin: 0 }}>
              Close
            </p>
          </button>

        </div>
      </motion.div>
    </div>
  );
};
