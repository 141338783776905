import { Colors } from '../../../../../themes/colors';

export const ProceduresView = ({ userProcedures }) =>
  userProcedures?.length > 0 && (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color: Colors.neutral_600,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <b style={{ ...styles.title, marginBottom: 20 }}>Procedures:</b>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
        }}
      >
        {userProcedures?.map((name) => (
          <li key={name} style={styles.text}>
            {name?.split('_')?.[1]}
          </li>
        ))}
      </div>
    </div>
  );

const styles = {
  title: {
    fontSize: 18,
  },
  text: {
    fontSize: 16,
  },
};
