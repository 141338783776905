import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import browserHistory from '../../history';
import { Colors } from '../../themes/colors';
import { hideReportIncidentSuccessPopup } from '../../actions';
import Button from '../../commonComponents/Button';
import AnimatedCheckCircle from '../shared/AnimatedCheckCircle';

export default function SuccessReportIncidentPopup() {
  const dispatch = useDispatch();
  const showReportIncidentSuccessPopup = useSelector(
    (state) => state.job.showReportIncidentSuccessPopup,
  );

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          backgroundColor: Colors.white,
          alignContent: 'center',
          justifyContent: 'center',
          maxWidth: 700,
          minWidth: 450,
          width: '30%',
          padding: '50px 35px 45px 35px',
          borderRadius: 18,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 50,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 20,
          }}
        >
          <AnimatedCheckCircle />
        </div>
        <div
          style={{
            color: Colors.neutral_600,
            fontSize: 18,
            fontFamily: 'Nunito',
            textAlign: 'center',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        >
          {showReportIncidentSuccessPopup?.incidentType} Successfully Reported
        </div>

        <div
          style={{
            color: Colors.neutral_500,
            fontSize: 16,
            fontFamily: 'Nunito',
            textAlign: 'center',
            marginTop: 30,
            marginBottom: 60,
          }}
        >
          Our Customer Success team will reach out to{' '}
          <strong>{showReportIncidentSuccessPopup?.name}</strong> immediately and provide an update
          on their status.
          <br />
          <br />
          We apologize for any inconveniences that this may have caused. We care about our
          customer’s satisfaction, and will take the appropriate action to make sure incidents like
          these are few and far between on GoTu.
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center',
            columnGap: 20,
          }}
        >
          <Button
            text="Contact Support"
            style={{
              margin: 0,
              width: 170,
              backgroundColor: Colors.secondary_500,
            }}
            onClick={() => {
              dispatch(hideReportIncidentSuccessPopup());
              browserHistory.push('/dashboard/help');
            }}
          />

          <Button
            text="Return"
            style={{
              margin: 0,
              width: 170,
            }}
            onClick={() => {
              dispatch(hideReportIncidentSuccessPopup());
            }}
          />
        </div>
      </motion.div>
    </div>
  );
}
