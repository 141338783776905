import { useSelector } from 'react-redux';
import { createLoadingSelector } from '../api/selectors';

/**
 * Hook to check if actions are loading
 *
 * @param {string[]} actions - actions to check if loading
 * @returns {{selector: Function, isLoading: boolean}} - isLoading and selector
 */
export default function useLoading(actions = []) {
  const loadingSelector = createLoadingSelector(actions);

  return {
    selector: loadingSelector,
    isLoading: useSelector((state) => loadingSelector(state))
  };
}
