import { useSelector } from 'react-redux';
import { Colors } from '../../../themes/colors';
import { Table } from '../../../commonComponents/Table/Table';
import { headerConfirmedWorkerTypesTitles } from '../utils';
import { renderRow } from './renderRow';

export const ConfirmedWorkerType = () => {
  const confirmedRequests = useSelector(
    (state) => state.user.workerClassificationManagement.confirmed,
  );

  if (!confirmedRequests?.length) {
    return (
      <div>
        <span
          style={{
            fontFamily: 'Nunito',
            display: 'flex',
            color: Colors.neutral_500,
            fontStyle: 'italic',
            textAlign: 'center',
          }}
        >
          No offices have a confirmed worker type yet. Please visit the &quot;Unselected&quot;
          and/or &quot;Pending Requests&quot; tabs on this page to verify the worker type
          types for your offices.
        </span>
      </div>
    );
  }

  return (
    <Table
      data={confirmedRequests}
      renderRow={renderRow}
      headerTitles={headerConfirmedWorkerTypesTitles}
    />
  );
};
