import React from 'react';

import { Colors } from '../themes/colors';
import browserHistory from '../history';

export default function SaveNotificationSettingsPopup({
  showSaveSettingsPopup,
  setShowSaveSettingsPopup,
}) {
  if (showSaveSettingsPopup) {
    return (
      <div
        className="modal"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <div
          style={{
            width: 500,
            backgroundColor: Colors.white,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 14,
          }}
        >
          <p
            className="global_font f-dark"
            style={{
              textAlign: 'center',
              width: '80%',
              margin: '40px 0px 30px 0px',
            }}
          >
            {showSaveSettingsPopup === 'reminder'
              ? 'Are you sure you would like to continue without saving your notifications preferences?'
              : 'Your notification preferences have been updated!'}
          </p>

          <div style={{ display: 'flex', gap: 16, marginBottom: 30 }}>
            {showSaveSettingsPopup === 'reminder' ? (
              <>
                <button
                  type="button"
                  className="green-button"
                  style={{
                    alignSelf: 'center',
                    width: 150,
                    margin: 0,
                    backgroundColor: Colors.secondary_500,
                  }}
                  onClick={() => setShowSaveSettingsPopup(false)}
                >
                  <p className="white-text-18" style={{ margin: 0 }}>
                    Continue
                  </p>
                </button>

                <button
                  type="button"
                  className="green-button"
                  style={{
                    alignSelf: 'center',
                    width: 150,
                    margin: 0,
                    backgroundColor: Colors.primary_500,
                  }}
                  onClick={() => browserHistory.goBack()}
                >
                  <p className="white-text-18" style={{ margin: 0 }}>
                    Go Back
                  </p>
                </button>
              </>
            ) : (
              <button
                type="button"
                className="green-button"
                style={{
                  alignSelf: 'center',
                  width: 150,
                  margin: 0,
                  backgroundColor: Colors.primary_500,
                }}
                onClick={() => browserHistory.goBack()}
              >
                <p className="white-text-18" style={{ margin: 0 }}>
                  Done
                </p>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}
