import React from 'react';
import { Colors } from '../../themes/colors';

const DashboardIcon = ({ color = Colors.neutral_600 }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66675 2.83333C1.66675 2.189 2.18908 1.66667 2.83341 1.66667H19.1667C19.8111 1.66667 20.3334 2.189 20.3334 2.83333V5.16667C20.3334 5.811 19.8111 6.33333 19.1667 6.33333H2.83341C2.18908 6.33333 1.66675 5.811 1.66675 5.16667V2.83333Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66675 12.1667C1.66675 11.5223 2.18908 11 2.83341 11H9.83342C10.4777 11 11.0001 11.5223 11.0001 12.1667V19.1667C11.0001 19.811 10.4777 20.3333 9.83342 20.3333H2.83341C2.18908 20.3333 1.66675 19.811 1.66675 19.1667V12.1667Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6667 12.1667C15.6667 11.5223 16.1891 11 16.8334 11H19.1667C19.8111 11 20.3334 11.5223 20.3334 12.1667V19.1667C20.3334 19.811 19.8111 20.3333 19.1667 20.3333H16.8334C16.1891 20.3333 15.6667 19.811 15.6667 19.1667V12.1667Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DashboardIcon;
