import { motion } from 'framer-motion';
import { Colors } from '../../../../themes/colors';

export const EmptyFavorites = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    style={{
      maxWidth: '827px',
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  >
    <span style={styles.text}>
      It looks like you have not added any favorite professionals yet!
    </span>
    <b
      style={{
        ...styles.text,
      }}
    >
      How to add a professional to your Favorites List:
    </b>
    <ul style={{ display: 'flex', flexDirection: 'column', gap: 24, margin: 0 }}>
      <li style={styles.text}>
        <b
          style={{
            ...styles.text,
          }}
        >
          End-of-day review:
        </b>{' '}
        Click “Add to Favorites” when prompted to leave a review for a professional.
      </li>
      <li style={styles.text}>
        <b
          style={{
            ...styles.text,
          }}
        >
          Completed Shifts screen:
        </b>{' '}
        Go to Schedule {'>'} Completed Shifts, and view the Shift Details screen.
      </li>
      <li style={styles.text}>
        <b
          style={{
            ...styles.text,
          }}
        >
          Professionals Hub:
        </b>{' '}
        You can add your recently worked professionals to your favorites.
      </li>
    </ul>
  </motion.div>
);

const styles = {
  text: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    color: Colors.neutral_600,
  },
  listTitle: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    color: Colors.neutral_500,
  },
};
