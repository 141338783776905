import { fetchFavoriteProfessionals } from '../../../actions';
import { EmptyFavorites } from '../../../components/ProfessionalsHub/components/FavoritesList/EmptyFavorites';
import { Colors } from '../../../themes/colors';
import { SendInviteToModal } from '../SendInviteToModal';
import { filterFavorites, mapFavoriteToRowProps } from '../utils';

export const SendToFavoritesModal = ({ onClose, refetchJob = true }) => (
  <SendInviteToModal
    fetchProfessionalsAction={fetchFavoriteProfessionals}
    professionalsSelector={(state) => state.user.favoriteProfessionals}
    filterFunction={filterFavorites}
    mapProfessionalToRowProps={mapFavoriteToRowProps}
    EmptyComponent={() => (
      <>
        <div
          style={{
            color: Colors.neutral_600,
            fontSize: 18,
            fontFamily: 'Nunito',
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: 30,
          }}
        >
          No Favorites Available
        </div>
        <EmptyFavorites />
      </>
    )}
    headerTitle="Favorite Professionals"
    headerSubtitle="Favorites will be confirmed if they accept the offer at the listed rate and time."
    onClose={onClose}
    refetchJob={refetchJob}
  />
);
