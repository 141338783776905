import React, { useState } from 'react';
import { CollapsibleCard } from '../../../commonComponents/CollapsibleCard';
import { Colors } from '../../../themes/colors';
import { NotificationItem } from './NotificationItem';

export const Content = ({ notifications, handleOnSwitchChange }) => {
  const [openCardIndex, setOpenCardIndex] = useState(null);

  const handleCardToggle = (index) => {
    setOpenCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        marginTop: 32,
      }}
    >
      {Object.keys(notifications)?.map((groupName, index) => (
        <CollapsibleCard
          key={groupName}
          title={groupName}
          description={notifications[groupName]?.description}
          isOpen={openCardIndex === index}
          onToggle={() => handleCardToggle(index)}
        >
          {notifications[groupName]?.items.map((item) => (
            <div key={item.event}>
              <NotificationItem item={item} handleOnSwitchChange={handleOnSwitchChange} />
              {notifications[groupName]?.items.indexOf(item) !==
                notifications[groupName]?.items.length - 1 && (
                <div
                  style={{
                    height: 1,
                    backgroundColor: Colors.neutral_100,
                    margin: '0px 20px',
                  }}
                />
              )}
            </div>
          ))}
        </CollapsibleCard>
      ))}
    </div>
  );
};
