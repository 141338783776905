import {
  call, put, take, all, fork, select,
} from 'redux-saga/effects';
import * as actions from '../actions';
import { apiMethods, apiMethodsConst } from '../services/api';

function* callAndDispatch(method, payload) {
  const response = yield call(apiMethods[method], payload);
  yield put({ type: method, payload: response });
}

function* callAndDispatchPayload(method, payload) {
  yield call(apiMethods[method], payload);
  yield put({ type: method, payload });
}

function* watchGetAllAlerts() {
  const events = actions.createRequestTypes(actions.GET_ALL_ALERTS_ACTION);
  while (true) {
    const { state } = yield take(actions.GET_ALL_ALERTS_ACTION);
    yield put({ type: events.REQUEST });
    try {
      const { searchParams } = yield select((store) => store.alerts);

      if (JSON.stringify(state) && JSON.stringify(searchParams) !== JSON.stringify(state)) {
        yield put({ type: events.REQUEST, payload: state });

        yield* callAndDispatch(apiMethodsConst.GET_ALERTS_REQUEST, state);

        yield put({
          type: events.SUCCESS,
        });
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchDeleteAllAlerts() {
  const events = actions.createRequestTypes(actions.DELETE_ALL_ALERTS_ACTION);
  while (true) {
    yield take(actions.DELETE_ALL_ALERTS_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.DELETE_ALERTS_REQUEST);

      yield put({
        type: events.SUCCESS,
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchDeleteOneAlert() {
  const events = actions.createRequestTypes(actions.DELETE_ONE_ALERT_ACTION);
  while (true) {
    const { alertId } = yield take(actions.DELETE_ONE_ALERT_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatchPayload(apiMethodsConst.DELETE_ONE_ALERT_REQUEST, alertId);

      yield put({
        type: events.SUCCESS,
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}
// use them in parallel
export default function* sagaAlerts() {
  yield all([fork(watchGetAllAlerts), fork(watchDeleteAllAlerts), fork(watchDeleteOneAlert)]);
}
