import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createLoadingSelector } from '../../api/selectors';
import Button from '../../commonComponents/Button';
import Checkcircle from '../../commonComponents/Checkcircle';
import browserHistory from '../../history';

import { EDIT_USER_OFFICE_INFO, editUserOfficeInfo } from '../../actions';
import { Colors } from '../../themes/colors';

const loadingSelector = createLoadingSelector([
  EDIT_USER_OFFICE_INFO,
]);

export function OfficeTimePerPatientPopup() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const user = useSelector((state) => state.user.selectedChildOffice);
  const [isAssistedHygiene, setIsAssistedHygiene] = useState(undefined);
  const redirectRef = useRef(false);
  const isLoadingUpdate = useRef(false);
  const isLoadingConfirm = useRef(false);
  const isDisabled = isLoading || isAssistedHygiene === undefined;

  const handleUpdateButton = () => {
    isLoadingConfirm.current = false;
    isLoadingUpdate.current = true;
    redirectRef.current = true;
    dispatch(editUserOfficeInfo({ data: { isAssistedHygiene } }));
  };
  
  const handleConfirmButton = () => {
    isLoadingUpdate.current = false;
    isLoadingConfirm.current = true;
    dispatch(editUserOfficeInfo({ data: { isAssistedHygiene } }));
  };
  
  useEffect(() => {
    if (redirectRef.current && user?.office_info?.isAssistedHygiene !== undefined) {
      browserHistory.push('/dashboard/office');
    }
  }, [user]);

  if (
    !(user?.is_profile_complete && typeof user?.office_info.isAssistedHygiene !== 'boolean')
  ) {
    return <></>;
  }

  return (
    <div
      className="modal"
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
        style={{
          width: 480,
          height: 520,
          backgroundColor: Colors.white,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 14,
          paddingTop: 28,
          paddingRight: 60,
          paddingLeft: 60,
          paddingBottom: 56,
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  fontFamily: 'Nunito',
                  fontWeight: 700,
                  fontSize: 18,
                  color: Colors.neutral_600,
                  marginTop: 32,
                  marginBottom: 24,
                }}
              >
                Additional Office Information
              </span>
              <span style={{ ...styles.text }}>
                We are updating a few “Office Information” fields based upon user feedback. Please
                answer and review the following:
              </span>

              <span style={{ ...styles.text, marginTop: 20, marginBottom: 16 }}>
                1. Is assisted hygiene available at your office for temp hygienists?
              </span>

              <div style={{ display: 'flex', gap: 80, marginBottom: 52 }}>
                <button
                  type="button"
                  style={{
                    all: 'unset',
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                  onClick={() => setIsAssistedHygiene(true)}
                >
                  <Checkcircle checked={isAssistedHygiene} />
                  <span
                    className="global_font f-dark"
                    style={{ ...styles.text, margin: '0px 0px 0px 22px' }}
                  >
                    Yes
                  </span>
                </button>

                <button
                  type="button"
                  style={{
                    all: 'unset',
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                  onClick={() => setIsAssistedHygiene(false)}
                >
                  <Checkcircle checked={isAssistedHygiene === false} />
                  <span
                    className="global_font"
                    style={{ ...styles.text, margin: '0px 0px 0px 22px' }}
                  >
                    No
                  </span>
                </button>
              </div>

              <span style={{ ...styles.text, marginBottom: 24 }}>
                2. We have converted your existing “patient per hour” expectations to “time per
                patient”. Please review the following values to confirm they are accurate. If
                correct, select “Confirm”.
              </span>

              <span style={{ ...styles.text }}>
                Dental Hygienist = <b>{user?.office_info?.timePerPatientRDH ?? 'N/A'}</b> per patient
                <br />
                Dental Assistant = <b>{user?.office_info?.timePerPatientDA ?? 'N/A'}</b> per patient
                <br />
                Associate Dentist = <b>{user?.office_info?.timePerPatientDN ?? 'N/A'}</b> per patient
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 20,
              }}
            >
              <Button
                isLoading={isLoading && isLoadingUpdate.current}
                disabled={isDisabled}
                style={{
                  width: 150,
                  backgroundColor: isDisabled ? Colors.secondary_200 : Colors.secondary_500,
                }}
                color='secondary'
                onClick={handleUpdateButton}
                text="Update"
              />
              <Button
                isLoading={isLoading && isLoadingConfirm.current}
                disabled={isDisabled}
                style={{
                  width: 150,
                }}
                onClick={handleConfirmButton}
                text="Confirm"
              />
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

const styles = {
  text: {
    fontFamily: 'Nunito',
    fontSize: 16,
    color: Colors.neutral_500,
    textAlign: 'center',
  },
};
