import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import breakIcon from '../../../../../images/break-icon.svg';
import { Colors } from '../../../../../themes/colors';
import { RadioOption } from '../../../../registration/components/RadioOption';
import { CollapsibleRow } from '../CollapsibleRow/CollapsibleRow';
import { Title } from '../CollapsibleRow/Title';
import { Value } from '../CollapsibleRow/Value';

export const lunchBreakItemsWithSuffix = [
  '0 min',
  '15 min',
  '30 min',
  '45 min',
  '1 hr',
  '1.5 hr',
  '2 hr',
];

export const BreakRow = ({ defaultBreak, defaultIsPaid, onToggle, isOpen, onConfirm }) => {
  const [selectedBreak, setSelectedBreak] = useState(defaultBreak);
  const [isPaid, setIsPaid] = useState(defaultIsPaid);

  useEffect(() => {
    onConfirm({
      time: selectedBreak,
      isPaid,
    });
  }, [selectedBreak, isPaid]);

  return (
    <CollapsibleRow
      isOpen={isOpen}
      onToggle={onToggle}
      TitleComponent={() => <Title text="Break:" />}
      ValueComponent={() => (
        <Value
          customStyles={{
            color: isOpen || !defaultBreak ? Colors.neutral_300 : Colors.neutral_600,
          }}
          text={
            !isOpen && defaultBreak
              ? `${defaultBreak} (${defaultIsPaid ? 'Paid' : 'Unpaid'})`
              : 'Time'
          }
        />
      )}
      icon={breakIcon}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <div
          style={{
            marginRight: 68,
          }}
        >
          {lunchBreakItemsWithSuffix.map((time) => (
            <div style={{ marginBottom: 10 }}>
              <RadioOption
                onClick={() => setSelectedBreak(time)}
                text={time}
                checked={time === selectedBreak}
              />
            </div>
          ))}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 10,
            marginTop: 16,
            marginRight: 32,
          }}
        >
          <Switch
            checked={isPaid}
            onChange={(value) => setIsPaid(value)}
            onColor={Colors.secondary_500}
            onHandleColor="#fff"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            offColor={Colors.neutral_100}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.35)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={25}
            width={50}
            className="react-switch"
            id="material-switch"
          />
          <span style={{ fontSize: 16, color: Colors.neutral_600, fontWeight: 700 }}>
            Lunch Break is Paid
          </span>
        </div>
      </div>
    </CollapsibleRow>
  );
};
