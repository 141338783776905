import React from 'react';

import moment from 'moment';
import { Colors } from '../../../themes/colors';

import { CardTypes, InterviewType } from './Enums';

import defaultProfileImage from '../../../images/defaultProfileImage.png';
import defaultProfileImageGray from '../../../images/defaultProfileImagePP.png';
import greenCheckmark from '../../../images/greenCheckmark.png';

import PPworkingIntrerview from '../../../images/PPworkingIntrerview.png';
import PPcallInterview from '../../../images/PPcallInterview.png';
import PPpersonInterview from '../../../images/PPpersonInterview.png';
import PPVirtualResume from '../../../images/PPVirtualResume.png';
import { styles } from '../Popups/StylesPopups';

const textTitleStyle = (type) => {
  const color = (type === CardTypes.candidate) ? Colors.neutral_600 : Colors.white;

  return {
    fontFamily: 'Nunito', fontWeight: 'bold', fontSize: 18, color,
  };
};

const getInterviewView = (interviewType) => {
  if (interviewType === InterviewType.call) {
    return (
      <div style={styles.interviewView}>
        <img src={PPcallInterview} alt="phone" style={styles.interviewIconStyle} />
        <div style={styles.whiteTextStyle}>Phone Interview</div>
      </div>
    );
  } if (interviewType === InterviewType.inPerson) {
    return (
      <div style={styles.interviewView}>
        <img src={PPpersonInterview} alt="phone" style={styles.interviewIconStyle} />
        <div style={styles.whiteTextStyle}>In-Person Interview</div>
      </div>
    );
  } if (interviewType === InterviewType.working) {
    return (
      <div style={styles.interviewView}>
        <img src={PPworkingIntrerview} alt="phone" style={styles.interviewIconStyle} />
        <div style={styles.whiteTextStyle}>Working Interview</div>
      </div>
    );
  }
  return (
    <div />
  );
};

export const CandidateCard = ({ openCandidateClicked, isDeclined, professional }) => (
  <div
    className={isDeclined ? 'permanent-cards-without-shadow' : 'permanent-cards-shadow'}
    style={{
      backgroundColor: isDeclined ? '#D9D9D9' : Colors.white, opacity: isDeclined ? 0.75 : 1, maxWidth: 450, width: '90%', alignSelf: 'center',
    }}
    onClick={() => openCandidateClicked()}
  >
    <div style={{
      display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
    }}
    >
      <img alt="profile" src={defaultProfileImage} style={{ resizeMode: 'contain', height: 50, width: 50 }} />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={PPVirtualResume} alt="resume" style={{ width: 25, height: 25 }} />
        <div style={styles.blueTextStyle}>
          Virtual
          Resume
        </div>

      </div>

    </div>

    <p style={textTitleStyle(CardTypes.candidate)}>
      {professional.first_name}
      {' '}
      {professional.last_name}
    </p>
    <p style={styles.blueTextStyle}>
      Registered Dental Hygienist
    </p>

  </div>
);

const getInterviewTime = (interviewInfo) => {
  if (interviewInfo?.status === 'scheduled') {
    return (

      <div style={{ width: '50%' }}>

        <div style={styles.greenTitle}>
          Confirmed
        </div>

        <div>
          <div style={styles.whiteTextStyle}>
            {moment(interviewInfo?.time).format('ddd, MMM DD, YYYY')}

          </div>
          <div style={styles.whiteTextStyle}>
            {moment(interviewInfo?.time).format('HH:mm A')}
          </div>
        </div>

      </div>
    );
  }
  return (
    <div style={{ width: '50%' }}>
      <div style={styles.greenTitle}>
        Status
      </div>
      <div style={styles.whiteTextStyle}>Awaiting Confirmation...</div>
    </div>
  );
};
export const InterviewCard = ({ professional, interviewInfo, showInterviewClicked }) => (
  <div
    onClick={() => showInterviewClicked()}
    className="permanent-cards-shadow"
    style={{
      backgroundColor: Colors.primary_500, maxWidth: 450, width: '90%', alignSelf: 'center',
    }}
  >
    <div style={{
      display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '90%',
    }}
    >
      <img alt="profile" src={defaultProfileImageGray} style={{ resizeMode: 'contain', height: 50, width: 50 }} />
      {getInterviewTime(interviewInfo)}

    </div>
    <p style={textTitleStyle(CardTypes.interview)}>
      {' '}
      {professional.first_name}
      {' '}
      {professional.last_name}
      {' '}
    </p>
    {interviewInfo?.type === InterviewType.call ? getInterviewView(InterviewType.call) : <div /> }
    {interviewInfo?.type === InterviewType.inPerson ? getInterviewView(InterviewType.inPerson) : <div /> }
    {interviewInfo?.type === InterviewType.working ? getInterviewView(InterviewType.working) : <div />}

  </div>
);

export const DecisionCard = ({ showDecisionPoup, professional, interviewInfo }) => (
  <div
    className="permanent-cards-shadow"
    style={{
      backgroundColor: Colors.primary_700, maxWidth: 450, width: '90%', alignSelf: 'center',
    }}
    onClick={() => showDecisionPoup()}
  >
    <div style={{
      display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
    }}
    >
      <img alt="profile" src={defaultProfileImage} style={{ resizeMode: 'contain', height: 50, width: 50 }} />
      <div style={{ width: '50%' }}>
        <div style={styles.greenTitle}>
          Completed Interview
        </div>

        <div style={styles.whiteTextStyle}>
          See Candidate
        </div>
      </div>

    </div>
    <p style={textTitleStyle(CardTypes.decision)}>
      {' '}
      {professional.first_name}
      {' '}
      {professional.last_name}
      {' '}
    </p>
    {interviewInfo?.type === InterviewType.call ? getInterviewView(InterviewType.call) : <div /> }
    {interviewInfo?.type === InterviewType.inPerson ? getInterviewView(InterviewType.inPerson) : <div /> }
    {interviewInfo?.type === InterviewType.working ? getInterviewView(InterviewType.working) : <div />}

  </div>
);

export const HiredCandidateCard = ({ showDecisionPoup, selectedCandidate, currentApplication }) => (
  <div
    className="permanent-cards-shadow"
    style={{
      backgroundColor: Colors.primary_700, maxWidth: 450, width: '90%', padding: 40, cursor: 'auto',
    }}
  >
    <div style={{
      display: 'flex', justifyContent: 'space-between', width: '100%',
    }}
    >
      <img alt="profile" src={defaultProfileImage} style={{ resizeMode: 'contain', height: 100, width: 100 }} />
      <div style={{
        width: '50%', display: 'flex', alignItems: 'flex-start', gap: 10,
      }}
      >
        <img src={greenCheckmark} style={{ height: 30, width: 30 }} />
        <div style={{
          fontFamily: 'Nunito', fontWeight: 'bold', fontSize: 18, color: Colors.success_500,
        }}
        >
          Interviewed & Hired
        </div>

      </div>

    </div>
    <p style={styles.whiteTextBigFont}>
      {' '}
      {currentApplication?.professional.first_name}
      {' '}
      {currentApplication?.professional.last_name}
      {' '}
    </p>
    <p style={{
      fontFamily: 'Nunito', fontWeight: 'bold', fontSize: 18, color: Colors.success_500,
    }}
    >
      Registered Dental Hygienist
    </p>
    <p
      style={styles.descriptionWhiteBigFont}
    >
      {currentApplication?.professional?.contact_number || 'N/A'}
    </p>

    <p
      style={styles.descriptionWhiteBigFont}
    >
      {currentApplication?.professional?.email_id || 'N/A'}
    </p>
    {currentApplication?.resume ? (
      <div>

        {
          currentApplication.resume?.officeNote && (
            <>
              <div
                style={styles.descriptionTitleGray}
              >
                Notes
              </div>
              <div
                style={styles.descriptionGray}
              >
                {currentApplication.resume.officeNote}
              </div>
            </>
          )
        }
        <p
          style={styles.whiteTextBigFount}
        >
          License #:
        </p>

        <p
          style={styles.descriptionWhiteBigFont}
        >
          {currentApplication?.resume?.licId || 'N/A'}
        </p>

        <p
          style={styles.whiteTextBigFount}
        >
          School Name:
        </p>

        <p
          style={styles.descriptionWhiteBigFont}
        >
          {currentApplication?.resume?.schoolName || 'N/A'}
        </p>

        <p
          style={styles.whiteTextBigFount}
        >
          State:
        </p>

        <p
          style={styles.descriptionWhiteBigFont}
        >
          {currentApplication?.resume?.state || 'N/A'}
        </p>
      </div>
) : <div />}
    {/* <p style={styles.titleGreen}>
      Interview Complete
    </p>
    {selectedCandidate?.interview?.type === InterviewType.call ? getInterviewView(InterviewType.call) : <div /> }
    {selectedCandidate?.interview?.type === InterviewType.inPerson ? getInterviewView(InterviewType.inPerson) : <div /> }
    {selectedCandidate?.interview?.type === InterviewType.working ? getInterviewView(InterviewType.working) : <div />} */}

  </div>
);
