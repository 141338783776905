import { Tooltip } from './TooltipWrapper/TooltipWrapper';

export const IconButton = ({
  src,
  buttonStyle,
  imgStyle,
  disabled,
  alt = '',
  onClick = () => {},
  tooltipContent = '',
}) => (
  <Tooltip text={disabled ? null : tooltipContent}>
    <button
      type="button"
      style={{
        all: 'unset',
        cursor: disabled ? 'default': 'pointer',
        ...buttonStyle,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <img
        src={src}
        alt={alt}
        style={{
          width: 10,
          ...imgStyle,
        }}
      />
    </button>
  </Tooltip>
);
