import { useSelector } from 'react-redux';
import { extractSkills } from 'commonComponents/CommonFunctions';
import WarningSolidIcon from '../../../../../images/icons/WarningSolid';
import { Colors } from '../../../../../themes/colors';
import { useCandidate } from '../../../hooks/useCandidate';
import { SpecialtiesAndProceduresView } from './SpecialtiesAndProceduresView';
import { ProceduresView } from './ProceduresView';
import { UserProfession } from '../../../../../enums/UserProfession';

export const Content = ({ user, job, candidate }) => {
  const allSkillCategories = useSelector((state) => state.user.allSkillCategories);
  const { userSpecialtySkills } = extractSkills(allSkillCategories, job, candidate);

  const { profession } = job || {};

  const { educationUpdated } = candidate || {};

  const {
    userSpecialties,
    userProcedures,
    specialtyMismatch,
    professionProcedures,
  } = useCandidate(user, candidate, job);

  const uniqueSpecialtySkills = [...new Set(userSpecialtySkills?.map(({ name }) => name))];

  return (
    <div
      className="global_font"
      style={{
        paddingBottom: 20,
        color: Colors.neutral_600,
      }}
    >

      {profession !== UserProfession.DN && userSpecialties?.length > 0 && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
              }}
            >
              <b style={styles.title}>Specialty(ies):</b>

              {specialtyMismatch && (
                <div style={{ marginLeft: 4 }}>
                  <WarningSolidIcon color={Colors.warning_500} />
                </div>
              )}
            </div>
            <span
              style={{
                ...styles.text,
                textAlign: 'right',
                width: 200,
              }}
            >
              {educationUpdated
                ? userSpecialties?.map((name) => name?.split('_')?.[1])?.join(', ')
                : uniqueSpecialtySkills?.join(', ')
              }
            </span>
          </div>

          <div
            style={{
              height: 1,
              backgroundColor: Colors.neutral_100,
              marginTop: 12,
              marginBottom: 20,
            }}
          />
        </>
      )}

      {[UserProfession.RDH, UserProfession.FD].includes(profession) && educationUpdated && (
        <ProceduresView userProcedures={userProcedures} />
      )}

      {[UserProfession.DN, UserProfession.DA].includes(profession) && educationUpdated && (
        <SpecialtiesAndProceduresView professionProcedures={professionProcedures} />
      )}
    </div>
  );
};

const styles = {
  title: {
    fontSize: 18,
  },
  text: {
    fontSize: 16,
  },
};
