import { Colors } from '../../../themes/colors';

export const CardActionsHeader = ({ actions = [] }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: 700,
      marginBottom: -12,
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginRight: 36,
        gap: 24,
      }}
    >
      {actions.map((action) => (
        <b
          key={action}
          style={{
            fontFamily: 'Nunito',
            color: Colors.primary_500,
            fontSize: 18,
          }}
        >
          {action}
        </b>
      ))}
    </div>
  </div>
);
