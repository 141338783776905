import { Colors } from '../../../../../themes/colors';

export const Title = ({ text }) => (
  <span
    style={{
      fontFamily: 'Nunito',
      color: Colors.neutral_600,
      fontSize: 18,
      fontWeight: 700,
    }}
  >
    {text}
  </span>
);
