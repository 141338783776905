import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import dotsVerticalIcon from '../../images/dots-vertical.svg';
import { Colors } from '../../themes/colors';

const opacityVariants = {
  show: { opacity: 1, display: 'flex' },
  hide: { opacity: 0, transitionEnd: { display: 'none' } },
};

// Event Bus for managing communication between DotsMenu instances
const eventBus = {
  callbacks: [],
  // Subscribe to the event bus
  subscribe(callback) {
    this.callbacks.push(callback);
  },
  // Unsubscribe from the event bus
  unsubscribe(callback) {
    this.callbacks = this.callbacks.filter((cb) => cb !== callback);
  },
  // Notify all subscribers
  notify() {
    this.callbacks.forEach((callback) => callback());
  },
};

export function DotsMenu({
  children,
  CustomMenuIcon,
  disabled = false,
  position = 'left',
  iconSrc = dotsVerticalIcon,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const menuRef = useRef(null);
  const getTooltipPosition = () => {
    if (position === 'bottom') {
      return { right: '0%', top: '110%' };
    }
    return { top: '-40%', left: '120%' };
  };

  // Toggle the tooltip visibility
  const toggleTooltip = () => {
    if (showTooltip) {
      setShowTooltip(false);
    } else {
      eventBus.notify(); // Notify all other DotsMenu instances to close
      setShowTooltip(true);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    const handleClose = () => setShowTooltip(false);
    document.addEventListener('click', handleClickOutside);
    eventBus.subscribe(handleClose);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      eventBus.unsubscribe(handleClose);
    };
  }, []);

  return (
    <button
      ref={menuRef}
      type="button"
      style={{
        all: 'unset',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();
        toggleTooltip();
      }}
    >
      <div style={styles.menu}>
        {CustomMenuIcon ? (
          <CustomMenuIcon />
        ) : (
          <div style={styles.icon}>
            <img
              src={iconSrc}
              alt="menu_icon"
              style={{
                width: 24,
                height: 24,
              }}
            />
          </div>
        )}
        <motion.div
          initial="hide"
          transition={{ duration: 0.1 }}
          animate={showTooltip ? 'show' : 'hide'}
          variants={opacityVariants}
          style={{
            ...styles.tooltip,
            ...getTooltipPosition(),
            display: showTooltip ? 'block' : 'none',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}
          </div>
        </motion.div>
      </div>
    </button>
  );
}

const styles = {
  menu: {
    position: 'relative',
    display: 'inline-block',
  },
  icon: {
    cursor: 'pointer',
    display: 'flex',
    width: 32,
    height: 32,
  },
  tooltip: {
    width: 'max-content',
    position: 'absolute',
    backgroundColor: Colors.white,
    padding: '5px',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    zIndex: 10,
  },
};
