import React from 'react';
import { motion } from 'framer-motion';

import { Colors } from '../../themes/colors';

const AnimatedCloseCircle = () => (
  <motion.svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.circle
      initial={{ strokeDashoffset: 2 * Math.PI * (35 * 1) }}
      animate={{ strokeDashoffset: 2 * Math.PI * (35 * 0.25) }}
      transition={{ duration: 0.8 }}
      cx={35}
      cy={35}
      r={30}
      fill="transparent"
      stroke={Colors.primary_500}
      strokeDasharray={`${2 * Math.PI * 35} ${2 * Math.PI * 35}`}
      strokeWidth={6}
      strokeLinecap="round"
    />

    <motion.path
      initial={{ opacity: 0, y: 14 }}
      animate={{ opacity: 1, y: 22 }}
      transition={{ duration: 0.8 }}
      style={{ x: 23 }}
      d="M1.95109 19.5365C0.763591 20.757 1.06919 22.6684 2.54139 23.4184C3.50839 23.9097 4.60049 23.7188 5.44419 22.8785C7.53449 20.7986 9.61429 18.7118 11.6994 16.6268C11.8418 16.4844 11.9945 16.3507 12.163 16.1945C12.3279 16.349 12.4668 16.4722 12.597 16.6042C14.2411 18.2465 15.8834 19.8924 17.5275 21.5347C18.0484 22.0556 18.5518 22.5972 19.097 23.0903C20.5275 24.382 22.7359 23.658 23.1126 21.7778C23.3001 20.842 22.9373 20.0868 22.2775 19.4323C20.1873 17.3542 18.1074 15.2656 16.0241 13.1806C15.8834 13.0399 15.7515 12.8889 15.5484 12.6702C15.7723 12.4879 15.9616 12.3594 16.1213 12.1997C18.1682 10.158 20.2081 8.1094 22.2567 6.0712C22.7567 5.5747 23.1126 5.0174 23.1543 4.2969C23.2116 3.3004 22.656 2.3802 21.755 1.9653C20.8036 1.5278 19.7845 1.7014 18.9928 2.4879C16.8609 4.6042 14.7428 6.7344 12.6178 8.8594C12.4755 9.0018 12.3227 9.1354 12.1543 9.2917C11.9755 9.1215 11.8244 8.9827 11.6803 8.8386C9.59519 6.7552 7.50839 4.6719 5.42859 2.5834C4.95809 2.1111 4.41989 1.7899 3.74629 1.7465C2.74799 1.6806 1.82089 2.2257 1.39559 3.1146C0.940691 4.0695 1.11949 5.1129 1.92509 5.9236C4.02749 8.0417 6.14209 10.1511 8.25839 12.257C8.40429 12.4011 8.59869 12.4965 8.83999 12.6615C7.79139 13.7031 6.89209 14.592 5.99629 15.4844C4.64559 16.8316 3.28099 18.1702 1.95109 19.5365Z"
      fill={Colors.primary_500}
      stroke={Colors.primary_500}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
  </motion.svg>
);

export default AnimatedCloseCircle;
