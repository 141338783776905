import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { motion } from 'framer-motion';
import { RadioOption } from '../../registration/components/RadioOption';
import { getUserEducation } from '../../../actions';
import { Colors } from '../../../themes/colors';
import arrow from '../../../images/arrow_right.svg';
import specialityIcon from '../../../images/tooth-icon.svg';
import medicalBriefcaseIcon from '../../../images/medical-briefcase-icon.svg';
import Tooltip from '../../Tooltip';
import { getShouldShowFrontDeskSpecialty } from '../../../growthbook';
import { filterObjectKeys } from '../../../utils/Object';

export default ({
  title,
  defaultSkills,
  professionType,
  skillsSubmit,
  tooltip,
  isAssociateDentist,
}) => {
  const dispatch = useDispatch();

  const userEducation = useSelector((state) => state.user.userEducation);

  const [isRowExpanded, setIsRowExpanded] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(defaultSkills || '');

  const specialtiesAndProcedures = userEducation?.DNT?.specialtiesAndProcedures

  const specialties = useMemo(() => {
    const filteredSpecialties = filterObjectKeys(specialtiesAndProcedures, 'specialty');
    const shouldShowFrontDeskSpecialty = getShouldShowFrontDeskSpecialty();

    if (!shouldShowFrontDeskSpecialty) {
      return Object.keys(filteredSpecialties).filter((specialty) => specialty !== 'specialty_Front Desk');
    }

    return Object.keys(filteredSpecialties);
  }, [professionType, specialtiesAndProcedures]);

  const closePopup = () => {
    setIsRowExpanded(false);
  };

  useEffect(() => {
    setSelectedSkill(defaultSkills);
  }, [defaultSkills]);

  useEffect(() => {
    if (!userEducation) {
      dispatch(getUserEducation());
    }

    window.addEventListener('click', () => (closePopup()));
    return () => window.removeEventListener('click', () => (closePopup()));
  }, [dispatch, userEducation]);

  const handleSkillsChange = (code) => {
    setSelectedSkill(code);
    skillsSubmit(code);
    setIsRowExpanded(true);
  };

  const collapsableVariants = {
    open: {
      maxHeight: 900,
      opacity: 1,

    },
    closed: {
      maxHeight: 0,
      opacity: 0,
    },
  };

  const arrowVariants = {
    open: {
      rotate: 90,
    },
    closed: {
      rotate: 0,
    },
  };

  return (
    <>
      <div
        className="row_container"
        style={{ borderBottom: `1px solid ${Colors.neutral_100}` }}
        onClick={(event) => {
          event.stopPropagation();
          setIsRowExpanded(!isRowExpanded);
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              alt="icon"
              src={isAssociateDentist ? medicalBriefcaseIcon : specialityIcon}
              style={{
                width: isAssociateDentist ? 20 : 24,
                height: isAssociateDentist ? 20 : 24,
                marginRight: 8,
              }}
            />
            <p className="date">
              {title}
              {!!tooltip && (
                <span
                  style={{
                    marginLeft: '5px',
                    position: 'relative',
                    top: '5px',
                  }}
                >
                  <Tooltip
                    content={tooltip}
                  />
                </span>
              )}
            </p>
          </div>
          <p
            style={{
              fontFamily: 'Nunito',
              color: Colors.neutral_600,
              textTransform: 'capitalize',
            }}
          >
            {selectedSkill?.split('_')?.[1] || 'General'}
          </p>
        </div>
        <div>
          <motion.img
            initial="closed"
            animate={isRowExpanded ? 'open' : 'closed'}
            transition={{ duration: 0.3 }}
            variants={arrowVariants}
            alt="arrow"
            src={arrow}
            style={{ marginLeft: 30, marginRight: -30 }}
          />
        </div>
      </div>
      <motion.div
        initial="closed"
        animate={isRowExpanded ? 'open' : 'closed'}
        transition={{ duration: 0.4 }}
        style={{ overflow: 'hidden' }}
        variants={collapsableVariants}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginRight: 75,
            marginTop: 20,
          }}
        >
          <div style={{ alignItems: 'flex-start', width: 250 }}>
            {specialties.map((code) => (
              <div style={{ marginBottom: 10 }}>
                <RadioOption
                  onClick={() => handleSkillsChange(code)}
                  text={code.split('_')?.[1]}
                  checked={code === selectedSkill}
                />
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </>
  );
};
