import moment from 'moment';
import { paymentHistoryFilterOptions } from '../../../../constants';
import { Colors } from '../../../../themes/colors';
import { StatusEnum } from '../../../../utils/Enum';
import { growthbook } from '../../../../growthbook';

export const getJobStatus = (status) => {
  if (status === 'cancelled') {
    return {
      label: StatusEnum.cancelled,
      color: Colors.warning_700,
    };
  }

  if (status === 'expired') {
    return {
      label: StatusEnum.expired,
      color: Colors.warning_700,
    };
  }

  if (status === 'complete' || status === 'pay-out' || status === 'past') {
    return {
      label: StatusEnum.completed,
      color: Colors.neutral_400,
    };
  }

  if (status === 'filled') {
    return {
      label: StatusEnum.scheduled,
      color: Colors.secondary_500,
    };
  }

  if (status === 'open') {
    return {
      label: StatusEnum.open,
      color: Colors.success_500,
    };
  }

  if (status === 'today') {
    return {
      label: StatusEnum.today,
      color: Colors.primary_500,
    };
  }

  if (status === 'counter' || status === 'pending' || status === 'pending-counter') {
    return {
      label: StatusEnum.pending,
      color: Colors.error_450,
    };
  }

  return {
    color: Colors.primary_500,
  };
};

export const returnTransactionComponent = (receipt) => {
  const hygienistPay =
    receipt.components?.find(({ label }) => label === 'hygienist-pay')?.amount || 0;
  const tmFee = receipt.components?.find(({ label }) => label === 'tm-fee')?.amount || 0;
  const convinienceFee =
    receipt.components?.find(({ label }) => label === 'convinience-fee')?.amount || 0;
  const employeeOptionFee =
    receipt.components?.find(({ label }) => label === 'peo-fee')?.amount || 0;

  return {
    hygienistPay,
    tmFee,
    convinienceFee,
    employeeOptionFee,
  };
};

export const getReceiptStatusColor = (receipt) => {
  if (growthbook.isOn('should-use-payment-service')) {
    if (receipt.status === 'succeeded' && receipt.paid) {
      return receipt.type === 'debit' ? Colors.success_500 : Colors.primary_500;
    }    
    if (receipt.status === 'failed') {
      return Colors.error_500;
    }
  }

  if (receipt.type === 'credit' && receipt.status !== 'failed') {
    return Colors.primary_500;
  }

  if (receipt.status === 'failed') {
    return Colors.error_500;
  }

  return Colors.success_500;
};

export const getStatusText = (receipt) => {
  if (growthbook.isOn('should-use-payment-service')) {
    if ( receipt.paid && (receipt.status === 'paid' || receipt.status === 'succeeded')) {
      return receipt.type === 'debit' ? 'Succeeded' : 'Refunded';
    } if (receipt.status === 'failed') {
      return 'Failed';
    } 

  }
  return receipt.type === 'credit' && receipt.status !== 'failed' ? 'Refunded' : receipt.status;
};

export const getFilterDateRage = (filter) => {
  let dateStart = null;
  let dateEnd = null;

  if (filter === paymentHistoryFilterOptions.CURRENT_MONTH) {
    // first day of month until today
    dateStart = moment().startOf('month').format('YYYY-MM-DD');
    dateEnd = moment().format('YYYY-MM-DD');
  } else if (filter === paymentHistoryFilterOptions.LAST_MONTH) {
    // first day of last month until last day of last month
    dateStart = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    dateEnd = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
  } else if (filter === paymentHistoryFilterOptions.LAST_3_MONTHS) {
    // first day of 3 months ago until last day of last month
    dateStart = moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD');
    dateEnd = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
  } else if (filter === paymentHistoryFilterOptions.LAST_6_MONTHS) {
    // first day of 6 months ago until last day of last month
    dateStart = moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD');
    dateEnd = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
  } else if (filter === paymentHistoryFilterOptions.YEAR_TO_DATE) {
    // first day of year until today
    dateStart = moment().startOf('year').format('YYYY-MM-DD');
    dateEnd = moment().format('YYYY-MM-DD');
  } else if (filter === paymentHistoryFilterOptions.LAST_YEAR) {
    // first day of last year until last day of last year
    dateStart = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
    dateEnd = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
  }

  return { dateStart, dateEnd };
};
