import { isPartialShift } from '../../../../../commonComponents/CommonFunctions';
import history from '../../../../../history';
import arrowRight from '../../../../../images/arrow_right.svg';
import { Colors } from '../../../../../themes/colors';
import { PartialShift } from '../../PartialShift';

export const Footer = ({ item, url }) => {
  const isAvailableForPartialShift = isPartialShift(item);
  
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: isAvailableForPartialShift ? 'space-between' : 'flex-end',
      }}
    >
      {isAvailableForPartialShift && (
        <PartialShift />
      )}
      <button
        type="button"
        style={{
          all: 'unset',
          cursor: 'pointer',
          fontFamily: 'Nunito',
          fontWeight: 700,
          fontSize: 18,
          color: Colors.secondary_500,
          display: 'flex',
          flexDirection: 'row',
          gap: 8,
        }}
        onClick={() => history.push(url)}
      >
        More Details
        <img
          src={arrowRight}
          style={{
            objectFit: 'contain',
          }}
          alt="arrow_right"
        />
      </button>
    </div>
  );
};
