import { showProfessionalAttendanceBadges } from '../../../../../growthbook';
import { Colors } from '../../../../../themes/colors';
import AttendanceBadge from '../../../../candidates/AttendanceBadge';
import MultiProfessionalBadges from '../../../../candidates/MultiProfessionalBadges';

export const Divider = ({ badges, currentRank }) => {
  const renderAttendanceBadges = () => {
    if (badges) {
      return <MultiProfessionalBadges badges={badges} disabled isPreview showPriorityOnly />;
    }

    return <AttendanceBadge currentRank={currentRank} width={26} height={26} noLabel disabled />;
  };

  return (
    <div
      style={{
        marginLeft: 8,
        marginRight: 8,
      }}
    >
      <div
        style={{
          backgroundColor: Colors.neutral_300,
          width: 'auto',
          height: 1,
        }}
      />

      {showProfessionalAttendanceBadges() && (
        <div
          style={{
            position: 'absolute',
            right: 0,
            transform: 'translateY(-50%)',
            paddingRight: 10,
            marginRight: 16,
          }}
        >
          {renderAttendanceBadges()}
        </div>
      )}
    </div>
  );
};
