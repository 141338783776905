import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../themes/office_info.scss';

import {
  EDIT_USER,
  EDIT_USER_OFFICE_INFO,
  GET_ALL_SKILL_CATEGORIES_ACTION,
  GET_ALL_SOFTWARES_ACTION,
  editUserOfficeInfo,
  fetchPartnershipOrganizations,
  getAllSkillCategories,
  getAllSoftwares,
  hidePartnershipCodeSuccessPopup,
  verifyPartnershipCode, getOfficeEducation,
} from '../actions';
import { createLoadingSelector } from '../api/selectors';
import BackButton from '../commonComponents/BackButton';
import Button from '../commonComponents/Button';
import CardSpinner from '../commonComponents/CardSpinner';
import Checkbox from '../commonComponents/Checkbox';
import Checkcircle from '../commonComponents/Checkcircle';
import InputSelect from '../commonComponents/InputSelect';
import browserHistory from '../history';
import confirmCircle from '../images/confirm-circle.svg';
import greenCircleCheck from '../images/green-circle-check.svg';
import logoDarkIcon from '../images/logos/logoDarkIcon.svg';
import arrowDown from '../images/thinArrowDown.svg';
import { Colors } from '../themes/colors';
import Tooltip from './Tooltip';
import { RadioOption } from './registration/components/RadioOption';
import ConfirmPopup from './shared/ConfirmPopup';
import { filterObjectKeys } from '../utils/Object';

const partnershipCodeVariants = {
  show: {
    opacity: 1,
    maxHeight: 200,
  },
  hide: {
    opacity: 0,
    maxHeight: 0,
  },
};

const timePerPatientOptions = ['N/A', '30 min', '45 min', '1 hr', '1.5 hr', '2+ hr'];

const loadingSelector = createLoadingSelector([
  EDIT_USER_OFFICE_INFO,
  GET_ALL_SOFTWARES_ACTION,
  GET_ALL_SKILL_CATEGORIES_ACTION,
  EDIT_USER,
]);

export default function OfficeInfo() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const selectedChildOffice = useSelector((state) => state.user.selectedChildOffice);
  const officeEducation = useSelector((state) => state.user.officeEducation);
  const educationInfo = selectedChildOffice?.office_info?.education;

  const allSkillCategories = useSelector((state) => state.user.allSkillCategories);
  const partnershipOrganizations = useSelector((state) => state.user.partnershipOrganizations);
  const showPartnershipCodeSuccess = useSelector((state) => state.user.showPartnershipCodeSuccess);

  const [selectedSpecialty, setSelectedSpecialty] = useState(
    Object.keys(educationInfo?.specialtiesAndProcedures || {})[0] || 'specialty_General Dentistry',
  );
  const [softwares, setSoftwares] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [isPrivatePracticeOrDSO, setIsPrivatePracticeOrDSO] = useState('');
  const [timePerPatientRDH, setTimePerPatientRDH] = useState('');
  const [timePerPatientDA, setTimePerPatientDA] = useState('');
  const [timePerPatientDN, setTimePerPatientDN] = useState('');
  const [partnershipOrganization, setPartnershipOrganization] = useState('');
  const [partnershipCode, setPartnershipCode] = useState('');
  const [isAssistedHygiene, setIsAssistedHygiene] = useState(undefined);
  const isSubmitDisabled =
    softwares.length === 0 ||
    insurances.length === 0 ||
    isPrivatePracticeOrDSO === '' ||
    timePerPatientRDH === '' ||
    timePerPatientDA === '' ||
    timePerPatientDN === '' ||
    isAssistedHygiene === undefined ||
    (partnershipOrganization && !selectedChildOffice?.partnerReferral);

  const allInsuranceProviders =
    allSkillCategories?.find((item) => item.code === 'category-insurance-provider')?.skills ?? [];

  useEffect(() => {
    dispatch(getAllSoftwares());
    dispatch(getAllSkillCategories());
    dispatch(fetchPartnershipOrganizations());
    dispatch(getOfficeEducation());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedChildOffice?.office_info) {
      const updatedInsurances = selectedChildOffice.office_info.insurances?.map((value) => {
        const selectedInsurance = allInsuranceProviders?.find(({ code }) => code === value);

        if (selectedInsurance) {
          return selectedInsurance.name;
        }

        return value;
      });

      setSoftwares(selectedChildOffice.office_info.education?.softwares || []);
      setInsurances(updatedInsurances || []);
      setIsPrivatePracticeOrDSO(selectedChildOffice.office_info.officeType || '');
      setTimePerPatientRDH(selectedChildOffice.office_info.timePerPatientRDH);
      setTimePerPatientDA(selectedChildOffice.office_info.timePerPatientDA);
      setTimePerPatientDN(selectedChildOffice.office_info.timePerPatientDN);
      setIsAssistedHygiene(selectedChildOffice.office_info.isAssistedHygiene);
    }

    const partnerReferral = selectedChildOffice?.partnerReferral?.partnershipCode;

    if (partnerReferral) {
      setPartnershipCode(partnerReferral?.code);
      setPartnershipOrganization(partnerReferral?.organization);
    }
  }, [selectedChildOffice]);

  const handleSubmitButton = () => {
    const jobId = browserHistory.location?.state?.jobId;

    const data = {
      education: {
        specialtiesAndProcedures: {
          [selectedSpecialty]: [],
        },
        softwares,
      },
      timePerPatientRDH,
      timePerPatientDA,
      timePerPatientDN,
      officeType: isPrivatePracticeOrDSO,
      is_info_asked: true,
      isAssistedHygiene,
    };

    dispatch(editUserOfficeInfo({ data, insurances, jobId }));
  };

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container global_font">
      <div className="floating_container">
        <div
          style={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0 100px',
          }}
        >
          <div style={{ position: 'absolute', left: 30 }}>
            <BackButton />
          </div>

          <img src={logoDarkIcon} style={{ width: 40, marginTop: 50 }} alt="logo_dark_icon" />

          <h1 className="f-dark" style={{ textAlign: 'center' }}>
            Additional Office Information
          </h1>

          <div>
            <div style={{ margin: '0 180px' }}>
              <h3 className="f-dark">Office name</h3>
              <p style={{ ...Styles.regularText, marginTop: -10, marginBottom: 30 }}>
                {selectedChildOffice?.office_name}
              </p>

              <h3 className="f-dark">Are you a private practice or DSO? </h3>
              <button
                type="button"
                style={{
                  all: 'unset',
                  display: 'flex',
                  cursor: 'pointer',
                  marginBottom: 22,
                  alignItems: 'center',
                }}
                onClick={() => setIsPrivatePracticeOrDSO('private_practice_independent')}
              >
                <Checkcircle checked={isPrivatePracticeOrDSO === 'private_practice_independent'} />

                <p className="f-dark" style={{ margin: '0px 0px 0px 22px' }}>
                  Private Practice (Independent Office)
                </p>
              </button>

              <button
                type="button"
                style={{
                  all: 'unset',
                  display: 'flex',
                  cursor: 'pointer',
                  marginBottom: 22,
                  alignItems: 'center',
                }}
                onClick={() => setIsPrivatePracticeOrDSO('private_practice_multi')}
              >
                <Checkcircle checked={isPrivatePracticeOrDSO === 'private_practice_multi'} />

                <p className="f-dark" style={{ margin: '0px 0px 0px 22px' }}>
                  Private Practice (Part of a Multi-Office Group)
                </p>
              </button>

              <button
                type="button"
                style={{ all: 'unset', display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                onClick={() => setIsPrivatePracticeOrDSO('dso')}
              >
                <Checkcircle checked={isPrivatePracticeOrDSO === 'dso'} />

                <p className="f-dark" style={{ margin: '0px 0px 0px 22px' }}>
                  DSO
                </p>
              </button>
            </div>

            <div style={{ margin: '0 180px' }}>
              <h3 className="f-dark">What is your area of primary practice? </h3>

              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 12 }}
              >
                {Object.keys(filterObjectKeys(officeEducation?.specialtiesAndProcedures, 'specialty') || {}).map((code) => (
                  <RadioOption
                    onClick={() => setSelectedSpecialty(code)}
                    text={code.split('_')?.[1]}
                    checked={code === selectedSpecialty}
                  />
                ))}
              </div>
            </div>

            <div style={{ margin: '0 140px' }}>
              <h3 className="f-dark" style={{ marginTop: 30 }}>
                Select which Dental Association or State this office is affiliated with, if
                applicable.
                <span
                  style={{
                    marginLeft: '5px',
                    position: 'relative',
                    top: '5px',
                  }}
                >
                  <Tooltip
                    content="GoTu is strategically partnered with various Dental Associations. If you are affiliated with any of the following associations, you may be eligible to have your booking fee refunded on your first completed shift." />
                </span>
              </h3>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <InputSelect
                  value={partnershipOrganization}
                  setValue={(value) => setPartnershipOrganization(value)}
                  options={[
                    ...partnershipOrganizations.map((item) => ({ title: item, value: item })),
                  ]}
                />
              </div>
              {partnershipOrganization ? (
                <motion.div
                  initial={selectedChildOffice?.partnerReferral ? 'show' : 'hide'}
                  animate={partnershipOrganization ? 'show' : 'hide'}
                  variants={partnershipCodeVariants}
                  transition={{ duration: 0.3 }}
                >
                  <h3 className="f-dark" style={{ marginTop: 30 }}>
                    Partner Discount Code:
                  </h3>

                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      className="input-field"
                      type="text"
                      value={partnershipCode}
                      placeholder="Enter Code"
                      onChange={(e) => setPartnershipCode(e.target.value)}
                      disabled={selectedChildOffice?.partnerReferral}
                      style={{ width: '100%', marginBottom: 0 }}
                    />

                    {selectedChildOffice?.partnerReferral && (
                      <img
                        src={greenCircleCheck}
                        style={{
                          position: 'absolute',
                          left: 'calc(100% + 8px)',
                        }}
                        alt="green_circle_check"
                      />
                    )}
                  </div>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: 46,
                    }}
                  >
                    {!selectedChildOffice?.partnerReferral && (
                      <button
                        type="button"
                        className="green-button"
                        style={{
                          alignSelf: 'center',
                          width: 150,
                          margin: 0,
                          backgroundColor: Colors.primary_500,
                        }}
                        onClick={() =>
                          dispatch(verifyPartnershipCode({
                            code: partnershipCode,
                            organization: partnershipOrganization,
                          }))
                        }
                      >
                        <p className="white-text-18" style={{ margin: 0 }}>
                          Apply Code
                        </p>
                      </button>
                    )}
                  </div>
                </motion.div>
              ) : (
                <div />
              )}
            </div>
            <div style={{ margin: '0 100px' }}>
              <h3 style={{ marginTop: 40, marginBottom: 10 }} className="f-dark">
                Select the expected “time per patient” for each profession:
              </h3>

              <div style={{ display: 'flex', flexDirection: 'row', gap: 32 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3 style={{ fontSize: 16, fontWeight: 400, color: Colors.neutral_600 }}>Dental
                    Hygienist:</h3>
                  <InputSelect
                    value={timePerPatientRDH}
                    setValue={(value) => setTimePerPatientRDH(value)}
                    options={timePerPatientOptions.map((item) => ({ title: item, value: item }))}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3 style={{ fontSize: 16, fontWeight: 400, color: Colors.neutral_600 }}>Dental
                    Assistant:
                  </h3>
                  <InputSelect
                    value={timePerPatientDA}
                    setValue={(value) => setTimePerPatientDA(value)}
                    options={timePerPatientOptions.map((item) => ({ title: item, value: item }))}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3 style={{ fontSize: 16, fontWeight: 400, color: Colors.neutral_600 }}>Associate
                    Dentist:
                  </h3>
                  <InputSelect
                    value={timePerPatientDN}
                    setValue={(value) => setTimePerPatientDN(value)}
                    options={timePerPatientOptions.map((item) => ({ title: item, value: item }))}
                  />
                </div>
              </div>

              <h3 style={{ marginTop: 40, marginBottom: 10 }} className="f-dark">
                Is assisted hygiene available at your office for temp hygienist?
              </h3>

              <button
                type="button"
                style={{
                  all: 'unset',
                  display: 'flex',
                  cursor: 'pointer',
                  marginBottom: 16,
                  alignItems: 'center',
                }}
                onClick={() => setIsAssistedHygiene(true)}
              >
                <Checkcircle checked={isAssistedHygiene} />

                <p className="f-dark" style={{ margin: '0px 0px 0px 22px' }}>
                  Yes
                </p>
              </button>

              <button
                type="button"
                style={{ all: 'unset', display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                onClick={() => setIsAssistedHygiene(false)}
              >
                <Checkcircle checked={isAssistedHygiene === false} />

                <p className="f-dark" style={{ margin: '0px 0px 0px 22px' }}>
                  No
                </p>
              </button>
            </div>
          </div>

          <h3 style={{ margin: '40px 0 30px 0' }} className="f-dark">
            What insurance provider does your office use?
            <span
              style={{
                marginLeft: '5px',
                position: 'relative',
                top: '5px',
              }}
            >
              <Tooltip
                content="GoTu now allows you to post temp shifts looking for a Associate Dentist. To be able to post those shifts, you will be required to indicate what insurance your office is covered under. This is to help us find Associate Dentists who are covered under the same insurance as your office." />
            </span>
          </h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 18,
              flexWrap: 'wrap',
              width: 'auto',
            }}
          >
            {officeEducation?.insurances?.map((name) => (
              <button
                type="button"
                onClick={() => {
                  if (insurances.includes(name)) {
                    setInsurances(insurances.filter((provider) => provider !== name));
                  } else {
                    setInsurances([...insurances, name]);
                  }
                }}
                style={{
                  all: 'unset',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: 'calc(25% - 18px)',
                  height: 30,
                  cursor: 'pointer',
                }}
              >
                <Checkbox checked={insurances.includes(name)} />
                <p style={{ ...Styles.regularText, marginLeft: 20 }}>{name}</p>
              </button>
            ))}
          </div>

          <h3 style={{ margin: '40px 0 30px 0' }} className="f-dark">
            What software does your office use?
          </h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 18,
              flexWrap: 'wrap',
              width: 'auto',
            }}
          >
            {officeEducation?.softwares?.map((name) => (
              <button
                type="button"
                onClick={() => {
                  if (softwares.includes(name)) {
                    setSoftwares(softwares.filter((software) => software !== name));
                  } else {
                    setSoftwares([...softwares, name]);
                  }
                }}
                style={{
                  all: 'unset',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: 'calc(25% - 18px)',
                  height: 30,
                  cursor: 'pointer',
                }}
              >
                <Checkbox checked={softwares.includes(name)} />
                <p style={{ ...Styles.regularText, marginLeft: 20 }}>{name}</p>
              </button>
            ))}
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '70px 0',
            }}
          >
            <Button text="Submit" onClick={handleSubmitButton} disabled={isSubmitDisabled} />
          </div>
        </div>
      </div>

      {showPartnershipCodeSuccess && (
        <ConfirmPopup
          title="Partner Discount Code Accepted"
          description={`
            Congratulations! As a partner of 
            ${selectedChildOffice?.partnerReferral?.partnershipCode?.organization}, 
            your booking fees will be refunded for your first completed shift on GoTu.`}
          descriptionStyle={{ width: '86%', marginBottom: 50 }}
          rightButtonText="Ok"
          rightButtonAction={() => dispatch(hidePartnershipCodeSuccessPopup())}
          Icon={() => <img src={confirmCircle} style={{ marginBottom: 20 }} alt="confirm_circle" />}
        />
      )}
    </div>
  );
}

const Styles = {
  regularText: {
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    fontSize: 18,
  },
  selectField: {
    width: '100%',
    backgroundColor: Colors.neutral_50,
    border: 'none',
    padding: '0 20px',
    borderRadius: 5,
    appearance: 'none',
    background: `url('${arrowDown}') no-repeat 96% center #F4F8FA`,
  },
  textField: {
    maxWidth: '100%',
    width: '100%',
    backgroundColor: Colors.neutral_50,
    border: 'none',
    padding: '10px 20px',
    margin: 0,
    borderRadius: 5,
    boxShadow: 'none',
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    fontSize: 16,
  },
};
