import { useEffect, useState } from 'react';
import greenCircleCheck from '../../images/green-circle-check.svg';
import warningIconRed from '../../images/warning_alert.svg';
import warningIconOrange from '../../images/warning_alert_orange.svg';
import { Colors } from '../../themes/colors';

export const useRate = ({ averagePrice, defaultValue }) => {
  const averageRate = averagePrice?.average.toFixed(2).toString() || '40.00';
  const [selectedRate, setSelectedRate] = useState(averageRate);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [industrialAverage, setIndustrialAverage] = useState(100.0);
  const [industrialAverageColor, setIndustrialAverageColor] = useState(Colors.success_500);
  const rates = [];
  const minPrice = averagePrice?.min || 28;
  const maxPrice = averagePrice?.max || 72;

  for (let i = minPrice; i <= maxPrice; i += 1) {
    rates.push(i);
  }

  const industrialAverageIcon = {
    [Colors.success_500]: greenCircleCheck,
    [Colors.warning_700]: warningIconOrange,
    [Colors.error_500]: warningIconRed,
  };

  const getIndustrialAverageColor = (percentage) => {
    if (percentage >= 0.9 && percentage <= 2) {
      return Colors.success_500;
    }

    if (percentage >= 0.8 && percentage < 0.9) {
      return Colors.warning_700;
    }

    return Colors.error_500;
  };

  useEffect(() => {
    setSelectedRate(averageRate);
    setIndustrialAverage(100.0);
    setIndustrialAverageColor(Colors.success_500)
    setDisabledBtn(false);
  }, [averageRate]);

  const getWarningMessage = () => {
    if (selectedRate < rates[0]) {
      return (
        <h3
          className="bold global_font f-dark"
          style={{ color: Colors.error_500, fontSize: 16, textAlign: 'center' }}
        >
          Invalid value, too low
        </h3>
      );
    }

    if (selectedRate > rates[rates.length - 1]) {
      return (
        <h3
          className="bold global_font f-dark"
          style={{ color: Colors.error_600, fontSize: 16, textAlign: 'center' }}
        >
          Invalid value, above maximum
        </h3>
      );
    }

    return (
      <>
        <h3
          className="bold global_font f-dark"
          style={{
            color: industrialAverageColor,
            marginRight: 5,
            fontSize: 16,
            textAlign: 'center',
          }}
        >
          {selectedRate ? `${industrialAverage}%` : '0%'}
        </h3>

        <h3 className="bold global_font f-dark" style={{ color: Colors.neutral_600, fontSize: 16 }}>
          of Industry average
        </h3>
      </>
    );
  };

  const setOfferRate = (newRate) => {
    setSelectedRate(newRate);

    if (newRate < rates[0] || newRate > rates[rates.length - 1]) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }

    const percentage = newRate / averageRate;

    setIndustrialAverage(parseFloat(percentage * 100).toFixed(1));
    setIndustrialAverageColor(getIndustrialAverageColor(percentage));
  };

  const currentIndex = rates.reduce((closestIndex, value, index) => {
    const currentValue = Math.abs(Math.ceil(value - selectedRate));
    const closestValue = Math.abs(Math.ceil(rates[closestIndex] - selectedRate));

    return currentValue < closestValue ? index : closestIndex;
  }, 0);

  useEffect(() => {
    if (defaultValue) {
      setOfferRate(defaultValue);
    }
  }, []);

  return {
    selectedRate,
    industrialAverageIcon,
    industrialAverageColor,
    rates,
    getWarningMessage,
    setOfferRate,
    currentIndex,
    disabledBtn,
    setSelectedRate,
  };
};
