import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: "tempmeedev.firebaseapp.com",
  databaseURL: "https://tempmeedev.firebaseio.com",
  projectId: "tempmeedev",
  storageBucket: "tempmeedev.appspot.com",
  messagingSenderId: "836175226934",
  appId: "1:836175226934:web:5211ef5ffc3211bd909097",
  measurementId: "G-QDV0ZKYKRM"
};

const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);

// Fetch and activate Remote Config values
fetchAndActivate(remoteConfig);
  
export { remoteConfig };
