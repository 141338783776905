import { apiMethodsConst } from '../services/api';

const initialState = {
  allAlerts: [],
  isFinalPage: false,
  isActiveRequest: false,
  totalAlerts: 0,
  searchParams: {
    page: 1,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ALERTS_ACTION_REQUEST':

      return {
        ...state,
        searchParams: action.payload,
        isActiveRequest: true,
      };

    case apiMethodsConst.GET_ALERTS_REQUEST:
      const { total, page, response } = action.payload;
      const mergedOffers = [...state.allAlerts, ...response];

      return {
        ...state,
        allAlerts: page === 1 ? response : mergedOffers,
        isFinalPage:
          response.length === 0
          || response.length < state.searchParams.limit
          || response.length === state.totalAlerts,
        totalAlerts: total,
        isActiveRequest: false,
      };
    case apiMethodsConst.DELETE_ALERTS_REQUEST:
      return {
        ...state,
        allAlerts: [],
      };
    case apiMethodsConst.DELETE_ONE_ALERT_REQUEST:
      const index = state.allAlerts.findIndex((alert) => alert.id === action.payload);
      if (index === -1) {
        return state;
      }
      return {
        ...state,
        allAlerts: [...state.allAlerts.slice(0, index), ...state.allAlerts.slice(index + 1)],
      };
    default:
      return state;
  }
};
