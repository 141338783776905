import '../../../themes/global.scss';
import '../../../themes/home.scss';

import { motion } from 'framer-motion';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSwitch from 'react-switch';
import {
  FEEDBACK_REQUIRED,
  getAllProfessionals,
  getAllSkillCategories,
  getUserEducation,
  getUserOvertimeLimits,
} from '../../../actions';
import Button from '../../../commonComponents/Button';
import MobileAppModal from '../../../commonComponents/MobileAppModal';
import history from '../../../history';
import WarningIcon from '../../../images/icons/Warning';
import { Colors } from '../../../themes/colors';
import ConfirmPopup from '../../shared/ConfirmPopup';

import BackButton from '../../../commonComponents/BackButton';
import CalendarPopup from '../../../commonComponents/CalendarPopup';
import CardSpinner from '../../../commonComponents/CardSpinner';
import ShowStartTime from '../../../commonComponents/showStartTime';
import { getAutoFillInfo } from '../../../constants';
import Tooltip from '../../Tooltip';
import ModalRatePopup from '../../ModalRatePopup/ModalRatePopup';
import WarningPopup from '../WarningPopup';
import { workerClassificationTooltipDescription } from '../constants';
import isHoliday from '../isHoliday';
import ProfessionPopup from './ProfessionPopup';
import SkillsPopup from './SkillsPopup';

import arrow from '../../../images/arrow_right.svg';
import autoFillIcon from '../../../images/autofill-icon.svg';
import breakIcon from '../../../images/break-icon.svg';
import briefcaseIcon from '../../../images/briefcase.svg';
import calendar from '../../../images/calendar-icon.svg';
import endClock from '../../../images/end-clock.svg';
import houseIcon from '../../../images/house-icon.svg';
import rateIcon from '../../../images/rate-icon.svg';
import startClock from '../../../images/start-clock.svg';
import userIcon from '../../../images/user-icon.svg';
import {
  getShouldAllowOfficeToPostProcedure,
  getShouldOfficeShowEfda,
} from '../../../growthbook';
import { getAllProfessionTypeOptions } from '../../../utils/DentistUtils';
import { UserProfession, UserSubProfession } from '../../../enums/UserProfession';
import { EnumHelper } from '../../../utils/EnumHelper';
import useLoading from '../../../hooks/useLoading';
import { ProcedureCollapsibleSelection } from './ProcedureCollapsibleSelection';
import { UserType } from '../../../enums/UserType';
import { EducationUtils } from '../../../utils/EducationUtils';

export default function JobPost(props) {
  const dispatch = useDispatch();

  const job = props?.history?.location?.state?.job || {};
  const {
    job_datetime: jobDateTime,
    subProfession,
    profession,
    specialty,
    local_start_time: localStartTime,
    local_end_time: localEndTime,
    rate,
    lunch_break_time: jobLunchBreakTime,
    is_lunch_break_paid: isJobLunchBreakPaid,
    autoFill: jobAutofill,
    procedures,
  } = job || {};

  const initialDateFromParams = jobDateTime ?? history.location?.state?.date;

  const averageRating = useSelector(
    (state) => state.job.averageRating || { average: 49, max: 100, min: 18 },
  );
  const userEducation = useSelector((state) => state.user.userEducation);

  const allProfessions = useSelector((state) => state.user.allProfessions);
  const showEfda = getShouldOfficeShowEfda();
  const allProfessionOptions = getAllProfessionTypeOptions(allProfessions, { showEfda });

  const parentUser = useSelector((state) => state.user);
  const user = useSelector(
    (state) => state.user.selectedChildOffice,
  );

  const {
    office_info: { education } = {},
    workerClassification,
    overtimeLimits: _overtimeLimits,
    office_name: officeName,
  } = user || {};

  const defaultSpecialty = specialty ?? Object.keys(education?.specialtiesAndProcedures || {})[0] ?? 'specialty_General Dentistry';
  const defaultRate = rate ? `${rate}.00` : 0;
  const defaultProfessionType = UserSubProfession.getName(subProfession) ?? UserProfession.getName(profession) ?? 'Dental Hygienist';

  const { isLoading } = useLoading([FEEDBACK_REQUIRED]);

  // FIXME(lucky): refactor to save profession code instead of name
  const [professionType, setProfessionType] = useState(defaultProfessionType);

  // original profession type code, including sub-profession
  const professionTypeCode = UserProfession.getCode(professionType);

  const specialtiesAndProcedures = userEducation?.[professionTypeCode]?.specialtiesAndProcedures;

  // use profession. normalized sub-profession to profession parent
  const professionTypeNormalizedCode = UserProfession.getNormalizedCode(professionTypeCode);

  const [skills, setSkills] = useState(defaultSpecialty);
  const [enableAutoFill, setEnableAutoFill] = useState(jobAutofill ?? false);
  const [offerStartDate, setOfferStartDate] = useState(initialDateFromParams ?? null);
  const [offerStartTime, setOfferStartTime] = useState(localStartTime ? moment(localStartTime, 'hh:mm aa') : null);
  const [offerEndTime, setOfferEndTime] = useState(localEndTime ? moment(localEndTime, 'hh:mm aa') : null);
  const [offerRate, setOfferRate] = useState(defaultRate);
  const [lunchBreakTime, setLunchBreakTime] = useState(jobLunchBreakTime?.padStart(2, '0') ?? '');
  const [isLunchBreakPaid, setIsLunchBreakPaid] = useState(isJobLunchBreakPaid ?? false);
  const [content, setContent] = useState(null);
  const [resultFromModal, setResultFromModal] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedProcedureIndices, setSelectedProcedureIndices] = useState([]);

  const classificationType = workerClassification?.classificationType;
  const overtimeLimits = _overtimeLimits?.[classificationType];

  // popups
  const [showRatePopup, setShowRatePopup] = useState(false);
  const [showCalendarPopup, setShowCalendarPopup] = useState(false);

  const [showStartTime, setShowStartTime] = useState(false);
  const [confirmPostOnHoliday, setConfirmPostOnHoliday] = useState(false);
  const [showOvertimeLimitPopup, setShowOvertimeLimitPopup] = useState(false);

  const allSkillCategories = useSelector((state) => state.user.allSkillCategories);

  // saving if profession change is ever made to prevent rate reset to default job passed
  const professionTypeChangedRef = useRef(false);

  const availableProcedures = useMemo(() => EducationUtils.getProceduresOfProfession({
    specialtiesAndProcedures,
    profession: UserProfession.getNormalizedCode(professionTypeCode),
    specialty: skills,
  }), [skills, professionTypeCode, specialtiesAndProcedures]);

  // updates selected procedures when available procedures is available
  // or not empty
  //
  // this, to handle `userEducation` might still load when editing occurs
  useEffect(() => {
    if (availableProcedures && availableProcedures.length > 0
      && procedures && procedures.length > 0) {
      const defaultProcedureIndices = procedures
        .map((procedure) => availableProcedures
          .findIndex((currentProcedure) => procedure === currentProcedure))
        .filter((procedureIndex) => procedureIndex > -1);

      setSelectedProcedureIndices(defaultProcedureIndices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableProcedures]);

  useEffect(() => {
    if (!allProfessions || allProfessions?.length === 0) {
      dispatch(getAllProfessionals());
    }

    if (!allSkillCategories?.length) {
      dispatch(getAllSkillCategories());
    }

    if (!_overtimeLimits) {
      dispatch(getUserOvertimeLimits());
    }
    // act as `componentDidMount`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSkills(defaultSpecialty);

    if (professionType === defaultProfessionType && !professionTypeChangedRef.current) {
      setOfferRate(defaultRate);
    } else {
      setOfferRate(0);
      setSelectedProcedureIndices([]);
    }

    professionTypeChangedRef.current = true;

    const professionCode = UserProfession.getNormalizedCode(UserProfession.getCode(professionType));

    dispatch(getUserEducation({ profession: professionCode }));
  }, [professionType]);

  const sendOffer = () => {
    const localDate = moment(offerStartDate).format('yyyy-MM-DD');

    const jobData = {
      localDate,
      localStart: moment(offerStartTime).format('hh:mm a'),
      localEnd: moment(offerEndTime).format('hh:mm a'),
      rate: parseFloat(offerRate),
      break_time: parseInt(lunchBreakTime, 10),
      is_break_paid: isLunchBreakPaid,

      // use profession. normalized if sub-profession is included
      profession: professionTypeNormalizedCode,

      // include sub-profession if it's a member of UserSubProfession
      subProfession: EnumHelper.getObjectKeys(UserSubProfession).includes(professionTypeCode)
        ? professionTypeCode : null,

      procedures: selectedProcedureIndices.map((index) => availableProcedures[index]),

      prefer_type: 'firs_available',
      specialty: skills,
      autoFill: enableAutoFill,
    };

    history.push('/dashboard/guideline', { jobData });
  };

  const startTime = (event) => {
    setOfferStartTime(moment(event, 'hh:mm aa'));
  };

  const endTime = (event) => {
    setOfferEndTime(moment(event, 'hh:mm aa'));
  };

  const breakTime = (event) => {
    setLunchBreakTime(event.time);
    setIsLunchBreakPaid(event.paid);
  };

  const showContent = (type) => {
    if (type === 'start_time') {
      setContent(type);

      setResultFromModal({ result: startTime });
    } else if (type === 'end_time') {
      setContent(type);

      setResultFromModal({ result: endTime });
    } else if (type === 'break') {
      setContent(type);

      setResultFromModal({ result: breakTime });
    }

    setIsModalOpen(true);
  };

  const sendOfferConfirmation = ({ alertOvertime = true }) => {
    const hoursDifference = offerEndTime.diff(offerStartTime, 'hours', true) - (!isLunchBreakPaid ? lunchBreakTime / 60 : 0);

    if (alertOvertime && overtimeLimits && (hoursDifference > overtimeLimits?.daily)) {
      setShowOvertimeLimitPopup(true);
      return;
    }

    if (isHoliday(offerStartDate)) {
      setConfirmPostOnHoliday(true);
    } else {
      sendOffer();
    }
  };

  const showTime = (type) => {
    if (type === 'start_time') {
      setContent(type);
      setResultFromModal({ result: startTime });
    } else if (type === 'end_time') {
      setContent(type);
      setResultFromModal({ result: endTime });
    }

    setShowStartTime(true);
  };

  const renderTimeField = (title, value, contentType, icon) => (
    <div
      className="row_container"
      style={{ borderBottom: `1px solid ${Colors.neutral_100}` }}
      onClick={() => showTime(contentType)}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            alt="icon"
            src={icon}
            style={{ width: 24, height: 24, marginRight: 8 }}
          />
          <p className="date">{title}</p>
        </div>
        <p
          style={{
            fontFamily: 'Nunito',
            color: Colors.neutral_600,
            textTransform: 'capitalize',
          }}
        >
          {value}
        </p>
      </div>
      <div>
        <img
          alt="arrow"
          src={arrow}
          style={{ marginLeft: 30, marginRight: -30 }}
        />
      </div>
    </div>
  );

  const renderCalendarField = (title, value) => (
    <div
      className="row_container"
      style={{ borderBottom: `1px solid ${Colors.neutral_100}` }}
      onClick={() => setShowCalendarPopup(true)}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            alt="icon"
            src={calendar}
            style={{ width: 24, height: 24, marginRight: 8 }}
          />
          <p className="date">{title}</p>
        </div>
        <p
          style={{
            fontFamily: 'Nunito',
            color: Colors.neutral_600,
            textTransform: 'capitalize',
          }}
        >
          {value}
        </p>
      </div>
      <div>
        <img
          alt="arrow"
          src={arrow}
          style={{ marginLeft: 30, marginRight: -30 }}
        />
      </div>
    </div>
  );

  const profRate = averageRating[professionTypeCode];

  const autoFillTooltipDescription = (
    <>
      Professional’s eligibility criteria:
      <br />
      <br />
      {'\u2022  '}
      Has a minimum of 1 year working experience.
      <br />
      {'\u2022  '}
      Has received exceptional performance ratings on over 95% of completed shifts.
      <br />
      {'\u2022  '}
      Has completed at least 3 shifts.
      <br />
      <br />
      Note: You may change your auto-confirm preference at any time by visiting the Shift Details
      page.
    </>
  );

  let btnDisabled;

  if (
    !offerStartDate
    || !offerStartTime
    || !offerEndTime
    || offerRate === 0
    || lunchBreakTime.length === 0
    // || !professionType
  ) {
    btnDisabled = true;
  } else {
    btnDisabled = false;
  }

  if (isLoading) {
    return <CardSpinner />;
  }

  let modalDefault = '';

  if (content === 'start_time') {
    modalDefault = offerStartTime;
  } else if (content === 'end_time') {
    modalDefault = offerEndTime;
  } else if (content === 'break') {
    modalDefault = {
      lunchBreakTime,
      isLunchBreakPaid,
    };
  }

  const opacityVariants = {
    show: { opacity: 1, display: 'flex' },
    hide: { opacity: 0, transitionEnd: { display: 'none' } },
  };

  return (
    <div className="component_container">
      <div className="floating_container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div style={{ width: '95%', marginTop: 30 }}>
            <BackButton />
          </div>

          <h1
            className="global_font"
            style={{
              color: Colors.neutral_600,
              fontSize: 38,
              fontWeight: 700,
              width: '100%',
              textAlign: 'center',
              margin: 0,
              marginBottom: 10,
            }}
          >
            Single-Day Temp
          </h1>

          <div style={{ width: '50%' }}>
            <div
              className="row_container"
              style={{ cursor: 'default', borderBottom: `1px solid ${Colors.neutral_100}` }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    alt="icon"
                    src={houseIcon}
                    style={{ width: 24, height: 24, marginRight: 8 }}
                  />

                  <p
                    className="date"
                    style={{
                      marginRight: 16,
                    }}
                  >
                    Office:
                  </p>
                </div>

                <p
                  style={{
                    fontFamily: 'Nunito',
                    color: Colors.neutral_600,
                  }}
                >
                  {officeName}
                </p>
              </div>
            </div>

            {parentUser.childOffices.length > 0
              && user.isWorkerClassificationEnabled
              && user.workerClassification
              && (
                <div
                  className="row_container"
                  style={{ cursor: 'default', borderBottom: `1px solid ${Colors.neutral_100}` }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        alt="icon"
                        src={briefcaseIcon}
                        style={{ width: 24, height: 24, marginRight: 8 }}
                      />

                      <p
                        className="date"
                        style={{
                          marginRight: 16,
                        }}
                      >
                        Worker Type:

                        <span
                          style={{
                            marginLeft: '5px',
                            position: 'relative',
                            top: '5px',
                          }}
                        >
                          <Tooltip
                            content={workerClassificationTooltipDescription}
                          />
                        </span>
                      </p>
                    </div>

                    <p
                      style={{
                        fontFamily: 'Nunito',
                        color: Colors.neutral_600,
                      }}
                    >
                      {user.workerClassification.classificationType === 'contractor'
                        ? 'Contracted'
                        : 'Employed'}
                    </p>
                  </div>
                </div>
              )}

            {renderCalendarField('Date:',
              !offerStartDate
                ? <span style={{ color: Colors.neutral_300 }}>Date</span>
                : moment(offerStartDate).format('ddd, MMM DD, YYYY'))}

            {allProfessionOptions?.length > 1
              && (
                <ProfessionPopup
                  title="Professional:"
                  value={!professionType
                    ? <span style={{ color: Colors.neutral_300 }}>Profession Type</span>
                    : professionType
                  }
                  icon={userIcon}
                  type="profession"
                  onChanged={(profession) => setProfessionType(profession)}
                  options={allProfessionOptions}
                />
              )}

            <SkillsPopup
              skillsSubmit={(selectedSkills) => {
                setSkills(selectedSkills);
              }}
              title="Shift Specialty:"
              professionType={professionType}
              defaultSkills={skills}
            />

            {
              getShouldAllowOfficeToPostProcedure() && (
                <ProcedureCollapsibleSelection
                  availableProcedures={availableProcedures}
                  selectedProcedureIndices={selectedProcedureIndices}
                  onItemPressed={(index) => {
                    const indices = (() => {
                      const selected = selectedProcedureIndices.includes(index);
                      const collection = selectedProcedureIndices;
                      const maxSelection = 4;

                      if (selected) {
                        return collection
                          .filter((currentIndex) => currentIndex !== index);
                      }

                      if (!(maxSelection && collection.length >= maxSelection)) {
                        return [...collection, index];
                      }

                      return collection;
                    })();

                    setSelectedProcedureIndices(indices);
                  }}
                />
              )
            }

            {renderTimeField(
              'Arrival:',
              !offerStartTime
                ? <span style={{ color: Colors.neutral_300 }}>Time</span>
                : moment(offerStartTime).format('hh:mm a'),
              'start_time',
              startClock,
            )}

            {renderTimeField(
              'Departure:',
              !offerEndTime ?
                <span
                  style={{ color: Colors.neutral_300 }}>Time</span> : moment(offerEndTime).format('hh:mm a'),
              'end_time',
              endClock,
            )}

            <div
              className="row_container"
              style={{ borderBottom: `1px solid ${Colors.neutral_100}` }}
              onClick={() => setShowRatePopup(true)}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    alt="icon"
                    src={rateIcon}
                    style={{ width: 24, height: 24, marginRight: 8 }}
                  />

                  <p
                    className="date"
                    style={{
                      marginRight: 16,
                    }}
                  >
                    Hourly Rate:
                  </p>
                </div>

                <p
                  style={{
                    fontFamily: 'Nunito',
                    color: Colors.neutral_600,
                  }}
                >
                  {offerRate
                    ? `$ ${offerRate}`
                    : <span style={{ color: Colors.neutral_300 }}>$ ⏤.⏤</span>}
                </p>
              </div>
              <div>
                <img
                  alt="arrow"
                  src={arrow}
                  style={{ marginLeft: 30, marginRight: -30 }}
                />
              </div>
            </div>

            <div
              className="row_container"
              style={{ borderBottom: `1px solid ${Colors.neutral_100}` }}
              onClick={() => showContent('break')}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    alt="icon"
                    src={breakIcon}
                    style={{ width: 24, height: 24, marginRight: 8 }}
                  />

                  <p className="date" style={{ marginRight: 16 }}>
                    Break:
                  </p>
                </div>

                <p
                  style={{
                    fontFamily: 'Nunito',
                    color: Colors.neutral_600,
                  }}
                >
                  {lunchBreakTime
                    ? `${lunchBreakTime} min ${isLunchBreakPaid ? '(Paid)' : '(Unpaid)'
                    }`
                    : <span style={{ color: Colors.neutral_300 }}>Time</span>}
                </p>
              </div>
              <div>
                <img
                  alt="arrow"
                  src={arrow}
                  style={{ marginLeft: 30, marginRight: -30 }}
                />
              </div>
            </div>

            <div style={{ borderBottom: `1px solid ${Colors.neutral_100}` }}>
              <div
                className="row_container"
                style={{ cursor: 'default' }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      alt="icon"
                      src={autoFillIcon}
                      style={{
                        width: 24,
                        height: 24,
                        marginLeft: 4,
                        marginRight: 6,
                      }}
                    />

                    <p
                      className="date"
                      style={{
                        marginRight: 16,
                      }}
                    >
                      Auto-Confirm:

                      <span
                        style={{
                          marginLeft: '5px',
                          position: 'relative',
                          top: '5px',
                        }}
                      >
                        <Tooltip
                          content={autoFillTooltipDescription}
                        />
                      </span>
                    </p>
                  </div>
                </div>

                <motion.div
                  animate={getAutoFillInfo(professionType, skills).show ? 'show' : 'hide'}
                  variants={opacityVariants}
                >
                  <ReactSwitch
                    checked={enableAutoFill}
                    onChange={() => setEnableAutoFill(!enableAutoFill)}
                    onColor={Colors.secondary_500}
                    onHandleColor="#fff"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor={Colors.neutral_100}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.35)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={25}
                    width={50}
                    className="react-switch"
                    id="material-switch"
                  />
                </motion.div>
              </div>

              <motion.p
                key={getAutoFillInfo(professionType, skills).message}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                className="global_font"
                style={{
                  color: Colors.neutral_500,
                  fontStyle: 'italic',
                  padding: '0px 40px',
                  marginTop: 0,
                  marginBottom: 25,
                }}
              >
                {getAutoFillInfo(professionType, skills).message}
              </motion.p>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 100,
                marginBottom: 80,
              }}
            >
              <Button
                type="button"
                className="green-button"
                disabled={btnDisabled}
                style={{
                  backgroundColor: btnDisabled ? Colors.primary_200 : Colors.primary_500,
                }}
                onClick={sendOfferConfirmation}
                text="Send Request"
              />
            </div>
          </div>
        </div>
      </div>

      <MobileAppModal
        show={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        height={973}
        defaultValue={modalDefault}
        content={content}
        result={resultFromModal?.result}
      />

      {showStartTime && (
        <ShowStartTime
          content={content}
          result={resultFromModal?.result}
          closeModal={() => setShowStartTime(false)}
          height={973}
          defaultStartTime={offerStartTime}
          defaultEndTime={offerEndTime}
        />
      )}

      {showRatePopup && (
        <ModalRatePopup
          closeModal={() => setShowRatePopup(false)}
          submitRate={(rate) => setOfferRate(rate)}
          averagePrice={profRate}
          defaultValue={offerRate}
        />
      )}

      {showCalendarPopup && (
        <CalendarPopup
          date={offerStartDate}
          closeModal={() => setShowCalendarPopup(false)}
          confirmDateBtn={(date) => setOfferStartDate(date)}
        />
      )}

      {confirmPostOnHoliday && (
        <WarningPopup
          title="Major U.S Holiday Conflict"
          text={
            'Looks like you are trying to post a shift on a\nMajor U.S. Holiday. Please make sure your office\nwill be open on this date!'
          }
          successText="Continue"
          cancelText="Go Back"
          btnSucces={() => {
            sendOffer();
            setConfirmPostOnHoliday(false);
          }}
          btnCancel={() => setConfirmPostOnHoliday(false)}
        />
      )}

      {showOvertimeLimitPopup && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={64} height={64} color={Colors.warning_500} />
            </div>
          )}
          title="Overtime Hours"
          description={`
            The payable hours of this shift exceed your state’s daily 
            overtime limit. Your office will be subject to additional
            charges for any overtime hours worked by a professional.
          `}
          descriptionStyle={{ width: '76%', marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonAction={() => setShowOvertimeLimitPopup(false)}
          rightButtonText="Continue"
          rightButtonAction={() => {
            sendOfferConfirmation({ alertOvertime: false });
            setShowOvertimeLimitPopup(false);
          }}
        />
      )}

    </div>
  );
}
