import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTodayJobs } from '../../../actions';
import browserHistory from '../../../history';

export const useTable = ({ type }) => {
  const dispatch = useDispatch();
  const jobListScrollRef = useRef(null);
  const jobListPagination = useSelector(
    (state) => state.job.dashboardJobListPagination
  );

  const fetchJobList = (data) => {
    dispatch(fetchTodayJobs(data));
  };

  useEffect(() => {
    fetchJobList({ page: 1 });
  }, [type]);

  const handleScrollJobList = () => {
    const jobListRef = jobListScrollRef?.current;
    const userScrollHeight = jobListRef?.scrollHeight - jobListRef?.scrollTop - 20;
    const clientHeight = jobListRef?.clientHeight;

    if (
      userScrollHeight <= clientHeight
      && !jobListPagination.isActiveRequest
      && !jobListPagination.isFinal
    ) {
      fetchJobList({
        page: jobListPagination?.page + 1,
      });
    }
  };

  const btnSelectedJobs = (item) => {
    browserHistory.push(`/dashboard/job/${item.id}`, {
      jobId: item.id,
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollJobList);
    return () => window.removeEventListener('scroll', handleScrollJobList);
  }, [jobListPagination]);

  return {
    onRowClick: btnSelectedJobs,
    jobListScrollRef
  };
};
