import '../../../themes/global.scss';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTodayJobs } from '../../../actions';
import BackButton from '../../../commonComponents/BackButton';
import { Table } from './Table';

export const TodayJobs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTodayJobs({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: 40,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 10,
          }}
        >
          <BackButton />
          <h1
            className="global_font f-dark"
            style={{
              marginLeft: 30,
              marginBottom: 50,
              marginTop: 0,
              textAlign: 'center',
              textTransform: 'capitalize',
            }}
          >
            Today&apos;s Shifts
          </h1>

          <Table />

        </div>
      </div>
    </div>
  );
};
