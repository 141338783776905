import React, { useState } from 'react';
import VerificationInputRVI from 'react-verification-input';

export const VerificationInput = ({ onChange, onComplete }) => {
  const [inputValue, setInputValue] = useState('');

  const handleOnChange = (e) => {
    setInputValue(e);
    onChange(e);
  };

  return (
    <VerificationInputRVI
      length={5}
      validChars="0-9"
      autoFocus
      placeholder=""
      classNames={{
        container: 'code-container',
        character: 'code-character',
        characterInactive: 'character--inactive',
        characterSelected: 'code-character--selected',
      }}
      onChange={handleOnChange}
      onComplete={onComplete}
      value={inputValue}
    />
  );
};
