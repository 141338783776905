import { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_JOBS_BY_STATUS_DATE_ACTION, getJobsByStatusOrDate } from '../../../../actions';
import { createLoadingSelector } from '../../../../api/selectors';

const loadingSelectorList = createLoadingSelector([
  GET_JOBS_BY_STATUS_DATE_ACTION,
]);

export const useJobList = ({ status }) => {
  const dispatch = useDispatch();
  const jobListScrollRef = createRef(null);
  const jobList = useSelector((state) => state.job.jobList);
  const jobListPagination = useSelector((state) => state.job.jobListPagination);
  const isLoadingList = useSelector((state) => loadingSelectorList(state));

  useEffect(() => {
    dispatch(getJobsByStatusOrDate({ status }));
  }, [status, dispatch]);

  const handleScrollJobList = () => {
    const jobListScroll = jobListScrollRef.current;

    const userScrollHeight = jobListScroll.scrollHeight - jobListScroll?.scrollTop;

    const clientHeight = jobListScroll?.clientHeight;

    if (Math.floor(userScrollHeight) <= clientHeight && !jobListPagination.isFinal) {
      dispatch(getJobsByStatusOrDate({ status, enablePagination: true }));
    }
  };

  return {
    jobListScrollRef,
    jobList,
    isLoadingList,
    handleScrollJobList,
  };
};
