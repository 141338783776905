import { motion } from 'framer-motion';
import arrow from '../../../../../images/arrow_right.svg';
import { Colors } from '../../../../../themes/colors';

const collapsableVariants = {
  open: {
    maxHeight: 2000,
    opacity: 1,
    marginTop: 30,
    paddingBottom: 40,
    overflow: 'visible',
  },
  closed: {
    maxHeight: 0,
    opacity: 0,
    overflow: 'hidden',
  },
};

const arrowVariants = {
  open: {
    rotate: -90,
  },
  closed: {
    rotate: 90,
  },
};

export const CollapsibleRow = ({
  TitleComponent,
  ValueComponent,
  icon,
  disabled,
  children,
  onToggle,
  isOpen,
  titlePosition = 'center',
  showArrow = true,
  customCardStyles,
  customImageStyles,
}) => (
  <div
    style={{
      border: `1.5px solid ${isOpen ? Colors.secondary_500 : Colors.neutral_100}`,
      borderRadius: 8,
      width: '60%',
      ...customCardStyles,
    }}
  >
    <button
      type="button"
      style={{
        all: 'unset',
        width: '100%',
        boxSizing: 'border-box',
        alignItems: 'center',
        cursor: disabled ? 'default' : 'pointer',
        padding: '20px 32px',
      }}
      onClick={(event) => {
        if (disabled) return;
        event.stopPropagation();
        onToggle();
      }}
    >
      <div
        className="row_container"
        style={{
          width: '100%',
          cursor: disabled ? 'default' : 'pointer',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', height: '100%', alignSelf: titlePosition }}>
            <img alt="icon" src={icon} style={{ width: 24, height: 24, marginRight: 12, ...customImageStyles }} />
            <TitleComponent />
          </div>

          <ValueComponent />
        </div>

        <div
          style={{
            paddingLeft: 16,
          }}
        >
          {showArrow && !disabled && (
            <motion.img
              initial="closed"
              animate={isOpen ? 'open' : 'closed'}
              transition={{ duration: 0.4 }}
              variants={arrowVariants}
              alt="arrow"
              src={arrow}
            />
          )}
        </div>
      </div>
    </button>

    <motion.div
      initial="closed"
      animate={isOpen ? 'open' : 'closed'}
      transition={{ duration: 0.5 }}
      style={{
        height: 'auto',
        overflow: 'hidden',
        backgroundColor: Colors.white,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      }}
      variants={collapsableVariants}
    >
      {children}
    </motion.div>
  </div>
);
