import { motion } from 'framer-motion';
import moment from 'moment';
import Checkbox from '../../../../commonComponents/Checkbox';
import { UserSubProfession } from '../../../../enums/UserProfession';
import history from '../../../../history';
import arrowRightIcon from '../../../../images/arrow_right.svg';
import { Colors } from '../../../../themes/colors';
import { getJobStatus } from '../../../Dashboard/ReceiptList/utils';
import { Date } from '../../../Schedule/components/JobCard/components/Date';

export const JobCard = ({ job, onSelect, isChecked }) => {
  const { color, label } = getJobStatus(job?.status);

  return (
    <>
      <button
        style={{
          all: 'unset',
          cursor: 'pointer',
          width: 822,
        }}
        type="button"
        onClick={() => history.push(`/dashboard/job/${job.id}`)}
      >
        <motion.div
          key={job.id}
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: Colors.white,
            borderRadius: 8,
            minHeight: 80,
            paddingLeft: 12,
            width: 822,
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px',
          }}
          whileHover={{
            boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
            transition: { duration: 0.3 },
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 12,
              marginRight: 12,
            }}>

            <button
              type="button"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onSelect(job.id)
              }}
              style={{
                all: 'unset',
                display: 'flex',
                width: 48,
                height: 48,
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Checkbox checked={isChecked} />
            </button>
          </div>
          <div
            style={{
              width: 60,
            }}
          >
            <Date
              backgroundColor={color}
              localDate={moment(job.date, 'ddd, MMM D, YYYY').format('YYYY-MM-DD')}
            />
          </div>
          <div
            style={{
              width: 200,
              marginLeft: 32,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <span style={styles.professionName}>
              {UserSubProfession.getName(job?.subProfession) ?? job?.professionName}
            </span>
          </div>

          <div
            style={{
              width: 160,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <span style={{ ...styles.datetimeText }}>{`${job.startTime} - ${job.endTime}`}</span>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <span style={{ ...styles.statusText, color }}>{label}</span>
          </div>
          <div style={{ width: 48, marginRight: 24, display: 'flex', justifyContent: 'center' }}>
            <img alt="arrow-right" src={arrowRightIcon} />
          </div>
        </motion.div>
      </button>
    </>
  );
};

const styles = {
  statusText: {
    fontFamily: 'Nunito',
    fontSize: 18,
    textTransform: 'uppercase',
  },
  datetimeText: {
    fontFamily: 'Nunito',
    fontSize: 16,
    textTransform: 'lowercase',
    color: Colors.neutral_600,
  },
  personName: {
    color: Colors.neutral_600,
    fontFamily: 'Nunito',
    fontSize: 18,
    fontWeight: '700',
  },
  professionName: {
    color: Colors.neutral_600,
    fontFamily: 'Nunito',
    fontSize: 16,
  },
};
