import React from 'react';
import { Colors } from '../../themes/colors';

const PlusIcon = ({ color = Colors.primary_500 }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 2V18M18 10L2 10" stroke={color} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default PlusIcon;
