import { RedirectButton } from '../../../../../commonComponents/Table/RedirectButton';
import { TableData } from '../../../../../commonComponents/Table/TableData';
import { UserSubProfession } from '../../../../../enums/UserProfession';
import { SendTo } from '../SendTo';

export const renderRow = (item, handleOnClick) => {
  const {
    offer_owner: offerOwner,
    local_date: localDate,
    local_start_time: localStartTime,
    local_end_time: localEndTime,
    subProfession,
    professionName,
    specialty,
    rate
  } = item || {};

  return (
    <>
      <TableData text={offerOwner.office_name} />
      <TableData
        text={
          <span>
            <strong>{localDate}</strong>
            <br />
            {`${localStartTime} - ${localEndTime}`}
          </span>
        }
      />
      <TableData
        text={
          <span>
            <strong>
              {UserSubProfession.getName(subProfession) ?? professionName}
            </strong>
            <br />
            {specialty?.split('_')?.[1] || 'General'}
          </span>
        }
      />
      <TableData text={`$${rate.toFixed(2)}`} bold />
      <SendTo item={item} />
      <RedirectButton onClick={() => handleOnClick(item)} />
    </>

  );

};
