import * as actions from '../actions';

const initialState = {
  message: {
    text: null,
    title: null,
    route: null,
    params: null,
    isSuccess: null,
    isError: null,
    popupProps: {},
    isCreateJob: null,
    jobData: null,
  },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SHOW_MESSAGE:
      const { payload } = action;

      const {
        message,
        title,
        route,
        params,
        isSuccess,
        isError,
        popupProps,
      } = payload;

      return {
        ...state,
        message: {
          text: message,
          title,
          route,
          params,
          isSuccess,
          isError,
          popupProps,
        },
      };
    case actions.SHOW_CREATE_JOB_MESSAGE:
      return {
        ...state,
        message: {
          route: action.payload.route,
          params: action.payload.params,
          jobData: action.payload.jobData,
        },
      };
    case actions.HIDE_MESSAGE:
    case actions.HIDE_CREATE_JOB_MESSAGE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
