import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CANCEL_JOB_ACTION,
  cancelJob
} from '../actions';
import { createLoadingSelector } from '../api/selectors';
import Button from '../commonComponents/Button';
import CustomSpinner from '../commonComponents/CustomSpinner';
import { Colors } from '../themes/colors';
import '../themes/confirm_hyg.scss';
import '../themes/global.scss';

export default ({ closePopup }) => {
  const loadingSelector = createLoadingSelector([CANCEL_JOB_ACTION]);
  const isLoading = useSelector((state) => loadingSelector(state));
  const dispatch = useDispatch();
  const job = useSelector((state) => state.job.job);

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        style={{
          backgroundColor: Colors.white,
          alignContent: 'center',
          justifyContent: 'center',
          maxWidth: 700,
          minWidth: 450,
          width: '30%',
          borderRadius: 25,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 50,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >

        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 20,
        }}
        >
          <p
            className="global_font f-dark"
            style={{
              textAlign: 'center', fontWeight: 'bold', fontSize: 20,
            }}
          >
            Cancel Job
          </p>
          <p
            className="global_font f-dark"
            style={{
              margin: 0,
              color: Colors.neutral_600,
              textAlign: 'center',
              marginLeft: 30,
              marginRight: 30,
            }}
          >
            Are you sure you want to cancel this shift on
            {' '}
            <strong>{job?.local_date}</strong>
            ?
            {' '}
            Please note: This shift will not be available to the community anymore.
          </p>
          <div style={{ width: 400, marginTop: 25 }}>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '10px 0px',
              }}
            >
              <p className="global_font bold_font" style={{ margin: 0, color: Colors.neutral_600 }}>
                Shift Charge:
              </p>
              <p className="global_font" style={{ margin: 0, color: Colors.neutral_600, fontSize: 18 }}>
                $
                {job.total.toFixed(2)}
              </p>
            </div>
            <div className="line_400" />
            {job.cancellation_fee !== 0
              ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '15px 0px',
                  }}
                >
                  <p className="global_font bold_font" style={{ margin: 0, color: Colors.error_500 }}>
                    Cancellation Fee:
                  </p>
                  <p className="global_font" style={{ margin: 0, color: Colors.neutral_600, fontSize: 18 }}>
                    - $
                    {job.cancellation_fee?.toFixed(2) || '0'}
                  </p>
                </div>
              )
              : <div />}
            <div className="line_400" />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '15px 0px',
              }}
            >
              <p className="global_font bold_font" style={{ margin: 0, color: Colors.neutral_600 }}>
                Total Refund:
              </p>
              <p className="global_font" style={{ margin: 0, color: Colors.neutral_600, fontSize: 18 }}>
                $
                {job.total_refund.toFixed(2)}
              </p>
            </div>
            <div className="line_400" />
            <p
              className="global_font f-dark"
              style={{
                margin: 0,
                marginTop: 20,
                marginBottom: 25,
                color: Colors.neutral_600,
                textAlign: 'center',
              }}
            >
              Please allow 5-7 business days for funds to post
              {'\n'}
              {' '}
              to your account.
            </p>
            {isLoading
              ? (
                <div style={{ marginTop: 25 }}>

                  <CustomSpinner isLoading={isLoading} />
                </div>
              ) : (
                <div style={{
                  marginTop: 25, marginBottom: 25, display: 'flex', gap: 20
                }}
                >
                  <Button
                    text="Go Back"
                    style={{
                      backgroundColor: Colors.secondary_500,
                    }}
                    onClick={() => closePopup()}
                  />

                  <Button
                    text="Cancel Job"
                    style={{
                      backgroundColor: Colors.primary_500,
                    }}
                    onClick={() => dispatch(cancelJob({ jobId: job.id, redirect: true }))}
                  />
                </div>
              )}

            <p
              className="global_font f-dark"
              style={{
                margin: 0,
                marginBottom: 25,
                color: Colors.neutral_600,
                textAlign: 'center',
              }}
            >
              For more details about our Cancellation Policy, please refer to our FAQs page.
            </p>

          </div>
        </div>
      </div>
    </div>
  );
};
